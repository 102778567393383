.selection-programms{
  .page-columns{
    width: 100%;
    box-sizing: border-box;
  }
  .page-columns > div{
    width: 100%;
    box-sizing: border-box;
  }

  .appartment-by-id__header-bottom-icons{
    display: flex;
    justify-content: flex-end;

    @media (max-width: $resolution-1000) {
      margin-bottom: 40px;
      justify-content: center;
    }

    & > div{
      width: 24px;
      height: 24px;

      @media (max-width: $resolution-1000) {
        margin-left: 0;
      }
    }

    & > a{
      width: 21px;
      height: 21px;
    }
  }
}

.selection-programms-form{
  padding: 40px 0 60px 2%;
  background: #f2f2f2;
  margin-bottom: 90px;

  @media (max-width: $resolution-1000) {
    padding: 40px;
    margin-bottom: 50px;
  }
}

.selection-programms-title{
  margin-bottom: 50px;
  font-family: "Travels";
  font-weight: 500;
  font-size: 30px;

  @media (max-width: $resolution-1000) {
    margin-bottom: 20px;
    font-size: 22px;
  }
}

.selection-programms-row{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @media (max-width: $resolution-1000) {
    display: block;
    margin-bottom: 40px;
  }

  .ui-select .MuiInputBase-root{
    width: 100%;
    max-width: 100%;
    height: 50px;
  }

  .filter-field{
    margin-bottom: 0;
  }

  & > div{
    width: 23%;
    margin-bottom: 40px;
    margin-right: 2%;

    @media (max-width: $resolution-1000) {
      margin-bottom: 20px;
      width: auto;
      margin-right: 0;
    }
  }

  & > div.double{
    width: 48%;
    margin-right: 2%;

    @media (max-width: $resolution-1000) {
      width: auto;
      margin-right: 0;
    }
  }

  &.last{
    justify-content: flex-start;
  }

  &.last > div{
    width: auto;
    margin-right: 5%;

    @media (max-width: $resolution-1000) {
     margin-right: 0;
    }
  }

  .filter-field__body{
    height: 50px;
    box-sizing: border-box;
    padding: 0;

    input{
      padding: 0 17px;
      height: 100%;
    }
  }

  .filter__checkbox-row{
    display: flex;

    @media (max-width: $resolution-1000) {
      flex-wrap: wrap;
    }
  }

  .ui-checkbox{
    margin-right: 27px;

    @media (max-width: $resolution-1000) {
      margin-right: 15px;
      margin-bottom: 15px;

      .ui-checkbox__rect{
        margin-right: 10px;
      }
    }
  }
}

.selection-programms-form-buttons{
  display: flex;
  align-items: center;

  @media (max-width: $resolution-1000) {
    flex-direction: column;
  }

  .btn{
    min-width: 220px;
    margin-right: 40px;

    @media (max-width: $resolution-1000) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}

.selection-programms-form-clear{
  cursor: pointer;
  font-family: Travels;
  font-weight: 500;
  font-size: 16px;
  position: relative;

  &:after{
    content: "";
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 1px;
    border-bottom: 1px dashed #a6a6a6;
    transition: $animation-speed opacity;
  }

  @media (min-width: $resolution-1000) {
    &:hover:after{
      opacity: 0;
    }
  }
}

.selection-programms-table{

  @media (min-width: $resolution-1000) {
    a:hover{
      text-decoration: none;
    }
  }

  @media (max-width: $resolution-1000) {
    overflow-x: auto;

    .selection-programms-table-in{
      padding: 0 40px;
      width: 1000px;
    }
  }

  .table{
    margin-top: 0;
    table-layout: auto;
    
    img{
      max-width: 150px;
    }

    @media (max-width: $resolution-1000) {

    }
  }

  .table td:nth-child(2),
  .table td:nth-child(5){
    text-align: left;
  }

  .table thead tr td {
    border-bottom: solid 1px #e6e6e6;
    line-height: 1.3;
    padding: 5px 10px;

    @media (max-width: $resolution-1600) {
      padding: 5px;
    }

    @media (max-width: $resolution-1000) {
      padding: 5px;
    }
  }

  .table tbody tr td {
    border-bottom: solid 1px #e6e6e6;
    line-height: 1.3;
    padding: 15px 10px;

    @media (max-width: $resolution-1600) {
      padding: 10px 5px;
    }

    @media (max-width: $resolution-1000) {
      padding: 5px;
      font-size: 14px;
    }
  }

  .table tbody tr:last-child td {
    border-bottom: none;
  }
}

.frame-selection-programms{
  @media (max-width: $resolution-1000) {
    padding: 0;
  }
}

.selection-programms-table-result{
  border-collapse: collapse;
  width: 100%;
  td{
    padding: 5px !important;
    font-size: 13px;
    border-right: 1px solid #e6e6e6;

    &:last-child{
      border-right: none !important;
    }
  }
}

.selection-programms-autocomplete{
  position: relative;

  .filter-field__body{
    position: relative;
  }

  svg{
    width: 16px;
    height: 9px;
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -3px;
    z-index: 1;
  }

  input{
    position: relative;
    z-index: 2;
  }
}