.page .agents {

  &__columns{
    justify-content: space-between;
	margin-top: 27px;
	@media (max-width: 1000px) {
		flex-direction: column-reverse;
	}
	&_reverse {
		flex-direction: row-reverse;
		@media (max-width: 1000px) {
			flex-direction: column-reverse;
			margin-top: 75px;
		}
	}
  }
  &__left1{
    padding-bottom: 113px;
	min-width: 50.5%;
    width: 50.5%;
    display: flex;
    flex-direction: column;
    @media (max-width: 1000px) {
      width: 100%; min-width: 100%; padding: 0;
    }
  }
  &__right1{
    padding-bottom: 162px;
    width: 43.5%;
    min-width: 43.5%;
    @media (max-width: 1000px) {
      width: 100%; min-width: 100%; padding: 0;
    }
  }
  &__img1{
	margin-left: 5.1vw;
    margin-right: -0.2vw;
    span{
      padding-top: 100%;
    }
    @media (max-width: 1000px) {
      width: 100%;
      max-width: 400px;
      margin: 0 auto 45px;
    }
    @media (max-width: 480px) {
      margin: 0 -22px 45px;
      width: calc(100% + 44px);
      max-width: initial;
      
    }
  }
  &__title{
    max-width: 590px;
    margin-bottom: 80px;
    font-family: "Travels";
    font-weight: 500;
    font-size: 48px;
    margin-top: 0;
    line-height: 0.85;
    @media(max-width: 1400px) {
      font-size: 28px;
    }
    @media(max-width: 1300px) {
      font-size: 24px;
    }
	@media (max-width: 1000px) {
		margin-bottom: 35px;
		margin-top: 45px;
	}
  }

  &__text1{
    font-size: 20px;
    line-height: 1.5;
    padding-left: 13.3vw;
	padding-right: 3vw;
    padding-bottom: 156px;
    box-sizing: border-box;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      top: 8px;
	  width: 5px;
      height: 122%;
      background: #ed1c24;
      left: 8.8vw;
    }
	a {
		text-decoration:none;
		border-bottom: solid 1px;
		color: #ed1c24;
		transition: border 0.3s;
		&:hover {
			border-bottom-color:transparent;
		}
	}
    @media (max-width: 1000px) {
      font-size: 16px;
      padding-left: 35px;
      padding-top: 45px;
	  padding-bottom: 15px;
      margin-bottom: 45px;
      &:before{
        top: 0; width: calc(100% + #{$padding_mobile});
        height: 4px;
        background: #ed1c24;
        left: 0;
      }
    }
  }


  &__left2{
    padding-bottom: 130px;
    min-width: 42.3%;
    width: 42.3%;
    @media (max-width: 1000px) {
      width: 100%; min-width: 100%; padding: 0;
    }
  }
  &__right2{
	padding-bottom: 130px;
    width: 57.7%;
    min-width: 57.7%;
    padding-left: 12.9vw;
    padding-right: 8.6vw;
    box-sizing: border-box;
    @media (max-width: 1000px) {
      width: 100%; min-width: 100%; padding: 0;
    }
  }
  &__img2{
	position:relative;
	margin: 81px 9vw 0 -6.5vw;
    span{
      padding-top: 100%;
    }
	&:before{
      content: '';
      position: absolute;
	  top: 51px;
	  width: 19.2vw;
	  height: 5px;
	  background: #ed1c24;
	  right: -9vw;
    }
    @media (max-width: 1000px) {
      margin: 0 auto 45px;
      max-width: 300px;
      position: relative;
      width: calc(100% - 15px);
    }
  }
  &__text2{
	position:relative;
    font-size: 20px;
    line-height: 1.5;
	&:before{
      content: '';
      position: absolute;
      top: 13px;
		width: 5.15vw;
		height: 5px;
		background: #ed1c24;
		left: -6.4vw;
    }
    @media (max-width: 1000px) {
      margin-bottom: 45px;
      font-size: 16px;
      p:first-child{
        margin-top: 0;
        margin-bottom: 45px;
      }
      p:last-child{
        padding-left: 35px;
      }
    }
  }


  &__left3{
    //padding-bottom: 110px;
    min-width: 56%;
    width: 56%;
    padding-top: 110px;
    @media (max-width: 1000px) {
      width: 100%; min-width: 100%; padding: 0;
    }
  }
  &__right3{
    //padding-bottom: 160px;
    padding-bottom: 50px;
    width: calc(44% + 100px);
    min-width: calc(44% + 100px);
    margin-left: -100px;
    box-sizing: border-box;
    @media (max-width: 1000px) {
      width: 100%; min-width: 100%; padding: 0; margin: 0;
    }
  }
  &__img3{
    span{
      padding-top: 95%;
    }
    @media (max-width: 1000px) {
      position: relative;
      margin-bottom: 75px;
      &:before{
        content: '';
        width: 55%;
        height: 4px; 
        background: #ed1c24;
        position: absolute;top: 0; right: 0;
      }
    }
  }
  &__text3{
    font-size: 20px;
    line-height: 1.4;
  }


  &__title2{
    font-size: 48px;
    margin-bottom: 50px;
    font-family: "Travels";
    font-weight: 500;
    @media (max-width: 1000px) {
      font-size: 24px;
    }
  }
  &__appartments{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px;
  }
  
}
