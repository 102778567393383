.favorites{
  .page-title{
    margin-bottom: 30px;
  }


  &__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    & > span{
      font-size: 48px;
      font-family: 'Travels';
      @media (max-width: 1000px) {
        font-size: 24px;
      }
      @media (max-width: 370px) {
        font-size: 21px;
      }
    }
  }

  .apartment-search__list{
    margin-bottom: 60px;
  }

  &__btn{
    margin: 0 auto 110px;
    display: flex;
    justify-content: center;
    @media (max-width: 1000px) {
      margin-bottom: 45px;
      flex-direction: column;
      align-items: center;
    }
    .btn{
      max-width: 300px;
      margin: 0 5px;
      width: 100%;
      @media (max-width: 1000px) {
        margin: 5px 0;
      }
    }
  }

  .apartment-search__item .favorites__checkbox{
    z-index: 10;
    position: absolute;
    bottom: 22px; left: 10px;
  }
  .checkbox-field--border{
    @media (max-width: 1000px) {
      font-size: 16px
    }
    @media (max-width: 370px) {
      font-size: 14px
    }
  }

  .inner-page-icons{
    @media (max-width: 1000px) {margin-bottom: 80px}
  }
}

.favorites-empty{
  font-family: 'Travels';
  font-weight: 500;
  text-align: center;
  font-size: 18px;
  margin: 50px 0 100px 0;

  @media(max-width: $resolution-1000) {
    font-size: 14px;
    padding-top: 20px;
    margin-bottom: 70px;
  }
}