a img{ border:none; }
*{ outline: none; }
input, button { -webkit-appearance: none; -moz-appearance: none; appearance: none; -webkit-box-shadow: none; box-shadow: none; }
b{ font-weight: 600 }

body{
  width:100%;
  margin: 0;
  font-size: 18px;
  line-height: 1.2;
  font-family: Georgia, Times, "Times New Roman", serif;
  box-sizing: border-box;

  @media (max-width: $resolution-1200) {
    font-size: 16px;
  }

  &.overflow{
    overflow: hidden;
  }
  &.wait {
    cursor: wait !important;
    * {
      cursor: wait !important;
      pointer-events: none !important;
    }
  }
}
a {color: inherit;}

.page{
  transition: opacity 500ms;

  &[indexpage="true"]{
    overflow: hidden;
  }

  @media (max-width: $resolution-1000) {
    padding-top: 70px;
  }
}
.page-loading{
  opacity: .5;
}

.border-link{
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
  span{
    border-bottom: 1px solid;
    transition: 500ms;
  }
  @media (min-width: $resolution-1001) {
    &:hover span{border-bottom-color: transparent !important;}
  }
}

.no-border-link{
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
  span {
    border-bottom: 1px solid transparent;
    transition: 500ms;
  }
  @media (min-width: $resolution-1001) {
    &:hover span{border-bottom-color: inherit;}
  }
}

.list{
  padding: 0; margin: 0; list-style: none;

  li{
    padding-left: 30px;
    position: relative;
    margin-bottom: 22px;
    &:before{
      width: 4px;
      height: 4px;
      background: #ed1c24;
      content: '';
      position: absolute;
      left: 0;
      top: 8px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      padding-left: 20px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      &:before{
        top: 12px;
      }
    }

  }
}
.scale-on-hover{
  transition: 600ms;
  @media (min-width: $resolution-1001) {
    &:hover{transform: scale(1.1);}
  }
}

// header
.flex{
  display: flex;
}
.header{
  height: $header_height;
  width: 100%;
  z-index: 2;
  position: relative;
  // padding-top: 70px;
  // align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}
.header__right{
  display: flex;
  justify-content: space-between;
  width: 33%;
  min-width: 33%;
  padding-left: 3.2% !important;
  box-sizing: border-box;
  padding-top: 80px;
  .compare-icon{margin-left: 10px !important; height: 23px;}
  @media (max-width: 1440px) {
    .lk-header{margin-right: 10px;}
    .fav-icon{margin-left: 5px !important}
    .compare-icon{margin-left: 5px !important; }
  }
}
.logo{
  position: absolute;
  left: $padding;
  top: 53px;
  animation: logo_animation 1.5s ease-in;
  z-index: 200;

  &.logo_nohome{
    animation: none;
  }

  @media (max-width: $resolution-1000) {
    animation: none;
  }

  a,
  span {
    width: 222px;
    height: 72px;
    display: block;
    position: relative;

    @media (max-width: $resolution-1200) {
      width: 160px;
    }

    &.active{}
    &:after, &:before{
      content: ''; position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(/static/img/logo.svg);
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: auto 100%;

      @media (max-width: $resolution-1200) {
        background-size: contain;
      }
    }
    &:before{opacity: .2; filter: grayscale(1);}
    &:after{
      animation: logo_bg_animation .8s ease-in-out;

      @media (max-width: $resolution-1000) {
        animation: none;
      }
    }
  }
}

//hack ie11
_:-ms-fullscreen, :root .logo {
  animation: none;
}

.scrolled-logo{
  position: absolute;
  top: 21px;
  left: $padding;
  display: none;
  width: 127px;
  height: 40px;
  background-position: 0 0; background-repeat: no-repeat;
  background-size: contain;

}
.scrolled {
    .scrolled-logo {
        background-image: url(/static/img/logo.svg);
    }
}
@keyframes logo_animation {
  0%, 50% {top: 50%; left: 50%; transform: translate(-50%, -50%) scale(1.4);}
}
@keyframes logo_bg_animation {
  from {width: 0;}
}
.phone{
  font-size: 22px; font-family: 'LabGrotesque'; line-height: 1;
  a{
    text-decoration: none;
    transition: 300ms;
    @media (min-width: $resolution-1001) {
      &:hover{opacity: .8}
    }
  }
}

.header__search{
  height: 49px;
  width: calc(77vw - 380px - 4.5vw);
  background: #fff;
  border-bottom: 3px solid #000;
  position: absolute;
  right: -15px;
  bottom: -15px;
  z-index: 1;
  box-sizing: border-box;
  padding-left: 10px;
  transition: $animation-speed opacity, $animation-speed visibility, $animation-speed transform;
  opacity: 0;
  visibility: hidden;
  transform-origin: 100% 100%;
  transform: scale(0.5);

  @media (max-width: 1480px) {
    width: calc(77vw - 310px - 3.5vw);
  }

  @media (max-width: 1200px) {
    width: calc(70.2vw - 230px);
  }

  @media (max-width: $resolution-1000) {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    position: relative;
    width: auto;
    left: auto;
    right: auto;
    top: 50%;
    margin-top: -15px;
    height: 30px;
    bottom: auto;
    border-bottom-width: 1px;
  }

  input{
    width: 90%;
    height: 100%;
    font-size: 18px;

    @media (max-width: $resolution-1000) {
      font-size: 16px;
    }
  }

  input::placeholder{
    color: #a6a6a6;
  }
}

.search-icon{
  font-size: 20px;
  height: 20px;
  width: 20px;
  position: relative;

  @media (max-width: $resolution-1000) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    background: #fff;
    z-index: 201;
    padding: 0 55px 0 40px;
    transform: translateX(calc(-100% + 50px));
    transition: $animation-speed transform;

    &.active{
      transform: translateX(0);
    }
  }
}

.search-icon:hover .header__search{
  @media (min-width: $resolution-1000) {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

.search-icon.active .header__search{
  @media (max-width: $resolution-1000) {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

.search-icon-in{
  position: relative;
  z-index: 2;
  cursor: pointer;
  font-size: 20px;
  height: 20px;
  width: 20px;

  @media (max-width: $resolution-1000) {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -10px;
  }
}

.file-icon{
  cursor: pointer;
  font-size: 23px;
  width: 23px;
  height: 18px;
  z-index: 10;
  position: relative;
  transition: $animation-speed color;

  &.red{
    color: $color-red;
  }
}
.fav-icon{
  margin-left: 15px;
  cursor: pointer;
  font-size: 18px;
  height: 18px;
  z-index: 11;
  text-decoration: none;

  svg{
    display: block;
    width: 18px;
    height: 18px;
  }
}

.compare-icon{
  width: 25px;
  position: relative;
  margin-left: 0 !important;
  z-index: 11;
  cursor: pointer;
  b, i{
    position: absolute;
    top: 0; left: 0;
  }
  i{opacity: 0; transition: 300ms;}
  &[data-active="true"] {
    b{opacity: 0;}
    i{opacity: 1}
  }
}
.header__right .compare-icon{
  transition: 300ms;
  b path{fill: #333;}
  .info-bubble{
    transition: 300ms; opacity: 0; pointer-events: none;
  }
  &:hover { transform: scale(1.1);}
  &:hover .info-bubble {opacity: 1;}
}

.fav-icon-empty{
  path{
    fill: #000;
  }
}

.menu-icon{
  cursor: pointer;
  font-size: 30px;
  height: 30px;
  position: relative;
  top: -4px;
  //display: block;
  //will-change: transform;
  transition: transform 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: transform 0.3s;
  }

  span {
    display: block;
    height: 2px;
    background-color: #000;
    border-radius: 2px;
    width: 30px;
    position: relative;
    transition: transform 0.3s;
    will-change: transform;

    &:before {
      content: '';
      display: block;
      height: 2px;
      background-color: #000;
      border-radius: 2px;
      width: 100%;
      margin-top: -10px;
    }
  }
  @media (min-width: 1001px) {
    &:hover {
      transform: scale(1.15);
    }
  }
  @media (max-width: 1000px) {
    top: 0;
    height: 27px;
    font-size: 27px;
  }
}

.page[indexpage="true"]{
 .header-menu__links{
    visibility: hidden;
  }
}

.scrolled{
  .page[indexpage="true"]{
    .header-menu__links{
       visibility: visible;
     }
   }

  .header{
    position: fixed; top: 0; left: 0;
    width: 100%;
    z-index: 100;
    background: #fff;
    animation: scrolled_header .5s linear;
    box-sizing: border-box;height: 80px;
  }
  .header__right{padding-top: 28px;}
  .page{
    padding-top: 90px;
  }
  .scrolled-logo{
    display: block;
  }
  .header-menu__links{padding-top: 30px; margin-left: 300px;}
}
@keyframes scrolled_header {
  0% {
    transform: translateY(-100%);
  }
}

.frame{
  padding-left: $padding;
  padding-right: $padding;
}

.slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div, .slick-slide > div > div, .slider-item{
  height: 100%;
}
.slick-arrow{
  padding: 0; margin: 0; font-size: 0; line-height: 0; background: none; border: none;
  cursor: pointer;
  position: absolute; top: 50%; transform: translateY(-50%);
  z-index: 1;
  left: 0;
  transition: 600ms;
  &:before{ font-size: 20px; }
  &:after{
    content: ''; position: absolute; top: -50px; bottom: -50px; left: -10px; right: -10px;
  }
  @media (min-width: $resolution-1000) {
    &:hover{
      transform: translateY(-50%) scale(1.1);
    }
  }
}
.slick-next{left: auto; right: 0}
.slider-item{
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}
.slick-dots{
  display: flex !important;
  justify-content: center;
  padding: 0; margin: 0; list-style: none;
  font-size: 0;
  li{
    margin: 0 3px;
    @media (max-width: 1000px) {
      margin: 0 5px;
    }
  }
  button{
    border: none;
    background: none;
    padding: 0; margin: 0;
    height: 7px; width: 7px; min-width: 7px;
    border-radius: 50%;
    background: #d9d9d9;
    font-size: 0;
    cursor: pointer;
    position: relative;
    transition: 600ms;
    &:before{
      content: '';
      position: absolute;
      top: -5px; bottom: -5px; left: -3px; right: -3px;
    }
    @media (min-width: $resolution-1000) {
      &:hover{background-color: #ed1c24}
    }
    @media (max-width: 1000px) {
      height: 10px; width: 10px;
    }
  }
  .slick-active button{background-color: #ed1c24}
}

.tab-nav{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
  }
}
.tab{
  position: relative;
  display: block;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Travels';
  font-weight: 500;
}
.tabs--links{
  margin-left: -25px;
  .tab{
    padding: 13px 25px;
    span{
      border-bottom: 1px solid;
      transition: 500ms;
      position: relative;
      &:after{
        content: '';
        position: absolute; bottom: -10px; left: 0;
        height: 5px;
        width: 0;
        background: #ed1c24;
        transition: 500ms;
      }
    }
    a {
      text-decoration: none;
    }
    @media (min-width: $resolution-1000) {
      &:hover span{border-bottom-color: transparent;}
    }
    @media (max-width: 1000px) {
      font-size: 17px !important;
      margin-bottom: 20px;
      &:last-child{margin-bottom: 0;}
    }

    &[data-active="true"]{
      span{
        border-color: transparent;
        &:after{
          width: calc(100% + 20px);
        }
      }
      &:before{
        color: #000;
        transform: rotate(180deg) translateY(55%);
      }
      // background: #ed1c24;
      // color: #fff;
    }
  }
  @media (max-width: 1000px) {
    margin-left: 0;
  }
}
.tabs--large-center{
  justify-content: center;
  align-items: flex-end;
  .tab{
    font-size: 21px;
    margin: 0 40px;
    text-align: center;
    line-height: 1;
    // line-height: 45px;
    span{
      border-bottom: 1px dashed #a6a6a6;
      transition: 300ms;
      display: inline-block;
    }
    @media (min-width: $resolution-1000) {
      &:hover span{border-bottom-color: transparent;}
    }

    &[data-active="true"]{
      span{border-color: transparent;}
      font-size: 50px;
      line-height: .7;
      // text-transform: uppercase;
    }

    &[data-active="true"]:first-child{
      font-size: 80px;
    }
    @media (max-width: 1000px) {
      margin: 0 0 25px !important;
      font-size: 21px !important;
      line-height: 1 !important;

      &[data-active="true"]{
        font-size: 25px !important;
      }
      &[data-active="true"]:first-child{
        font-size: 40px !important;
      }
    }
    @media (max-width: 370px) {
      font-size: 18px !important;
    }
  }
  @media (max-width: 1000px) {
    align-items: center;
  }
}

.tab--arrow{
  padding-right: 20px;
  &:before{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-40%);
    font-size: 8px;
    color: #ed1d25;
  }

  &.active:before{
    transform: translateY(-40%) rotate(-180deg);
  }

    @media (max-width: 1000px) {
      padding-right: 25px;
      &:before{font-size: 12px;}
    }
}


.title{
  font-size: 72px;
  font-family: 'Travels';
  font-weight: 500;
  margin-bottom: 60px;
  text-align: center;
  @media (max-width: 1000px) {
    font-size: 36px !important;
  }
  @media (max-width: 370px) {
    font-size: 28px !important;
  }
}
.inner-page{
  position: relative;
  @media (max-width: 1000px) {
    padding-top: 35px;
  }
}
.page-title{
  font-size: 72px;
  line-height: 1;
  font-family: 'Travels';
  margin-bottom: 72px;
  display: inline-block;
  padding-right: 20px;
  max-width: 100%;
  box-sizing: border-box;
  font-weight: 500;
  &.-small {
    font-size: 68px;
  }
  @media (max-width: 1000px) {
    font-size: 40px !important;
    padding: 0;
    display: block;
    margin-bottom: 30px;
  }
  @media (max-width: 370px) {
    font-size: 32px !important;
    overflow-wrap: break-word;
  }
}
.path{
  text-align: left;
  font-size: 14px;
  color: #808080;
  &:not(:first-child){
    padding-top: 10px;
  }
  a{color: inherit;}
  @media (max-width: 1000px) {
    display: none;
  }




}

.page-title {
  &.-pad_left {
    .path {
      padding-left: 75px;
      @media (max-width: 1300px) {
        padding-left: 0;
      }
    }
  }
}

.page-columns{
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  &>div{
    width: 50%;
    min-width: 50%;
    box-sizing: border-box;
    position: relative;
    @media (max-width: 1000px) {
      width: 100%;
      min-width: 100%;
    }
  }
  &__no-padding{
    margin-right: -$padding;
    width: calc(100% + #{$padding});
    @media (max-width: 1480px) {
      margin-right: -$padding_middle;
      width: calc(100% + #{$padding_middle});
    }
    @media (max-width: 1200px) {
      margin-right: -$padding_small;
      width: calc(100% + #{$padding_small});
    }
    @media (max-width: 1000px) {
      margin-right: 0;
      width: 100%;
    }
  }
  &__img{
    span{
      display: block;
      width: 100%;
      padding-top: 116%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }
  &__img2{
    position: absolute;
    bottom: -21vw;
    right: 0;
    width: 30vw;
    @media (max-width: 1000px) {
      position: relative;
      bottom: initial;
      width: 100%;
    }
    &:before{
      content: '';
      position: absolute;
      left: -5.9vw; bottom: 20px;
      width: 11vw; height: 5px;
      background: #ed1c24;
      @media (max-width: 1000px) {
        left: -$padding_mobile;
        width: 170px;
      }

    }
    span{
      display: block;
      width: 100%;
      padding-top: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }
}

.page-text{
  font-family: 'Travels';
  font-weight: 500;
  font-size: 24px;
  position: relative;
  line-height: 1.4;
  &--line:before{
    content: '';
    position: absolute;
    top: 18px;
    left: 0;
    width: 5.1vw;
    height: 5px;
    background: #ed1c24;
    @media (max-width: 1000px) {
      top: 12px;
      width: 40px;
    }
    @media (max-width: 370px) {
      top: 12px;
      width: 30px;
    }
  }
  @media (max-width: $resolution-1400) {
    font-size: 18px;
  }
}

.page-menu{
    border: 1px solid #e6e6e6;
  &__item{
    display: block;
    margin-bottom: -1px;
    padding: 22px 50px;
    font-size: 20px;
    @media (min-width: $resolution-1000) {
      &:hover span{border-color: #000;}
    }
  }
  @media (max-width: $resolution-1300) {
     &__item{font-size: 16px;}
  }
}

.fixed-menu{
  position: relative;
  z-index: 90;
  height: 70px;
  &>div{
    position: absolute;
    top: 0; left: 0;
    display: flex;
    background: #f2f2f2;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
  }
  &__item{
    display: block;
    padding: 23px 26px;
    font-family: 'Travels';
    font-weight: 500;
    white-space: nowrap;
  }
  .menu-icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $padding;
    display: none;
  }

  &.fixed > div{
    position: fixed;
    top: 0; left: 0;
    width: 100%;
    animation: fixedMenu .5s ease-in-out;
    padding-left: $padding;
    padding-right: $padding;
    .fixed-menu__item:first-child{
      padding-left: 0;
    }
    .menu-icon{display: block;}
  }
  @media(max-width: $resolution-1440) {
    &>div {
      padding-left: 20px;
      padding-right: 20px;
    }
    &__item{
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

@keyframes fixedMenu {
  from {
    transform: translateY(-100%);
  }

}

.like-icon{
  padding-left: 30px;
  position: relative;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &:before{
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-62%);
    color: #d9d9d9;
    font-size: 18px;
  }

}



.social-item{
  text-decoration: none;
  font-size: 16px;
  color: #545454;
  padding-top: 80px;
  position: relative;
  min-width: 45px;
  display: inline-block;
  transition: 300ms transform;
  // margin: 0 40px;
  &:before{
    height: 47px;
    line-height: 47px;
    font-size: 45px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &--tg:before{font-size: 43px;}
  &--vk:before{font-size: 32px;}
  &--youtube:before{font-size: 32px;}
  &--ok:before{font-size: 43px;}
  &--fb:before{font-size: 47px;}
  @media (min-width: 1000px) {
    &:hover{
      transform: scale(1.1);
    }
  }
  @media (max-width: 1000px) {
    font-size: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 0;
    &:before{
      height: auto;
      line-height: 1;
      font-size: 25px;
      color: #545454;
      position: static;
      top: 0;
      left: 0;
      transform: none;
    }
    &--tg:before{font-size: 23px;}
    &--vk:before{font-size: 25px;}
    &--youtube:before{font-size: 20px;}
    &--ok:before{font-size: 25px;}
    &--fb:before{font-size: 28px;}
  }
}

.footer{
  position: relative;
  z-index: 3;
  background: #fff;
  &__row{
    position: relative;
    padding-top: 90px;
    display: flex;
    justify-content: space-between;
    &:first-child{
      border-top: 1px solid #e6e6e6;
    }
    &:last-child{
      padding-bottom: 65px;
      color: #4c4c4c;
      font-size: 16px;
      font-family: 'Travels';
    }
    @media (max-width: 1000px) {
      padding-top: 50px;
      flex-direction: column;
      align-items: center;
      font-size: 14px;
      &:last-child{
        padding-bottom: 60px;
        font-size: 14px;
        text-align: center;
      }
      &:first-child{
        padding-top: 75px;
      }
    }
  }

  &__police{
    margin-top: 8px;
    display: inline-block;
    @media (max-width: 1000px) {
      margin-top: 10px;
      display: block;
      text-align: center;
      line-height: 1.5;
      margin-bottom: 30px;
    }
  }

  &__links{
    width: 50%;
    padding-top: 6px;
    &>*{
      cursor: pointer;
      display: block;
      font-family: 'Travels';
      font-size: 24px;
      text-decoration: none;
      line-height: 1;
      margin-bottom: 5px;
    }
    @media (max-width: 1000px) {
      width: 230px;
    &>*{
      font-size: 16px !important;
      margin-bottom: 15px;
    }
    }
  }
  &__left{
    white-space: nowrap;
    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
  .phone{
    color: #ed1c24;
    font-size: 36px;
    margin-bottom: 15px;
    @media (max-width: 1000px) {
      width: 230px;
      margin-bottom: 25px;
      font-size: 26px !important;
      font-weight: 300;
    }
  }
  &__social{
    padding-top: 90px;
    display: flex;
    justify-content: flex-end;
  }
  .social-item{
    font-size: 0;
    padding-top: 0;
    height: 24px;
    &:before{
      height: 24px;
      line-height: 24px;
      font-size: 22px;
    }
    &--tg:before{font-size: 21px;}
    &--vk:before{font-size: 22px;}
    &--youtube:before{font-size: 22px;}
    &--ok:before{font-size: 22px;}
    &--fb:before{font-size: 24px;}
  }
  &__mobile-app{
    display: flex;
    &__qr{
      width: 124px !important;
        height: auto !important;
      min-width: 124px;
    }
    &__links{
      width: 152px;
      padding-left: 23px;
        &.animate {
            opacity: 1;
        }
      a{
        display: block;
        transition: 600ms;
        margin-bottom: 10px;
        margin-top: 5px;
        @media(min-width: $resolution-1000) {
          &:hover{
            transform: scale(1.1);

          }
        }
      }
    }
    img{width: 100% !important; height: auto !important;}

  }
}

.footer-menu{
  &>div{
    padding-right: 10px;
    &:last-child{
      padding-right: 0
    }
    @media (max-width: 1000px) {
      width: 222px;
      padding-right: 0;
    }
  }
  &__title{
    font-family: 'Travels';
    font-weight: 600;
    margin-bottom: 30px;
    &:before{
      display: none;
      position: absolute;
      top: 50%; right: 0; transform: translateY(-50%);
      color: #ed1c24;
    }
    &[data-active="true"]:before{transform: rotate(180deg) translateY(50%); color: #000;}
    @media (max-width: 1000px) {
      padding-right: 25px;
      box-sizing: border-box;
      position: relative;
      &:before{
        display: block;
      }
    }
  }
  &__item{
    margin-bottom: 8px;
    font-size: 16px;
    @media (max-width: 1000px) {
      &:last-child{
        margin-bottom: 30px;
      }
    }
  }
}

.header-menu__links{
  width: 100%;
  box-sizing: border-box;
  margin-left: 380px;
  padding-top: 80px;
  justify-content: space-between;
  display: flex;
  align-items: flex-start;
  a{
    font-size: 16px;
    font-family: 'Travels';
    text-decoration: none;
    position: relative;
    font-weight: 500;
    white-space: nowrap;

    &:before{
      font-size: 5px;
      position: absolute;
      top: 50%; right: 0; transform: translateY(-50%);
    }
    &:after{
      content: '';
      position: absolute;
      bottom: -10px; left: 0;
      height: 3px; width: 0;
      background: #ed1c24;
      transition: 500ms
    }

    &.ico-7{padding-right: 16px;}
    &.active{
      font-weight: 600;
    }

    &.active:after{
      width: 100%;
    }

    @media(max-width: $resolution-1600) {
      font-size: 14px;
    }
    @media(max-width: $resolution-1500) {
      font-size: 13px;
    }
    @media(min-width: $resolution-1000) {
      &:hover:after{
        width: 100%;
      }
    }
  }
  @media(max-width: $resolution-1480){
    margin-left: 310px;
  }
  @media(max-width: $resolution-1400){
    padding-top: 65px;
    padding-right: 30px;
  }
  @media(max-width: $resolution-1200) {
    margin-left: 270px;
    padding-top: 50px;
    padding-bottom: 20px;
    justify-content: center;
    a{margin-right: 10px; margin-left: 10px;}
  }
}



.inner-page-icons{
  position: absolute;
  top: 10px; right: $padding;
  display: flex;
  align-items: center;
  & > div{
    margin-left: 12px;
  }

  @media (max-width: 1480px) {
    right: $padding_middle;
  }
  @media (max-width: 1200px) {
    right: $padding_small;
  }
  @media (max-width: 1000px) {
    position: static;
    justify-content: center;
  }
}

.share{
  color: #c3c3c3; font-size: 20px; cursor: pointer; position: relative;
}
.print{
  color: #c3c3c3; font-size: 24px; cursor: pointer; position: relative;
}
.letter{
  color: #c3c3c3; font-size: 20px; cursor: pointer; position: relative;
}
.info-bubble{
  position: absolute;
  top: 50%; right: 100%;
  transform: translateY(-50%);
  padding: 15px 20px;
  background: #fff;
  font-family: 'Travels';
  font-size: 12px;
  color: #000;
  box-shadow: 0 10px 30px rgba(0,0,0,.15);
  white-space: nowrap;
  margin-right: 5px;
  animation: info_bubble .3s ease-in-out;
  pointer-events: none;
  font-weight: 400;
  z-index: 10;
  &--right{
    left: 100%; right: auto;
    margin-right: 0; margin-left: 5px;
  }
}

@keyframes info_bubble {
  from {
    opacity: 0;
    // transform:  scale(0) translateY(-50%);
  }
}


@media (max-width: $resolution-1400){
  body{font-size: 17px;}
  .header{height: $header_height_smalll;}
  .header__right{padding-top: 60px;}
  .header--inner .header__right{padding-left: 0 !important; width: 29.8% !important; min-width: 29.8% !important}
  .logo{top: 33px;}
  .header__links{font-size: 15px;}
  .phone{font-size: 21px;}
  .title{font-size: 60px; margin-bottom: 50px;}

  .footer-menu{
    &__title{
      font-size: 16px;
      margin-bottom: 20px;
    }
    &__item{
      font-size: 15px;
    }
  }
}
@media (max-width: $resolution-1480) {
  .tabs--links .tab{padding-left: 20px; padding-right: 20px;}
  .frame{
    padding-left: $padding_middle;
    padding-right: $padding_middle;
  }
  .logo{left: $padding_middle;}

  .fixed-menu{
    &.fixed > div{
      padding-left: $padding_middle;
      padding-right: $padding_middle;
    }
    .menu-icon{
      right: $padding_middle;
    }
  }



  .page-title{
    font-size: 62px;
    &.-small {
      font-size: 58px;
    }
  }
}
@media (max-width: $resolution-1300) {
  .tabs--large-center .tab{
    font-size: 17px;
    margin-left: 20px;
    margin-right: 20px;
    &[data-active=true]{font-size: 28px;}
    &[data-active=true]:first-child{font-size: 50px;}
  }
  .tabs--links .tab{font-size: 14px;}
  .footer {
    .phone{
      font-size: 30px;
    }
    &__links > *{
      font-size: 22px;
    }
    &__mobile-app{
      display: flex;
      &__qr{
        width: 94px !important;
          height: auto !important;
        min-width: 94px;
      }
      &__links{
        width: 122px;
        padding-left: 10px;
        a{
          margin-bottom: 3px;
          margin-top: 3px;
        }
      }

    }
  }
  .header__right{
    padding-left: 2% !important;
  }

  .footer-menu{
    &__title{
      font-size: 15px;
      margin-bottom: 20px;
    }
    &__item{
      font-size: 14px;
    }
  }
  .page-title{
    font-size: 52px;
    &.-small {
      font-size: 48px;
    }
  }
}
@media (max-width: $resolution-1200) {
  .fixed-menu{
    &.fixed > div{
      padding-left: $padding_small;
      padding-right: $padding_small;
    }
    .menu-icon{
      right: $padding_small;
    }
  }
  .logo{left: $padding_small;}
  .frame{
    padding-left: $padding_small;
    padding-right: $padding_small;
  }
  .title{font-size: 40px;}
  .tabs--links .tab{ font-size: 13px;}
  .phone{font-size: 17px;}
  .page-title{
    font-size: 45px;
    &.-small {
      font-size: 41px;
    }
  }
}
@media (max-width: $resolution-1100) {
  .tabs--links .tab{ padding-left: 12px;}

  .footer-menu{
    &__title{
      font-size: 14px;
    }
    &__item{
      font-size: 13px;
    }
  }
}


.header-mobile{
  display: flex;
  height: 70px;
  background: #fff;
  justify-content: flex-end;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
  transition: $animation-speed transform;

  &.hide{
    transform: translateY(-100%);
  }
}

.popups{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9);
  z-index: 10000;

  .slick-arrow{
    margin-top: -35px;

    @media (max-width: $resolution-1000) {
      width: 15px;
      margin-top: -7px;

      svg{
        width: 100%;
        height: auto;
      }
    }
  }

  .slick-arrow:after,
  .slick-arrow:before{
    display: none;
  }

  .slick-prev{
    left: 40px;

    @media (max-width: $resolution-1000) {
      left: 20px;
    }
  }

  .slick-next{
    right: 40px;

    @media (max-width: $resolution-1000) {
      right: 20px;
    }
  }

  .slick-arrow path{
    fill: #000;
  }
}

.popups__in{
  position: relative;
}

.popups__close{
  cursor: pointer;
  position: absolute;
  right: -55px;
  top: -55px;
  width: 38px;
  height: 38px;
  transition: $animation-speed transform;
  z-index: 2;

  @media (max-width: $resolution-1000) {
    width: 30px;
    height: 30px;
    top: 20px;
    right: 20px;
  }

  @media (min-width: $resolution-1000){
    &:hover{
      transform: scale(0.9);
    }
  }

  svg{
    width: 100%;
    height: 100%;
  }
}

.popups__slider{
  width: 80vw;
  height: 80vh;

  video{
    width: 100%;
    height: 100%;
  }

  iframe{
    width: 100%;
    height: 100%;
    border: none;
  }

  @media (max-width: $resolution-1000) {
    width: 100vw;
    height: 100vh;
  }
}

.popups__slider-counter{
  height: 60px;
  left: 0;
  right: 0;
  z-index: 3;
  bottom: 70px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #fff;

  @media (max-width: $resolution-1000) {
    bottom: 30px;
  }
}

.popups__slide{
  width: 80vw;
  height: calc(80vh - 70px);
  background-position: 50% 50%;
  background-size: contain;
  background-color: #f2f2f2;
  background-repeat: no-repeat;

  @media (max-width: $resolution-1000) {
    width: 100vw;
    height: 100vh;
  }
}

.popups__label{
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.popups__ok{
  width: 80vw;
  height: 80vh;
  background: #f2f2f2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $resolution-1000) {
    width: 100vw;
    height: 100vh;
  }

  .popups__close{
    right: 30px;
    top: 30px;

    @media (max-width: $resolution-1000) {
      top: 20px;
      right: 20px;
    }
  }
}

.popups__ok-in{
  padding-left: 80px;
  border-left: 5px solid $color-red;
  font-family: "Travels";
  font-weight: 500;

  @media (max-width: $resolution-1000) {
    border: none;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .btn{
    display: inline-block;
    padding-left: 110px;
    padding-right: 110px;
    font-size: 16px;
  }
}

.popups__ok-title{
  color: $color-red;
  font-size: 36px;
  margin-bottom: 45px;

  @media (max-width: $resolution-1400) {
    font-size: 34px;
    margin-bottom: 40px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 32px;
    margin-bottom: 35px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 26px;
    margin-bottom: 25px;
  }
}

.popups__ok-text{
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 55px;
  padding-right: 10%;

  a{
    text-decoration: underline;
  }

  @media (min-width: $resolution-1000) {
    a:hover{
      text-decoration: none;
    }
  }

  @media (max-width: $resolution-1400) {
    font-size: 24px;
    margin-bottom: 50px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 22px;
    margin-bottom: 45px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 20px;
    margin-bottom: 35px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 18px;
    margin-bottom: 25px;
    padding-right: 0;
  }
}

@media(max-width: 1000px) {
  .frame{
    padding-left: $padding_mobile;
    padding-right: $padding_mobile;
  }
  .logo {
    top: 7px;
    left: 50%;
    margin-left: -84px;
    a{
      width: 168px; height: 54px;
    }
  }
  @keyframes logo_animation {
    0%, 50% {top: 50%; left: 50%; transform: translate(0, -50%) scale(1.4);}
  }
}

.show-g-menu{
  @media (max-width: $resolution-1000) {
    overflow: hidden;
  }
}

.g-menu{
  position: fixed;
  right: 0;
  top: 0;
  width: 33.3%;
  background: #fff;
  padding: 80px 4.76%;
  box-sizing: border-box;
  z-index: 20000;
  transition: 600ms transform, 600ms opacity, 600ms visibility, 600ms padding;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-30px);


  @media (max-width: $resolution-1400) {
    padding: 70px 4.76%;
  }

  @media (max-width: $resolution-1300) {
    padding: 60px 4.76%;
  }

  @media (max-width: $resolution-1200) {
    padding: 50px 4.76%;
  }

  @media (max-width: $resolution-1000) {
    width: 100%;
    //padding: 27px 15px;
    transform: translateX(100%);
    height: 100%;
    overflow: auto;
    padding: 27px 15px 60px 15px;
  }

  &.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0) translateX(0);
  }
}

.scrolled .g-menu{
  padding-top: 28px;
}

.g-menu__items{
  font-family: 'Travels';
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  margin-bottom: 50px;
  .sub {
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    margin-bottom: 30px;
    a {
      margin-bottom: 15px;
      &:after {
        background-color: #000;
        top: 3px;
      }
    }
  }
  @media (max-width: $resolution-1400) {
    font-size: 17px;
    margin-bottom: 40px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 16px;
    margin-bottom: 35px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 15px;
    margin-bottom: 25px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 18px;
    margin-bottom: 50px;
  }

  a{
    display: block;
    text-decoration: none;
    margin-bottom: 23px;
    position: relative;
    padding-left: 30px;

    @media (max-width: $resolution-1400) {
      margin-bottom: 20px;
    }

    @media (max-width: $resolution-1300) {
      margin-bottom: 20px;
    }

    @media (max-width: $resolution-1200) {
      margin-bottom: 20px;
    }

    @media (max-width: $resolution-1000) {
      padding-left: 20px;
      margin-bottom: 23px;
    }

    &:after{
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      background: $color-red;
      position: absolute;
      left: 0;
      top: 6px;
    }

    @media(min-width: $resolution-1000) {
      &:hover span:after,
      &:hover span:before{
        transform: scaleX(1);
      }
    }
  }

  span{
    position: relative;

    &:after{
      content: '';
      display: block;
      left: 0;
      bottom: 0;
      right: 0;
      height: 1px;
      background: #000;
      position: absolute;
      transition: $animation-speed transform;
      transform-origin: 0 100%;
      transform: scaleX(0);
    }
  }

  .line span:before{
    content: '';
    display: block;
    left: 0;
    bottom: -3px;
    right: 0;
    height: 3px;
    background: $color-red;
    position: absolute;
    transition: $animation-speed transform;
    transform-origin: 0 100%;
    transform: scaleX(1.3);
  }

  .line span:after{
    display: none;
  }
}

.g-menu__close{
  width: 42px;
  height: 42px;
  position: absolute;
  z-index: 3;
  right: 13.9%;
  top: 80px;
  cursor: pointer;
  padding: 10px;
  margin: -10px 0 0 -10px;
  box-sizing: border-box;
  transition: 600ms top;

  @media (max-width: $resolution-1400) {
    top: 70px;
  }

  @media (max-width: $resolution-1300) {
    top: 60px;
  }

  @media (max-width: $resolution-1200) {
    top: 50px;
  }

  @media (max-width: $resolution-1000) {
    right: 7px;
    top: 24px;
  }

  @media(min-width: $resolution-1000) {
    &:hover svg{
      transform: scale(0.9);
    }
  }

  svg{
    width: 100%;
    height: 100%;
    transition: $animation-speed transform;
  }
}

.scrolled .g-menu__close{
  top: 28px;
}

.g-menu__lnk{
  display: flex;
  align-items: center;
  font-family: 'Travels';
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 20px;
  text-decoration: none;

  &:last-child{
    margin-bottom: 0;
  }

  @media(min-width: $resolution-1000) {
    &:hover path{
      fill: #000;
    }
  }
}

.g-menu__lnk-icon{
  display: flex;
  width: 19px;
  height: 20px;
  margin-right: 18px;

  svg{
    width: 100%;
    height: 100%;

    path{
      transition: $animation-speed fill;
    }
  }
}

.sharing-icons{
  width: 18px;
  height: 20px;
  position: relative;
  z-index: 4;

  svg{
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
  }

  &:hover .sharing-icons__in{
    opacity: 1;
    visibility: visible;
    transform: scale(1);
  }
}

.sharing-icons__in{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute !important;
  box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
  width: 190px;
  height: 50px;
  background: #fff;
  right: 20px;
  left: auto;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.5);
  transition: $animation-speed opacity, $animation-speed visibility, $animation-speed transform;
  bottom: 50%;
  margin-bottom: -25px !important;

  & > div{
    margin: 0 5px !important;
    transition: $animation-speed opacity;
    cursor: pointer;

    @media(min-width: $resolution-1000) {
      &:hover{
        opacity: 0.3;
      }
    }
  }

  & > div[data-id="1"]{
    width: 20px;
    height: 12px;
  }

  & > div[data-id="2"]{
    width: 10px;
    height: 20px;
  }

  & > div[data-id="3"]{
    width: 20px;
    height: 18px;
  }

  & > div[data-id="4"]{
    width: 20px;
    height: 20px;
  }
  & > div[data-id="5"]{
    width: 12px;
    height: 20px;
  }

  & > div[data-id="6"]{
    width: 20px;
    height: 20px;
  }

  & > div[data-id="7"]{
    width: 20px;
    height: 20px;
  }

  & > div[data-id="8"]{
    width: 20px;
    height: 20px;
  }
}
.fav_inline {
  .add-favorite {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
  }
}
.add-favorite{
  width: 28px;
  height: 28px;
  cursor: pointer;
  margin: 0 !important;
  position: relative;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: $resolution-1000) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  svg{
    width: calc(100% - 10px);
    height: calc(100% - 10px);
  }

  i{
    position: absolute;
    //display: block;
    z-index: 2;
    opacity: 0;
    transition: $animation-speed opacity;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: $resolution-1000) {
      justify-content: flex-start;
    }
  }

  b{
    position: absolute;
    z-index: 3;
    //display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
      @media(max-width: $resolution-1000) {
          justify-content: flex-start;
      }
  }

  &.active-anim b{
    visibility: visible;
  }

  &.active i{
    opacity: 1;
  }
}

.popups-photos{
  @media(max-width: $resolution-1000) {
    display: block;
    bottom: auto;
    right: auto;
    height: 100%;
    width: 100%;

    .popups__in,.popups__slider,.popups__slide{
      height: 100%;
    }
  }
}

.popup-auth{
  .call-request{
    padding: 0;
    background: none;
  }
}

.lk-header{
  width: 19px;
  height: 20px;
  position: relative;
  margin-right: 18px;
  cursor: pointer;

  path{
    fill: #000;
  }

  &.active path{
    fill: $color-red;
  }

  svg{
    width: 19px;
    height: 20px;
  }
}

.g-menu__lnk-lk{
  display: flex;

  path{
    fill: #c4c4c4;
  }

  &.active path{
    fill: #ed1c24;
  }
}

.input-search-word{
  background: #fff;
  height: 60px;
  position: relative;

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot{
    padding-right: 0;
  }

  .MuiAutocomplete-clearIndicator{
    margin-right: 20px;
  }
  .MuiIconButton-label {
    svg {
      display: none;
    }
  }

  .MuiAutocomplete-root,
  .MuiFormControl-root,
  .MuiInputBase-root{
    height: 100%;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after{
    display: none;
  }

  input{
    padding: 0 17px !important;
    font-family: Georgia, Times, "Times New Roman", serif !important;
    font-size: 18px;
    font-weight: 500;
    @media (max-width: 1000px) {
      font-size: 16px;
    }

    &::placeholder{
      color: #a6a6a6;
      opacity: 1;
    }
  }

  .MuiAutocomplete-endAdornment{
    right: 17px;
  }
}

.field-error{
  font-family: "Travels";
  font-weight: 500;
  font-size: 14px;
  color: $color-red;
  margin-bottom: 20px;
}

.lds-ring-wrap{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-ring {
  display: block;
  position: relative;
  width: 46px;
  height: 46px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 38px;
  height: 38px;
  margin: 4px;
  border: 4px solid $color-red;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $color-red transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popups-agree-buttons{
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: $resolution-1000) {
    display: block;
    .btn{
      margin-top: 10px;
      display: block;
      width: auto;
    }
  }
}

.popups__ok-agree{
  width: auto;
  height: auto;
  padding: 80px;
  max-width: 600px;

  @media(max-width: $resolution-1000) {
    max-width: none;
    padding: 0;
  }
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}

.header-menu-select{
  position: relative;
  top: -3px;

  a{
    text-decoration: none;
  }

  i{
    font-style: normal;
  }

  @media(max-width: $resolution-1600) {
    top: -5px;
  }
  @media(max-width: $resolution-1500) {
    top: -5px;
  }

  .MuiInput-root{
    line-height: 1;
    &:after, &:before{
      display: none;
    }

    svg{
      width: 12px;
      height: 7px;
      position: absolute;
      right: 0;
      top: 4px;
    }
  }

  .MuiSelect-root{
    padding: 0 16px 0 0 !important;
    background: none !important;
    font-family: "Travels";
    font-weight: 500;
    font-size: 16px;
    z-index: 3;
    position: relative;
    width: 130px;
    white-space: pre-wrap;

    @media(max-width: $resolution-1600) {
      font-size: 14px;
      width: 115px;
    }
    @media(max-width: $resolution-1500) {
      font-size: 13px;
      width: 105px;
    }
  }
}

.header-menu-select-menu.MuiMenu-paper{
  box-shadow: 0px 0px 20px 0px rgba(34, 60, 80, 0.10) !important;
  background: #fff;
}

.header-menu-select-menu.MuiMenu-paper ul{
  border: none;
  padding: 30px;

  a{
    text-decoration: none;
  }

  li{
    padding: 0;
    margin-bottom: 20px;
    background: none !important;
    font-family: "Travels" !important;
    font-weight: 500;
    font-size: 16px;
    position: relative;

    i{
      font-style: normal;
      position: relative;

      &:after{
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background: #c3c3c3;
        bottom: 0;
        left: 0;
        right: 0;
        transition: $animation-speed transform, $animation-speed opacity;
        opacity: 0;
        transform: scaleX(0);
        transform-origin: 0 50%;
      }
    }

    @media(max-width: $resolution-1600) {
      font-size: 14px;
    }
    @media(max-width: $resolution-1500) {
      font-size: 13px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    &.Mui-selected i:after{
      transform: scaleX(1);
      opacity: 1;
    }
  }
}

[class*="-placemark-overlay"] * {
  background-size: 100% 100% !important;
}

.hidden-footer{
  display: none;
}

.MuiAutocomplete-noOptions{
  display: none !important;
}
.icons-flex{
  display: flex;
}


.cookie-info{
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255,255,255,.9);
  font-size: 16px;
  font-weight: 500;
  z-index: 100;
  font-family: 'Travels';
  padding: 26px 38px 26px 30px;
  box-sizing: border-box;
  width: 683px;
  max-width: calc(100% - 40px);
  animation: fadeIn 1s linear;
  a{color: #ed1c24; white-space: nowrap}
  @media (max-width: 1000px) {
    bottom: 20px;
    font-size: 12px;
    padding: 30px;
  }
}
.cookie-info__close{
  position: absolute;
  top: 14px; right:14px;
  cursor: pointer;

  svg{width: 14px; height: 14px;}
}


.rc-slider-handle span {
  display: none;
}

.no_filter_block {
  background: #fff;
  padding: 50px 0 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .appartment-by-id__header-button {
    margin-left: 0;
  }
}
.no_filter_text {
  font-size: 18px;
  line-height: 1.2;
  padding-right: 50px;
  font-family: 'Travels';
  font-weight: 500;

}

@media (max-width: 1000px) {
  .no_filter_block {
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    padding: 30px 20px 30px 20px;

    .appartment-by-id__header-button {
      display: inline-flex;
      max-width: 210px;
      margin: auto !important;
    }
  }

  .no_filter_text {
    margin-bottom: 20px;
    padding: 0;
  }
}


@media (min-width: 1000px) {
  .header--inner {
    height: 90px;
    .header-menu__links{padding-top: 30px;}



    .header__right{padding-top: 28px;}
    &~.logo{
      top: 13px;
      a, span {
        width: 155px;
        height: 50px;
      }
    }
    .header-menu__links{padding-top: 30px;}
  }
}

.news-feed-tales {
    &.animate {
        opacity: 1;
    }
}

.-no_vis_link {
  opacity: 0;
  font-size: 0;
  padding: 0;
  margin: 0;
  pointer-events: none;
}


.footer__offert {
  color: #808080;
  font-family: "Travels";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  max-width: 709px;
  width: 42%;

  @media (max-width: 1000px) {
   width: auto;
    text-align: left;
    order: 10;
    margin-top: 30px;
    font-size: 12px;
  }
}
