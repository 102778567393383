
.compare__table {
  display: flex;
  position: relative;

  .compare__table__head .compare__row{
    position: relative;
    &:after{
      content: '';
      position: absolute;
      top: 0; left: 0; bottom: 0;
      width: calc(100vw - #{$padding} - #{$padding});
      border: 1px solid;
      z-index: -1;
      opacity: 0;
      transition: 300ms;
      @media (max-width: 1480px) {
        width: calc(100vw - #{$padding_middle} - #{$padding_middle});
      }
      @media (max-width: 1200px) {
        width: calc(100vw - #{$padding_small} - #{$padding_small});
      }
    }
  }
  &--price .compare__table__head [data-row="price"]:after{opacity: 1; }
  &--name .compare__table__head [data-row="name"]:after{opacity: 1; }
  &--type .compare__table__head [data-row="type"]:after{opacity: 1; }
  &--status .compare__table__head [data-row="status"]:after{opacity: 1; }
  &--area .compare__table__head [data-row="area"]:after{opacity: 1; }
  &--rooms .compare__table__head [data-row="rooms"]:after{opacity: 1; }
  &--floor .compare__table__head [data-row="floor"]:after{opacity: 1; }
  &--decor .compare__table__head [data-row="decor"]:after{opacity: 1; }

  &__head {
    width: 250px;
    min-width: 250px;
    .compare__row {
      justify-content: flex-start;
      padding-left: 52px;
      padding-right: 0;
      position: relative;
      font-weight: bold;
      &:before{
        content: '';
        position: absolute;
        top: 50%; left: 26px; width: 4px; height: 4px;
        background: #ed1c24; margin-top: -2px;
      }
      &:first-child{
        border: none;
        &:before{display: none;}
      }
    }
  }
  &__body{
    width: 100%;
    display: flex;
    overflow-x: hidden;
    padding-bottom: 70px;
  }
  &__content{
    width: calc(100% - 250px);
  }
  .slick-slider {width: 100%; }
  .slick-track{display: flex; margin: 0;}
}
.compare-text{
  padding-bottom: 70px;
  font-size: 17px;
  font-weight: 500;
  font-family: 'Travels'
}

.compare__column{
  width: 395px;
  min-width: 395px;
  &--head{
    height: 370px !important;
    padding-top: 56px;
    box-sizing: border-box;
  }
}
.compare__row {
  height: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  font-size: 18px;
  width: 100%;
  color: #1e1f26;
  box-sizing: border-box;
  border-top: 1px solid #f2f2f2;
  padding-left: 20px;
  &--img{
    padding-bottom: 20px;
    height: 140px;
    background: #fff;
    box-sizing: content-box;
    border: none;
  }
  &--btn{
    border: none;
    width: 220px;
    .btn{
      height: 50px;
      line-height: 50px;
      padding: 0;
      width: 100%;
    }
  }
  &--address{
    width: 220px;
    border: none;
    font-size: 16px;
    font-family: 'Travels'; font-weight: 500;
    margin-bottom: 0;
    height: auto;
    min-height: 0;
    position: relative;
    //min-height: 107px;
  }
  &--price{
    font-weight: bold;
    border: none;
    display: none;
  }
  &--name{
    text-transform: uppercase;
    span{line-height: 1.3;}
  }
  sup{
    position: relative;
    transform: scale(.8) translateY(-40%);
  }
}
.compare-img{
  width: 220px;
  height: 140px;
  display: block;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}
.compare__remove{
  position: absolute;
  top: 50%; left: 0; width: 20px; height: 20px;
  font-size: 11px;
  color: #000;
  cursor: pointer;
  transition: transform 600ms;
  transform-origin: top left;
  margin-top: -7px;
  &:hover{
    // color: #f14950;
    transform: scale(1.3);

  }
}
.compare__plan{
  height: 55px;
  width: 90px;
  background-size: contain;
  background-position: 0 50%; background-repeat: no-repeat;
  cursor: zoom-in;
}

.sticky-header{
  position: sticky;
  top: -370px;
  .slick-slider{transition: 600ms;}
  .slick-arrow{
    height: 40px; width: 40px; color: #fff;
    background: #f14950;
    border-radius: 50%;
    transform: none;
    top: 110px;
  }
  .slick-prev{transform: translateX(-50%); }
  .slick-next{transform: translateX(50%); }
  .slick-arrow.slick-disabled{opacity: 0; pointer-events: none;}
  &:before{
    content: '';
    position: absolute;
    top: 0; left: -1000px; width: calc(100% + 1000px + #{$padding}); height: 100%;
    background: #fff;
    box-shadow: 0 3px 30px rgba(0,0,0,.2);
    opacity: 0;
    transition: 600ms;
    @media(max-width: 1480px) {
      width: calc(100% + 1000px + 3.5vw);
    }
  }
  &.sticky{
    z-index: 999;
    .slick-slider{transform: translateY(130px);}
    .slick-arrow{top: 280px; }
    .compare__row--btn {display: none; }
    &:before{opacity: 1;transform: translateY(130px); }
    .compare__row--price{display: block;}
    .compare__row--address{
      //padding-top: 50px;
      margin-top: 50px;
      margin-bottom: 5px;
    }
    .compare__remove{
      //top: 52px;

    }

  }
}

.zoom-modal{
  position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  transform: translate(-40px, -100%);
  margin-top: 60px;
  width: 400px;
  height: 400px;
  background-color: #fff;
  z-index: 100;
  border: 2px solid #d8d7d8;
  // border-radius: 25px 25px 25px 0;
  animation: fadeIn .6s ease;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1  }
}
.zoom-modal img{
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.zoom-modal__close{
  right: 30px;
  top: 30px;
  position: absolute;
  font-size: 21px;
  color: #a0a747;
  cursor: pointer;
  transition: 300ms;
  line-height: 1;
}
.zoom-modal__close:hover{
  transform: scale(1.1);
}

.compare__filter{
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f2f2;
  padding-top: 40px;
  padding-bottom: 40px;
  &>div{position: relative;}
  span{
    margin-right: 60px;
    transition: 300ms;
    cursor: pointer;
    position: relative;
    padding-left: 40px;
    &:before{
      content: '';
      position: absolute;
      left: 0; top: 50%; height: 25px; width: 25px;
      transform: translateY(-50%);

      background: #fff;
    }
    &.active:before{background-color: #ed1c24;}
  }
  .btn{
    width: 250px;
    padding: 17px 20px 15px;
    text-align: left;
    &:before{
      position: absolute;
      top: 50%; right: 15px; font-size: 15px;
      color: #ed1c24;
      transform: translateY(-50%);
    }
    &:hover:before{color: #fff;}
  }

  .share{
    position: absolute; top: -94px; right: 0;
  }
  .print{
    position: absolute; top: -96px; right: 38px;
  }
}
.compare__help{
  text-align: center;
  font-family: 'Travels';
  font-size: 30px;
  font-weight: 500;
  padding-bottom: 100px;
  .phone{
    color: #ed1c24;
    font-size: 30px;
    padding-top: 5px;
  }
}



.fixed-flat{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 200;
  animation: fadeIn .6s ease;
  box-shadow: 0 2px 46px rgba(0,0,0,.1)
}
.fixed-flat > div{
  margin-left: 6vw;
  margin-right: 4vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 85px;
}
.fixed-flat__close{
  display: flex;
  align-items: center;

}
.fixed-flat__close .icons-12{transition: 300ms; cursor: pointer;}
.fixed-flat__close .icons-12:hover{
  transform: scale(1.2);
}
.fixed-flat__close a{
  color: #a0a747;
  border-bottom: 1px dashed;
  text-decoration: none;
  display: inline-block;
  line-height: 1;
}
.fixed-flat__close span{
  margin-left: 50px;
  font-size: 11px;
  height: 15px;
  line-height: 18px;
}
.fixed-flat__gray{color: #808080;}

.params-table__hcell-sort.params-table__hcell-sort--compare:after{
  display: none;
}
// @media (max-width: 1400px) {
//   .compare__table__head .compare__row:before{
//     width: calc(100vw - 220px);
//   }
// }

.mobile{display: none !important;}
@media (max-width: 1000px) {
  // .compare__table__head .compare__row:before{display: none;}
  .compare{
    padding-left: 0;
  }
  .compare__table > div{
    width: 50%;
    min-width: 50%;
  }
  .compare__table__head{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 1
  }
  .compare__table__head .compare__row{
    background: none;
    text-align: center;
    padding-left: 0;
    justify-content: center;
    transform: translateY(-30%);
    font-size: 12px;
    color: #b2b2b2;
    border-color: transparent !important;
  }
  .compare__column{
    width: 100%;
    min-width: 100%;
  }
  .compare__row{
    padding-left: 0;
    padding-right: 0;
    background: #fff;
    border-bottom: 1px solid #f4f4f4;
    font-size: 12px;
  }
  .compare__row--img{
    height: 100px;
    padding-bottom: 50px;
    box-sizing: content-box;

  }
  .compare-img {
    width: 135px;
    height: 100px;
  }
  .compare__row{padding-top: 10px;}
  .compare__row--view{
    box-sizing: content-box;
    line-height: 1.2;
    /*height: 75px;*/
  }
  .compare__table__head .compare__row--view{transform: translateY(-40%);}
  .compare__row--btn, .compare__row--remove{
    border: none;
  }
  .compare__row--remove > span{font-size: 10px;}
  .compare__row--btn .button{font-size: 12px;}
  .compare__row--remove > span:before{margin-right: 12px;}

  .compare__filter{
    flex-direction: column;
    margin-bottom: 25px;
  }
  .compare__filter span{margin-bottom: 25px;}
  .zoom-modal{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 0;
    position: fixed;
  }

  .compare-link--top-link{display: none;}
  .detail-compare-link{text-align: center;}
  .detail-compare-link{padding-top: 20px;}

  .flat__info-lnk{white-space: nowrap;}
  .flat__info-options > div{font-size: 12px;}


  .params-table__hcell-sort--compare:after{
    display: none;
  }
  .params-table__row .compare-link{font-size: 0; top: -2px;}
  .params-table__row  .compare-link:before, .params-table__row  .compare-link:after{left: -25px;}
  .fixed-flat > div{
    flex-direction: column;
  }
  .fixed-flat__close{
    position: absolute;
    top: 10px;
    right: 0;
  }
  .fixed-flat > div{
    font-size: 11px;
    padding-top: 34px;
    height: auto;
    padding-bottom: 15px;
    line-height: 1.3
  }
  .ta{text-align: center !important;}

  .compare__column{width: 43.75vw; min-width: 43.75vw; position: relative;}
  .compare .slick-slide > div{width: 43.75vw;}

  .compare .slick-slider{
    position: relative
  }
  .compare__table__left .slick-dots{text-align: right;}
  .compare__table__right .slick-dots{text-align: left;}
  .compare .slick-dots{top: 120px;}
  .compare .slick-dots li{opacity: .6;}
  .compare .slick-dots li.slick-active{opacity: 1;}
  .compare__table__left .slick-dots li:after{
    background: #a0a747;
  }
  .compare__table__right .slick-dots li:after{
    background: #d1b6a1;
  }
  .compare .slick-track{
    display: flex;
  }
  .compare-menu .compare-link--top-link{
    display: inline-block;
    font-size: 32px;
    margin-left: 0;
    color: inherit;
  }
  .compare-link:before, .compare-link:after{border-color: #a0a747}
  .compare-link--gray:before, .compare-link--gray:after{border-color: #c1c1c1 !important; }
  .fixed-flat__gray{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    align-items: center;
  }
  .desctope{display: none !important;}
  .mobile{display: block !important;}
  .zoom-modal{
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
@media (max-width: 370px) {
  .compare-menu .compare-link--top-link{font-size: 28px;}
  .compare-img {
    width: 110px;
    height: 80px;
  }
  .compare__row--img{
    height: 80px;
    padding-bottom: 40px;
  }
  .compare__table > div:first-child .compare__row{
    padding-right: 5px
  }
  .compare__table > div:last-child .compare__row{
    padding-left: 5px
  }
  .params-table{padding: 0 10px;}
  .params-table__row-head > div{
    padding: 0 6px;
  }
  .params-table__row > div{
    padding: 5px 3px;
  }
  .MuiSlider-root .MuiSlider-valueLabel, .MuiSlider-root .MuiSlider-valueLabel *{
    font-size: 11px;
  }
  .compare .slick-dots{top: 95px;}
  .compare .slick-dots li{
    width: 15px;
  }
  .slick-dots li:after {
    width: 8px;
    height: 8px;
  }
}
