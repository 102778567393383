.events-tale {
  clear: both;
  margin-left: -40px;
  width: calc(100% + 40px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.events-tale__column:first-child {
  width: calc(100% / 3 - 40px);
  margin-left: 40px;
  .sharing-icons__in {
   right: auto;
    left: 30px;
  }

  .news-feed__item__img{
    height: 543px;
    padding: 0;
  }
}

.events-tale__column:last-child {
  margin-left: 40px;
  flex: 1;
}

.events-tale__box{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.last{
    .events-tale__box-in:last-child{
      .news-feed__item{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        a{
          flex: 1;
          display: block;
          display: flex;

          .news-feed__item__img{
            flex: 1;
            display: block;
            height: auto;
          }
        }
      }

      .news-feed__item__img{
        flex: 1;
      }
    }
  }

  &.first{
    .events-tale__box-in:first-child{
      .news-feed__item{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        a{
          flex: 1;
          display: block;
          display: flex;

          .news-feed__item__img{
            flex: 1;
            display: block;
            height: auto;
          }
        }
      }

      .news-feed__item__img{
        flex: 1;
      }
    }
  }
}

.events-tale__box-in{
  width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .news-feed__item__img{
    padding-top: 0;
    height: 285px;
  }
}

.events-tale__double{
  height: 444px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  text-decoration: none;
  background: #f2efea;

  &.no-img{
    background: #fff;
    border: 3px solid #f3eeea;

    img{
      position: absolute;
      height: calc(100% + 6px);
      top: -3px;
      left: -3px;
    }
  }

  & > div:first-child{
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  & > div{
    width: 50%;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .news-feed__item__info{
    padding: 0 50px 25px 50px;
  }
}

.events-tale__double-wrap{
  padding: 0 50px;
}

.events-tale__double-title{
  font-size: 24px;
  line-height: 1.3;
  font-family: "Travels";
  margin-bottom: 20px;
  font-weight: 500;

  @media (max-width: $resolution-1400) {
    font-size: 22px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 20px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 18px;
  }
}

.events-tale__double-description{
  font-size: 16px;
  line-height: 1.3;

  @media (max-width: $resolution-1400) {
    font-size: 16px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 15px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 14px;
  }
}

.mobile-new-events{
  padding: 0 0 50px 0 !important;
  overflow: hidden;

  .btn{
    display: block;
  }

  .news-feed__item{
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .news-feed__filter__item:after{
    display: none;
  }

  .news-feed__filter__item[active=true]:before {
    width: calc(100% + 11px) !important;
  }

  .news-feed__filter{
    width: auto;
    margin-left: 0;
  }

  .news-feed__gray{
    margin: 0 0 50px 0;
    padding-left: 0;
    padding-right: 0;

    .news-feed__slider__title{
      padding-left: 40px;
      padding-right: 40px;
    }

    .slick-slide > div{
      padding: 0 40px;
    }
  }

  .news-feed__filter__item{
    width: 27%;
    text-align: center;
    box-sizing: border-box;
    font-size: 13px;
    position: relative;
    left: -4px;

    span{
      padding-left: 0;
      padding-right: 0;
    }

    &:nth-child(3n+3):after,
    &:last-child:after{
      content: '';
      display: block;
      width: 14px;
      top: 2px;
      bottom: 0;
      left: 100%;
      background: #fff;
      position: absolute;
    }
  }
}

.news-feed-tales{
  .btn{
    display: block;
  }

  &[data-size="1"],
  &[data-size="2"]{
    .events-tale__column:last-child{
      .news-feed__item__img{
        height: 285px !important;
      }
    }
  }
}

.news-feed__item__like{
  cursor: pointer;

  &:before{
    transition: $animation-speed;
  }

  @media (min-width: $resolution-1000) {
    &:hover:before{
      color: $color-red;
    }
  }
}

.lenta_tale {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  .news-feed__item {
    width: calc(33.33% - 40px);
    margin-left: 40px;
  }
}
