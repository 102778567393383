.news-feed{
  padding-bottom: 150px;
  @media (max-width: 1000px) {
    padding-bottom: 50px;
  }
  &__column{
    width: calc(100% / 3 - 40px);
    margin-left: 40px;
    @media (max-width: 1000px) {
      width: 100%;
      margin-left: 0;
    }
    &:nth-child(1) {
      .news-feed__item .news-feed__item__img {
        padding-top: 543px;

        @media (max-width: $resolution-1400) {
          padding-top: 513px;
        }

        @media (max-width: $resolution-1300) {
          padding-top: 483px;
        }

        @media (max-width: $resolution-1200) {
          padding-top: 453px;
        }

        @media (max-width: $resolution-1000) {
          padding-top: 286px;
        }
      }
    }
    &:nth-child(2) {
      .news-feed__item .news-feed__item__img {
        padding-top: 285px;

        @media (max-width: $resolution-1400) {
          padding-top: 255px;
        }

        @media (max-width: $resolution-1300) {
          padding-top: 225px;
        }

        @media (max-width: $resolution-1200) {
          padding-top: 195px;
        }

        @media (max-width: $resolution-1000) {
          padding-top: 286px;
        }
      }
      .news-feed__item:nth-child(3n+3) .news-feed__item__img {
        padding-top: 713px;

        @media (max-width: $resolution-1400) {
          padding-top: 648px;
        }

        @media (max-width: $resolution-1300) {
          padding-top: 583px;
        }

        @media (max-width: $resolution-1200) {
          padding-top: 513px;
        }

        @media (max-width: $resolution-1000) {
          padding-top: 286px;
        }
      }
    }
    &:nth-child(3) {
      .news-feed__item .news-feed__item__img {
        padding-top: 285px;

        @media (max-width: $resolution-1400) {
          padding-top: 255px;
        }

        @media (max-width: $resolution-1300) {
          padding-top: 225px;
        }

        @media (max-width: $resolution-1200) {
          padding-top: 195px;
        }

        @media (max-width: $resolution-1000) {
          padding-top: 286px;
        }
      }
      .news-feed__item:nth-child(3n+1) .news-feed__item__img {
        padding-top: 713px;

        @media (max-width: $resolution-1400) {
          padding-top: 648px;
        }

        @media (max-width: $resolution-1300) {
          padding-top: 583px;
        }

        @media (max-width: $resolution-1200) {
          padding-top: 513px;
        }

        @media (max-width: $resolution-1000) {
          padding-top: 286px;
        }
      }
    }
  }
  &__gray{
    background: #f2eeea;
    margin-left: -$padding_mobile;
    margin-right: -$padding_mobile;
    padding-top: 50px;
    margin-bottom: 50px;

    @media (max-width: $resolution-1000) {
      .subscribe-form{
        padding-bottom: 50px;
      }
    }
  }
}
.news-feed__filter{
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  margin-left: -20px;
  width: calc(100% + 20px);
  @media (max-width: 1000px) {
    overflow: hidden;
    margin-bottom: 15px;
  }
  &__item{
    padding-bottom: 14px;
    position: relative;
    margin-left: 20px;
    cursor: pointer;
    margin-bottom: 15px;
    font-size: 18px;
    @media (max-width: $resolution-1680) {
      font-size: 16px;
    }
    @media (max-width: $resolution-1400) {
      font-size: 15px;
    }
    @media (max-width: 1000px) {
      font-size: 14px;
      margin-left: 11px;
      padding-bottom: 9px;
      margin-bottom: 10px;
    }
    @media (max-width: 370px) {
      font-size: 13px;
    }
    &:before{
      content: '';
      width: 0; height: 4px;
      background: #ed1c24;
      position: absolute; bottom: 0; left: 0;
      transition: 300ms;
    }
    &:after{
      content: '';
      position: absolute; left: -20px; bottom: 0;
      height: 100%; width: 0;
      background: #fff;
      z-index: 1;
    }
    &[active="true"]{
      &:before{
        width: calc(100% + 20px);
      }
      @media (max-width: 1000px) {
        &:before{width: calc(100% + 11px);}
        &:nth-child(2):before{width: 100%;}
        &:nth-child(5):before{width: 100%;}
      }
    }
    &[active="true"]:last-child:before{
      width: 100%;
    }
    &[active="false"]:after{
      width: 20px;
      transition: 300ms;
    }
    span{
      border: 1px solid #f2f2f2;
      padding: 13px 20px;
      display: block;
      transition: 300ms;
      @media (max-width: $resolution-1680) {
        padding: 13px 15px;
      }
      @media (max-width: $resolution-1400) {
        padding: 13px 13px;
      }

      @media (max-width: 1000px) {
        padding: 15px 12px;
      }
      @media (max-width: 370px) {
        padding: 12px 9px;
      }
    }
    &[active="true"] span{background: #f2f2f2}
  }

  @media (max-width: 1400px), (max-height: 800px) {
    &__filter__item{font-size: 15px;}
    &__filter__item span{padding-left: 25px; padding-right: 25px;}
  }

}

_:-ms-fullscreen, :root .news-feed__filter__item:before{
  transition: none !important;
}

.news-feed__clear{
  float: right;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 45px;
  span{border-bottom-style: dashed; border-bottom-color: #a6a6a6}
  @media (max-width: 1000px) {
    float: none;
    text-align: center;
    margin-bottom: 40px;
    font-size: 14px;
  }
}

.news-feed__list{
  clear: both;
  margin-left: -40px;
  width: calc(100% + 40px);
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1000px) {
    margin-left: 0;
    width: 100%;
    .slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div, .slick-slide > div > div, .slider-item{
      height: auto;
    }
    .slick-list{transition: 300ms;}
    .slick-dots{padding-top: 10px;}
  }
}
.news-feed__left{
  width: calc(100% / 3 - 40px);
  margin-left: 40px;
}
.news-feed__right{
  width: calc(100% / 3 * 2 - 40px);
  margin-left: 40px;
}

.news-feed__item{
  margin-bottom: 40px;
  display: block;
  text-decoration: none;
  width: 100%;

  a{
    text-decoration: none;
  }

  .news-feed__item__like{
    cursor: pointer;

    &:before{
      transition: $animation-speed;
    }

    @media (min-width: $resolution-1000) {
      &:hover:before{
        color: $color-red;
      }
    }
  }

  @media (max-width: $resolution-1400) {
    margin-bottom: 35px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 30px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 20px;
  }

  &__video-icon{
    position: absolute;
    top: 0; left: 0; width: 100%; height: 100%;
    &:before{
      position: absolute;
      top: 50%; left: 50%; transform: translate(-50%, -50%);
      border-radius: 50%;
      height: 95px; width: 95px;
      background: rgba(255,255,255,.9);
      color: #ed1c24; font-size: 20px;
      text-align: center;
      line-height: 95px;
    }
  }
  &__img{
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-top: 69%;
    &_default {
      background-size: contain;
    }
    img{width: 100%;}
    span{
      position: absolute;
      top: 0; left: 0; bottom: 0;
      width: 100%;
      background-position:  0 0;
      background-repeat: no-repeat;
      background-size: cover;
      transition: 1200ms;
    }
  }
  &--no-image {
    .news-feed__item__img span{background-size: contain;}
  }
  &__title{
    margin-bottom: 20px;
    box-sizing: border-box;
    transition: 600ms;
    font-size: 22px;
    font-family: 'Travels';
    font-weight: 500;
    @media (max-width: $resolution-1680) {
      line-height: 1;
    }

    @media (max-width: 1000px) {
      font-size: 18px;
      height: auto;
      margin-bottom: 20px;
    }
  }
  .news-feed__item__type {
    font-size: 16px;
    line-height: 19px;
    font-family: 'Travels';
    color: #666;
    margin-top: 20px;
    margin-bottom: 15px;
    @media (max-width: $resolution-1200) {
      margin-bottom: 10px;
      margin-top: 15px;
    }
  }
  .news-feed__item__desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #666666;
    margin-bottom: 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: Georgia, Times, "Times New Roman", serif;
  }

  &__info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2b2b2b;
    font-size: 14px;
    font-family: 'Travels';
    height: 16px;
  }

  &__likes {
    display: flex;
    align-items: center;
    .sharing-icons {
      margin-left: 25px;
      margin-top: -4px;
    }
  }

  @media (min-width: 1000px) {
    &:hover .news-feed__item__title{
      opacity: .6
    }
    &:hover .news-feed__item__img span{
      transform: scale(1.1);
    }
  }
}
.news__feed__double{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 25px;
  &>*{
    width: calc(50% - 20px);
  }
  &:nth-child(3n) {
    .news-feed__item{order: -1;}
  }
  &>.news-feed__item > .news-feed__item__img{
    padding-top: calc(116% + 116px + 30px);
  }
}

.news-feed__left .news-feed__item .news-feed__item__img{
  padding-top: 110%;
}
.news-feed__load-more{
  max-width: 300px;
  margin: 60px auto 0;
  font-family: 'Travels';
  font-weight: 600;
}
.ap-but-tab {
  margin-top: 0;
}


.news-feed__slider{
  background: #f2eeea;
  padding: 40px 30px 0;
  margin-bottom: 50px;

  &__title{
    font-size: 48px;
    font-family: 'Travels';
    margin-bottom: 25px;
    @media (max-width: 1800px) {
      font-size: 44px;
    }

    @media (max-width: 1680px) {
      font-size: 38px;
    }

    @media (max-width: 1450px) {
      font-size: 34px;
    }

    @media (max-width: 1300px) {
      font-size: 30px;
    }

    @media (max-width: 1150px) {
      font-size: 26px;
    }

    @media (max-width: 1000px) {
      font-size: 28px;
    }
  }
  &__body{
    padding-bottom: 40px;
    @media (max-width: 1000px) {
      margin-left: -10px;
      width: calc(100% + 20px);
      padding-bottom: 50px;
    }
    .slick-dots{
      padding-top: 25px;
      button{width: 5px; min-width: 5px; height: 5px;}
      @media (max-width: 1000px) {
        padding-top: 30px;
        button{width: 10px; min-width: 10px; height: 10px;}
      }
    }
  }

  &__item{
    position: relative;
    padding-left: 87px;
    display: block;
    margin-bottom: 25px;
    text-decoration: none;
    min-height: 50px;
    display: flex;
    align-items: center;
    @media (max-width: 1000px) {
      padding-left: 70px;
    }
    &__img{
      position: absolute;
      top: 50%;
      left: 1px;
      width: 57px;
      height: 57px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0 0;
      transform: translateY(-50%);
      background-color: #fff;
    }
    &__date{
      display: block;
      font-size: 14px;
      font-family: 'Travels';
      margin-top: 3px;
    }
    &__title{
      transition: 300ms;
      border-bottom: 1px solid transparent;
    }

    @media (max-width: 1400px), (max-height: 800px) {
      &__title{font-size: 15px;}
    }
    @media(min-width: 1000px) {
      &:hover .news-feed__slider__item__title{
        border-bottom-color: inherit;
      }
    }
  }

  .subscribe-form{
    width: 100%;
    min-width: 100%;
    padding-bottom: 50px;


    @media (max-width: $resolution-1200) {
      display: block;
    }

    &__btn{
      width: 36%;
      min-width: 127px;
      margin-left: 15px;
      // font-size: 11px;

      @media (max-width: $resolution-1200) {
        margin-left: 0;
        margin-top: 15px;
        width: 100%;
      }

      @media (max-width: $resolution-1000) {
        margin-top: 0;
      }
    }
    .input-field{
      background: #fff;
      padding-left: 15px;
      color: #a4a4a4;
      width: 100%;
      &:before{display: none;}
    }
  }
}

.news-feed__social{
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
  margin-right: -10px;
  padding-bottom: 50px;

  @media (max-width: $resolution-1000) {
    justify-content: center;
  }

  .social-item{
    font-size: 0;
    padding-top: 23px;

    @media (max-width: $resolution-1200) {
      min-width: 25px;
    }

    @media (max-width: $resolution-1000) {
      margin: 0 7px;
    }

    &:before{
      height: 23px;
      line-height: 23px;
      font-size: 22px;
      color: #696969;
    }
    &--tg:before{font-size: 20px;}
    &--vk:before{font-size: 20px;}
    &--youtube:before{font-size: 20px;}
    &--ok:before{font-size: 23px;}
    &--fb:before{font-size: 23px;}
    @media (min-width: $resolution-1000) {
      &:hover:before{
        transform: scale(1.15) translateX(-45%);
      }
    }
  }
}

.news-feed__space{
  height: 40px;
  margin-left: -30px;
  margin-right: -30px;
  background: #fff;
  margin-bottom: 40px;

  @media (max-width: $resolution-1000) {
    margin-left: -40px;
    margin-right: -40px;
  }
}

.news-feed--live{
  .news-feed__left .news-feed__item{
    .news-feed__item__img{
      padding-top: 152%;
    }
    &:first-child .news-feed__item__img{
      padding-top: 113%
    }
    &:last-child .news-feed__item__img{
      padding-top: 100%
    }
  }
}

.news-feed__single{
  background: #f2eeea;
  margin-bottom: 30px;
  &__body{
    min-height: 250px;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 30px;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 65px;
    padding-bottom: 25px;
  }
  &__title{
    font-size: 24px;
    font-family: 'Travels';
    font-weight: 500;
    transition: 600ms;
    margin-bottom: 25px;
  }
  &__text{
    transition: 600ms;
    font-size: 16px;
  }
  &__item{
    &>a, &>div{
      text-decoration: none;
      padding-top: 30px;
      position: relative;
      padding-left: calc(50% + 20px);
      display: block;
    }
    @media (min-width: 1000px) {
      &:hover .news-feed__single__title, &:hover .news-feed__single__text {
        opacity: 0.6;
      }
      &:hover .news-feed__single__img span{
        transform: scale(1.1);
      }
    }
    &--no-image {
      background: #fff;
      box-shadow: inset 0 0 0 3px #f2eeea;
      .news-feed__single__img span{background-size: contain; background-position: 0 0; top: -3px; bottom: -3px; left: -3px;}
    }
  }
  &__img{
    position: absolute;
    top: 0;
    left: 1px;
    bottom: 0;
    width: calc(50% - 20px);
    overflow: hidden;
    span{
      position: absolute;
      top: 0; left: 0; bottom: 0;
      width: 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      transition: 1200ms;
    }
  }
  .news-feed__item__info {
    width: 100%;
    padding-top: 40px;
  }
  .slick-dots{
    position: absolute;
    bottom: 0; left: 0;
    padding-top: 45px;
    padding-bottom: 25px;
    padding-left: calc(50% + 20px);
    width: 100%;
    box-sizing: border-box;
  }
  &__slider{
    .news-feed__single__item{
       &>a, &>div{padding-bottom: 77px;}
    }
  }
}



@media (max-width: 1400px), (max-height: 800px) {
  .index__news-slider__item{font-size: 15px;}
}

.news-feed{
  @media (max-width: $resolution-1000) {
    nav{
      margin-top: 30px;
    }
  }
}
