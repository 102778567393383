.apartment-booking{
  .page-menu{
    margin-right: 11vw;
    margin-bottom: 150px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .call-request{
    margin-bottom: 150px;
  }

  .page-menu__item{
    position: relative;
  }

  .page-menu__item.border-link:after{
    content: '';
    display: block;
    width: 5px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: $color-red;
  }

  .call-request__title{
    color: $color-red;
    font-weight: 500;
  }

  .list li{
    margin-bottom: 8px;
    padding-left: 25px;

    &:before{
      top: 10px;
    }

    &:last-child{
      margin-bottom: 0;
    }
  }
}