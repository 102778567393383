.consulting {
  @media (max-width: 1000px) {
    .news-feed__gray{padding-bottom: 40px;}
  }

  .page-columns {
    &__left {
      padding-bottom: 70px;

      .page-text {
        padding-left: 7.6vw;
        max-width: 450px;
        margin-bottom: 175px;
        @media(max-width: 1400px) {
          max-width: 350px;
          margin-bottom: 100px;
        }
        @media (max-width: 1000px) {
          padding-left: 60px;
          margin-bottom: 0;
        }
      }
    }
    &__img {
      @media (max-width: 1000px) {
        margin-left: -25px;
        width: calc(100% + 50px)
      }
      span {
        padding-top: 108%;
      }
      &.bought__img3 {
        @media (max-width: 1000px) {
          width: calc(100% - 30px);
          margin-left: 15px;
          bottom: auto;
          margin-top: -50px;
          margin-bottom: 0;
        }
        span {
          padding-top: 96.3%;
        }
      }
    }
    &__img2 {
      bottom: -26.1vw;
      width: 29.6vw;
      @media (max-width: 1000px) {
        width: calc(100% - 30px);
        margin-left: 15px;
        bottom: auto;
        margin-top: -50px;
      }
    }
  }
  .consulting-request{
    @media (max-width: 1000px) {
      padding-bottom: 130px;
    }
  }

  .services__links {
    margin-top: -30px;
    padding-top: 4.75vw;
    padding-bottom: 4.3vw;
    margin-bottom: 12.7vw;
    @media (max-width: 1000px) {
      padding: 130px 40px 90px;
      margin-bottom: 0;
      margin-top: -75px;
    }
    .tab {
      font-family: Georgia, Times, "Times New Roman", serif;
      line-height: 20px;
    }
    & > li {
      margin-left: 8.3vw;
      padding-left: 25px;
      @media (max-width: 1000px) {
        margin-left: 0;
      }
    }
  }
  .tab--arrow {
    padding-right: 75px;
    &:before {
      right: 39px;
      font-size: 16px;
    }
    @media (max-width: 1000px) {
      padding-right: 40px;
      &:before {
        right: 0;
        color: #000;
      }
    }
  }

  .index__partners {
    margin-top: 64px;
    margin-bottom: 127px;
    @media (max-width: 1000px) {
      margin-top: 0;
      margin-bottom: 80px;
    }
    .title {
      font-size:80px;
    }
  }

}
.portfolioBlock {
  .title {
    font-size:48px;
    margin-bottom: 69px;
    @media (max-width: 1000px) {
      margin-bottom: 30px;
    }
  }
}

.portfolioConsalt {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  &__item {
    position: relative;
    width:31.5%;
    padding: 40px 51px 43px;
    box-sizing: border-box;
    border:solid 1px #e6e6e6;
    margin-bottom: 3.36%;
    @media (max-width: 1000px) {
      width: 100%;
      padding: 20px;
      margin-bottom: 20px;
    }
    &:nth-child(3n):before {
      content: '';
      position: absolute;
      left:0;
      top: 0;
      background: $color-red;
      height:150px;
      width:5px;
    }
    &:nth-child(3n - 2):before {
      content: '';
      position: absolute;
      left:0;
      top: 0;
      background: $color-red;
      height:93px;
      width:5px;
    }
  }
  &__img {
    margin-bottom: 23px;
    height:64px;
    line-height: 64px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__title {
    font-family: 'Travels';
    font-weight: 500;
    font-size:24px;
    @media (max-width: 1000px) {
      font-size: 18px;
    }
  }
  &__text {
    font-size:16px;
    line-height: 20px;
  }
}

.portfolioConsalt-more {
  max-width: 300px;
  font-size:16px;
  margin: 0 auto 60px;
}

.redList {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
    &:before {
      content:'';
      position: absolute;
      top: 8px;
      left: 1px;
      width:4px;
      height:4px;
      background: $color-red;
    }
  }
}

.reportsBlock {
  margin-top: 131px;
  margin-bottom: 111px;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  @media (max-width: 1000px) {
    padding-left: 40px; padding-right: 40px;
    margin-top: 40px; margin-bottom: 40px;
  }
  .title {
    font-size:48px;
    margin-bottom: 73px;
  }
}
.reportsConsalt {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @media (max-width: 1000px) {
    flex-direction: column;
  }

  &__item {
    position: relative;
    width:31%;
    padding-left: 122px;
    text-decoration: none;
    margin-bottom: 3.33%;
    box-sizing: border-box;
    @media (max-width: 1000px) {
      width: 100%;
      margin-bottom: 30px;
      padding-left: 0;
      padding-top: 122px;
      text-align: center;
    }
    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: 1px;
      width:90px;
      height: 90px;
      background: #f2f2f2;
      border-radius: 50%;
      transition: transform 0.3s;
      @media (max-width: 1000px) {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &:after {
      content: url('/static/img/consulting/doc.svg');
      position: absolute;
      left:30px;
      top: 17px;
      transition: transform 0.3s;
      @media (max-width: 1000px) {
        left: 50%;
        top: 20px;
        transform: translateX(-50%);
      }
    }
    &:nth-child(1) {
      .reportsConsalt__name:before {
        content:'';
        position: absolute;
        left: 1px;
        top: -21px;
        height: 5px;
        width: 27%;
        background: $color-red;
        @media (max-width: 1000px) {
          display: none;
        }
      }
    }
    @media (min-width: $resolution-1001) {
      &:hover {
        &:before,
        &:after {
          transform: scale(1.2) perspective(1px) translateZ(0);
        }
        .reportsConsalt__link {
          border-bottom-color:transparent;
        }
      }
    }
  }

  &__name {
    position: relative;
    font-size: 18px;
    margin-bottom: 15px;
    word-break: break-word;
  }

  &__size {
    font-size: 18px;
    margin-bottom: 16px;
  }

  &__link {
    font-size: 16px;
    text-decoration: none;
    border-bottom: dashed 1px;
    transition: border 0.3s;
  }
}
