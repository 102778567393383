$padding: 4.5vw;
$padding_middle: 3.5vw;
$padding_small: 25px;
$padding_mobile: 40px;
$header_height: 190px;
$header_height_smalll: 140px;
@import './vars.scss';
@import './fonts.scss';
@import './animation.scss';
@import './app.scss';
@import './ui.scss';
@import './form.scss';
@import './index.scss';
@import './feed.scss';
@import './journal.scss';
@import './services.scss';
@import './apartment-booking.scss';
@import './about.scss';
@import './news.scss';
@import './apartment-search.scss';
@import './for-those-who-bought.scss';
@import './favorites.scss';
@import './compare.scss';
@import './promo.scss';
@import './detail.scss';
@import './howbuy.scss';
@import './online-registration';
@import './appartment-by-id';
@import './mortgage.scss';
@import './popups.scss';
@import './building.scss';
@import './consulting.scss';
@import './partners.scss';
@import './recommends.scss';
@import './error.scss';
@import './contacts.scss';
@import './search.scss';
@import './events-tale.scss';
@import './recommend.scss';
@import './lk.scss';
@import './selection-programms.scss';
@import './for-private-agents.scss';
@import './recommended.scss';
@import './franchise.scss';
