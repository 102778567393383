
.journal {
  .index__subscribe{
    margin-bottom: 200px;
  }

  @media (max-width: $resolution-1000) {

    .mobile-new-events .subscribe-form{
      padding-left: 40px;
      padding-right: 40px;
      width: auto;

      .input-field:before{
        width: auto;
        right: 0;
      }
    }

    .news-feed__filter{
      width: calc(100% + 80px);
      margin-left: -40px;
    }

    .news-feed__filter__item[active="true"]:before {
      width: calc(100% + 11px) !important;
    }

    .news-feed__filter__item{
      width: 27%;
      text-align: center;
      box-sizing: border-box;
      font-size: 13px;
      position: relative;
      left: -4px;
  
      span{
        padding-left: 0;
        padding-right: 0;
      }

      &:after{
        display: none;
      }
  
      &:nth-child(3n+3):after,
      &:last-child:after{
        content: '';
        display: block;
        width: 14px;
        top: 2px;
        bottom: 0;
        left: 100%;
        background: #fff;
        position: absolute;
      }
    }

    .slick-arrow{
      top: 133px;
      transform: none;
    }
  }
}