@keyframes fadeIn {
  0% {opacity: 0;}
}
@keyframes fadeInDelay {
  0%,30% {opacity: 0;}
}
@keyframes fadeIn20 {
  0%,20% {opacity: 0;}
}


@keyframes fadeInUp20 {
  0%,20% {opacity: 0; transform: translateY(10%);}
}
@keyframes fadeInUp30 {
  0%,30% {opacity: 0; transform: translateY(10%);}
}
@keyframes fadeInUp40 {
  0%,40% {opacity: 0; transform: translateY(10%);}
}
@keyframes fadeInUp50 {
  0%,50% {opacity: 0; transform: translateY(10%);}
}
@keyframes fadeInUp60 {
  0%,60% {opacity: 0; transform: translateY(10%);}
}


@keyframes fadeInDown20 {
  0%,20% {opacity: 0; transform: translateY(-10%);}
}
@keyframes fadeInDown30 {
  0%,30% {opacity: 0; transform: translateY(-50px);}
}
@keyframes fadeInDown40 {
  0%,40% {opacity: 0; transform: translateY(-50px);}
}
@keyframes fadeInDown50 {
  0%,50% {opacity: 0; transform: translateY(-50px);}
}
@keyframes fadeInDown60 {
  0%,60% {opacity: 0; transform: translateY(-50px);}
}

@keyframes fadeInDown70 {
  0%,70% {opacity: 0; transform: translateY(-50px);}
}



@keyframes fadeInLeft20 {
  0%,20% {opacity: 0; transform: translateX(50px);}
}
@keyframes fadeInLeft30 {
  0%,30% {opacity: 0; transform: translateX(50px);}
}
@keyframes fadeInLeft40 {
  0%,40% {opacity: 0; transform: translateX(10%);}
}
@keyframes fadeInLeft50 {
  0%,50% {opacity: 0; transform: translateX(10%);}
}
@keyframes fadeInLeft60 {
  0%,60% {opacity: 0; transform: translateX(50px);}
}


@keyframes fadeInRight20 {
  0%,20% {opacity: 0; transform: translateX(-50px);}
}
@keyframes fadeInRight60 {
  0%,60% {opacity: 0; transform: translateX(-50px);}
}
.fade-in{
  animation: fadeIn .6s ease-in-out;
}
.fade-in-delay{
  animation: fadeInDelay 1s ease-in-out;
}







.inner-page{
  animation: fadeIn 1s ease-in-out;
  &.building{
    animation: none;
    .animate{opacity: 1 !important}
  }
}

.scroll-helper{
  position: fixed;
  bottom: 30px;
  left: 0;
}
.animate{
  opacity: 0;
  transition: 1000ms opacity, 1000ms transform;
}
.animated{
  opacity: 1;
}
.fadeInUp{
  transform: translateY(50px);
}
.fadeInDown {
  transform: translateY(-50px);
}
.fadeInLeft{
  transform: translateX(50px);
}
.fadeInRight{
  transform: translateX(-50px);
}

[data-delay=".2s"] {transition-delay: 200ms; }
[data-delay=".3s"] {transition-delay: 300ms; }
[data-delay=".4s"] {transition-delay: 400ms; }
[data-delay=".5s"] {transition-delay: 500ms; }
[data-delay=".6s"] {transition-delay: 600ms; }

.animated{
  transform: translate(0,0) !important;
  opacity: 1 !important; 
}