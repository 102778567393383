.services {
  .page-columns{
    &__left{
      padding-bottom: 70px;
      @media (max-width: 1000px) {
        padding-bottom: 45px;
      }
      .page-text{
        padding-left: 7.4vw;
        max-width: 450px;
        margin-bottom: 170px;
        @media(max-width: 1400px) {
          max-width: 350px;
          margin-bottom: 170px;
        }
        @media (max-width: 1000px) {
          margin-bottom: 0;
          max-width: 100%;
          padding-left: 60px;
        }
        @media (max-width: 370px) {
          padding-left: 50px;
        }
      }
    }
    &__no-padding{
      margin-left: -25px;
      margin-right: -25px;
      width: calc(100% + 50px);
    }
    &__img span{
      padding-top: 100%;
      @media (max-width: 1000px) {
        padding-top: 100%;
      }
    }
  }
  &__links{
    background: #f2eeea url(/static/img/services/estatet.png) 50% 100% no-repeat;
    background-size: 100% auto;
    padding-right: 58vw;
    margin-top: -220px;
    padding-top: 5vw;
    padding-bottom: 5vw;
    margin-bottom: 10vw;
    &>li{
        margin-left: 7.4vw;
        border-bottom: 1px solid #d9d9d9;
        margin-bottom: 0;
        padding-top: 0; padding-bottom: 0;
        overflow: hidden;
        &:before{top: 28px;}
        @media (max-width: 1000px) {
          &:last-child{
            border-bottom: none;
          }
        }
    }
    .tab{
      font-size: 20px;
      padding-top: 100px;
      margin-top: -80px;
      padding-bottom: 20px;
      &:before{color: #7a7876}
      &[data-active="true"]:before{transform: rotate(180deg) translateY(45%)}
      &--arrow:before {
        top: 112px;
        font-size: 15px;
      }
      &.active {
        &+.tab-content {
          opacity: 1;
          display: block;
        }
      }
    }
    .tab-content{
      padding-top: 10px;
      padding-bottom: 40px;
      line-height: 1.5;
      display: none;
      opacity: 0;
      transition: opacity 0.3s;
      .btn {
        margin-top: 20px;
        width: 300px;
        max-width: 100%;
      }
      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      @media(max-width: 1450px) {
        font-size: 16px;
      }
    }
    @media(max-width: 1450px) {
      .tab{font-size: 16px; padding-bottom: 16px;}
      &>li:before{top: 28px;}
    }
    @media(max-width: 1300px) {
      padding-right: 56vw;
      &> li{margin-left: 0;}
    }
    @media (max-width: 1000px) {
      margin: -75px 0 -50px;
      padding: 120px $padding_mobile 100px;
    }
  }
}
