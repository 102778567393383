.contacts {
  overflow: hidden;

  .contactsList__item a{
    border-bottom: 1px solid #000;
    padding-bottom: 2px;
    text-decoration: none;

    &:hover{
      @media (min-width: $resolution-1000) {
        border-color: transparent;
      }
    }
  }
  .form-block .btn {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media (max-width: $resolution-1000) {

    .contactsBlocks{
      padding: 0;
    }

    .page-columns{
      display: block;
    }

    .contactsBlock__text{
      padding: 0 40px !important;
    }

    .contactsBlock__title{
      font-size: 26px;
      line-height: 1;
      margin-bottom: 30px;
      padding: 0;
    }

    .contactsList{
      margin: 0;
    }

    .contactsList__item{
      font-size: 16px;
      line-height: 1.8;
      margin-top: 0 !important;

      a{
        font-size: inherit;
        line-height: inherit;
      }
    }

    .contactsBlock__img{
      margin: 0 15px 70px 15px;
      height: 346px;
      padding: 0;
    }

    .contactsBlock__map{
      padding: 0;
      width: auto;
      margin: 0 !important;
    }

    .contacts-map{
      position: relative !important;
      height: 374px;
    }

    .contactsBlock__address{
      position: relative;
      left: 0;
      bottom: 0;
      padding: 0;
      width: auto;
      padding: 60px 40px;
      font-size: 17px;
      line-height: 1.6;
      right: 0 !important;
    }

    .contactsBlock{
      margin-bottom: 70px !important;
    }
  }
}

.contactsBlocks {

  .contactsBlock:nth-child(1) {
    //margin-bottom: 105px;
    align-items: flex-start;
    .contactsBlock__text {
        padding: 36px 32% 36px 0;
    }
  }
  .contactsBlock:nth-child(even) {
    flex-direction: row-reverse;
    .contactsBlock__map {
      margin: 0 0 0 93px;
    }
    .contactsBlock__address {
      left:auto;
      right:-70px;
    }
  }
}
.contactsBlock {
  align-items: center;
  margin-bottom: 207px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 170px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 130px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 90px;
  }

  &__img {
    padding-top: 85.5%;
    background-size: cover;
    background-position: center center;
    margin: -163px -4.5vw -77px -22.3%;
  }
  &__text {
    padding: 36px 14.5%;
  }
  &__title {
    font-family: 'Travels';
    font-weight: 500;
    font-size:30px;
    margin-bottom: 34px;
  }
  &__phone {
    font-size:20px;
    line-height: 1.4;
    text-decoration: none;
  }
  &__worktime {
    font-size:20px;
    line-height: 1.4;
    & + .contactsList__item:not(.contactsBlock__worktime) {
      margin-top: 50px;
    }
  }
  &__map {
    position: relative;
    width: 85%;
    padding-top: 68.5%;
    margin: 0 0 0 19px;

    [class*="copyrights-pane"]{
      bottom: auto !important;
      top: 40px !important;
    }
  }
  &__address {
    background: #ece8e4;
    position: absolute;
    left: -70px;
    bottom: -61px;
    font-size: 20px;
    line-height: 1.4;
    padding: 56px 43px;
    width: 47%;

    @media (max-width: $resolution-1400) {
      padding: 30px;
      line-height: 1.3;
      font-size: 18px;
    }

    @media (max-width: $resolution-1200) {
      padding: 20px;
      line-height: 1.3;
      font-size: 16px;
    }

    &:before {
      content:'';
      position: absolute;
      top:-5px;
      left: 0;
      height:5px;
      width: 67%;
      background: $color-red;
    }
  }
  .contacts-map {
    position: absolute;
    top: 0;
    left:0;
    right:0;
    bottom: 0;
  }
}

.contactsList {
  font-size:16px;
  line-height: 1.5;
  margin-left: 43px;
  &__item {
    position: relative;
    padding-left: 32px;
    margin-bottom: 26px;
    &:before {
      content:'';
      position: absolute;
      top:13px;
      left:0;
      width:4px;
      height:4px;
      background: $color-red;
    }
  }
}