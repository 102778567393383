.recommends {
  margin-bottom: 150px;
  .page-title {
    line-height: 0.83;
    margin-bottom: 67px;
    padding-right: 4.5vw;
  }
  .path {
    padding-top: 24px;
  }
  .page-columns__right {
    margin-top: 0;
  }
}
.recommendsBlock {

  &__text1 {
    font-family: 'Travels';
    font-weight: 500;
    font-size:30px;
    line-height: 40px;
    margin-bottom: 48px;
    padding-right: 9.2vw;
  }

  &__text2 {
    font-size:20px;
    line-height: 30px;
    border-left: solid 5px $color-red;
    padding: 3.25vw 4.2vw 3.6vw 4.3vw;
    margin-left: 9.2vw;
  }

  .slick-slider {
    margin: 0 -4.5vw 0 0;
  }
}

.recommendsBlock2  {
  display: flex;
  margin-top: 42px;
  margin-bottom: 144px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  &__left {
    text-align: center;
    width: 26.5%;
    @media (max-width: 1000px) {
      width: 100% !important;
      max-width: 500px !important;
      margin: 0 auto !important;
    }
  }

  &__img {
    padding-top: 100%;
    background-size:cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 17px;
  }

  &__label {
    font-size: 14px;
    color:#808080;
  }

  &__text {
    font-size:20px;
    line-height: 30px;
    padding: 2% 6% 0 17.6%;
    width: 74.5%;
    box-sizing: border-box;
    @media (max-width: 1000px) {
      padding: 0;
      font-size: 16px;
      width: 100%;
    }
  }
}