.error{
  padding-bottom: 140px;
  padding-top: 40px;
  &__numbers{
    font-size: 420px;
    color: #fff;
    -webkit-text-stroke: 4px #f2eeea;
    // text-shadow: 0 0 4px #f2eeea;
    font-family: 'Travels';
    font-weight: 600;
    letter-spacing: -20px;
    text-align: center;
    animation: error__numbers .6s linear;
    line-height: 1;
  }
  &__title{
    font-size: 48px;
    font-family: 'Travels';
    position: relative;
    margin-bottom: 55px;
    color: #000;
    &:before{
      content: '';
      position: absolute;
      bottom: -15px; left: -20px;
      width: 320px; height: 5px;
      background: #ed1c24;
    }
  }
  &__text{
    max-width: 700px;
    margin: 0 auto;
    padding-left: 97px;
    box-sizing: border-box;
    color: #808080;
    animation: fadeIn .6s linear;
    a{color: #ed1c24;}
  }
  @media (max-height: 800px) {
    padding-bottom: 100px;
    &__numbers{
      font-size: 320px;
      letter-spacing: -17px;
    }
    &__title{
      font-size: 40px;
      margin-bottom: 45px;
      &:before{
        bottom: -12px;
        width: 280px;
      }
    }
  }
  @media (max-height: 700px) {
    padding-bottom: 80px;
    padding-top: 20px;
    &__numbers{
      font-size: 280px;
      letter-spacing: -15px;
    }
  }
  @media (max-width: 780px) {
    padding-top: 40px;
    padding-bottom: 60px;
    &__numbers{
      font-size: 153px;
      letter-spacing: -5px;
      -webkit-text-stroke: 2px #f2eeea;
      margin-left: -$padding_mobile;
      margin-right: -$padding_mobile;
    }
    .error__text{padding-left: 30px; width: 100%; max-width: 300px; font-size: 14px;}
    .error__title{font-size: 21px; margin-bottom: 30px;}
    .error__title:before{
      height: 2px;
      width: 120px;
    }
  }
  @media (max-width: 370px) {
    &__numbers{
      font-size: 133px;
    }
    .error__text{padding-left: 0;}
  }
}
@keyframes error__numbers {
  0% {
    transform: scale(0);
    opacity: 0;
  }
}

