.about{
  overflow: hidden;
  .news-feed{
    padding-bottom: 120px;
    .title{
      text-align: left; 
      br{display: none;}
    }
  }
  .title{font-weight: normal;}
  .form-block--review{
    margin-top: -200px; animation: fadeIn 1s linear;

    @media (max-width: $resolution-1000) {
      margin: 0;
      animation: none;
      padding: 0;
    }
  }
  @media (max-width: $resolution-1000){
    .detail__form-row .btn{
      margin-bottom: 30px;
    }

    .checkbox-field{
      margin-bottom: 0;
    }
  }
}
.slick-slider, .slick-list, .slick-track{
  width: 100%;
}
.about__awards{
  padding-right: 0 !important;
  padding-top: 150px;

  @media (max-width: $resolution-1000) {
    .frame{
      padding: 0;
    }
  }

  &__title{text-align: right; padding-right: 9vw;}
  .tab-nav{
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    border-bottom: 5px solid #ed1c24;
    align-items: flex-end;
    padding-bottom: 35px;

    @media (max-width: $resolution-1000) {
      padding-bottom: 17px;
      position: relative;
      padding-left: 40px;
    }

    .slick-arrow {
      &:before {
        font-size: 27px;
      }
    }
    .slick-prev {
      left: -60px;
    }
    .slick-next {
      right: -60px;
    }
  }
  .tab {
    width: 9.3vw !important;
    min-width: 9.3vw;
    padding-top: 5px;
    font-size: 30px;
    font-weight: 400;
    transition: 400ms;
    font-weight: 500;
    height: 86px;
    line-height: 86px;
    cursor: pointer;

    @media (max-width: $resolution-1000) {
      font-size: 40px;
      line-height: 1;
      height: auto;
    }

    left: 30vw;
    position: relative;

    &.prev{
      left: -2vw;
      display: none;
    }

    &:hover{
      transform: scale(0.9);
    }

  }
  .slick-disabled {
    opacity: 0.3;
    cursor: default;
  }
  .slick-list{overflow: visible;}
  .slick-track{
    display: flex;
    align-items: center;
  }
  .slick-current.slick-active .tab {
      font-size: 72px;
      left: 0vw;

      &:hover{
        transform: scale(1);
      }

      color: #ed1c24;
      padding-bottom: 0;

      @media (max-width: $resolution-1000) {
        font-size: 40px;
        font-weight: 600;
      }
  }

  &__body{
    position: relative;
  }

  &__arrow {
    position: absolute; top: 70px; left: 6.5vw;
    font-size: 27px;
    cursor: pointer;
    transition: $animation-speed transform, $animation-speed opacity;
    z-index: 10;

    &.disable{
      opacity: 0.2;
      pointer-events: none;
    }

    @media (max-width: $resolution-1000) {
      font-size: 30px;
      top: 8px;
      left: auto;
      right: 88px;
    }

    &--right{
      left: calc(6.5vw + 50px);

      @media (max-width: $resolution-1000) {
        left: auto;
        right: 40px;
      }
    }
    @media (min-width: 1000px) {
      &:hover{
        transform: scale(1.2);
        
      }
    }
  }
  .frame{
    //padding-right: 0;
  }
  &__content{
    // display: flex !important;
    // flex-wrap: wrap;
    position: relative;
    border-left: 1px solid #e6e6e6;
    padding-left: 5vw;
    padding-right: 5vw;
    box-sizing: border-box;
    padding-top: 100px;
    padding-bottom: 70px;
    margin-bottom: 60px;
    overflow: hidden;

    @media (max-width: $resolution-1000) {
      padding: 0;
      margin: 0 0 65px 0;
      border: none;
    }

    &>div{
      display: flex;
      flex-wrap: wrap;
      width: 69vw;

      @media (max-width: $resolution-1000) {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    &>span{
      position: absolute;
      top: 50px; left: 50px;
      font-size: 30px;
      font-family: "Travels";
    }
  }
  &__item{
    width: 33.3%;
    padding-right: 4vw;
    padding-left: 76px;
    margin-bottom: 20px;
    margin-top: 110px;
    position: relative;
    box-sizing: border-box;

    div{width: 100%;}
    span{
      position: absolute;
      top: -110px; left: 0;
      height: 87px; width: 87px;
      border-radius: 50%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }
    &:before{
      top: -110px; left: 43px; position: absolute; bottom: 0;
      width: 1px; background: #ccc;
      content: '';
    }
  }
  .slick-slide{
    opacity: 0;
    transition: 400ms;
    &:first-child, &:nth-child(2n+3){
      .about__awards__item:first-child, .about__awards__item:nth-child(2n+3){
        margin-top: 200px;
        &:before{
          top: -160px;
        }
      }
    }
    &:nth-child(2n+2){
      .about__awards__item:nth-child(2n+2){
        margin-top: 200px;
      }
    }
  }

  .tab-nav .slick-slide{
    opacity: 1;
  }

  .about__awards__content > span{
    transition: 500ms opacity;

    @media (max-width: $resolution-1000) {
      opacity: 0;
    }
  }

  .slick-current{
    opacity: 1;
    //.about__awards__content > span{display: none;}

    .about__awards__content > span{
      opacity: 0;
    }

    .slick-active {
      opacity: 1;
    }
  }
}

.about__awards-slider-parrent{
  & > .slick-list > .slick-track > .slick-slide{
    opacity: 0.3;

    &.slick-active{
      opacity: 1 !important;
    }
  }
}

.about__career{
  padding-bottom: 160px;

  @media (max-width: $resolution-1000) {
    padding: 0 0 75px 0;
  }

  &__gray{
    padding-top: 90px;
    padding-bottom: 90px;
    position: relative;

    @media (max-width: $resolution-1000) {
      padding: 0;
      background: #f2efea;
    }

    &__bg{
      background: #ece8e4 url(/static/img/about/line.png) 50% 100% no-repeat;
      background-size: 85vw;
      position: absolute; top: 0; left: 0; width: 100%; height: 100%;
      transform: translateX(-100vw);
      
      @media (max-width: $resolution-1000) {
        display: none;
      }
    }
  }

  .about__career__text{
    margin-bottom: -45px;
  }

  &__body{
    position: relative;
    margin-left: 7.3vw;
    margin-right: 7.3vw;
    font-size: 20px;
    padding-right: 37.5vw;

    @media (max-width: $resolution-1000) {
      margin: 0;
      padding: 50px 40px 0 40px;
      font-size: 17px;

      p{
        margin: 0 0 20px 0;
      }
    }
  }
  &__title{
    text-align: left;
    line-height: 1;
    margin-bottom: 90px;

    @media (max-width: $resolution-1000) {
      margin-bottom: 40px;
      font-weight: 500 !important;
      font-size: 32px !important;
    }
  }
  &__img{
    width: 32.7vw;
    max-width: 560px;
    position: absolute;
    top: 0; right: 0;

    @media (max-width: $resolution-1000) {
      position: relative;
      width: auto;
      max-width: 100%;
      top: 65px;
    }
    span{
      display: block;
      padding-top: 125%;
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
    &:before{
      content: '';
      position: absolute;
      bottom: 12%; top: 56%;
      width: 5px; background: #ed1c24;
    }
  }
}



  
.about__links{
  // padding-right: 46vw;
  padding-top: 90px;
  padding-left: 7.3vw;
  width: 37vw;
  min-width: 550px;

  @media (max-width: $resolution-1000) {
    min-width: 0;
    width: auto;
    padding: 115px 0 0 0;     
  }

  .list{padding-top: 0; margin: 0;}
  .list>li{
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 0;
      padding-top: 0; padding-bottom: 0;
      padding-left: 0;
      &:before{display: none;}
      &:last-child{border: none;}
  }
  &__title{
    font-size: 30px;
    color: #ed1c24;
    font-family: 'Travels';
    margin-bottom: 15px;

    @media (max-width: $resolution-1000) {
      font-size: 28px !important;
      font-weight: 500;
      margin: 0;
    }
  }
  .tab{
    font-size: 20px;
    padding: 35px;
    font-family: inherit;

    @media (max-width: $resolution-1000) {
      padding: 50px 50px 50px 0;
      font-size: 18px;
    }

    &:before{
      color: #7a7876;
      @media (max-width: $resolution-1000) {
        color: #000;
        font-size: 16px;
      }
    }
    &[data-active="true"]:before{transform: rotate(180deg) translateY(55%)}
  }
  .tab-content{
    padding: 0 35px 35px;
    line-height: 1.5;
    margin-top: -10px;

    @media (max-width: $resolution-1000) {
      padding: 0 0 25px 25px;
      font-size: 16px;
      line-height: 1.2;
    }
  }

  .about__links__text{
    @media (max-width: $resolution-1000) {
      font-weight: 500;
      padding-top: 20px;
    }
  }
  
  &__btn{
    display: flex;
    align-items: center;
    font-family: 'Travels';
    font-size: 16px;
    padding-top: 50px;

    @media (max-width: $resolution-1000) {
      display: block;
      padding-top: 20px;
    }

    .btn{
      margin-right: 40px;
      max-width: 280px;
      width: 16vw;
      min-width: 220px;

      @media (max-width: $resolution-1000) {
        max-width: 100%;
        width: auto;
        min-width: 0;
        display: block;
        margin-right: 0 !important;
      }
    }
  }

  @media (max-width: 1440px) {
    &__btn{
      font-size: 15px;
      .btn{
        margin-right: 30px;
      }
    }
  }
}


.about__share{
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 55%;
  background: #f5f5f5;
  margin-bottom: 200px;

  @media (max-width: $resolution-1000) {
    padding: 70px 40px 0 40px;
    display: block;
    height: auto;
    width: auto;
    margin-bottom: 130px;

    img{
      display: block;
      max-width: 100%;
      position: relative;
      top: 50px;
    }
  }
  &__before{
    content: '';
    position: absolute;
    right: 50%; top: 70px; 
    height: 370px; width: 555px;
    background-image: url(/static/img/about/4.jpg);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0; transform: translateX(-20vw);

    @media (max-width: $resolution-1000) {
      display: none;
    }
  }

  &__text{
    font-family: 'Travels';
    font-size: 36px;
    padding-bottom: 50px;
    @media (max-width: $resolution-1000) {
      font-size: 28px;
      font-weight: 500;
      line-height: 1;
      padding-bottom: 40px;
    }

    span{color: #ed1c24;}
  }
  .btn{
    width: 210px;

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }
}

.about__office{
  padding-bottom: 160px;
  padding-right: 0;

  @media (max-width: $resolution-1000) {
    padding: 0;
    margin-bottom: 70px;
  }

  &__title{
    text-align: left;
    margin-bottom: 80px;
    padding: 0 40px;

    @media (max-width: $resolution-1000) {
      font-weight: 500 !important;
      line-height: 1;
      font-size: 32px !important;
      margin-bottom: 45px;
    }
  }
  &__body{
    padding-left: 7.5vw;
    display: flex;
    justify-content: space-between;

    @media (max-width: $resolution-1000) {
      display: block;
      padding: 0;
    }
  }
  &__left{
    width: 33vw;
    min-width: 390px;

    @media (max-width: $resolution-1000) {
      width: auto;
      min-width: 0;
    }
  }
  &__right{
    flex: 1;  
  }
  .list{
    font-size: 20px;

    @media (max-width: $resolution-1000) {
      font-size: 17px;
      padding: 0 40px;
    }

    span{
      /*border-bottom: 1px solid;
      cursor: pointer;*/
      transition: $animation-speed border-color;

      @media(min-width: $resolution-1000) {
        &:hover{
          border-color: transparent;
        }
      }
    }
    li{margin-bottom: 35px;

      @media (max-width: $resolution-1000) {
        margin-bottom: 25px !important;
        padding-left: 20px;
      }

      &:nth-child(2), &:nth-child(3) {
        margin-bottom: 55px;
      }
    }
  }
  &__photo{
    width: 24vw;
    margin-left: 2.9vw;
    position: relative;

    @media (max-width: $resolution-1000) {
      margin: 0 40px -80px 40px;
      width: auto;
    }

    img{
      width: 100%;

      @media (max-width: $resolution-1000) {
        display: block;
      }
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      bottom: 190px;
      height: 5px;
      background: #ed1c24;
      right: 0;
      width: 16vw;
      transform: translateX(100%);
      margin-right: 35px;
      @media (max-width: $resolution-1000) {
        position: absolute;
        bottom: 0;
        right: auto;
        left: -40px;
        width: 60%;
        transform: translateX(0);
        margin-right: 0;
      }
    }
  }
  &__contacts{
    background: #ece8e4;
    margin-top: -190px;
    padding: 55px 0 70px;
    padding-left: 31.6vw;
    padding-right: 20px;
    box-sizing: border-box;
    line-height: 1.5;
    font-size: 20px;

    @media (max-width: $resolution-1000) {
      margin: 0;
      padding: 130px 70px 45px 70px;
      line-height: 1.4;
      font-size: 17px;
    }
  }
}

.about__info{
  position: relative;
  height: 68.5vw;
  width: 100%;
  img{width: 100%;}
  &__text1{
    font-size: 28px;
    padding-bottom: 4.7vw;
    position: absolute;
    top: 0; left: 0;
    // width: 28.92vw;
    line-height: 1.4;
    display: flex;
    font-family: 'Travels';
    width: 100%;
    &>div{
      max-width: 400px;

      @media (max-width: $resolution-1400) {
        max-width: 360px;
      }
    
      @media (max-width: $resolution-1300) {
        max-width: 320px;
      }
    
      @media (max-width: $resolution-1200) {
        max-width: 300px;
      }

    }
    &__line{
      content: '';
      position: absolute;
      bottom: 0; left: 0; 
      width: 100%;
      height: 5px;
      background: #e82c2b;

    }
    &__arrow{
      content: '';
      position: absolute;
      bottom: 0; left: 50%; 
      transform: translateY(170px);
      font-size: 30px;
      cursor: pointer;
      padding: 30px;
      margin: -30px 0 0 -30px;

      &:before{
        display: block;
        transition: $animation-speed transform;
      }

      @media(min-width: 1000px) {
        &:hover:before{
          transform: scale(1.4);
        }
      }

      @media(max-width: 1440px) {
        transform: translateY(70px);
        font-size: 25px;
      }
      @media(max-width: 1280px) {
        transform: translateY(70px);
      }
      
    }
    @media(max-width: 1440px) {
      font-size: 25px
    }
    @media(max-width: 1280px) {
      font-size: 20px
    }
    // &.animate{
    //   width: 100%;
    //   opacity: 1;
    //   font-size: 60px;
    //   line-height: 1;
      
    // }
    // &.animated{
      // width: 28.92vw;
      // font-size: 30px;
    // }
  }
  &__estatet{
    position: absolute;
    top: 26.19vw;
    left: 50%;
    width: 80%;
    height: 10.65vw;
    background-size: auto 80%;
    background-repeat: no-repeat;
    margin-left: -40%;
    &__1{
      background-position: 0 0;
      background-image: url(/static/img/about/info/1.svg);
    }
    &__2{
      background-position: 50% 0;
      background-image: url(/static/img/about/info/2.svg);
    }
    &__3{
      background-position: 100% 0;
      background-image: url(/static/img/about/info/3.svg);
    }
  }
  &__text2{
    font-size: 20px;
    padding-bottom: 4.7vw;
    position: absolute;
    top: 9.5vw; left: 31vw;
    width: 27.5vw;
    line-height: 1.4;
    @media(max-width: 1440px) {
      font-size: 17px
    }
    @media(max-width: 1280px) {
      font-size: 15px
    }
    @media(max-width: 1100px) {
      top: 6vw;
      width: 32vw;
    }
  }
  &__img1{
    position: absolute;
    // top: -11.3vw; 
    top: calc(-60px - 96px);
    left: 47.6vw;
    width: 11.4vw;
    @media (max-width: 1480px) {
      top: calc(-60px - 86px);
    }
    @media (max-width: 1300px) {
      top: calc(-60px - 76px);
    }
    @media (max-width: 1200px) {
      top: calc(-60px - 69px);
    }
  }
  &__img2{
    position: absolute;
    top: 0; right: 0;
    width: 28.85vw;
  }
  &__img3{
    position: absolute;
    top: 34.1vw; left: 11vw;
    width: 17.73vw;
  }
  &__img4{
    position: absolute;
    top: 50.7vw; left: 59.5vw;
    width: 18.6vw;
  }
  &__line{
    content: '';
    position: absolute;
    bottom: 0; left: 50%; margin-left: -2px;
    width: 5px;
    height: 27.9vw;
    background: #e82c2b;
  }
  
}



.gsap1{
  transform-origin: center top;
  transform: scale(1.6) translateX(80%);
  line-height: 1.2;
  @media(max-width: 1440px) {
    transform: scale(1.3) translateX(90%);
  }
  @media(max-width: 1280px) {

  }

  @media (max-width: $resolution-1000) {
    transform: scale(1) translateX(0);
  }
  
}
.gsap2{
  transform: translateY(250px);
  min-width: 25vw;
  @media(max-width: 1440px) {
    transform: translateY(120px);
  }
  @media(max-width: 1280px) {
    transform: translateY(100px);
  }

  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}
.gsap3{
  transform: translateY(100vh);

  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}
.gsap4{
  transform: translateY(80vh);

  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}
.gsap5{
  transform: translateY(110vh);

  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}
.gsap6{
  transform: translateY(120vh);

  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}
.gsap7{
  transform: translateY(120vh);

  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}
.gsap8{
  transform: translateY(120vh);

  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}
.gsap9{
  transform: translateY(100vh);

  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}
.gsap10{
  transform: translateY(100vh);

  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}
.gsap11{
  transform: translateY(100vh);

  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}
.gsapFadeInUp{
  transform: translateY(100vh);
  opacity: 0;

  @media (max-width: $resolution-1000) {
    transform:translateY(0);
    opacity: 1;
  }
}
.gsapFadeInUp2{
  opacity: 0;

  @media (max-width: $resolution-1000) {
    opacity: 1;
  }
}

.up1{
  transform: translateY(50vh);
  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}
.up2{
  transform: translateY(100vh);
  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}
.up3{
  transform: translateY(150vh);
  @media (max-width: $resolution-1000) {
    transform:translateY(0);
  }
}

.about__awards__title{
  margin-bottom: 50px;
}

.about__info-wrap{
  overflow: hidden;
  @media (max-width: $resolution-1000) {
    padding: 0;
    
    .page-title{
      padding-left: 40px;
      padding-right: 40px;
      margin-bottom: 25px;
    }
  }
}

.about{
  @media (max-width: $resolution-1000) {
    .about__info{
      height: auto;
      width: auto;
    }

    .about__info__text1{
      position: relative;
      padding: 0 40px 45px 0;
      margin-left: 40px;
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 24px;
      line-height: 1.2;
      display: block;
      width: auto;
    }

    .about__info__text1 > div{
      max-width: 100%;
      white-space: normal;
    }

    .about__info__text2{
      position: relative;
      width: auto;
      top: 0;
      left: 0;
      padding: 0 40px 45px 75px;
      font-size: 16px;
      line-height: 1.8;
    }

    .about__mobile-img1{
      padding: 0 15px;
      margin-bottom: -14px;
      img{
        display: block;
        width: 100%;
      }
    }

    .about__mobile-logo{
      margin-bottom: 25px;
      img{
        display: block;
        width: 100%;
      }
    }

    .about__mobile-line{
      width: 5px;
      height: 175px;
      background: $color-red;
      margin: 0 auto 25px auto;
    }

    .about__mobile-img2{
      max-width: 238px;
      margin: 0 auto 75px auto;

      img{
        display: block;
        width: 100%;
      }
    }

    .about__awards{
      padding-top: 0;
    }

    .about__awards__title{
      padding: 0 40px;
      margin: 0 0 40px 0;
      text-align: left;
      font-size: 32px !important;
      line-height: 1;
      font-weight: 500;
    }

    .news-feed{
      padding: 0;
      margin-bottom: 75px;
    }

    .news-feed .title{
      font-size: 32px !important;
      line-height: 1;
      font-weight: 500;
      margin-bottom: 45px;
      padding: 0 40px;
    }

    .news-feed__item{
      padding: 0 40px;
      margin-bottom: 35px;
      width: auto;
    }

    .index__partners{
      padding: 0 40px;
      margin-bottom: 70px;

      .title{
        font-size: 32px !important;
        line-height: 1;
        font-weight: 500;
        margin-bottom: 45px;
        padding: 0;
        text-align: left;
      }

      .index__partners__item{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .apartment-search__view{
      margin: 0 !important;
      padding: 0 !important;
    }

    .apartment-search__view-title{
      border-top: none !important;
      padding: 0 40px 45px 40px !important;
      margin: 0 !important;
    }

    .apartment-object .apartment-search__slider{
      padding: 0 !important;
    }

    .apartment-object{
      margin-bottom: 0;
    }

    .apartment-search__slider{
      margin-bottom: 75px;
    }
  }
}

.about__awards__content-mobile{
  padding-top: 100px;
  margin-bottom: 20px;

  & > span{
    opacity: 1 !important;
    font-size: 40px;
    font-weight: 600;
    left: 33px;
    color: $color-red;
    top: 0;
  }

  .about__awards__item{
    width: auto;
  }
}

.about__awards-mobile.about__awards .slick-track{
  align-items: flex-start;
}

.about__awards-mobile .slick-slider:after{
  content: '';
  display: block;
  left: 0;
  top: 60px;
  right: 0;
  height: 5px;
  position: absolute;
  background: $color-red;
}

.about__awards-mobile .slick-arrow{
  font-size: 30px;
  top: 22px;
  left: auto;
  right: 88px;
  font-size: 0;

  &:before{
    font-size: 30px;
  }

  &.slick-next{
    right: 40px;
  }
}

.about__press {
  .news-feed__load-more {
    margin-top: 10px;
    font-size: 16px;
  }
  .news-feed__filter__item {
    @media (max-width: $resolution-1000) {
      margin-left: 20px;
    }
  }
}
.pressList {
	display:flex;
	flex-wrap: wrap;
	margin: 0 -20px;

    @media (max-width: $resolution-1200) {
      margin: 0 -10px;
    }
    @media (max-width: $resolution-1000) {
      padding: 0 40px;
    }
	&__item {
		position:relative;
		border:solid 1px #e6e6e6;
		width:calc(33.333% - 40px);
		margin: 0 20px 39px;
		box-sizing: border-box;

        @media (max-width: $resolution-1200) {
          width:calc(50% - 20px);
          margin: 0 10px 39px;
        }
        @media (max-width: $resolution-767) {
          width:100%;
          margin-bottom: 25px;
        }
		a {
			display:block;
			text-decoration:none;
			padding:46px 49px 48px;
            @media (max-width: $resolution-1000) {
              padding: 30px;
            }
            @media (max-width: $resolution-767) {
              padding: 25px;
            }
            &:hover {
              .pressList__title span {
                border-bottom-color: inherit;
              }
            }
		}
		&:nth-child(6n - 4):before {
			content:"";
			position:absolute;
			top: -1px;
			left: -1px;
			height:5px;
			background:#ed1c24;
			width:36%;
		}
		&:nth-child(6n - 3):before {
			content:"";
			position:absolute;
			top: -1px;
			left: -1px;
			height: 5px;
			background: #ed1c24;
			width: 23.5%;
		}
		&:nth-child(6n - 2):before {
			content:"";
			position:absolute;
			top: -1px;
			left: -1px;
			height:5px;
			background:#ed1c24;
			width:19.5%;
		}
	}
	&__title {
		font-family: "Travels";
		font-weight:500;
		font-size:24px;
		margin-bottom: 18px;
		line-height: 1.22;
		letter-spacing: 0.01em;
        span {
          border-bottom: solid 1px transparent;
          transition: border 0.3s;
        }
        @media (max-width: $resolution-767) {
          font-size:20px;
        }
	}
	&__text {
		font-size:16px;
		margin-bottom:20px;
        @media (max-width: $resolution-767) {
          font-size:14px;
        }
	}
	&__date {
		font-family: "Travels";
		font-size:14px;
        @media (max-width: $resolution-767) {
          font-size:12px;
        }
	}
}