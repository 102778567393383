.index__top{
  height: calc(100vh - 129px - #{$header_height});
  display: flex;
  min-height: 580px;

  @media screen and (max-width: 1600px), screen and (max-height: 950px) {
    min-height: 560px;
  }

  @media screen and (max-width: 1500px), screen and (max-height: 850px) {
    min-height: 530px;
  }

  @media screen and (max-width: 1400px), screen and (max-height: 750px) {
    min-height: 520px;
  }

  @media screen and (max-width: 1300px), screen and (max-height: 650px) {
    min-height: 500px;
  }

  @media screen and (max-width: 1200px), screen and (max-height: 550px) {
    min-height: 480px;
  }


  justify-content: space-between;
  @media (max-width: 1000px) {
    height: auto !important;
    flex-direction: column;
    min-height: 0;
    max-height: initial;
    overflow: hidden;
    width: 100%;
  }

}
.index__events, .index__find-flat, .index__top-slider{
  background: #fff;
  box-sizing: border-box;
  width: 33.3%;
  min-width: 33.3%;
  max-width: 33.3%;
  padding-top: 0;
  padding-bottom: $padding;
  display: flex;
  z-index: 1;
  align-items: center;

  .index__cbox{
    width: 100%;
  }

  @media (max-width: 1000px) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding-bottom: 60px;
  }
}

.index__events__title, .index__find-flat__title{
  font-family: 'Travels';
  font-weight: 500;
  font-size: 72px;
  line-height: .9;
  margin-bottom: 30px;
  position: relative;

/*  @media screen and (max-width: 1630px), screen and (max-height: 950px) {
    font-size: 60px;
    margin-bottom: 25px;
  }*/
  @media screen and (max-width: 1960px) {
    font-size: 60px;
    margin-bottom: 25px;
  }
  @media screen and (max-width: 1630px) {
    font-size: 50px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1500px), screen and (max-height: 850px) {
    font-size: 50px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1400px), screen and (max-height: 750px) {
    font-size: 45px;
  }

  @media screen and (max-width: 1300px), screen and (max-height: 650px) {
    font-size: 40px;
  }

  @media screen and (max-width: 1200px), screen and (max-height: 550px) {
    font-size: 35px;
  }

  @media (max-width: 1000px) {
    padding-top: 25px;
    br{display: none;}
  }
}
.index__events__link{
  font-size: 16px;
  font-family: 'Travels';
  font-weight: 500;
  span{border-bottom: 1px dashed #a6a6a6;}

}
.index__find-flat{
  background: #f5f5f5;
  position: relative;

  @media (max-width: $resolution-1000){
    .filter-field__body{
      .filter-input{
        display: flex;
        justify-content: space-between;
        width: 100%;

        span{
          margin: 0 8px;
        }
      }

      input{
        flex-grow: 1;
        text-align: center;
        padding: 0 5px;

        &:first-child{
          text-align: right;
        }

        &:last-child{
          text-align: left;
        }
      }
    }
  }


  &>div{
    overflow: hidden;
    padding-left: 1px;
    padding-right: 1px;
  }
  &:before{
    content: '';
    position: absolute;
    top: -300px; left: 0;
    height: 350px;
    width: 100%;
    background: #f5f5f5;
    @media (max-width: 1000px) {
      display: none;
    }
  }
  &__body{
    font-size: 16px;
    font-family: 'Travels';
    font-weight: 500;
    margin-top: 30px;
  }

  &__btn{
    display: flex;
    margin-top: -10px;
    justify-content: flex-end;

    a, span{width: 100%; white-space: nowrap;}
    & > a:first-child,
    & > span:first-child{width: auto; min-width: 45%;}
    //& > span:first-child{width: 100%; min-width: 100%;}
    //& > a:last-child, & > span:last-child{margin-left: 10px;}
    & > a:last-child, & > span:last-child{margin-left: 0;}
    @media (max-width: 1000px) {
      flex-direction: column;
      & > a:first-child,& > span:first-child{width: 100%; min-width: 100%; margin-bottom: 5px;}
      & > a:last-child, & > span:last-child{margin-left: 0;}
    }
  }
  @media (max-width: 1000px) {
    margin-bottom: 25px;
  }
}
.index__top-slider{
  position: relative;
  padding-bottom: $header_height;
  @media (max-width: 1000px) {
    padding-bottom: 0;
    height: 340px;
  }
}

.index__slider{
  position: absolute; bottom: -$header_height; left: 0; top: 0;
  width: 100%;
  .slick-arrow{
    z-index: 3;
    left: auto; bottom: 115px; top: auto; transform: none; color: #fff;
    right: 3.1vw;

    @media (min-width: $resolution-1000) {
      &:hover{
        transform: scale(1.2);
      }
    }
  }
  .slick-prev{
    margin-right: 34px;
  }

  &-item{position: relative;}
  &-item:after{
    content: '';
    position: absolute;
    height: 35%; width: 100%;
    bottom: 0; left: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,.01) 0%, rgba(0,0,0,.65) 100%);
  }
  &-item__title{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-transform: uppercase;
    height: 105px;
    display: flex;
    align-items: center;
    padding-left: 45px;
    font-size: 14px;
    z-index: 100;
    font-family: 'Travels';
    color: #fff;
  }
  @media (max-width: 1000px) {
    bottom: auto; height: 100%;
    .slick-arrow{
      bottom: auto; top: 190px;
    }
    .slick-prev{right: 50px;}
    .slick-next{right: 20px;}
  }
}

.index__list{
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;

  a{
    text-decoration: none;

    @media (min-width: $resolution-1000) {
      &:hover{
       text-decoration: underline;
      }
    }
  }

  .list{
    // overflow: auto;
    width: 100%;
    max-height: 100%;
    font-size: 17px;
    @media (max-width: $resolution-1600) {
      font-size: 17px;
    }

    @media (max-width: $resolution-1400) {
      font-size: 16px;
    }

    @media (max-width: $resolution-1300) {
      font-size: 15px;
    }

    @media (max-width: $resolution-1200) {
      font-size: 14px;
    }

    li{
      margin-bottom: 20px;
      @media (max-width: $resolution-1600) {
        margin-bottom: 17px;
      }
      @media (max-width: $resolution-1400) {
        margin-bottom: 17px;
      }

      @media (max-width: $resolution-1300) {
        margin-bottom: 15px;
      }

      @media (max-width: $resolution-1200) {
        fmargin-bottom: 13px;
      }

    }

  }
}


.index__promotions{
  position: absolute;
  bottom: 0; right: 0;
  background: rgba(236, 232, 228, .9);
  padding: 1vw 0;
  z-index: 1;
  box-sizing: border-box;
  transform: translateX(100%);
  min-height: 100%;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-family: "Travels";
  animation: index__promotions .5s linear;
  overflow: hidden;
  width: 24vw;

  @media (max-width: $resolution-1000) {
    .slick-dots{
      top: -20px;
      position: relative;
    }
  }

  .slick-dots button{
    background: #fff;
  }

  .slick-dots .slick-active button{
    background: $color-red;
  }
  .slick-slide {
    padding-right: 1px;
  }

  .slick-slide > div{
    position: relative;
    left: 3px;
  }

  @media (max-width: $resolution-1000) {
    .slick-slide > div{
      display: flex;
      align-items: center;
      left: 0;
    }
  }

  .slick-track{
    display: flex;
    align-items: center;
  }

  &__title{
    text-transform: uppercase;
    color: #ed1c24;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 14px;
  }
  &>div{
    position: relative;
    padding: 0 1vw 0 2.5vw;
    box-sizing: border-box;
    width: 24vw;
    min-width: 24vw;
    font-size: 16px;

    @media (max-width: $resolution-1680) {
      font-size: 15px;
    }
    @media (max-width: $resolution-1300) {
      font-size: 14px;
    }
    @media (max-width: $resolution-1200) {
        font-size: 13px;
    }
  }
  &>div:before{
    content: '';
    position: absolute; top: 0; bottom: 0; left: 0;
    width: 4px; background: #ed1c24;
  }
  a {
    text-decoration: none;
  }
  @media (max-width: $resolution-1680) {
     font-size: 15px;
  }
  @media (max-width: $resolution-1600) {
    &>div{
      width: 24vw;
      width: 24vw;
    }
  }
  @media (max-width: $resolution-1300) {
     font-size: 14px;
  }
  @media (max-width: $resolution-1200) {
     font-size: 13px;
  }
  @media (max-width: 1000px) {
    bottom: auto; top: 0; left: 0; right: auto;
    margin: 0;
    transform: translateY(-100%);
    height: 130px; min-height: 0;
    width: 100%;
    box-sizing: border-box;
    &>div{
      padding:0 20px 0 40px;
      width: 100%;
      min-width: 100%;
    }

  }

}

@keyframes index__promotions {
  0% {
    width: 0;
  }
}

.index__links-in{

  & > div{
    margin-bottom: 15px;
    text-align: center;
  }

  & > div:last-child{
    margin-bottom: 0;
  }

  a{
    font-family: "Travels";
    font-weight: 500;
    font-size: 17px;
    line-height: 17px;
    text-decoration: none;
    border-bottom: 1px solid #000;

    @media (max-width: $resolution-1000) {
      border-bottom: none;
    }
  }
}

.index__links-wrap{
  display: flex;
  justify-content: space-between;
}

.index__links-column{
  width: 31%;
}

.index__links-column:nth-child(1){
  .index__links__item:nth-child(2n+3) .index__links__item__img{padding-top: 100%;}
  .index__links__item:nth-child(2n+3) .index__links__item__title:after {width:30%}
}

.index__links-column:nth-child(2){
  .index__links__item:nth-child(odd) .index__links__item__img{padding-top: 100%;}
  .index__links__item:nth-child(odd) .index__links__item__title:after {width:48%}
}

.index__links-column:nth-child(3){
  .index__links__item:nth-child(2n+2) .index__links__item__img{padding-top: 100%;}
  .index__links__item:nth-child(2n+2) .index__links__item__title:after {width:10%}
}

.index__links{
  position: relative;
  width: 90%;
  font-size: 18px;
  margin-bottom: 250px;
  box-sizing: border-box;
  z-index: 1;
  @media (max-width: 1000px) {
    margin-bottom: 75px !important;
    width: auto;
  }

  &[data-open="true"] {
    z-index: 1;
  }
  .tab-nav{
    display: flex;
    height: 129px;
    align-items: center;
    background: #ece8e4;
    margin-right: 33.3%;
    position: relative;
    width: 74%;
    box-sizing: border-box;
    margin-left: 0;

    .tab{
      position: relative;
      left: -25px;

      @media (max-width: 1000px) {
        left: 0;
      }
    }

    @media (max-width: 1000px) {
      margin-right: 0;
      height: auto;
      padding-top: 30px;
      padding-bottom: 20px;
      width: auto;
    }
  }
  .tab-content{
    background: #ece8e4;
    padding-top: 41px;
    @media (max-width: 1000px) {
      padding-top: 0;
    }
  }

  &__body{
    padding-bottom: 80px;
    @media (max-width: 1000px) {
      padding-left: 0; padding-right: 0;
      padding-bottom: 50px;
    }
    .sign-up-for-central-office {
      padding: 0 10vw 0 7vw;
      background: none;
      &:before {
        display: none;
      }
    }
    .sign-up-for-central-office__title {
      background: none;
    }
    .services__links {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      background: none;
    }
  }
  &__item{
    position: relative;
    cursor: pointer;
    text-decoration: none;
    margin-bottom: auto;
    display: block;
    margin-bottom: 37px;
    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 5px;
      background: #ed1c24;
      transition: 600ms;
      opacity: 0;
      @media (max-width: 1000px) {
        width: 170px !important;
        opacity: 1;
        left: 7px;
      }
    }
    &:nth-child(1):before{width: 25%;}
    &:nth-child(2):before{width: 65%;}
    &:nth-child(3):before{width: 13%;}
    &:nth-child(4):before{width: 84%;}
    &:hover:before{
      width: 100% !important;
    }

    &__img{
      padding-top: 57%;
      width: 100%;
      overflow: hidden;
      position: relative;
      display: block;
      margin-bottom: 30px;
      span{
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute; top: 0; left: 0;
        width: 100%;
        height: 100%;
        transition: 2000ms;
      }
      @media (max-width: 1000px) {
        padding-top: 0;
        height: 350px;
        margin-bottom: 20px;
        margin-left: 7px; margin-right: 7px;
        width: auto;
      }
    }
    /*&:nth-child(1) {width: 26.4vw;}
    &:nth-child(1) .index__links__item__img{padding-top: 136%;}
    &:nth-child(1) .index__links__item__title{padding-bottom: 55px;}
    &:nth-child(2) {width: 20.7vw;}
    &:nth-child(2) .index__links__item__img{padding-top: 117%;}
    &:nth-child(3) {width: 20.4vw;}
    &:nth-child(3) .index__links__item__img{padding-top: 54%;}
    &:nth-child(4) {width: 30vw;}
    &:nth-child(4) .index__links__item__img{padding-top: 52%;}*/
    &:hover &__img span{
      transform: scale(1.1);

    }
    &__title{
      position: relative;
      font-family: 'Travels';
      font-weight: 500;
      font-size: 30px;
      //max-width: 190px;
      display: block;
      line-height: 1;
      padding-bottom: 25px;
      @media(max-width: $resolution-1500) {
        font-size: 25px;
      }
      @media(max-width: $resolution-1400) {
        font-size: 20px;
      }
      @media (max-width: 1000px) {
        margin-left: 7px; margin-right: 7px;
        font-size: 24px;
      }

      &:after {
        content:'';
        position: absolute;
        left:0;
        bottom: 0;
        height: 5px;
        background: $color-red;
        width: 18%;
      }
    }
  }


  @media (max-width: $resolution-1600){
    font-size: 16px;
  }

  @media (max-width: $resolution-1400){
    font-size: 15px;
  }
}



.index__realty-types{
  position: relative;
  padding-right: 0 !important;
  margin-bottom: calc(220px + 7.7vw);
  @media (max-width: 1000px) {
    margin-bottom: 50px !important;
    .slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div, .slick-slide > div > div, .slider-item{
      height: auto;
    }
    .slick-list{transition: 600ms;}
  }
  &>div{position: relative;}
  &__item{
    position: absolute;
    display: block;
    text-decoration: none;
    @media (max-width: 1000px) {
      width: 100% !important;
      position: relative; top: 0 !important; left: 0 !important;
      &__img{
        padding-top: 0 !important; height: 245px; position: relative;
        margin: 0 15px;
      }
    }
    &__title{
      position: absolute; top: 0; left: 0;
      background: #fff;
      font-family: 'Travels';
      font-size: 30px;
      padding: 1.4vw 1.4vw 1.4vw 1.1vw;
      font-weight: 500;
      i{
        font-style: normal;
        font-size: 18px;
        display: block;
        font-family: 'Georgia';
        padding-top: 1.2vw;
        @media (max-width: $resolution-1480) {
          font-size:14px;
        }
      }
      &:after{
        content: '';
        position: absolute; left: 0;
        top: 37px;
        height: 4px;
        width: 80%;
        background: #ed1c24;
        right: 100%;
        left: auto;
      }
      @media (max-width: $resolution-1480) {
        font-size:24px;
      }
      @media (max-width: 1000px) {
        position: relative; top: 0 !important; left: 0 !important;
        font-size: 24px;
        padding: 25px;
        transform: translateY(-45px) !important;
        margin: 0 15px;

        i{font-size: 16px; padding-top: 20px;}
        &:after{
          position: static;
          transform: none;
          display: block;
          width: 85px;
          margin-top: 25px;
        }
      }
    }
    &__img > span{
      position: absolute; top: 0; left: 0;
      width: 100%; height: 100%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      transition: 1200ms;
    }
    &--1{
      position: relative; top: 5.4vw; left: 0;
      width: 46%;
      .index__realty-types__item__img{
        padding-top: 74%;
      }
      .index__realty-types__item__title{
        top: 100%;
        margin-top: -36px;
        left: -1.1vw;
        max-width: 66%;
      }
    }
    &--2{
      width: 21%; top: 0; left: 42%;
      .index__realty-types__item__img{
        padding-top: 128%;
      }
      .index__realty-types__item__title{
        top: 0;
        margin-top: -80px;
        max-width: 68%;
        left: -3vw;
      }
    }
    &--3{
      width: 21.8%; top: 16.1vw; left: 60.2%;
      z-index: 1;
      .index__realty-types__item__img{
        padding-top: 72%;
      }
      .index__realty-types__item__title{
        top: 100%;
        margin-top: -50px;
        max-width: 60%;
        left: -3vw;
        &:before{
          width: 30%;
        }
        &:after {
          width:27%;
        }
      }
    }
    &--4{
      width: 26.8%; top: 7.7vw; right: 0;
      .index__realty-types__item__img{
        padding-top: 126%;
      }
      .index__realty-types__item__title{
        top: 100%;
        margin-top: -60px;
        max-width: 63%;
        left: -12%;
        z-index: 1;
        &:before{
          width: 65%;
        }
        &:after {
          width:57%;
        }
      }
    }
  }
}

.index__mortgage-scr{
  position: absolute;
  left: 0;
  top: -80px;

  @media (max-width: 1000px) {
    top: -70px;
  }
}

.index__mortgage{
  background: #f2f2f2;
  position: relative;
  @media (max-width: 1000px) {
    background: transparent;
    position: relative;
    padding-left: 0;
    padding-right: 0;

    &:before{
      content: '';
      position: absolute; top: 0; left: 0; width: 100%; height: 620px;
      background: #f2f2f2
    }
  }
  .filter-input{width: 100%;}
  input{
    width: 100% !important;
    @media (max-width: 1000px) {
      font-size: 21px;
    }
  }
  .rc-slider-handle, .rc-slider-track{background: #ed1c24}
  .tab-nav{
    padding-bottom: 85px;
    padding-top: 68px;
    @media (max-width: 1000px) {
      padding-top: 50px;
      padding-bottom: 20px;
      align-items: center;
    }
  }

  &__info{
    height: 222px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 510px;
    animation: fadeIn 1s linear;
    @media (max-width: 1300px) {
      margin-bottom: 410px;
    }
    @media (max-width: 1000px) {
      height: auto;
      flex-direction: column;
      margin-bottom: 100px;
    }
    i{
      font-size: 24px;
      margin-left: 10px; margin-right: 10px;
      display: inline-block;
      bottom: 15px; position: relative;
    }
    &__left{
      margin-right: 40px;
      width: 34%;
      min-width: 34%;
      height: 485px;
      background-position:  100% 100%;
      background-repeat: no-repeat;
      background-size: cover;
      //background-image: url(/static/img/index/mortgage/1.jpg?t=3);
      @media (max-width: 1000px) {
        background: transparent;
        height: auto;
        width: 100%; min-width: 100%;
        margin: 0;
        padding-left: 40px;
        padding-right: 40px;
        box-sizing: border-box;
      }
    }
    &__right{
      width: 100%;
      padding-top: 60px;
      @media (max-width: 1000px) {
        padding-top: 25px;
      }
    }
    &__item-wrap{
      @media (max-width: 1000px) {
        padding: 0 40px;
        box-sizing: border-box;
      }
    }
    &__item{
      width: calc(100% / 3);
      margin-left: -1px;
      padding: 0 10px;
      border: 1px solid #e6e6e6;
      font-size: 16px;
      float: left;
      box-sizing: border-box;
      text-align: center;
      position: relative;
      @media (max-width: 1000px) {
        margin-left: 0;
        width: auto;
        min-width: 0;
        box-sizing: border-box;
        float: none;
      }
      @media (max-width: 370px) {
        width: 240px;
        min-width: 240px;
      }
      &:before{
        content: '';
        position: absolute; top: 40px; left: 0;
        width: 6px; height: 79px;
        background: #ed1c24;
      }
      &[data-value="0"]:before{top: 124px; }
      &[data-value="2"]:before{top: auto; bottom: 0; }
      &__title{
        font-size: 100px;
        font-family: 'Styrene';
        font-weight: 100;
        line-height: 1;
        height: 163px;
        display: flex;
        align-items: center;
        justify-content: center;
        span{font-size: 75px;}
        i{font-style: normal;}
        @media (max-width: 1300px) {
          font-size: 75px;
          span{font-size: 50px; }
        }
      }
      &__text{
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        div {
          /*border-bottom: solid 1px;*/
          transition: 0.3s border;
        }
        &:hover {
          div {
            border-bottom-color: transparent;
          }
        }
        @media (max-width: 1300px) {
          font-size: 14px;
        }
      }

    }
    &__filter{
      max-width: 82%;
      margin: 0 auto;
      padding-top: 59px;
      @media (max-width: 1000px) {
        max-width: 100%;
        padding-top: 0px;
      }
      .filter-field--single .filter-field__body {
        padding: 31px 17px 14px;
      }
      .filter-field__min,
      .filter-field__max {
        color: #000;
      }
    }
    &__btn{
      width: 62%;
      min-width: 200px;
      margin-top: 50px;
      padding-top: 28px;
      padding-bottom: 27px;
      @media (max-width: 1000px) {
        width: 100%;
      }
    }
  }
  .slick-slider{
    width: calc(100% + 40px);
    margin-top: 25px;

    @media (max-width: 1000px) {
      width: 100%;
    }
    .slick-dots{
      margin-top: 35px;
      padding-right: $padding_mobile;

      @media (max-width: 1000px) {
        padding-right: 0;
      }
    }
  }
  &__calc{
    padding-bottom: 150px;
    margin-bottom: 200px;
    @media (max-width: 1000px) {
      padding-bottom: 50px;
      margin-bottom: 100px;
    }
  }
  .tab-content {
    .frame {
      padding: 0;
    }
  }
  .apartment-calc {
    padding-top: 0;
  }
}
.animated {
    .index__mortgage__info__left {
        background-image: url(/static/img/index/mortgage/1.webp?t=3);
    }
}


.index__mortgage__partners{
  position: relative;
  margin-bottom: 200px;
  display: flex;

  @media (max-width: 1000px) {
    padding-bottom: 50px;
    margin-bottom: 100px;
    display: block;
  }

  & > div:first-child{
    width: 42%;
    position: relative;
    z-index: 3;
    animation: fadeIn 1s linear;

    @media (max-width: 1000px) {
      width: auto;
    }
  }

  & > div:last-child{
    width: 58%;
    position: relative;
    z-index: 3;
    animation: fadeIn 1s linear;

    @media (max-width: 1000px) {
      width: auto;
    }
  }

  .slick-slider{
    height: 160px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;

    @media (max-width: 1000px) {
      position: relative;
    }
  }

  .slick-slider .slick-dots{
    margin-top: 0;
  }

  &:after{
    content: '';
    display: block;
    left: -4.5vw;
    bottom: 0;
    right: -4.5vw;
    height: 160px;
    background: #fff;
    position: absolute;

    @media (max-width: 1480px){
      left: -3.5vw;
      right: -3.5vw;
    }

    @media (max-width: 1200px){
      left: -25px;
      right: -25px;
    }

    @media (max-width: 1000px){
      left: -40px;
      right: -40px;
      height: 190px;
    }
  }
}

.index__mortgage__partners-img{
  width: 80%;
  max-width: 520px;
  height: 485px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @media (max-width: $resolution-1400) {
    height: 450px;
  }

  @media (max-width: $resolution-1300) {
    height: 400px;
  }

  @media (max-width: $resolution-1200) {
    height: 360px;
  }

  @media (max-width: $resolution-1000) {
    width: auto;
    max-width: 100%;
    height: 200px;
    margin: 0 40px;
  }

  &:after{
    content: '';
    display: block;
    width: 180px;
    height: 5px;
    position: absolute;
    background: $color-red;
    right: -113px;
    bottom: 30px;

    @media (max-width: $resolution-1400) {
      right: -100px;
      width: 160px;
    }

    @media (max-width: $resolution-1300) {
      right: -80px;
      width: 140px;
    }

    @media (max-width: $resolution-1200) {
      right: -60px;
      width: 120px;
    }
  }
}

.index__mortgage__partners-text{
  padding-left: 9%;
  padding-top: 95px;
  font-size: 18px;
  line-height: 1.6;

  @media (max-width: $resolution-1400) {
    font-size: 17px;
    line-height: 1.5;
    padding-top: 80px;
    padding-left: 8%;
  }

  @media (max-width: $resolution-1300) {
    font-size: 16px;
    line-height: 1.4;
    padding-top: 60px;
    padding-left: 6%;
  }

  @media (max-width: $resolution-1200) {
    font-size: 16px;
    line-height: 1.3;
    padding-top: 50px;
    padding-left: 4%;
  }

  @media (max-width: $resolution-1000) {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 0;
    margin-bottom: 20px;
  }
}

.index__mortgage__partners-slide{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  padding: 0 5%;

  img{
    display: block;
    max-width: 142px;
    filter: grayscale(100%);

    @media (max-width: $resolution-1400) {
      max-width: 90%;
    }

    @media (max-width: $resolution-1300) {
      max-width: 80%;
    }

    @media (max-width: $resolution-1000) {
      max-width: 60%;
      margin: 0 auto;
    }
  }
}

.index__buy{
  height: 300px;
  position: relative;
    &.animated {
        background-image: url('/static/img/index/estatet4.png');
        @media (max-width: 1000px) {
            background-image: url('/static/img/index/estatet4m.png');
        }
    }
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #ed1b24;
  background-size: auto 169px;
  margin-bottom: 250px;
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 55%;

  @media (max-width: 1000px) {
    //background: #ed1b24 url('/static/img/index/estatet4m.png') 0 50% no-repeat;
      background-position: 0 center;
      background-repeat: no-repeat;
      background-color: #ed1b24;

      background-size: auto 100%;
  }
  &:before{
    content: '';
    position: absolute;
    right: 50%; top: -135px;
    height: 595px; width: 711px;
    background-image: url(/static/img/index/6.png?t=3);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;

  }
  @media (max-width: 1000px) {
    height: auto !important;
    flex-direction: column;
    padding-left: $padding_mobile !important;
    padding-right: $padding_mobile !important;
    margin-bottom: 65px !important;
    padding-top: 215px;
    padding-bottom: 45px;
    &>div{width: 100%;}
    &:before{
      content: '';
      position: absolute;
      right: auto !important; left: 0; top: -60px;
      height: 300px !important; width: 100% !important;
      background-image: url(/static/img/index/6.png?t=3);
      background-position: 50% 0;

    }

  }
  &.animated:before{
    animation: fadeInRight20 1s ease-in-out;
  }
  &__title{
    font-family: 'Travels';
    font-weight: 500;
    font-size: 48px;
    margin-bottom: 25px;
    @media (max-width: 1000px) {
      font-size: 30px !important;
    }
  }
  &__text{
    margin-bottom: 30px;
    @media (max-width: 1000px) {
      margin-bottom: 35px !important;
    }
  }
  &__btn{
    min-width: 280px;
    @media (max-width: 1000px) {
      width: 100%;
      min-width: 0;
    }
  }
}

.index__subscribe{
  background: #f2f2f2;
  background-size: 1074px auto;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 270px;
  @media (max-width: 1000px) {
    background: #f2f2f2;
    height: auto !important;
    flex-direction: column;
    padding-top: 70px;
    margin-bottom: 75px !important;
    padding-bottom: 75px;
    div{width: 100%;}
  }
  .subscribe-form{
    padding-top: 30px;
    margin-left: 5.1vw;
    @media (max-width: 1000px) {
      padding-top: 0;
      margin-left: 0;
    }
  }
  &__text{
    margin-bottom: 13px;
    @media (max-width: 1000px) {
      margin-bottom: 15px;
    }
  }
  &__title{
    font-family: 'Travels';
    font-weight: 500;
    font-size: 30px;
    color: #ed1c24;
    @media (max-width: 1000px) {
      margin-bottom: 40px;
    }
  }
  &__logo{
    width: 183px;
    height: 239px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position:  0 0;
    margin-right: 4.1vw;
    position: relative;
    top: 30px;
    @media (max-width: 1000px) {
      display: none;
    }
  }
  &.animated .index__subscribe__logo{
    animation: fadeInRight20 1s ease-in-out;
      background-image: url(/static/img/index/estatet-logo.png?t=3);
  }
}

.index__mobile-app-info{
  //background: #f2f2f2 -20px 110% no-repeat;
    background-color: #f2f2f2;
    background-position: -20px 110%;
    background-repeat: no-repeat;
  background-size: auto 150px;
  height: 434px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 230px;
    &.animated {
        background-image: url(/static/img/index/estatet3.png?t=3);
    }
  @media (max-width: 1000px) {
    margin-bottom: 75px !important;
    height: auto;
    background: #f2f2f2;
    position: relative;
    padding-bottom: 290px;
    padding-top: 45px;
  }
  &__title{
    font-family: 'Travels';
    font-weight: 500;
    font-size: 36px;
    line-height: 1;
    margin-bottom: 30px;
    @media (max-width: 1000px) {
      font-size: 30px;
      font-weight: 400;
    }
  }
  &>div{
    margin-right: 7vw;
    @media (max-width: 1000px) {
      margin-right: 0 !important;
    }
  }
  &__img{
    width: 435px;
    height: 731px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position:  0 0;
    // margin-right: 7.6vw;
    position: relative;
    top: 60px;
    margin-left: -6vw;
    @media (max-width: 1000px) {
      width: 250px;
      height: 245px;
      background-position:  50% 100%;
      position: absolute;
      top: auto; bottom: 0; left: 50%; transform: translateX(-50%);
      margin-left: 0 !important;
      animation:none !important;
    }
  }
  &.animated .index__mobile-app-info__img{
    animation: fadeInUp20 1s ease-in-out;
      background-image: url(/static/img/index/7.png?t=3);
      @media (max-width: 1000px) {
          background-image: url(/static/img/index/7-mob.png?t=3);
      }
  }
  &__link{
    margin-top: 25px;
    display: inline-block;
    font-size: 0;
    margin-right: 8px;
    img{width: 144px; height: auto !important; transition: 600ms;}
    @media (min-width: $resolution-1000) {
      &:hover img{
        transform: scale(1.1);

      }
    }
    @media (max-width: 1000px) {
      display: block;
      width: 170px !important;
      margin: 0 auto 8px;
      img{width: 100%;}
    }
  }
  .list {
    li{
      margin-bottom: 10px;
      font-size: 16px;
    }
    @media (max-width: 1000px) {
      margin-bottom: 45px;
    }
  }
}

.index__social{
  display: flex;
  justify-content: space-around;
  max-width: 1000px;
  margin: 0 auto 275px;
  @media (max-width: 1000px) {
    max-width: 400px;
    margin-bottom: 75px;
  }
}

.index__info-wrap{
  margin-bottom: 280px;
  & > .btn{
    max-width: 300px;
    margin: 100px auto 0 auto;
    display: block;

    @media (max-width: $resolution-1000) {
      margin-top: 30px;
    }
  }
}

.index__info{
  display: flex;
  justify-content: space-between;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: -90px;
    bottom: -90px;
    left: 0;
    right: 0;
    /*
    background: url(/static/img/index/estatet.png?t=3) 50% 50% no-repeat;
    background-size: cover;
    */
  }
    &.animated {
        &:before {
            background: url(/static/img/index/estatet.png?t=3) 50% 50% no-repeat;
            background-size: cover;
        }
    }
  @media (max-width: 1000px) {
    margin-bottom: 75px !important;
    background: #ece8e4;
    padding-top: 44px;
    padding-bottom: 50px;
    &:before{display: none;}
    .slick-slider{width: 100%;}
    .slick-arrow{
      top: auto; bottom: 70px; transform: none;
      color: #fff;
    }
    .slick-next{right: 20px;}
    .slick-prev{left:auto; right: 48px;}
    .slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div, .slick-slide > div > div, .slider-item{height: auto;}
    .slick-dots{padding-top: 35px;}
  }
  &__left{
    display: flex;
    width: 50%;
    &>*{ width: calc(100% - 40px); }
    .index__info__item__text{ font-size: 18px;}
    .index__info__item__title{margin-bottom: 0; margin-top: 8px; font-size: 30px; font-weight: 400;}

    .index__info__item__text:after{
      left: 0;
      bottom: 0;
      width: 50%;
    }
  }
  &__right{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 50%;
    &>*{ width: calc(50% - 20px); }

    & > .index__info__item .index__info__item__text:after{
      width: 39%;
      right: 0;
      top: 0;
    }

    & > div .index__info__item:first-child .index__info__item__text:after{
      width: 51%;
      left: 0;
      top: 0;
    }

    & > div .index__info__item:last-child .index__info__item__text:after{
      width: 23%;
      right: 0;
      top: 0;
    }
  }
  &__item{
    display: block;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    &__body{
      position: absolute;
      top: 0; left: 0;
      width: 100%; height: 100%;
      display: flex;
      flex-direction: column;
      @media (max-width: 1000px) {
        position: relative;
        display: flex;
        flex-direction: column;
      }
    }
    &__text{
      background: #f2eeea;
      padding: 20px 35px;
      font-size: 14px;
      position: relative;

      &:after{
        content: '';
        display: block;
        position: absolute;
        background: $color-red;
        height: 5px;
      }

      @media (max-width: 1000px) {
        order: -1;
        background: transparent;
        height: 85px;
        display: flex;
        flex-direction: column;
        font-size: 16px !important;
        padding: 0 0 10px !important;
      }
    }
    &__title{
      font-family: 'Travels';
      font-size: 18px;
      margin-bottom: 8px;
      font-weight: 500;
      @media (max-width: 1000px) {
        order: 2;
        margin-bottom: 0;
        height: 98px;
        display: flex;
        align-items: center;
        font-size: 24px !important;
        line-height: 1;
      }
    }
    &__img{
      height: 100%;
      position: relative;
      overflow: hidden;
      span{
        position: absolute;
        top: 0; left: 0; bottom: 0;
        width: 100%;
        background-position:  0 0;
        background-repeat: no-repeat;
        background-size: cover;
        transition: 1200ms;
      }
      @media (max-width: 1000px) {
        height: 340px;
      }
    }
    &--large{
      padding-top: calc(86% + 40px);
      .index__info__item__text{padding: 35px;}
    }
    &--middle{
      padding-top: 100%;
    }
    &--small{
      padding-top: 86%;
      margin-top: 40px;

      @media (max-width: $resolution-1400) {
        margin-top: 30px;
      }
      @media (max-width: $resolution-1200) {
        margin-top: 20px;
      }
      @media (max-width: $resolution-1000) {
        margin-top: 30px;
      }
    }
    &--mobile{
      padding-top: 0;
    }
    @media (min-width: $resolution-1000) {
      &:hover .index__info__item__img span{
        transform: scale(1.1);

      }
    }
  }
}

.index__partners {
  margin-bottom: 180px;

  .slick-arrow:after,
  .slick-arrow:before{
    display: none;
  }

  .slick-arrow{
    width: 20px;
    top: 50%;
    margin-top: 0px;

    @media (max-width: $resolution-1000) {
      margin-top: 0px;
    }

    svg{
      width: 100%;
      height: 100%;
    }
    path{
      fill: #000 !important;
    }
  }

  .slick-prev{
    left: -35px;
  }

  .slick-next{
    right: -35px;
  }

  @media (max-width: 1200px) {
    .slick-prev{
      left: -15px;
    }

    .slick-next{
      right: -15px;
    }
  }

  @media (max-width: 1000px) {
    margin-bottom: 75px;
  }
  &__title{
    font-size: 72px;
    text-transform: uppercase;
    margin-bottom: 80px;
    font-family: 'Travels';
    text-align: center;
    @media (max-width: 1000px) {
      font-size: 40px;
    }
  }
  .title{margin-bottom: 35px;
    @media (max-width: 1000px) {
      margin-bottom: 20px;
    }
}
  &__body{
    max-width: 1420px;
    margin: 0 auto;
  }
  &__item{
    height: 150px;
    line-height: 150px;
    vertical-align: middle;
    text-align: center;
    transition: 600ms;
    margin: 0 auto;
    display: block;
    &:after{
      content: '';
      height: 150px;
      width: 0;
      display: inline-block;
      vertical-align: middle;
    }
    img{
      max-width: 70%;
      max-height: 100px;
        width: auto !important;
        height: auto !important;
      display: inline-block !important;
      vertical-align: middle;
      filter: grayscale(100%);
    }
    @media (min-width: $resolution-1000) {
      &:hover{
        transform: scale(1.1);

      }
    }
    @media (max-width: 1000px) {
      line-height: 100px;
      height: 100px;
      &:after{height: 100px;}
      img{max-height: 80px; max-width: 130px;}
    }
  }
  .slick-dots{
    padding-top: 50px;
    @media (max-width: 1000px) {
      padding-top: 20px;
    }
  }
}
@media(max-width: $resolution-1400) {
  .index__find-flat__body{font-size: 14px; margin-bottom: 20px;}

  .index__top{height: calc(100vh - 129px - #{$header_height_smalll})}
  .index__info{
    &__left{
      .index__info__item__text{font-size: 16px;}
      .index__info__item__title{font-size: 27px;}
    }
    &__item{
      &__text{
        padding: 20px 25px;
        font-size: 13px;
      }
      &__title{
        font-size: 16px;
      }
      &--large{
        .index__info__item__text{padding: 35px 25px;}
      }
    }
  }
}
@media (max-width: $resolution-1400) {
  .index__events .list li{font-size: 16px;}
  .index__realty-types__item__title{
    font-size: 26px;
  }
  .index__subscribe{
    background-size: 900px auto;
    height: 210px;
    &__title{
      font-size: 25px;
    }
    &__logo{
      width: 150px;
      height: 196px;
      margin-right: 3vw;
      top: 20px;
    }
  }
}
@media (max-width: $resolution-1300) {
  .index__info-wrap{
    margin-bottom: 180px;
  }

  .index__info{
    &:before{
      top: -50px;
      bottom: -50px;
    }
  }
  .index__links{margin-bottom: 200px; }
  .index__realty-types{margin-bottom: calc(200px + 5vw) }
  .index__buy{
    margin-bottom: 150px;
    height: 230px;
    padding-left: 53%;
    &:before{
      right: 45%;
      height: 415px; width: 686px;

    }
    &__title{
      font-size: 40px;
      margin-bottom: 20px;
    }
    &__text{
      margin-bottom: 20px;
      font-size: 16px;
    }
  }
  .index__subscribe{
    background-size: 700px auto;
    height: 190px;
    margin-bottom: 190px;
    &__title{
      font-size: 22px;
    }
  }
  .index__realty-types__item__title{
    font-size: 24px;
  }

}
@media(max-width: $resolution-1370) {
  .index__find-flat {
    .filter-field__body{font-size: 14px; }
    .filter-field{margin-bottom: 10px;}
    .btn{padding: 15px 10px; font-size: 12px; }
    .filter-field__title{margin-bottom: 2px; font-size: 13px;}
    &__body{font-size: 13px; margin-bottom: 10px;}
  }
  .index__events .list li{margin-bottom: 12px; font-size: 15px;}

}
@media(max-width: $resolution-1200) {
  .index__realty-types__item__title{
    font-size: 20px;
  }
  .index__buy:before{right: 42%; }

  .index__mobile-app-info{
    &>div{
      margin-right: 0;
    }
    &__img{
      margin-right: 7vw !important;
    }
  }
}
@media(max-width: 1000px) {
  .index__find-flat {
    .filter-field__body{font-size: 16px; }
    .filter-field{margin-bottom: 20px;}
    .btn{padding: 20px 10px; font-size: 13px; }
    .filter-field__title{margin-bottom: 5px; font-size: 14px;}
    &__body{font-size: 16px; margin-top: 22px;}
  }
  .index__events__title, .index__find-flat__title{font-size: 36px;}
  .index__events .list li{margin-bottom: 12px; font-size: 15px;}

}

.index__mortgage__info__filter{
  .rc-slider-track{
    margin-left: -4px;
  }

  .ui-range-slider-labels div{
    color: #000;
    font-size: 14px;
    font-family: Styrene;
  }
}
