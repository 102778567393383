.detail{
  .sharing-icons__in{
    right: auto;
    left: 20px;
  }

  overflow: hidden;
  @media (max-width: $resolution-1000) {
    padding-top: 50px;

    .page-title{
      margin-bottom: 40px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .promo__item-line{
    width: 5px;
  }

  .promo__list{
    padding-bottom: 113px;

    @media (max-width: $resolution-1400) {
      padding-bottom: 100px;
    }

    @media (max-width: $resolution-1300) {
      padding-bottom: 80px;
    }

    @media (max-width: $resolution-1200) {
      padding-bottom: 60px;
    }
  }
}

.detail__titles{
  font-family: "Travels";
  font-size: 48px;
  line-height: 1;
  font-weight: 500;
  position: relative;

  @media (max-width: $resolution-1400) {
    font-size: 44px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 40px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 36px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 32px;
    margin-left: 40px;
    margin-right: 40px;
  }
}

.detail__texts{
  font-size: 20px;
  line-height: 1.5;

  @media (max-width: $resolution-1400) {
    font-size: 19px;
    line-height: 1.4;
  }

  @media (max-width: $resolution-1300) {
    font-size: 18px;
    line-height: 1.3;
  }

  @media (max-width: $resolution-1200) {
    font-size: 16px;
    line-height: 1.2;
  }

  @media (max-width: $resolution-1000) {
    font-size: 17px;
    line-height: 1.6;
    padding-left: 40px;
    padding-right: 40px;
  }

  iframe{width: 100%;}

  p{
    margin: 0 0 30px 0;

    a:hover{
      text-decoration: none;
    }
  }

  ul{
    margin: 0 0 30px 0;
    padding: 0;
    list-style: none;
  }

  li{
    margin: 0;
    padding: 0 0 0 30px;
    position: relative;

    &:after{
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      background: $color-red;
      position: absolute;
      left: 0;
      top: 13px
    }
  }
}

.frame-detail{
  overflow: hidden;
  margin-bottom: 110px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 100px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 80px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 60px;
  }

  @media (max-width: $resolution-1000) {
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 50px;
  }
}

.detail__box-title{
  display: flex;

  @media (max-width: $resolution-1000) {
    display: block;
  }

  & > div{
    width: 50%;

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }

  & > div:first-child{
    padding-right: 5%;

    @media (max-width: $resolution-1000) {
      padding-right: 0;
    }
  }

  &.single > div,
  &.single > div:first-child{
    width: auto;
    padding-right: 4.5vw;

    @media (max-width: $resolution-1480) {
      padding-right: 3.5vw;
    }

    @media (max-width: $resolution-1200) {
      padding-right: 25px;
    }

    @media (max-width: $resolution-1000) {
      padding-right: 0;
    }
  }

  .btn{
    max-width: 300px;
  }
}

.detail__title{
  margin-bottom: 30px;
  h1{
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: $resolution-1400) {
    margin-bottom: 25px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 20px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 20px;
  }

  @media (max-width: $resolution-1000) {
    margin-bottom: 20px;
  }
}

.detail__date{
  font-family: "Travels";
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;

  span{
    display: block;
    height: 5px;
    width: 86px;
    background: $color-red;
    margin-right: 25px;
  }

  @media (max-width: $resolution-1400) {
    font-size: 15px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 14px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 16px;
    margin-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.detail__dsc{
  margin-bottom: 45px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 40px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 35px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 30px;
  }

  @media (max-width: $resolution-1000) {
    margin-bottom: 45px;
  }
}

//.detail__slider-item{
//  height: 100%;
//}

.detail__slider-item-bg{
  height: 525px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  @media (max-width: 1680px) {
    height: calc(525 / 1680 * 100vw)
  }


  @media (max-width: $resolution-1000) {
    height: 375px;
  }
}

.detail__slider{
  .slick-arrow{
    width: 23px;
    height: 62px;
    margin-top: -25px;

    @media (max-width: $resolution-1000) {
      width: 14px;
      height: 22px;
      margin-top: 0;
      top: 335px;
    }

    svg{
      width: 100%;
      height: 100%;
    }

    &:after,
    &:before{
      display: none;
    }
  }

  .slick-prev{
    left: 39px;
    @media (max-width: $resolution-1000) {
      left: auto;
      right: 51px;
    }
  }

  .slick-next{
    right: 39px;
    @media (max-width: $resolution-1000) {
      right: 20px
    }
  }
}

.detail__slider_about{
  width: 570px;
  position: relative;
  top: -70px;

  @media (max-width: 1680px) {
    width: calc(570 / 1680 * 100vw);
    top: calc(-70 / 1680 * 100vw);
  }
  @media (max-width: $resolution-1000) {
    width: auto;
    top: 0;
    margin-left: 0;
  }

  .detail__slider-item-bg{
    height: 570px;

    @media (max-width: $resolution-1400) {
      height: 540px;
    }

    @media (max-width: $resolution-1300) {
      height: 500px;
    }

    @media (max-width: $resolution-1200) {
      height: 450px;
    }

    @media (max-width: $resolution-1000) {
      height: 375px;
    }
  }

  .detail__slider-item{
    margin-bottom: -20px;

    @media (max-width: $resolution-1000) {
      margin-bottom: 30px;
    }
  }
}

.detail__slider_dream{
  width: 652px;
  position: relative;

  @media (max-width: $resolution-1400) {
    width: 620px;
  }

  @media (max-width: $resolution-1300) {
    width: 550px;
  }

  @media (max-width: $resolution-1200) {
    width: 450px;
  }

  @media (max-width: $resolution-1000) {
    width: auto;
  }

  .detail__slider-item-bg{
    height: 652px;

    @media (max-width: $resolution-1400) {
      height: 620px;
    }

    @media (max-width: $resolution-1300) {
      height: 550px;
    }

    @media (max-width: $resolution-1200) {
      height: 450px;
    }

    @media (max-width: $resolution-1000) {
      height: 375px;
    }
  }
}

.detail__slider-item-name{
  color: #808080;
  font-size: 13px;
  text-align: center;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame + .detail__about {
  margin-top: 140px;
  @media (max-width: $resolution-1300) {
    margin-top: 120px;
  }
  @media (max-width: $resolution-1200) {
    margin-top: 80px;
  }
  @media (max-width: $resolution-1000) {
    margin-top: 0;
  }
}
.detail-gray{
  background: #f2f2f2;
}
.detail__about{
  //align-items: center;
  margin-bottom: 100px;
  margin-top: 200px;
  .frame{
    display: flex;
  }

  @media (max-width: $resolution-1400) {
    margin-bottom: 90px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 80px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 60px;
  }

  @media (max-width: $resolution-1000) {
    padding: 0;
    margin-top: 0;
    margin-bottom: 70px;
    .frame{
      display: block;
    }
  }
}

.detail__about-box{
  margin-left: 6.25%;
  padding-left: 3.43%;
  position: relative;
  margin-top: 6%;
  margin-bottom: 6%;
  &[data-left="true"] {
    margin-left: 0;
    margin-right: 6.25%;
  }
  .btn{
    margin-top: 40px;
    @media (max-width: 1000px) {
      margin-top: 30px;
      margin-left: 40px;
    }
  }
  @media (max-width: 1680px) {
    //width: calc(570 / 1680 * 100vw);
    //top: calc(-70 / 1680 * 100vw);
  }

  @media (max-width: $resolution-1000) {
    margin: 0;
    padding: 50px 0;
    .detail__titles{
      margin-left: 20px;
      margin-right: 0;
    }
    .detail__texts{
      padding-left: 20px;
      padding-right: 0;
    }
    .btn{
      margin-left: 20px;
    }
  }

  &:after{
    content: '';
    display: block;
    width: 5px;
    background: $color-red;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    @media (max-width: $resolution-1000) {
      top: 50px;
      bottom: 50px;
    }

  }
}

.detail__about-title{
  margin-bottom: 25px;

  @media (max-width: $resolution-1000) {
    margin-bottom: 15px;
  }
}

.detail__dream{
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  align-items: center;

  @media (max-width: $resolution-1400) {
    margin-bottom: 50px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 40px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 30px;
  }

  @media (max-width: $resolution-1000) {
    display: block;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 30px;
  }
}
.detail__dream-box{
  position: relative;
  padding-right: 5%;

  @media (max-width: $resolution-1000) {
    padding-right: 0;
  }
}

.detail__dream-title{
  font-family: "Travels";
  font-size: 30px;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 30px;

  @media (max-width: $resolution-1000) {
    padding-top: 0;
    font-size: 25px;
    margin: 0 40px 45px 40px;
  }
}

.detail__dream-dsc{
  position: relative;
  padding-left: 8%;
  padding-bottom: 15%;

  @media (max-width: $resolution-1400) {
    padding-bottom: 10%;
  }

  @media (max-width: $resolution-1300) {
    padding-bottom: 5%;
  }

  @media (max-width: $resolution-1200) {
    padding-bottom: 5%;
  }

  @media (max-width: $resolution-1000) {
    padding: 45px 40px 45px 35px;
    margin: 0 0 0 40px;
  }

  &:after{
    content: '';
    display: block;
    width: 5px;
    background: $color-red;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    @media (max-width: $resolution-1000) {
      left: 0;
      bottom: auto;
      top: 0;
      right: 0;
      width: auto;
      height: 5px;
    }
  }
}

.detail__contract{
  display: flex;
  justify-content: space-between;
  margin-bottom: 130px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 120px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 80px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 40px;
  }

  @media (max-width: $resolution-1000) {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
}

.detail__slider_contract{
  width: 385px;
  position: relative;

  @media (max-width: $resolution-1400) {
    width: 360px;
  }

  @media (max-width: $resolution-1300) {
    width: 320px;
  }

  @media (max-width: $resolution-1200) {
    width: 300px;
  }

  @media (max-width: $resolution-1000) {
    width: auto;
  }

  .detail__slider-item-bg{
    height: 385px;

    @media (max-width: $resolution-1400) {
      height: 360px;
    }

    @media (max-width: $resolution-1300) {
      height: 320px;
    }

    @media (max-width: $resolution-1200) {
      height: 300px;
    }

    @media (max-width: $resolution-1000) {
      height: 375px;
    }
  }
}

.detail__contract-dsc{
  padding-left: 15.6%;
  padding-right: 10%;
  padding-top: 70px;

  &[data-left="true"] {
    padding-right: 15.6%;
    padding-left: 10%;
  }
  @media (max-width: $resolution-1400) {
    padding-left: 12%;
    padding-right: 8%;
    padding-top: 60px;
  }

  @media (max-width: $resolution-1300) {
    padding-left: 8%;
    padding-right: 6%;
    padding-top: 40px;
  }

  @media (max-width: $resolution-1200) {
    padding-left: 5%;
    padding-right: 4%;
    padding-top: 20px;
  }

  @media (max-width: $resolution-1000) {
    padding: 0 40px 10px 40px;
  }
}

.detail__multiple{
  background: #f2eeea;
  padding: 50px 0 60px;
  margin-bottom: 100px;

  @media (max-width: $resolution-1400) {
    padding: 40px 0 50px;
  }

  @media (max-width: $resolution-1300) {
    padding: 30px 0 40px;
  }

  @media (max-width: $resolution-1000) {
    padding: 15px 0;
    margin-bottom: 75px;
  }
}

.detail__slider_multiple{
  margin-bottom: 45px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 40px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 35px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 15px;
  }

  .detail__slider-item-bg{
    width: 350px;
    height: 230px;
    margin: 0 20px;

    @media (max-width: $resolution-1400) {
      width: 330px;
      height: 210px;
      margin: 0 20px;
    }

    @media (max-width: $resolution-1300) {
      width: 300px;
      height: 190px;
      margin: 0 15px;
    }

    @media (max-width: $resolution-1200) {
      width: 280px;
      height: 180px;
      margin: 0 10px;
    }

    @media (max-width: $resolution-1000) {
      width: auto;
      margin: 0 15px;
      height: 375px;
    }
  }

	.detail__slider-item-label{
		text-align: center;
		width: 350px;
    margin: 10px 20px;
		font-family: Travels;
		font-weight: 500;
		font-size: 14px;

		@media (max-width: $resolution-1400) {
      width: 330px;
      margin: 10px 20px;
    }

    @media (max-width: $resolution-1300) {
      width: 300px;
      margin: 10px 15px;
    }

    @media (max-width: $resolution-1200) {
      width: 280px;
      margin: 10px 10px;
    }

    @media (max-width: $resolution-1000) {
      width: auto;
      margin: 10px 15px;
    }
	}

  .slick-arrow{
    margin-top: 0;

    @media (max-width: $resolution-1000) {
      margin-right: 15px;
    }
  }
}

.detail__multiple-dsc{
  padding: 0 17.85%;

  @media (max-width: $resolution-1400) {
    padding: 0 16%;
  }

  @media (max-width: $resolution-1300) {
    padding: 0 15%;
  }

  @media (max-width: $resolution-1200) {
    padding: 0 12%;
  }
}

.detail__multiple-dsc-in{
  font-family: "Travels";
  font-weight: 500;
  font-size: 16px;
  position: relative;

  @media (max-width: $resolution-1400) {
    font-size: 15px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 14px;
  }

  @media (max-width: $resolution-1000) {
    padding: 70px 0 45px 0;
    font-size: 17px;
    line-height: 1.2;
  }

  //&:after{
  //  content: '“';
  //  position: absolute;
  //  font-weight: 700;
  //  font-size: 80px;
  //  line-height: 1;
  //  font-family: Georgia, sans-serif;
  //  left: -85px;
  //  top: -5px;
  //
  //  @media (max-width: $resolution-1000) {
  //    left: 0;
  //    top: 0;
  //  }
  //}
}

.detail__description{
  margin-bottom: 150px;
  display: flex;
  justify-content: space-between;

  @media (max-width: $resolution-1400) {
    margin-bottom: 130px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 110px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 80px;
  }

  @media (max-width: $resolution-1000) {
    display: block;
    padding: 0;
    margin-bottom: 70px;
  }

  //.detail__slider-item-bg{
  //  height: 100%;
  //}
  //
  //.detail__slider-item{
  //  height: 100%;
  //}

  .slick-arrow{
    margin-top: 0;
  }

  & > div:first-child{
    width: 66%;

    @media (max-width: $resolution-1000) {
      width: auto;
      height: 375px;
    }
  }

  & > div:last-child{
    width: 34%;
    background: #f2f2f2;
    box-sizing: border-box;
    padding: 5.27%;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: $resolution-1400) {
      padding: 5%;
    }

    @media (max-width: $resolution-1300) {
      padding: 4%;
    }

    @media (max-width: $resolution-1200) {
      padding: 3%;
    }

    @media (max-width: $resolution-1000) {
      width: auto;
      padding: 50px 40px 20px 40px;
    }

    &:after{
      content: '';
      height: 5px;
      background: $color-red;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }
  }

  .slick-dots{
    position: absolute;
    left: 0;
    right: 0;
    bottom: -25px;
  }
}

.detail__description-dsc{
  @media (max-width: $resolution-1000) {
    padding: 0;
  }
}

.detail__video{
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 90px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 80px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 60px;
  }

  @media (max-width: $resolution-1000) {
    display: block;
    padding: 0;
    margin-bottom: 45px;
  }

  & > div{
    width: 50%;

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }
}

.detail__video-box{
  position: relative;
  height: 487px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000;

  @media (max-width: $resolution-1400) {
    height: 480px;
  }

  @media (max-width: $resolution-1300) {
    height: 420px;
  }

  @media (max-width: $resolution-1200) {
    height: 380px;
  }

  @media (max-width: $resolution-1000) {
    //height: 250px;
  }

  video{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @media (max-width: $resolution-1000) {
      //background: #000;
    }
  }
}

.detail__video-button{
  position: absolute;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  z-index: 3;
  left: 50%;
  top: 50%;
  margin: -48px 0 0 -48px;
  background: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms transform;


  &:after{
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11.5px 0 11.5px 20px;
    border-color: transparent transparent transparent $color-red;
  }

  &:hover{
    transform: scale(1.2);
  }
}

.detail__video-dsc{
  padding: 0 5.5% 0 0;


  @media (max-width: $resolution-1000) {
    padding: 0 40px 10px 40px;
  }
}

.detail__share{
  display: flex;
  margin-bottom: 100px;
  align-items: center;

  @media (max-width: $resolution-1000) {
    display: block;
    padding: 0;
    margin-bottom: 25px;
  }
}

.detail__slider_share{
  width: 500px;
  position: relative;

  @media (max-width: $resolution-1400) {
    width: 480px;
  }

  @media (max-width: $resolution-1300) {
    width: 450px;
  }

  @media (max-width: $resolution-1200) {
    width: 400px;
  }

  @media (max-width: $resolution-1000) {
    width: auto
  }

  .detail__slider-item-bg{
    height: 500px;

    @media (max-width: $resolution-1400) {
      height: 480px;
    }

    @media (max-width: $resolution-1300) {
      height: 450px;
    }

    @media (max-width: $resolution-1200) {
      height: 400px;
    }

    @media (max-width: $resolution-1000) {
      height: 375px;
    }
  }

  &:after{
    content: '';
    display: block;
    width: 186px;
    height: 5px;
    background: $color-red;
    position: absolute;
    right: -93px;
    bottom: 70px;

    @media (max-width: $resolution-1400) {
      width: 156px;
      right: -63px;
    }

    @media (max-width: $resolution-1300) {
      width: 146px;
      right: -53px;
    }

    @media (max-width: $resolution-1200) {
      width: 126px;
      right: -33px;
    }

    @media (max-width: $resolution-1000) {
      display: none;
    }
  }
}

.detail__share-dsc{
  padding: 0 14.4%;

  @media (max-width: $resolution-1400) {
    padding: 0 12%;
  }

  @media (max-width: $resolution-1300) {
    padding: 0 10%;
  }

  @media (max-width: $resolution-1200) {
    padding: 0 8%;
  }

  @media (max-width: $resolution-1000) {
    padding: 0 40px;
    margin-bottom: 75px;

    .detail__texts{
      padding: 0;
    }
  }
}

.detail__share-menu{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 40px;

  @media (max-width: $resolution-1000) {
    margin-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    display: block;
  }
}

.detail__share-menu-opt{
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-top: 20px;

  @media (max-width: $resolution-1000) {
    margin: 0;
  }
}

.detail__share-menu-list{
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;

  & > div{
    margin-right: 10px;
    padding: 0 20px;
    height: 40px;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    border: 1px solid #e6e6e6;
    font-size: 14px;
    margin-top: 10px;

    @media (max-width: $resolution-1000) {
      border-width: 2px;
    }
  }
}

.detail__share-likes{
  margin-right: 30px;
  display: flex;
  align-items: center;
  font-family: 'Travels';
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  svg{
    width: 18px;
    height: auto;
    margin-right: 9px;
  }
}

.detail__more-title{
  margin-bottom: 50px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 45px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 40px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 30px;
  }

  @media (max-width: $resolution-1000) {
    margin-left: 0;
    margin-right: 0;
  }
}

.detail__form{
  background: #f5f5f5;
  margin-bottom: 150px;
  padding: 100px 16.79%;

  @media (max-width: $resolution-1400) {
    margin-bottom: 120px;
    padding: 90px 15%;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 100px;
    padding: 80px 12;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 70px;
    padding: 60px 8%;
  }
  @media (max-width: 1000px) {
    padding: 40px;
    margin-left: -$padding_mobile;
    margin-right: -$padding_mobile;

  }

  input{
    color: #000;
  }

  input::placeholder{
    color: #a6a6a6;
  }
}

.detail__form-row{
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-direction: column;
  }

  .btn{
    max-width: 220px;
    font-size: 16px;
    @media (max-width: 1000px) {
      max-width: 400px;
      margin: 0 auto;
    }
  }

  .checkbox-field__check:before{
    font-size: 17px;
  }
}

.detail__form-cell{
  width: 48%;
  @media (max-width: 1000px) {
    width: 100%;
  }
}

.detail__form-title{
  font-family: "Travels";
  font-weight: 500;
  font-size: 36px;
  line-height: 1.1;
  text-align: center;
  color: $color-red;
  margin-bottom: 35px;

  @media (max-width: $resolution-1400) {
    font-size: 34px;
    margin-bottom: 30px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 30px;
    margin-bottom: 25px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 28px;
  }
  @media (max-width: 1000px) {
    font-size: 23px;
  }
}

.detail__more-box{
  @media (max-width: $resolution-1000) {
    padding: 0;
    margin-bottom: 50px;

    .detail__more-title{
      padding-left: 40px;
      padding-right: 40px;
    }

    .promo__item{
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}

.detail__texts-description{
  padding: 0 17%;
  margin-bottom: 110px;

  .btn{
    margin-top: 30px;
  }

  @media (max-width: $resolution-1400) {
    margin-bottom: 100px;
    padding: 0 15%;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 80px;
    padding: 0 12%;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 60px;
    padding: 0 8%;
  }

  @media (max-width: $resolution-1000) {
    padding: 0;

    .detail__texts{
      padding-left: 0;
      padding-right: 0;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
}
.detail__files{
  padding: 0 17%;
  margin-bottom: 110px;

  &__item{
    margin-bottom: 10px;
    word-break: break-word;
  }

  @media (max-width: $resolution-1400) {
    margin-bottom: 100px;
    padding: 0 15%;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 80px;
    padding: 0 12%;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 60px;
    padding: 0 8%;
  }

  @media (max-width: $resolution-1000) {
    padding: 0;
  }
}

.detail-properties{
  margin-bottom: 50px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 40px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 30px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 25px;
  }

  @media (max-width: $resolution-1000) {
    padding-left: 0;
    padding-right: 0;
  }
}

.detail-footer{
  margin-bottom: 100px;

  .news-feed__filter{
    margin-bottom: 30px;
    justify-content: flex-start;
  }

  .news-feed__filter__item{
    text-decoration: none;
  }

  .news-feed__filter__item:after,
  .news-feed__filter__item:before{
    display: none !important;
  }
}

.detail-like-share{
  display: flex;
  align-items: center;
  z-index: 10;
  .sharing-icons{
    margin-left: 30px;
  }
}

.detail__form-subtitle{
  color: $color-red;
  font-family: Travels;
  font-size: 14px;
  margin-bottom: 20px;
}

.detail{
  h2, h3, h4, h5, h6{
    font-family: "Travels";
    line-height: 1;
    font-weight: 500;
    position: relative;
    margin-bottom: 30px;
    text-overflow: ellipsis;
    overflow: hidden;

    @media (max-width: $resolution-1400) {margin-bottom: 25px;}
    @media (max-width: $resolution-1000) {margin-bottom: 20px;}
  }

  h2{
    font-size: 40px;
    @media (max-width: $resolution-1400) {font-size:36px;}
    @media (max-width: $resolution-1300) {font-size: 32px;}
    @media (max-width: $resolution-1200) {font-size: 28px;}
    @media (max-width: $resolution-1000) {font-size: 28px;}
  }

  h3{
    font-size: 36px;
    @media (max-width: $resolution-1400) {font-size:32px;}
    @media (max-width: $resolution-1300) {font-size: 28px;}
    @media (max-width: $resolution-1200) {font-size: 24px;}
    @media (max-width: $resolution-1000) {font-size: 24px;}
  }

  h4{
    font-size: 32px;
    @media (max-width: $resolution-1400) {font-size: 28px;}
    @media (max-width: $resolution-1300) {font-size: 24px;}
    @media (max-width: $resolution-1200) {font-size: 20px;}
    @media (max-width: $resolution-1000) {font-size: 20px;}
  }

  h5{
    font-size: 28px;
    @media (max-width: $resolution-1400) {font-size: 24px;}
    @media (max-width: $resolution-1300) {font-size: 20px;}
    @media (max-width: $resolution-1200) {font-size: 18px;}
    @media (max-width: $resolution-1000) {font-size: 18px;}
  }

  h6{
    font-size: 24px;
    @media (max-width: $resolution-1400) {font-size: 20px;}
    @media (max-width: $resolution-1300) {font-size: 18px;}
    @media (max-width: $resolution-1200) {font-size: 16px;}
    @media (max-width: $resolution-1000) {font-size: 16px;}
  }
  .btn{min-width: 220px;}
}
.footer-article {
  margin-bottom: 0;
}
.article-detail__footer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 1000px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
  .artilce-detail__footer__inner {
    display: flex;
    justify-content: center;
    margin: 0 50px;
    align-items: flex-start;
    @media (max-width: 1000px) {
      margin: 0 0 50px;
      flex-direction: column;
    }
  }
  .article-detail__footer__btns-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1000px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .btn {
      @media (max-width: 1000px) {
        margin-bottom: 30px;
        &:first-child {
          margin-right: 30px;
        }
      }
      @media (max-width: 572px) {
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }
  .news-feed__filter {
    width: 100%;
    @media (max-width: 1000px) {
      margin-bottom: 0;
      margin-left: -11px;
    }
  }
  .detail-like-share {
    margin-left: 50px;
    transform: translateY(50%);
    @media (max-width: 1000px) {
      margin-left: 0;
    }
  }
  .btn-left {
    span {
      margin-left: 10px;
    }
  }
  .btn-right {
    span {
      margin-right: 10px;
    }
  }
  .detail-footer {
    margin-bottom: 0;
  }
}
.article__more-apart {
  .apartment-search__view-title {
    border-top: none;
  }
  .apartment-search__view {
    margin-top: 40px;
  }
}

.detail-properties[data-contents="true"] {
  max-width: 50%;
  @media (max-width: 1000px) {
    max-width: 100%;
  }
}

.detail-contents{
  background: #F2EEEA;
  padding-top: 80px;
  padding-bottom: 80px;
  font-family: 'Travels';
  position: relative;
  margin-bottom: 86px;
  .frame{min-height: 350px;}
  @media (max-width: 1680px) {
    margin-bottom: calc(86 / 1680 * 100vw);
    padding-top: calc(80 / 1680 * 100vw);
    padding-bottom: calc(80 / 1680 * 100vw);
  }
  @media (max-width: 1000px) {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 43px;
  }
}
.detail-contents__text{
  margin-bottom: 92px;
  width: 45%;
  line-height: 1.5;
  max-width: 750px;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    width: 100%;
    margin-bottom: 45px;
    box-sizing: border-box;
  }
}
.detail-contents__title{
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 30px;
}

.detail-contents__menu{
  max-width: 49%;
  @media (max-width: 1000px) {
    max-width: 100%;
  }
  & > div{
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 30px;
    position: relative;
    padding-left: 28px;
    @media (max-width: 1680px) and (min-width: 1001px) {
      margin-bottom: calc(30 / 1680 * 100vw);
      font-size: 16px;
      padding-left: calc(28 / 1680 * 100vw);
    }
    @media (max-width: 1000px) {
      font-size: 14px;
      margin-bottom: 15px;
    }
    &:last-child{margin-bottom: 0;}
    &:before{
      content: '';
      position: absolute;
      top: 5px; left: 0;
      width: 4px; height: 4px;
      background: #ED1C24;
    }
    span{
      border-bottom: 1px solid transparent;
      transition: 300ms;
    }
    @media (min-width: 1000px) {
      &:hover span{border-bottom-color: inherit}
    }
  }
}
.detail-contents__img1{
  position: absolute;
  width: 722px;
  height: 750px;
  max-height: 175%;
  top: -240px;
  right: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1680px) and (min-width: 1001px) {
    width: calc(722 / 1680 * 100vw);
    height: calc(750 / 1680 * 100vw);
    top: calc(-240 / 1680 * 100vw);
  }
  @media (max-width: 1000px) {
    position: relative;
    top: 0;
    margin: 40px auto -40px;
    height: 375px;
    width: 361px;
  }
  @media (max-width: 439px) {
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
  }
}
.detail-contents__img2{
  position: absolute;
  width: 500px;
  height: 500px;
  max-height: 150%;
  top: 60%;
  right: 80px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1680px) and (min-width: 1001px) {
    width: calc(500 / 1680 * 100vw);
    height: calc(500 / 1680 * 100vw);
    right: calc(80 / 1680 * 100vw);
  }
  @media (max-width: 1000px) {
    position: relative;
    top: 0; right: 0;
    margin: -20px auto 0;
    height: 250px;
    width: 250px;
  }
  &:after{
    content: '';
    position: absolute;
    width: 180px;
    height: 5px;
    left: -90px;
    bottom: 20px;
    background: #ED1C24;
    @media (max-width: 1680px) and (min-width: 1001px) {
      width: calc(180 / 1680 * 100vw);
      height: calc(5 / 1680 * 100vw);
      bottom: calc(20 / 1680 * 100vw);
      left: calc(-90 / 1680 * 100vw);
    }
    @media (max-width: 1000px) {
      width: 90px;
      height: 2px;
      left: -30px;
      bottom: 10px;
    }
  }
}
a[name^="item"] {
  position: relative;
  top: -100px;
}
.frame2{
  //max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.detail__slideshow2-wrap{

  margin-bottom: 120px;
  @media (max-width: 1000px) {
    margin-bottom: 80px;
    padding-left: 0; padding-right: 0;
    .slick-arrow{top: auto; bottom: 20px;}
  }
}
.detail__slideshow2-bg{
  padding-top: 65%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.detail__slideshow2 .slick-dots{
  position: absolute;
  bottom: 30px; left: 0; right: 0;
}
.detail__slideshow2{
  margin-bottom: 20px;
}
.detail__slideshow2-text{
  text-align: center;
  margin-bottom: 20px;
  color: #4c4c4c;
  font-size: 16px;
}
.detail__slideshow2-preview{
  background: #f2eeea;
  padding: 20px 10px;
  position: relative;
  @media (max-width: 1000px) {
    display: none;
  }
}
.detail__slideshow2-preview__slider{width: 100%}
.detail__slideshow2-preview__item{
}
.detail__slideshow2-preview__bg{
  width: 200px;
  height: 120px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
}
.detail__multiple .detail__slider-item-bg {
  background-size: contain;
}
.footer-article {
  margin-bottom: 0;
}
.article-detail__footer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 1000px) {
    flex-direction: column;
    margin-bottom: 50px;
  }
  .artilce-detail__footer__inner {
    display: flex;
    justify-content: center;
    margin: 0 50px;
    align-items: flex-start;
    @media (max-width: 1000px) {
      margin: 0 0 50px;
      flex-direction: column;
    }
  }
  .article-detail__footer__btns-inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1000px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .btn {
      @media (max-width: 1000px) {
        margin-bottom: 30px;
        &:first-child {
          margin-right: 30px;
        }
      }
      @media (max-width: 572px) {
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }
  .news-feed__filter {
    width: 100%;
    @media (max-width: 1000px) {
      margin-bottom: 0;
      margin-left: -11px;
    }
  }
  .detail-like-share {
    margin-left: 50px;
    transform: translateY(50%);
    @media (max-width: 1000px) {
      margin-left: 0;
    }
  }
  .btn-left {
    span {
      margin-left: 10px;
    }
  }
  .btn-right {
    span {
      margin-right: 10px;
    }
  }
  .detail-footer {
    margin-bottom: 0;
  }
}
.article__more-apart {
  .apartment-search__view-title {
    border-top: none;
  }
  .apartment-search__view {
    margin-top: 40px;
  }
}