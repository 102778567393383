.lk-wrap{
  padding: 0 8.4%;

  @media (max-width: $resolution-1400) {
    padding: 0 4%;
  }

  @media (max-width: $resolution-1300) {
    padding: 0 2%;
  }

  @media (max-width: $resolution-1200) {
    padding: 0;
  }
  
  @media (max-width: $resolution-1000) {
    overflow: hidden;
  }

  .slick-arrow{
    width: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    &:before{
      display: none;
    }
    path{
      fill: #000;
    }
    svg{
      width: 20px;
    }
  }
}

.lk-box{
  display: flex;

  @media (max-width: $resolution-1000) {
    display: block;
    padding: 0 40px;
  }
}

.lk-title{
  display: flex;
  align-items: center;
  font-family: "Travels";
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 40px;

  @media (max-width: $resolution-1000) {
    font-size: 22px;
    margin-bottom: 20px;
  }

  &:before{
    content: '';
    display: block;
    width: 183px;
    height: 5px;
    background: $color-red;
    margin-right: 35px;

    @media (max-width: $resolution-1400) {
      width: 183px;
      margin-right: 30px;
    }
  
    @media (max-width: $resolution-1300) {
      width: 153px;
      margin-right: 25px;
    }
  
    @media (max-width: $resolution-1200) {
      width: 83px;
      margin-right: 20px;
    }

    @media (max-width: $resolution-1200) {
      width: 40px;
      margin-right: 15px;
    }
  }
}

.lk-form{
  position: relative;
  @media (max-width: $resolution-1000) {
    margin-bottom: 40px;
  }
}

.lk-form-wrap{
  padding-left: 218px;
  margin-bottom: 100px;

  @media (max-width: $resolution-1400) {
    padding-left: 213px;
  }

  @media (max-width: $resolution-1300) {
    padding-left: 178px;
  }

  @media (max-width: $resolution-1200) {
    padding-left: 103px;
  }

  @media (max-width: $resolution-1000) {
    padding-left: 0;
    margin-bottom: 30px;
  }
}

.lk-form__row{
  display: flex;
  align-items: center;
  font-family: "Travels";
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;

  @media (max-width: $resolution-1000) {
    display: block;
    margin-bottom: 20px;
  }

  & > div:first-child{
    width: 132px;

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }

  input{
    height: 60px;
    width: 290px;
    padding: 0 20px;
    background: #f7f7f7;
    box-sizing: border-box;
    font-family: Georgia, Times, "Times New Roman", serif;
    font-size: 18px;
    transition: $animation-speed;

    @media (max-width: $resolution-1000) {
      width: 100%;
    }
  }

  .input-field[error="true"] input{
    box-shadow: inset 0 0 0 1px #ed1c24;
  }

  .btn{
    height: 70px;
    width: 290px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }
}

.lk-exit{
  border: 1px solid #e6e6e6;
  height: 120px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 135px;
  font-size: 16px;
  text-decoration: underline;
  padding: 0 30px;
  cursor: pointer;

  @media (max-width: $resolution-1400) {
    margin-left: 100px;
  }

  @media (max-width: $resolution-1300) {
    margin-left: 70px;
  }

  @media (max-width: $resolution-1200) {
    margin-left: 50px;
  }

  @media (max-width: $resolution-1000) {
    margin-left: 0;
    margin-bottom: 30px;
    height: 100px;
  }

  &:hover{
    text-decoration: none;
  }

  &:after{
    content: '';
    display: block;
    width: 4px;
    top: 20px;
    bottom: 20px;
    background: $color-red;
    position: absolute;
    left: -4px;
  }
}

.lk-exit-icon{
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  background: #f7f7f7;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    width: 24px;
    height: auto;
  }
}

.lk-item-reserv{
  display: flex;
  justify-content: space-between;
  font-family: "Travels";
  font-size: 14px;
  margin-top: 30px;
  flex-wrap: wrap;

  @media (max-width: $resolution-1200) {
    flex-wrap: wrap;
  }
}

.lk-item-reserv-text{
  font-weight: 600;
  margin-bottom: 5px;

  @media (max-width: $resolution-1200) {
    width: 100%;
  }

  span{
    color: $color-red;
  }
}

.lk-item-reserv-button{
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  border-bottom: 1px dashed #a6a6a6;
  padding-bottom: 3px;
  transition: $animation-speed border-color;

  @media (max-width: $resolution-1200) {
    margin-top: 10px;
    font-size: 14px;
  }
  
  &:hover{
    border-color: transparent;
  }
}

.lk-list{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &:after{
    content: '';
    width: 350px;

    @media (max-width: $resolution-1400) {
      width: 300px;
    }
  }

  .apartment-search__item{
    width: 350px;
    margin-right: 1%;

    @media (max-width: $resolution-1400) {
      width: 300px;
    }

    @media (max-width: $resolution-1000) {
      width: auto;
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

.frame-lk{
  @media (max-width: $resolution-1000) {
    padding: 0;
  }
}

.lk-title2{
  @media (max-width: $resolution-1000) {
    padding: 0 40px;
  }
}
