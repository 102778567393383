.partnerBlock {

  @media (max-width: $resolution-1000) {
    padding: 0;
  }

  &__title {
    position: relative;
    display: inline-block;
    font-family: 'Travels';
    font-weight: 500;
    font-size:30px;
    line-height: 40px;
    padding-bottom: 24px;

    @media (max-width: $resolution-1400) {
      font-size:28px;
      line-height: 38px;
    }
  
    @media (max-width: $resolution-1300) {
      font-size:26px;
      line-height: 36px;
    }
  
    @media (max-width: $resolution-1200) {
      font-size:24px;
      line-height: 34px;
    }

    @media (max-width: $resolution-1000) {
      font-size: 26px;
      line-height: 1.2;
      padding: 0 40px 50px 40px;
      margin-bottom: 45px;
    }

    &:after {
      content:'';
      position: absolute;
      bottom: 0;
      left: 2px;
      height: 5px;
      width: 60.5%;
      background: $color-red;

      @media (max-width: $resolution-1000) {
        width: auto;
        right: 0;
        left: 40px;
      }
    }
  }

  &__content {
    padding: 0 15.1vw;
    margin: 100px 0 0;
    font-size: 20px;
    line-height: 30px;

    @media (max-width: $resolution-1000) {
      margin: 0;
      padding: 0;
      font-size: 18px;
      line-height: 1.5;
    }
  }

  &__about {
    display: flex;
    align-items: center;
    margin-bottom: 91px;

    @media (max-width: $resolution-1000) {
      display: block;
      margin: 0;
    }
  }

  &__img {
    width: 272px;
    height: 272px;
    padding: 31px;
    line-height: 210px;
    text-align: center;
    background: #f2f2f2;
    box-sizing: border-box;

    @media (max-width: $resolution-1000) {
      width: calc(100vw - 30px);
      height: calc(100vw - 30px);
      margin: 0 auto 45px auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      vertical-align: middle;
      max-width: 100%;
      filter: grayscale(100%);
    }
  }

  &__text {
    padding-left: 7.8%;
    width: calc(100% - 272px);
    box-sizing: border-box;
    padding-bottom: 9px;

    @media (max-width: $resolution-1000) {
      width: auto;
      padding: 0 40px 45px 75px;
    }
  }

  .partnerBlock__text2{
    @media (max-width: $resolution-1000) {
      padding: 0 40px;
    }
  }
}

.partners{
  @media (max-width: $resolution-1000) {
    .apartment-search__view{
      margin: 45px 40px 0 40px;
    }

    .apartment-search__view-title{
      padding: 45px 0 35px 0;
      margin: 0;
    }

    .apartment-search__slider{
      padding: 0;
      margin-bottom: 0px;
    }

    .apartment-object{
      margin-bottom: 70px;
    }
  }
}