.page .bought {
  .bought__map-addr{

    a{
      border-bottom: 1px solid #000;
      padding-bottom: 2px;
      text-decoration: none;

      @media (min-width: 1000px) {
        &:hover{
          border-color: transparent;
        }
      }
    }

    p{
      margin: 0 0 20px 0;
      position: relative;
      padding-left: 25px;

      @media (max-width: 1000px) {
        padding-left: 20px;
      }

      &:after{
        width: 4px;
        height: 4px;
        background: #ed1c24;
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
      }
    }
  }

  &__columns{
    justify-content: space-between;
  }
  &__left1{
    padding-bottom: 120px;
    min-width: 45%;
    width: 45%;
    display: flex;
    flex-direction: column;
    @media (max-width: 1000px) {
      width: 100%; min-width: 100%; padding: 0;
    }
  }
  &__right1{
    padding-bottom: 120px;
    width: 42.7%;
    min-width: 42.7%;
    @media (max-width: 1000px) {
      width: 100%; min-width: 100%; padding: 0;
    }
  }
  &__img1{
    margin-top: -60px;
    span{
      padding-top: 100%;
    }
    @media (max-width: 1000px) {
      width: 100%;
      max-width: 400px;
      margin: 0 auto 45px;
    }
    @media (max-width: 480px) {
      margin: 0 -22px 45px;
      width: calc(100% + 44px);
      max-width: initial;
      
    }
  }
  &__title{
    max-width: 590px;
    font-size: 30px;
    margin-bottom: 50px;
    font-family: "Travels";
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5;
    @media(max-width: 1400px) {
      font-size: 28px;
    }
    @media(max-width: 1300px) {
      font-size: 24px;
    }
  }

  &__text1{
    font-size: 20px;
    line-height: 1.4;
    padding-left: 13.5vw;
    box-sizing: border-box;
    position: relative;
    flex: 1;
    &:before{
      content: '';
      position: absolute;
      top: 0; width: 6px;
      height: 100%;
      background: #ed1c24;
      left: 8.9vw;
    }
    @media (max-width: 1000px) {
      font-size: 16px;
      padding-left: 35px;
      padding-top: 45px;
      margin-bottom: 45px;
      &:before{
        top: 0; width: calc(100% + #{$padding_mobile});
        height: 4px;
        background: #ed1c24;
        left: 0;
      }
    }
  }


  &__left2{
    padding-bottom: 130px;
    min-width: 25%;
    width: 25%;
    @media (max-width: 1000px) {
      width: 100%; min-width: 100%; padding: 0;
    }
  }
  &__right2{
    padding-bottom: 130px;
    padding-top: 90px;
    width: 57.7%;
    min-width: 57.7%;
    padding-right: 8.9vw;
    box-sizing: border-box;
    @media (max-width: 1000px) {
      width: 100%; min-width: 100%; padding: 0;
    }
  }
  &__img2{
    span{
      padding-top: 100%;
    }
    @media (max-width: 1000px) {
      margin: 0 0 45px 35px;
      max-width: 300px;
      position: relative;
      width: calc(100% - 15px);
      &:before{
        content: '';
        width: 4px;
        height: 400px; 
        background: #ed1c24;
        position: absolute;top: 0; left: -35px;
      }
    }
  }
  &__text2{
    font-size: 20px;
    line-height: 1.4;
    @media (max-width: 1000px) {
      margin-bottom: 45px;
      font-size: 16px;
      p:first-child{
        margin-top: 0;
        margin-bottom: 45px;
      }
      p:last-child{
        padding-left: 35px;
      }
    }
  }


  &__left3{
    //padding-bottom: 110px;
    min-width: 56%;
    width: 56%;
    padding-top: 110px;
    @media (max-width: 1000px) {
      width: 100%; min-width: 100%; padding: 0;
    }
  }
  &__right3{
    //padding-bottom: 160px;
    padding-bottom: 50px;
    width: calc(44% + 100px);
    min-width: calc(44% + 100px);
    margin-left: -100px;
    box-sizing: border-box;
    @media (max-width: 1000px) {
      width: 100%; min-width: 100%; padding: 0; margin: 0;
    }
  }
  &__img3{
    span{
      padding-top: 95%;
    }
    @media (max-width: 1000px) {
      position: relative;
      margin-bottom: 75px;
      &:before{
        content: '';
        width: 55%;
        height: 4px; 
        background: #ed1c24;
        position: absolute;top: 0; right: 0;
      }
    }
  }
  &__text3{
    font-size: 20px;
    line-height: 1.4;
  }


  &__links{
    &>li{
        border-top: 1px solid #d9d9d9;
        margin-bottom: 0;
        padding-top: 0; padding-bottom: 0;
        padding-left: 85px;
        &:before{display: none;}
        i{
          font-style: normal;
          color: #ed1c24;
          position: absolute;
          width: 25px;
          left: 0; transform: translateX(-100%);
        }
        @media (max-width: 1000px) {
          padding-left: 20px;
        }
    }
    .tab{
      font-size: 20px;
      padding-top: 45px;
      padding-bottom: 45px;
      &:before{
        color: #000; right: 27px; transform: translateY(-50%);
        @media (max-width: 1000px) {
          right: 0;
        }
      }
      &[data-active="true"]:before{transform: rotate(180deg) translateY(50%)}
      @media (max-width: 1000px) {
        font-size: 16px;
        padding-top: 35px;
        padding-bottom: 35px;
      }
    }
    .tab-content{
      padding-top: 0;
      padding-bottom: 65px;
      line-height: 1.5;
      font-size: 16px;
    }
  }
  &__title2{
    font-size: 48px;
    margin-bottom: 50px;
    font-family: "Travels";
    font-weight: 500;
    @media (max-width: 1000px) {
      font-size: 24px;
    }
  }
  &__appartments{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 80px;
  }
  &__map{
    display: flex;
    margin-bottom: 150px;
    padding-top: 110px;
    @media (max-width: 1000px) {
      flex-direction: column;
      margin-bottom: 70px;
      padding-top: 0;
    }
    &>div:first-child{
      width: 40%;
      position: relative;
      @media (max-width: 1000px) {
        width: 100%;
      }
    }
    &>div:last-child{
      width: 60%;
      @media (max-width: 1000px) {
        width: 100%;
      }
    }
    &__img{
      position: relative;
      float: right;
      // bottom: 0; right: 0;
      width: 519px;
      height: 350px;
      transform: translate(130px, 20px);
      z-index: 1;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      @media (max-width: 1000px) {
        width: 100%;
        transform: none;
      }
    }
    .bought__title2{
      margin-bottom: 30px;
    }
  }
  .tab--arrow:before{font-size: 17px;}
  .contacts-map{
    height: 400px;

    [class*="copyrights-pane"]{
      bottom: auto !important;
      top: 40px !important;
    }

    @media (max-width: 1000px) {
      width: calc(100% + #{$padding_mobile} + #{$padding_mobile});
      margin-left: -$padding_mobile;
      margin-top: 50px;
    }
  }
}

.bought-by-id{
  .appartment-by-id__header-wrap{
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }

  .title{
    font-size: 50px;
    text-align: left;
    margin-bottom: 90px;
    
    @media (max-width: $resolution-1400) {
      font-size: 46px;
      margin-bottom: 70px;
    }
  
    @media (max-width: $resolution-1300) {
      font-size: 42px;
      margin-bottom: 50px;
    }
  
    @media (max-width: $resolution-1200) {
      font-size: 36px;
      margin-bottom: 30px;
    }

    @media (max-width: $resolution-1000) {
      font-size: 26px;
      margin-bottom: 30px;
    }
  }

  .bought__title{
    font-size: 30px;
    line-height: 1.2;

    @media (max-width: $resolution-1400) {
      font-size: 28px;
    }
  
    @media (max-width: $resolution-1300) {
      font-size: 24px;
    }
  
    @media (max-width: $resolution-1200) {
      font-size: 20px;
    }
  }
  
  .appartment-menu-fixed-detect{
    margin-bottom: 60px;
  }

  @media (max-width: $resolution-1000) {
    .appartment-by-id__header-button > div:last-child{
      min-width: 17px;
    }

    .appartment-by-id__header-title{
      font-size: 26px;
      margin-bottom: 15px;
    }

    .appartment-by-id__header-metro{
      margin-right: 0;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .appartment-by-id__header-name{
      font-size: 16px;
    }

    .appartment-by-id__header-metro-wrap{
      display: block;
    }

    .appartment-by-id__header-in-last{
      display: block;
    }

    .appartment-by-id__header-in > div:first-child{
      margin-bottom: 30px;
      padding-bottom: 25px;
      border-bottom: 1px solid #d9d9d9;
    }

    .appartment-menu-fixed__priceup{
      top: 0;
      margin-right: 0;
      text-align: left;
      margin-bottom: 20px;
    }

    .appartment-by-id__header-in-last > div:first-child{
      display: block;
    }

    .appartment-by-id__header-price-wrap-first{
      display: block;
    }

    .appartment-by-id__header-button{
      margin-left: 0;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      padding: 20px 18px;
      margin-top: 25px;
    }

    .appartment-by-id__header-bottom-icons{
      justify-content: center;
    }

    .appartment-by-id__header-in{
      border: none;
      padding: 0;
    }
  }
}