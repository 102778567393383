.online-registration{

  .page-columns{
    margin-bottom: -215px;
    position: relative;
    z-index: 3;

    @media (max-width: $resolution-1400) {
      margin-bottom: -200px;
    }
  
    @media (max-width: $resolution-1300) {
      margin-bottom: -185px;
    }
  
    @media (max-width: $resolution-1200) {
      margin-bottom: -170px;
    }
  }

  .call-request{
    padding-bottom: 115px;
    position: relative;

    @media (max-width: $resolution-1400) {
      padding-bottom: 100px;
    }
  
    @media (max-width: $resolution-1300) {
      padding-bottom: 85px;
    }
  
    @media (max-width: $resolution-1200) {
      padding-bottom: 70px;
    }

    @media (max-width: $resolution-1000) {
      padding: 70px 40px;

      .call-request__btn{
        width: auto;
        float: none;
      }

      .call-request__get-sms{
        font-size: 13px;
        padding: 7px;
        top: 33px;
      }

      input{
        font-size: 16px;
      }
    }

    
    @media (max-width: $resolution-320) {
      padding: 40px 20px;
    }
  }

  .call-request:before{
    content: '';
    display: block;
    width: 5px;
    height: 314px;
    background: $color-red;
    position: absolute;
    left: 0;
    top: 153px;

    @media (max-width: $resolution-1000) {
      display: none;
    }
  }

  .call-request:after{
    content: '';
    display: block;
    clear: both;
  }

  .page-columns__left{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: $resolution-320) {
    .frame{
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.online-registration__fotm-title{
  font-family: "Travels";
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 80px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 70px;
    font-size: 28px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 60px;
    font-size: 26px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 50px;
    font-size: 24px;
  }

  span{
    color: $color-red;
  }
}

.online-registration__menu{
  font-size: 20px;
  padding-bottom: 218px;

  @media (max-width: $resolution-1400) {
    font-size: 19px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 18px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 16px;
  }

  @media (max-width: $resolution-1000) {
    padding: 0 40px 65px 40px;
  }

  @media (max-width: $resolution-320) {
    padding: 0 20px 65px 20px;
  }

  span{
    color: $color-red;
  }

  & > div{
    margin-bottom: 35px;
    cursor: pointer;

    @media (min-width: $resolution-1000) {
      &:hover > div{
        border-color: transparent;
      }
    }

        
    @media (max-width: $resolution-1000) {
      margin-bottom: 45px;
    }

    & > div{
      position: relative;
      display: inline-block;
      border-bottom: 1px dashed #a6a6a6;
      padding-bottom: 5px;
      transition: $animation-speed border-color;

      @media (max-width: $resolution-1400) {
        padding-bottom: 4px;
      }
    
      @media (max-width: $resolution-1300) {
        padding-bottom: 3px;
      }
    
      @media (max-width: $resolution-1200) {
        padding-bottom: 2px;
      }
    }
  }

  & > div:last-child{
    margin-bottom: 0;
  }
}

.online-registration__bg{
  height: 747px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-bottom: 155px;

  @media (max-width: $resolution-1400) {
    height: 720px;
    margin-bottom: 130px;
  }

  @media (max-width: $resolution-1300) {
    height: 650px;
    margin-bottom: 110px;
  }

  @media (max-width: $resolution-1200) {
    height: 650px;
    margin-bottom: 80px;
  }

  @media (max-width: $resolution-1000) {
    transform: none !important;
    height: 372px;
    position: relative;
    margin-bottom: 75px;

    &:after{
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 200px;
      height: 5px;
      background: $color-red;
    }
  }
}

.online-registration__sheme{
  position: relative;
  margin-bottom: 170px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 140px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 100px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 50px;
  }
}

.online-registration__sheme-in{
  position: relative;
  z-index: 2;
  display: flex;

  & > div:first-child{
    width: 135px;
    border-right: 1px solid #bfbfbf;

    @media (max-width: $resolution-1000) {
      transform: none !important;
      border-left: 1px solid #bfbfbf;
      border-right: 0;
      width: auto;
    }
  }

  & > div:last-child{
    flex: 1;
  }
}

.online-registration__sheme-title{
  font-family: "Travels";
  font-weight: 500;
  margin-bottom: 90px;
  font-size: 50px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 80px;
    font-size: 45px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 70px;
    font-size: 40px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 60px;
    font-size: 35px;
  }

  @media (max-width: $resolution-1000) {
    transform: none !important;
    font-size: 26px;
    margin-bottom: 70px;
  }
}

.online-registration__sheme-row{
  display: flex;
  position: relative;
  left: -115px;

  @media (max-width: $resolution-1000) {
    display: block;
    left: 0;

    &:first-child{
      &:before{
        content: '';
        display: block;
        width: 17%;
        position: absolute;
        left: -2px;
        top: 0;
        height: 111px;
        background: #fff;
      }
    }
  }

  &:last-child{
    &:before{
      content: '';
      display: block;
      width: 17%;
      position: absolute;
      left: -2px;
      top: 111px;
      bottom: 0;
      background: #fff;
    }
  }

  & > div:last-child{
    max-width: 50%;
    padding-top: 80px;

    @media (max-width: $resolution-1000) {
      padding: 0 0 0 28px;
      max-width: 100%;
      position: relative;
      z-index: 3;
    }
  }
}

.online-registration__sheme-row:nth-child(even){
  padding-left: 23%;
  left: 0;

  @media (max-width: $resolution-1000) {
    padding-left: 0;
  }

  &:after{
    content: '';
    display: block;
    width: 17%;
    height: 110px;
    position: absolute;
    left: 0;
    top: 0;
    border-bottom: 1px solid #bfbfbf;
  }
}

.online-registration__sheme-row:nth-child(odd){
  @media (max-width: $resolution-1000) {
    &:after{
      content: '';
      display: block;
      width: 17%;
      height: 110px;
      position: absolute;
      left: 0;
      top: 0;
      border-bottom: 1px solid #bfbfbf;
    }
  }
}

.online-registration__sheme-img{
  width: 215px;
  height: 215px;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: relative;

  @media (max-width: $resolution-1000) {
    left: 40px;
  }
}

.online-registration__sheme-img-wrap{
  position: relative;
  padding: 48px 35px 48px 0;
  top: -48px;
  background: #fff;

  @media (max-width: $resolution-1000) {
    top: 0;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }
}

.online-registration__sheme-stitle{
  color: $color-red;
  font-family: "Travels";
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 35px;

  @media (max-width: $resolution-1400) {
    font-size: 23px;
    margin-bottom: 30px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 22px;
    margin-bottom: 25px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: $resolution-1000) {
    margin-bottom: 35px;
  }
}

.online-registration__sheme-text{
  font-size: 20px;
  line-height: 1.4;
  padding-bottom: 50px;

  @media (max-width: $resolution-1400) {
    font-size: 19px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 18px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 16px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 17px;
    line-height: 1.7;
    padding-bottom: 60px;
  }
}

.online-registration__sheme-number{
  color: $color-red;
  font-family: "Travels";
  font-size: 120px;
  line-height: 120px;
  position: absolute;
  font-weight: 500;
  left: 20px;
  top: 180px;

  @media (max-width: $resolution-1000) {
    left: 50%;
    top: -30px;
    margin-left: -140px;
    line-height: 1;
    text-align: right;
    width: 120px;
  }
}

.online-registration__gsap{
  transform: translateY(300px);
}
