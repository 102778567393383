.recommended h2{
  font-size: 30px;
  margin-bottom: 45px;
  text-align: left;
}
.recommended__list{
  margin-bottom: 100px;
    @media (max-width: 1000px) {
        margin-bottom: 50px;
    }
  .btn{
    margin: 45px auto 0;
    max-width: 300px;
  }
}
.recommended__tags__row{
  margin-bottom: 20px;
  display: flex;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
}
.recommended__tags__title{
  width: 200px;
  min-width: 200px;
  font-size: 18px;
  font-weight: 600;
  font-family: "Travels";
  padding-top: 15px;
  @media (max-width: 1000px) {
    padding-top: 0; margin-bottom: 10px;
  }
}
.recommended__tags__btn{
  min-width: 160px;
  margin-left: auto;
  width: 160px;
  height: 40px;
  border: 1px solid #f2eeea;
  background: #f2eeea;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 300ms;
  font-size: 16px;
  cursor: pointer;
    text-decoration: none;
  &:hover{
    background: #dad6d2;
  }
  @media (max-width: 1000px) {
    margin-left: 0;
  }
}
.recommended__tags--first{
  margin-bottom: 50px;
}
.recommended__tags__item{
  margin-right: 10px;
  margin-bottom: 10px;
  height: 40px;
  border: 1px solid #e6e6e6;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: 300ms;
    text-decoration: none;
  &:hover{
    background: #f2eeea;
  }
  @media (max-width: 1000px) {
    margin-right: 0;
    font-size: 15px;
    display: block;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 370px) {
    margin-right: 0;
    font-size: 14px;
  }
}
.recommended__tags__body{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 1000px) {
    //flex-direction: column;
  }
}


.frame-left{
  padding-left: $padding;
  @media (max-width: $resolution-1480) {
    padding-left: $padding_middle;
  }
  @media (max-width: $resolution-1200) {
    padding-left: $padding_small;
  }
  @media(max-width: 1000px) {
    padding-left: $padding_mobile;
  }
}

.frame-right{
  padding-right: $padding;
  @media (max-width: $resolution-1480) {
    padding-right: $padding_middle;
  }
  @media (max-width: $resolution-1200) {
    padding-right: $padding_small;
  }
  @media(max-width: 1000px) {
    padding-right: $padding_mobile;
  }
}

.page .recommended-detail .bought__img1{
  margin-top: 0;
}
.recommended-detail .apartment-search__map-outer.fixed{
  position: sticky;
  width: 100%;
  top: 80px;
}
@media (max-width: 1000px) {
  .page .recommended-detail .bought__text1{
    padding-right: 25px;
    padding-left: 25px;
  }
}
.recommended-detail .page-columns__right bought__right1 .slick-arrow:before{
  content: '';
  width: 24px; height: 60px;
  background-image: url(/static/img/arrow.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}
.recommended-detail .page-columns__right bought__right1 .slick-next:before{
  transform: scaleX(-1);
}
.recommended-detail .page-columns__right bought__right1 .slick-prev{left: 40px;}
.recommended-detail .page-columns__right bought__right1 .slick-next{right: 40px;}


.recommended-detail .recommended__tags--first{
  margin-bottom: 75px;
}
.recommended-detail .apartment-search__list-wrap{
  padding-bottom: 30px;
}
@media (min-width: 1000px) {
  .recommended-detail .apartment-search{min-height: 0; margin-bottom: 90px;}
  .recommended-detail .apartment-search-similar{
    margin-bottom: 130px;
  }
  .recommended-detail .recommended__tags--first{
    margin-bottom: 130px;
  }
  .recommended-detail .apartment-search-similar{
    margin-bottom: 60px;
  }
  .recommended-detail .apartment-search-similar__title{
    padding-left: 215px;
  }
  .recommended-detail .apartment-search-similar__title:after{
    right: auto;
    width: 390px;
  }
}

.index__info__right2 > div .index__info__item:first-child .index__info__item__text:after{
  left: auto; right: 0;
}
.index__info__right2 > .index__info__item .index__info__item__text:after,
.index__info__right2 > div .index__info__item:last-child .index__info__item__text:after{
  right: auto; left: 0;
 }


.recommended {
    .news-feed__item {
        &:first-child,
        &:nth-child(3n),
        &:nth-child(4n)
        {
            margin-top: -50px;
            @media (max-width: 1200px) {
                margin-top: -30px;
            }
            @media (max-width: 1000px) {
                margin-top: 0;
            }
            @media (min-width: 1001px) {
                .news-feed__item__img {
                    padding-top: 52%;
                }
            }
        }
        &:first-child,
        &:nth-child(3) {
            margin-top: 0;
        }

    }

    .popup__title {
        color: #000;
    }
    .form-block .btn {
        width: 289px;
        max-width: 100%;
        margin-top: 0;
        @media (max-width: 1000px) {
            margin-top: 10px;
        }
    }

    .consulting-request {
        padding: 6.35vw 12.2vw 6vw 8.8vw;
        @media (max-width: 1000px) {
            padding: 75px 40px 80px;
        }
    }

    @media (max-width: 1000px) {
        .apartment-search__view-title {
            /*
            margin-left: 0;
            margin-right: 0;
            */
        }
        .mobile-new-events .news-feed__item {
            padding-left: 40px;
            padding-right: 40px;
        }

       /* .similar-appartments {
            .frame {
                padding-left: 0;
                padding-right: 0;
            }

        }*/
    }
}


.rec_info_boxes {
    max-width: 1520px;
    margin: 100px auto 0 auto;
    @media (max-width: 1680px) and (min-width: 1001px) {
        margin: 50px auto 0 auto;
    }
    @media (max-width: 1000px) {

        margin: 0;
    }

}
.rec_info_box {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    @media (max-width: 1680px) and (min-width: 1001px) {
        margin-bottom: calc(40 / 1680 * 100vw);
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.rec_info__content {
    width: 100%;
    margin-right: 68px;
    @media (max-width: 1680px) and (min-width: 1001px) {
        margin-right: calc(68 / 1680 * 100vw);
    }
}

.rec_info__img {
    min-width: 622px;
    height: 450px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-left: 58px;
    position: relative;
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 180px;
        height: 5px;
        left: -60px;
        bottom: 40px;
        background: #ED1C24;
        @media (max-width: 1680px) and (min-width: 1001px) {
            width: calc(180 / 1680 * 100vw);
            height: calc(5 / 1680 * 100vw);
            left: calc(-60 / 1680 * 100vw);
            bottom: calc(40 / 1680 * 100vw);
        }
    }
    @media (max-width: 1680px) and (min-width: 1001px) {
        min-width: calc(622 / 1680 * 100vw);
        height: calc(450 / 1680 * 100vw);
        margin-left: calc(58 / 1680 * 100vw);

    }

}


.rec_info__title {
    font-family: 'Travels', sans-serif;
    font-weight: 500;
    font-size: 40px;
    line-height: 1;
    color: #000000;
    @media (max-width: 1680px) and (min-width: 1001px) {
        font-size: calc(40 / 1680 * 100vw);
    }

}
.rec_info__text {
    font-family: Georgia, Times, "Times New Roman", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5;
    color: #000000;
    margin-top: 30px;

    @media (max-width: 1680px) and (min-width: 1001px) {
        font-size: calc(20 / 1680 * 100vw);
        margin-top: calc(30 / 1680 * 100vw);
    }

}

@media (min-width: 1001px) {
    .rec_info_box {
        &:nth-child(2n) {
            .rec_info__content {
                margin-right: 0;
            }
            .rec_info__img {
                order: -1;
                margin-left: 0;
                margin-right: 119px;
                @media (max-width: 1680px) and (min-width: 1001px) {
                    margin-right: calc(119 / 1680 * 100vw);
                }
                &:before {
                    transform: rotate(90deg);
                    left: auto;
                    bottom: 40px;
                    background: #ED1C24;
                    right: -50px;
                    transform-origin: center center;
                    @media (max-width: 1680px) and (min-width: 1001px) {
                        bottom: calc(40 / 1680 * 100vw);
                        right: calc(-50 / 1680 * 100vw);
                    }

                }
            }
        }
    }

}
@media (max-width: 1000px) {
    .rec_info_box {
        display: block;
    }
    .rec_info__title {
        font-size: 24px;
    }
    .rec_info__text {
        font-size: 16px;
        margin-top: 20px;
    }
    .rec_info__img {
        min-width: auto;
        height: 55vw;
        max-height: 450px;
        margin-left: auto;
        position: relative;
        max-width: 622px;
        margin-top: 20px;
    }
    .rec_info__img:before {
        width: 90px;
    }
}

.page {
  .recommended-detail {

    .bought__columns {
      @media (max-width: 1000px) {
        flex-direction: column;
        &.frame-left {
          padding: 0 40px;
        }
      }
    }
    .bought__title {
      max-width: none;
      font-size: 30px;
      font-weight: 500;
      line-height: 1.2;
      font-family: "Travels", sans-serif;

      min-height: 581px;
      display: flex;
      justify-content: center;
      padding: 30px 0;
      box-sizing: border-box;
      flex-direction: column;

      @media (max-width: 1000px) {
        font-size: 24px;
        min-height: auto;
        margin-bottom: 0;
      }
    }

    .bought__left1 {
      padding-bottom: 0;
      min-width: 40%;
      width: 40%;
      @media (max-width: 1000px) {
        width: auto;
        min-width: auto;
      }
    }
    .bought__right1 {
      padding-bottom: 0;
      width: 52.98%;
      min-width: 52.98%;
      @media (max-width: 1000px) {
        width: auto;
        min-width: auto;
        padding: 0;
        margin-left: -40px;
        margin-right: -40px;
      }
    }
    .bought__img1 {
      @media (max-width: 1000px) {
         margin: 0;
      }

    }
    .bought__img1 span {
      padding-top: 88.236%;
      @media (max-width: 1000px) {
        padding-top: 100%;
      }
    }
    .bought__text1 {
      font-size: 20px;
      line-height: 1.4;
      padding: 60px 0 60px 12vw;

      @media (max-width: 1000px) {
        font-family: Georgia, sans-serif;
        font-size: 16px;
        padding: 40px 0 0 0;
        margin-bottom: 40px;
      }

      &:before {
        left: 8vw;
        @media (max-width: 1000px) {
          left: 0;
        }
      }
      ol {
        margin-left: -20px;
      }

      ol, ul {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        li {
          margin: 20px 0;

          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

    }


    .bought__left2 {
      padding-bottom: 80px;
      min-width: 32.706%;
      width: 32.706%;
      @media (max-width: 1000px) {
        width: auto;
        min-width: auto;
        padding: 0;
      }
      .bought__img2 span {
        padding-top: 80%;
        @media (max-width: 1000px) {
          padding-top: 59.705%;
        }
      }
    }
    .bought__img2 {
      @media (max-width: 1000px) {
        margin: 40px 0 0 0;
        padding: 0;
        max-width: none;
        width: calc(100% + 40px);
        span {
          //padding-top: 59.705%;
        }
        &:before {
          display: none;
        }
      }
    }
    .bought__right2 {
      padding: 0 0 80px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 59.9%;
      min-width: 59.9%;
      @media (max-width: 1000px) {
        width: auto;
        min-width: auto;
        padding: 0;
      }
    }

    .bought__text2 {
      font-family: "Travels", sans-serif;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.2;
      @media (max-width: 1000px) {
        font-size: 20px;
      }
    }
  }
}
.recommended-detail {
  .page-title {
    margin-bottom: 0;
  }

  .p_columns_e {
    display: flex;
    justify-content: space-between;
    padding: 0 4.5vw;
    margin-bottom: 80px;
    position: relative;
    @media (max-width: 1000px) {
      flex-direction: column;
      margin-top: 40px;
      padding-left: 40px;
      padding-right: 40px;
      margin-bottom: 40px;
    }
  }
  .p_columns_e_left {
    width: 100%;
  }
  .p_columns_e_right {
    width: 32.706%;
    min-width: 32.706%;
    //box-sizing: border-box;
    padding-left: 12.7%;

    @media (max-width: 1000px) {
      width: auto;
      min-width: auto;
      padding: 0;
      /*
      margin-left: -40px;
      margin-right: -40px;
      */
    }

    .bought__img2 {
      margin-top: -36%;
      @media (max-width: 1000px) {

        max-width: none;
        margin: 0;
        width: 100%;
        &:before {
          display: none;
        }
      }
    }
  }
}
