.mortgage {

  @media (max-width: $resolution-1000) {

    .mortgage-programms-btn{
      margin-bottom: 50px;
    }

    .table{
      margin-top: 75px;
      margin-bottom: 50px;

      tbody td{
        font-size: 13px;
        line-height: 1.2;
        padding: 10px;
        height: 100px;
      }

      tbody tr.table__tr-bottom td{
        height: auto;
        padding: 0;
      }

      img{
        max-width: none;
        width: 100%;
        display: block;
      }
    }
  }

  .apartment-search__view-title{
    border: none;
    padding-top: 0;

    @media (max-width: $resolution-1000) {
      padding-bottom: 20px;
    }
  }

  .news-feed{
    @media (max-width: $resolution-1000) {
      padding: 0;
      margin-bottom: 70px;

      .news-feed__item{
        padding-left: 40px;
        padding-right: 40px;
        width: auto;
      }
    }
  }

  .appartment-by-id-news-feed .title{
    text-align: left;
    font-size: 48px;
    margin-bottom: 30px;

    @media (max-width: $resolution-1000) {
      font-size: 26px !important;
      margin-bottom: 18px;
      padding: 0 40px;
    }
  }

  .services__links{
    margin-bottom: 250px;

    @media (max-width: $resolution-1400) {
      margin-bottom: 200px;
    }

    @media (max-width: $resolution-1300) {
      margin-bottom: 180px;
    }

    @media (max-width: $resolution-1200) {
      margin-bottom: 150px;
    }

    @media (max-width: $resolution-1000) {
      margin-bottom: -50px;
    }
  }

  .services__links .tab{
    font-family: Georgia, Times, "Times New Roman", serif;
  }

  .page-text--line {
    padding-left: 7.9vw;
    padding-right: 10vw;
    font-family: Georgia, Times, "Times New Roman", serif;
    font-size:20px;
    line-height: 1.4;
    margin-bottom: 260px;

    @media (max-width: $resolution-1400) {
      font-size:18px;
    }

    @media (max-width: $resolution-1300) {
      font-size:17px;
    }

    @media (max-width: $resolution-1200) {
      font-size:16px;
    }

    @media (max-width: $resolution-1000) {
      margin-bottom: 50px;
      padding: 35px 0 0 0;
      font-size: 17px;
      line-height: 1.6;

      p{
        margin: 0 0 25px 0;
      }

      li{
        line-height: 1.2;
        padding-left: 20px !important;
      }

      &:before{
        top: 0;
        right: -40px;
        display: block;
        width: auto;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 30px 0;
      li {
        position: relative;
        padding-left: 39px;
        margin-bottom: 30px;
        &:before {
          content:'';
          position: absolute;
          top:12px;
          left:0;
          width:4px;
          height:4px;
          background: $color-red;
        }
      }
    }
  }
  .page-columns__no-padding {
    margin-right: -2.5vw;
    width: calc(100% + 2.5vw) !important;
    height: 100%;

    @media (max-width: $resolution-1000) {
      width: auto !important;
      margin-right: -25px;
      margin-left: -25px;
    }

    span{
      padding-top: 0;
    }
  }
  .page-columns__img span {
    padding-top: 100%;
  }
  .apartment-search__view {
    margin-top: 95px;
    margin-left: 4.5vw;
    margin-right: 4.5vw;
  }
  .apartment-search__slider {
    padding: 0px 3.28vw;
    margin-bottom: 94px;

    .slick-dots {
      margin-top: 31px;
    }
  }
}

.mortgage-programms-btn{
  max-width: 300px;
  margin: 0 auto 80px auto;
}

.table {
  width:100%;
  font-family: 'Travels';
  font-weight: 500;
  text-align: center;
  table-layout: fixed;
  border-collapse: collapse;
  margin-top: 102px;
  margin-bottom: 80px;
  thead {
    font-size: 12px;
    line-height: 20px;
    color:#808080;
  }
  tbody {
    font-size: 16px;
    line-height: 25px;

    @media (max-width: $resolution-1000) {
      font-size: 13px;
      line-height: 1.2;
    }
    tr:last-child {
      td {
        border-bottom: solid 1px #e6e6e6;
      }
    }
  }
  img {
    max-width: 100px;

    @media (max-width: $resolution-1000) {
      width: 100%;
    }
  }
  &__tr-top {
    td {
      border-top: solid 1px #e6e6e6;
      padding: 40px 20px 23px;

      @media (max-width: $resolution-1000) {
        padding: 20px 5px 5px;
      }
    }
  }
  &__tr-bottom {
    td {
      padding-bottom: 17px;
    }
  }
  &__hidden {
    padding-bottom: 27px;
    span {
      color:#808080;
    }
  }
  &__moreLnk {
    border-bottom: solid 1px;
    transition: border 0.3s;
    cursor: pointer;
    &:hover {
      border-bottom-color:transparent;
    }
  }
}

.mortgage__news{
  padding-bottom: 100px;

  @media (max-width: $resolution-1000) {
    padding: 0 0 75px 0;

    .promo__item{
      margin: 0 40px 40px 40px;
    }

    .slick-track{
      height: auto !important;
    }

    .slick-list{
      transition: $animation-speed;
    }
  }

  .btn{
    max-width: 300px;
    margin: 10px auto 0;
  }
}

.mortgage__calc .frame{
  @media (max-width: $resolution-1000) {
    padding: 0;
  }
}

.mortgage_buts {
  display: flex;
  justify-content: space-between;

  .mortgage_but {
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    img {
      margin-top: -2px;
    }
  }

  @media (max-width: $resolution-1000) {
    flex-direction: column;
    gap: 15px;
  }
}

.mortgage_buts {
  display: flex;
  justify-content: space-between;

  .mortgage_but {
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    img {
      margin-top: -2px;
    }
  }

  @media (max-width: $resolution-1000) {
    flex-direction: column;
    gap: 15px;
  }
}
