.recommend{
  padding-bottom: 150px;

  @media (max-width: $resolution-1400) {
    padding-bottom: 120px;
  }
  @media (max-width: $resolution-1200) {
    padding-bottom: 100px;
  }
  @media (max-width: $resolution-1000) {
    padding-bottom: 75px;
  }
  .recommend-nav{
    margin-top: 60px;

    @media (max-width: $resolution-1000) {
      margin-top: 0;
    }
  }

  .index__info__left.pd .index__info__item--large{
    width: 100%;
  }

  .index__info:before{
    display: none;
  }

  .index__info{
    margin-bottom: 40px;
    @media (max-width: $resolution-1400) {
      margin-bottom: 30px;
    }
    @media (max-width: $resolution-1200) {
      margin-bottom: 20px;
    }
    @media (max-width: $resolution-1000) {
      margin-bottom: 30px !important;
    }
    &[data-hidden="true"] {display: none}

    .double > a{
      margin-right: 20px;
      @media (max-width: $resolution-1400) {
        margin-right: 15px;
      }
      @media (max-width: $resolution-1200) {
        margin-right: 10px;
      }
    }

    .double > div{
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: $resolution-1400) {
        margin-left: 15px;
      }
      @media (max-width: $resolution-1200) {
        margin-left: 10px;
      }

      .index__info__item--middle{
        flex: 1;
      }
    }
  }

  .index__info__right > *{
    display: flex;
    flex: 1;
    flex-direction: column;

    .index__info__item{
      flex: 1;
    }
  }

  .index__info__item--large{
    width: 100%;
  }

  .index__info__left{
    padding-right: 20px;
    @media (max-width: $resolution-1400) {
      padding-right: 15px;
    }
    @media (max-width: $resolution-1200) {
      padding-right: 10px;
    }
  }

  .index__info__right{
    padding-left: 20px;
      &:first-child {
          padding-left: 0;
      }
    @media (max-width: $resolution-1400) {
      padding-left: 15px;
    }
    @media (max-width: $resolution-1200) {
      padding-left: 10px;
    }
  }

  .index__info__item__title{
    font-size: 18px;
    margin-bottom: 8px;
    font-weight: 500;
    margin-top: 0;

    @media (max-width: $resolution-1400) {
      font-size: 17px;
    }
    @media (max-width: $resolution-1200) {
      font-size: 16px;
    }

    &.big{
      margin-bottom: 0;
      margin-top: 8px;
      font-size: 30px;
      font-weight: 400;

      @media (max-width: $resolution-1400) {
        font-size: 28px;
      }
      @media (max-width: $resolution-1200) {
        font-size: 26px;
      }
    }
  }

  .index__info__item__dsc{
    font-size: 14px;
    @media (max-width: $resolution-1400) {
      font-size: 13px;
    }
    @media (max-width: $resolution-1200) {
      font-size: 13px;
    }
    &.big{
      font-size: 18px;
      @media (max-width: $resolution-1400) {
        font-size: 17px;
      }
      @media (max-width: $resolution-1200) {
        font-size: 16px;
      }
    }
  }
}
