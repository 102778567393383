.apartment-search{
  min-height: 1200px;
  .filter{
    background: #f2f2f2;
    padding: 50px 0 35px 0;
    position: relative;
    z-index: 30;

    .input-search-word{
      height: 50px;
      min-width: 280px;

      @media (max-width: $resolution-1000){
        min-width: none;
        height: 64px;
      }
    }

    .frame{
      position: relative;
      z-index: 2;

      @media (max-width: $resolution-1000){
        padding: 0 30px;
      }
    }

    .filter-field__body{
      position: relative;

      @media (max-width: $resolution-1000){
        .filter-input{
          display: flex;
          width: 100%;

          span{
            margin: 0 8px;
          }
        }
      }

      input{
        color: #000;

        @media (max-width: $resolution-1000){
          flex-grow: 1;
          text-align: center;
          padding: 0 5px;

          &:first-child{
            text-align: right;
          }

          &:last-child{
            text-align: left;
          }
        }

        &::placeholder{
          color: #999;
        }
      }
    }

    .voice-field__mike{
      width: auto;
      height: auto !important;
      min-width: 50px;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before{
        transition: $animation-speed;
        transform: scale(1);
        position: relative;
        top: 0;
        left: 0;
      }


      @media (min-width: $resolution-1000){
        &:hover:before{
          transform: scale(0.9);
        }
      }
    }
  }

  .filter__top{
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
  }

  .filter__save-search{
    display: flex;
    align-items: center;
    font-family: 'Travels';
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;

    & > div{
      position: relative;
    }

    & > div:first-child{
      margin-right: 7px;
      width: 19px;
      height: 23px;
    }

    svg{
      width: 100%;
      height: auto;
    }

    span{
      position: absolute;
      left: 0;
      top: 0;
      width: 19px;
      height: 23px;
      visibility: hidden;
    }

    span.active{
      visibility: visible !important;
    }

    @media (min-width: $resolution-1000){
      &:hover{
        text-decoration: underline;
      }
    }
  }

  .filter__menu{
    display: flex;
    align-items: center;

    div{
      position: relative;
      font-family: 'Travels';
      margin-right: 50px;
      font-size: 22px;
      line-height: 22px;
      font-weight: 500;
      cursor: pointer;

      @media (max-width: $resolution-1400){
        margin-right: 35px;
        font-size: 20px;
        line-height: 20px;
      }

      @media (max-width: $resolution-1200){
        margin-right: 15px;
        font-size: 16px;
        line-height: 16px;
      }

      @media (max-width: $resolution-1000){
        margin-right: 0;
        font-size: 18px;
        line-height: 18px;
        margin-bottom: 10px;
      }

      &:after{
        content: '';
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: -7px;
        height: 1px;
        border-bottom: 1px dashed #a6a6a6;
        transition: $animation-speed opacity;

        @media (max-width: $resolution-1000){
          bottom: -5px;
        }
      }
    }

    .none {
      display: none;
    }
    .active{
      font-size: 30px;
      line-height: 30px;

      @media (max-width: $resolution-1400){
        font-size: 26px;
        line-height: 26px;
      }

      @media (max-width: $resolution-1200){
        font-size: 22px;
        line-height: 22px;
      }

      @media (max-width: $resolution-1000){
        font-size: 26px;
        line-height: 26px;
      }

      &:after{
        opacity: 0;
      }
    }

    @media (max-width: $resolution-1000){
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .filter__fields{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    flex: 1;

    @media (max-width: $resolution-1000){
      display: block;
    }
  }

  .filter-field__body{
    height: 50px;
    padding: 0 17px;
    display: flex;
    align-items: center;

    @media (max-width: $resolution-1000){
      height: 64px;
    }
  }

  .filter-field{
    margin-bottom: 0;
  }

  .filter__fields-cell{
    margin-right: 20px;

    @media (max-width: 1430px){
      margin-right: 16px;
    }
    @media (max-width: $resolution-1000){
      margin-right: 0;
      margin-bottom: 18px;
    }
  }

  .filter-input{
    color: #000;
  }

  .filter-input span{
    margin-right: 10px;
    background: #000;
  }

  .filter__center{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $resolution-1000){
      display: block;
    }
  }

  .filter__more-wrap{
    @media (max-width: $resolution-1000){
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .filter__more{
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Travels';
    font-weight: 500;
    font-size: 16px;
    position: relative;
    margin-bottom: 0px;
    margin-top: 0px;
    justify-content: flex-end;
    position: relative;
    margin-top: 37px;

    div{
      position: relative;

      &:after{
        content: "";
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: -5px;
        height: 1px;
        border-bottom: 1px dashed #a6a6a6;
        transition: $animation-speed opacity;
      }
    }

    @media (min-width: $resolution-1000){
      &:hover div:after{
        opacity: 0;
      }
    }

    span{
      display: block;
      width: 16px;
      height: 9px;
      margin-left: 11px;
      position: relative;
    }

    svg{
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
    }

    &.active span{
      transform: rotate(180deg);
    }

    @media (max-width: $resolution-1000){
      justify-content: center;
      margin-top: 40px;
      font-size: 17px;
    }

    i{
      display: flex;
      font-style: normal;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $color-red;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -20px;
      bottom: 10px;
      color: #fff;
      font-size: 11px;
    }
  }

  .filter-hidden{
    position: absolute;
    right: 0;
    top: 100%;
    background: #f2f2f2;
    padding: 2.32%;
    box-sizing: border-box;
    width: 50%;

    @media (max-width: $resolution-1000){
      width: auto;
      padding: 0 40px;
      top: 0;
      position: relative;
      background: #f2f2f2;
    }

    .filter__fields-cell{
      margin-right: 0;
      margin-bottom: 40px;
      width: 47%;

      &.single{
        width: 100%;

        .ui-checkbox{
          width: 25%;
          box-sizing: border-box;
          margin-right: 0;
          padding-right: 20px;

          @media (max-width: $resolution-1000){
            width: auto;
          }
        }
      }

      @media (max-width: $resolution-1200){
        margin-bottom: 25px;
      }

      @media (max-width: $resolution-1000){
        display: block;
        width: auto;
        margin-bottom: 18px;
      }
    }
  }

  .filter-hidden-extras{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: $resolution-1000){
      display: block;
    }
  }

  .filter-hidden__row{
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;

    @media (max-width: $resolution-1200){
      margin-bottom: 25px;
    }

    @media (max-width: $resolution-1000){
      display: block;
      margin-bottom: 18px;
    }
  }

  .filter-hidden__row-mr{
    margin-bottom: 26px;
  }

  .filter-hidden__cell{
    width: 47%;

    @media (max-width: $resolution-1000){
      display: block;
      width: auto;
      margin-bottom: 18px;
    }
  }

  .ui-select .MuiInputBase-root{
    max-width: 100%;
    width: 100%;
    min-width: 160px;

    @media (max-width: $resolution-1000){
      min-width: 0;
    }
  }

  .filter__checkbox-row{
    display: flex;
    margin-top: 17px;
    flex-wrap: wrap;

    .ui-checkbox{
      margin-right: 40px;
      margin-bottom: 14px;

      @media (max-width: $resolution-1200){
        margin-right: 20px;
      }

      @media (max-width: $resolution-1000){
        margin-right: 0px;
        margin-bottom: 28px;
      }
    }

    @media (max-width: $resolution-1000){
      display: block;
      margin-bottom: 40px;
    }
  }

  .filter-hidden__row-box{
    margin-bottom: 26px;

    .ui-checkbox{
      width: 25%;
      box-sizing: border-box;
      padding-right: 10px;
      margin-right: 0;
      margin-bottom: 14px;

      @media (max-width: $resolution-1000){
        width: auto;
        padding-right: 0;
        margin-bottom: 28px;
      }
    }
  }

  .filter__result-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    @media (max-width: $resolution-1000){
      padding: 20px 30px 0 30px;
      flex-direction: column;

      .btn{
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  .filter-sep{
    @media (max-width: $resolution-1400){
      width: 100%;
    }
  }

  .filter-clear{
    cursor: pointer;
    font-family: "Travels";
    font-weight: 500;
    font-size: 16px;
    display: flex;
    position: relative;

    &.filter-clear_top{
      top: 17px;
      margin-right: 20px;
      margin-bottom: 15px;
    }

    @media (max-width: $resolution-1400){
      font-size: 15px;
    }

    @media (max-width: $resolution-1200){
      font-size: 14px;
    }

    @media (max-width: $resolution-1000){
      font-size: 16px;
    }

    &:after{
      content: '';
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: -5px;
      height: 1px;
      border-bottom: 1px dashed #a6a6a6;
      transition: $animation-speed opacity;
    }

    @media (min-width: $resolution-1000){
      &:hover:after{
        opacity: 0;
      }
    }
  }

  @media (max-width: $resolution-1000){
    .filter-field__title{
      font-weight: 500;
      font-size: 15px;
    }

    .rc-slider-handle{
      width: 22px !important;
      height: 22px !important;
    }

    padding-top: 0;
  }
}

.apartment-object {
  margin-bottom: 152px;

  .apartment-search__view {
    margin-top: 92px;
    margin-left: 4.5vw;
    margin-right: 4.5vw;
  }
  .apartment-search__view-title {
    margin-bottom: 40px;
    padding-top: 46px;
  }
  .apartment-search__slider {
    padding: 0 4.28vw;
  }
  .apartment-search__item-slider-values {
    color:#fff;
    bottom: 4px;
  }
  .apartment-search__item-slider .slick-arrow  {
    bottom: -3px;
    path {
      fill:#fff;
    }
  }
  .apartment-search__item-slide {
    background-size: cover;
    background-color: #f2f2f2;
  }
  .apartment-search__item-label {
    background: rgba(255,255,255,0.7);
    right: 10px;
    top: 9px;
    padding: 8px 10px;
    line-height: 1;
  }
}

.apartment-search__list{
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &:after{
    content: '';
    flex: auto;
  }

  @media (max-width: $resolution-1000){
    display: block;
  }
}
.hidden_list{display: none !important;}

.apartment-search__item-image.apartment-search__item-image-mobile-list{
  height: auto;
}

.apartment-search__item-mobile-list{
  display: flex;
  justify-content: space-between;

  & > div{
    width: 48%;
    position: relative;
    box-sizing: border-box;
  }

  & > div:last-child{
    padding-right: 30px;

    .apartment-search__item-metro{
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  & + .apartment-search__item-label{
    right: auto;
    left: 20px;
  }

  .apartment-search__item-slide{
    height: 120px;
  }

  .apartment-search__item-info{
    font-size: 15px;
    margin-bottom: 5px;
  }
}

.apartment-search__link {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.apartment-search__item{
  width: 23%;
  margin-right: 2.66%;
  margin-bottom: 40px;
  transition: $animation-speed opacity;
  position: relative;

  a {
    text-decoration: none;
  }

  .apartment-search__item-logo{
    z-index: 11;
  }

  &.opacity{
    opacity: 0.3;

    .apartment-search__item-similar svg{
      transform: rotate(-180deg);
    }
  }

  &.active{
    .apartment-search__item-similar svg{
      transform: rotate(-180deg);
    }
  }

  @media (max-width: $resolution-1000){
    width: auto;
    margin-right: 0;
    margin-bottom: 50px;
  }

  &:nth-child(4n){
    margin-right: 0;
  }
  @media (min-width: 1000px) {
     z-index: 3;
    .apartment-search__item-image{ overflow: visible}
    .slick-arrow svg{transition: 600ms;}
    .slick-list{transform-origin: bottom; transition: 600ms;}
    &:hover .slick-list{transform: scale(1.2);}
  }
}
.slick-slider .apartment-search__item:hover .slick-list{transform: none;}

.apartment-search__item-image{
  position: relative;
  height: 230px;
  margin-bottom: 7px;
  text-decoration: none;
  display: block;
  overflow: hidden;

  .apartment-search__item-image-in{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-position: 50% 50%;
    background-size: contain;
    transition: $animation-speed transform;
    background-repeat: no-repeat;
    background-color: #f7f7f7;
    display: block;
    text-decoration: none;

    &.cover{
      background-size: cover;
    }
  }

  @media (min-width: $resolution-1000) {
    /*&:hover .apartment-search__item-image-in{
      transform: scale(1.2);
    }*/
  }
}

.apartment-search__item-slider{
  position: relative;

  .slick-arrow:after,
  .slick-arrow:before{
    display: none;
  }

  .slick-arrow{
    transform: none;
    top: auto;
    bottom: 0;
    z-index: 11;

    &.slick-prev{
      left: 0px;
      padding: 50px 50px 20px 10px;
    }

    &.slick-next{
      right: 0px;
      padding: 50px 10px 20px 50px;
    }

    svg{
      width: 13px;
      height: 33px;

      transition: $animation-speed transform;
    }

    @media (min-width: $resolution-1000) {
      &:hover svg{
        transform: scale(1.1);
      }
    }

    path{
      fill: #000;
    }
  }
}
.articles-detail__slider {
  .apartment-search__item-slider {
    .apartment-search__item-slider-values {
      color: #fff;
    }
    .slick-arrow{
      path{
        fill: #fff;
      }
    }
  }
}

.apartment-search__item-slider.cover .apartment-search__item-slide{
  background-size: cover;
}

.apartment-search__item-slider-values{
  height: 33px;
  bottom: 9px;
  position: absolute;
  left: 50px;
  right: 50px;
  text-align: center;
  z-index: 1;
  font-family: "Travels";
  font-size: 14px;
  font-weight: 500;
}

.apartment-search__item-slide{
  height: 230px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  background-color: #f2f2f2;
  position: relative;

  &.cover{
    background-size: cover;
  }

  &.empty{
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;

    path{
      fill: #e4e4e4;
    }

    svg{
      width: 80px;
      height: auto;
    }
  }
}

.apartment-search__item-metro{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #808080;
  font-family: 'Travels';
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 7px;

  @media (max-width: $resolution-1200){
    font-size: 12px;
  }

  @media (max-width: $resolution-1000){
    font-size: 13px;
    margin-bottom: 10px;
  }

  div{
    position: relative;
  }

  span{
    position: absolute;
    display: block;
    z-index: 2;
    opacity: 0;
    transition: $animation-speed opacity;
  }

  &.active span{
    opacity: 1;
  }

  svg{
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
}

.apartment-search__item-addr{
  font-family: 'Travels';
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 7px;

  @media (max-width: $resolution-1200){
    font-size: 14px;
  }

  @media (max-width: $resolution-1000){
    font-size: 16px;
    margin-bottom: 10px;
  }
  a {
    border-bottom: solid 1px transparent;
    transition: border-color 0.3s;
    &:hover {
      border-color: inherit;
    }
  }
}

.apartment-search__item-name{
  color: #808080;
  font-family: 'Travels';
  font-size: 13px;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 13px;

  @media (max-width: $resolution-1200){
    font-size: 12px;
  }

  @media (max-width: $resolution-1000){
    font-size: 14px;
  }

  a {
    border-bottom: solid 1px transparent;
    transition: border-color 0.3s;
    &:hover {
      border-color: inherit;
    }
  }
}

a.apartment-search__item-name{
  text-decoration: underline;
  display: block;

  @media (min-width: $resolution-1000){
    &:hover{
      text-decoration: none;
    }
  }
}

.apartment-search__item-info{
  font-family: 'Travels';
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 9px;

  @media (max-width: $resolution-1200){
    font-size: 13px;
  }

  @media (max-width: $resolution-1000){
    font-size: 14px;
  }
}

.apartment-search__item-price{
  font-family: 'Travels';
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;

  @media (max-width: $resolution-1200){
    font-size: 14px;
  }

  @media (max-width: $resolution-1000){
    font-size: 16px;
  }

  &>div{
    position: relative;
  }

  span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ed1c24;
    color: #fff;
    font-size: 12px;
    margin-left: 6px;
    position: relative;
    top: -2px;
    cursor: pointer;

    &:hover i{
      opacity: 1;
      visibility: visible;
      transform: translateY(-50%) scale(1);
    }
  }

  i{
    font-style: normal;
    display: block;
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%) scale(0.8);
    padding: 15px 20px;
    background: #fff;
    font-family: "Travels";
    font-size: 12px;
    color: #000;
    box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
    white-space: nowrap;
    margin-right: 5px;
    pointer-events: none;
    font-weight: 400;
    transition: $animation-speed opacity, $animation-speed visiblity, $animation-speed transform;
    opacity: 0;
    visibility: hidden;
  }
}

.apartment-discount{
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $color-red;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 0;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  line-height: 11px;

  @media (max-width: $resolution-1000){
    margin-top: -10px;
  }
}

.apartment-search__item-label{
  position: absolute;
  right: 20px;
  top: 20px;
  color: $color-red;
  font-family: 'Travels';
  font-weight: 500;
  font-size: 12px;
  background: #fff;
  padding: 6px 10px 5px;
  box-shadow: 1px 1px 5px rgb(0, 0, 0, .2);
  z-index: 5;
}

.apartment-search__item-logo{
  position: absolute;
  left: 10px;
  top: 10px;
  width: 30px; height: 30px;
  background-image: url(/static/img/apartment-search/logo-icon.svg);
  background-position: 50% 50%; background-repeat: no-repeat;
  background-color: #ed1c24;
  background-size: 12px;
  border-radius: 50%;
}

.apartment-search__top{
  padding: 40px 0 30px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;

  @media (max-width: $resolution-1000){
    padding: 40px 0 20px 0;
  }
}

.apartment-search__result-count{
  font-family: 'Travels';
  font-weight: 500;
  font-size: 16px;

  @media (max-width: $resolution-1400){
    font-size: 15px;
  }

  @media (max-width: $resolution-1200){
    font-size: 14px;
  }

  @media (max-width: $resolution-1000){
    display: none;
  }
}

.apartment-search__item-similar{
  padding-top: 20px;
  font-family: 'Travels';
  font-weight: 500;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 11;

  @media (max-width: $resolution-1000){
    padding-top: 0;
    font-size: 13px;
  }

  div{
    cursor: pointer;
    position: relative;
    margin-right: 13px;

    &:after{
      content: "";
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: -3px;
      height: 1px;
      border-bottom: 1px dashed #a6a6a6;

      @media (max-width: $resolution-1000){
        bottom: 3px;
      }
    }

    svg{
      position: absolute;
      right: -13px;
      top: 50%;
      margin-top: -3px;
      width: 10px;
      height: 6px;

      @media (max-width: $resolution-1000){
        margin-top: -5px;
      }
    }
  }
}

.apartment-search__bottom{
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  min-height: 69px;
  box-sizing: border-box;

  @media (max-width: $resolution-1000) {
    padding: 22px 0 40px 0;
  }
}

.apartment-search__button-show-map{
  background: #666666;
  padding: 13px 19px;
  color: #fff;
  font-family: 'Travels';
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: $animation-speed background;

  @media (min-width: $resolution-1000) {
    &:hover{
      background: $color-red;
    }
  }

  svg{
    width: 10px;
    height: 15px;
    margin-left: 14px;
  }
}

.apartment-search__sort{
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: $resolution-1000) {
    justify-content: flex-start;
    width: 100%;

    .apartment-search__sort-icons{
      display: none;
    }

    .apartment-search__sort-select{
      padding-left: 0;
    }

    .apartment-search__sort-select-icon{
      display: none;
    }
  }
}

.apartment-search__sort-tales{
  width: 23px;
  height: 23px;
  cursor: pointer;

  svg{
    width: 100%;
    height: 100%;
  }

  path{
    transition: $animation-speed fill;
  }

  &.active path{
    fill: $color-red;
  }
}

.apartment-search__sort-lines{
  width: 24px;
  height: 23px;
  cursor: pointer;
  margin-left: 10px;

  svg{
    width: 100%;
    height: 100%;
  }

  path{
    transition: $animation-speed fill;
  }

  &.active path{
    fill: $color-red;
  }
}

.apartment-search__sort-select{
  display: flex;
  align-items: center;
  font-family: 'Travels';
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-left: 50px;

  .ui-select .MuiInputBase-root{
    height: auto;
    position: relative;
    min-width: 0;

    svg{
      display: none !important;
    }
  }

  .ui-select .MuiSelect-select{
    font-family: 'Travels';
    font-weight: 500;
    font-size: 16px;
    padding: 0 0 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    white-space: nowrap;
  }
}

.apartment-search__sort-select-wrap{
  position: relative;
  display: flex;
  margin-left: 10px;
  align-items: center;

  &:after{
    content: '';
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    transform: scale(1);
    bottom: -3px;
    height: 1px;
    border-bottom: 1px dashed #a6a6a6;
  }

  .apartment-search__sort-select-icon{
    width: 8px;
    height: 12px;
    position: relative;
    margin-left: 5px;

    &.active{
      transform: rotate(180deg);
    }

    svg{
      width: 100%;
      height: 100%;
    }
  }
}

.apartment-search__footer{
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $resolution-1000) {
    display: block;
  }

  & > div{
    width: 33.3%;
    display: flex;

    @media (max-width: $resolution-1000) {
      width: auto;
      display: block;
    }

    &:nth-child(2){
      justify-content: center;
    }
  }
}

.apartment-search__btn{
  font-weight: 600;
  font-size: 16px;
  padding-left: 60px;
  padding-right: 60px;
}

.apartment-search__btn-filter{
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  margin-right: 20px;
  @media (max-width: 1530px) {
    padding: 0 35px;
  }
}

.apartment-search__table-wrap{
  @media (max-width: $resolution-1000) {
    overflow: auto;
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 40px;
  }
}

.apartment-search__table{
  width: 100%;
  border-collapse: collapse;
  margin-top: 38px;
  margin-bottom: 50px;

  @media (max-width: $resolution-1000) {
    width: 800px;
  }

  .apartment-search-similar__row td{
    border-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .apartment-search-similar__title{
    margin: 10px 0;
  }

  td{
    vertical-align: top;
  }

  td.vbottom{
    vertical-align: bottom;
  }

  td:last-child{
    @media (max-width: $resolution-1000) {
      padding-right: 40px !important;
    }
  }

  thead{
    td{
      font-family: 'Travels';
      font-weight: 400;
      font-size: 12px;
      color: #808080;
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 7px;
    }
  }

  tbody{
    tr{
      transition: $animation-speed opacity;
      cursor: pointer;
    }

    tr[data-similar="opacity"]{
      opacity: 0.5;
    }

    tr[data-similar="active"]{
      opacity: 1;

      td{
        border-bottom: 0 !important;
      }
    }

    td{
      padding-left: 5px;
      padding-right: 5px;
      font-family: "Travels";
      font-size: 14px;
      font-weight: 500;
    }

    td:first-child{
      padding-left: 0;
      padding-bottom: 20px;
      border-bottom: 1px solid #e6e6e6;
    }

    td:last-child{
      padding-right: 0;
    }

    tr.first td{
      padding-top: 20px !important;
    }

    tr.last td{
      padding-bottom: 20px;
      border-bottom: 1px solid #e6e6e6;
    }
  }

  .apartment-search__table-image{
    width: 125px;
    height: 100px;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #f2f2f2;

    &.cover{
      background-size: cover;
    }
    @media (min-width: 1000px) {
        transition: 600ms;
        position: relative;
      &:hover {
        transform: scale(3);
        z-index: 9;
      }
    }
  }

  .apartment-search__item-price{
    justify-content: flex-end;
    white-space: nowrap;
  }

  .apartment-search__item-similar{
    padding-top: 0;
  }
}

.apartment-search__sort-icons{
  display: flex;
  align-items: center;
}

.apartment-search__list-wrap{
  display: flex;
  min-height: 100vh;

  @media (max-width: $resolution-1000){
    display: block;
    padding-bottom: 74px;
    min-height: 0;
  }
}

.apartment-search__frame{
  padding-left: 3.5vw;
  padding-right: 3.5vw;
  box-sizing: border-box;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  & > .MuiPagination-root{
    margin-bottom: 40px;

    @media (max-width: $resolution-1000){
      margin-bottom: 0;
    }
  }

  @media (max-width: $resolution-1200){
    padding-left: 25px;
    padding-right: 25px;
  }

  @media (max-width: $resolution-1000){
    padding-left: 40px;
    padding-right: 40px;
  }
}

.apartment-search__map{
  width: 50%;
  position: relative;

  @media (max-width: $resolution-1000){
    width: 100%;
  }
}

.apartment-search__map-container{
  height: 100vh;
  min-height: 600px;

  @media (max-width: $resolution-1000){
    height: 600px;
  }

  [class*=balloon__content]{
    margin-right: 0 !important;
    padding: 0;
    border: none !important;
    box-shadow: none !important;
  }

  [class*=balloon]{
    border: none !important;
    box-shadow: none !important;
  }

  [class*=balloon__tail]{
    display: none;
  }

  [class*=hint-overlay]{
    & * {
      background: none !important;
      color: #fff;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .apartment-search__map-hint{
    background: rgba(0, 0, 0, 0.6) !important;
    color: #fff;
    font-family: Georgia, Times, "Times New Roman", serif;
    font-size: 14px;
    padding: 10px 15px;
  }

  [class*=default-cluster]{
    color: #fff;
    font-family: "Travels";
    font-weight: 500;
    font-size: 15px;

    & > *{
      position: relative;
      top: 1px;
    }
  }
}

.apartment-search__showmap{
  .apartment-search__frame{
    width: 50%;
    padding-left: 2.38%;
    padding-right: 2.38%;
  }

  .apartment-search__sort{
    justify-content: space-between;
    width: 100%;
  }

  .apartment-search__table{
    margin-top: 0;
  }

  .apartment-search__item{
    margin-right: 0;
    width: 47.3%;
  }

  .apartment-search__list{
    justify-content: space-between;
  }

  .apartment-search__list:after{
    display: none;
  }
}

.apartment-search__button-close-map{
  position: absolute;
  cursor: pointer;
  z-index: 3;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 65px;
  padding: 0 20px;
  background: rgba(0,0,0, 0.6);
  font-family: 'Travels';
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  transition: $animation-speed background;

  @media (min-width: $resolution-1000) {
    &:hover{
      background: $color-red;
    }
  }

  @media (max-width: $resolution-1000) {
    left: 40px;
    right: 40px;
    font-size: 16px;
    justify-content: center;
  }

  svg{
    width: 10px;
    height: 15px;
    margin-left: 15px;
    transform: rotate(-180deg);

    @media (max-width: $resolution-1000) {
      transform: rotate(-90deg);
    }
  }
}

.apartment-search__map-outer{
  position: relative;

  &.fixed{
    position: fixed;
    width: 50%;
    left: 0;
    top: 0;
    z-index: 3;

    @media (max-width: $resolution-1000) {
      position: relative;
      width: auto;
    }
  }

  &:after{
    content: '';
    display: block;
    height: 5px;
    width: 369px;
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    background: $color-red;

    @media (max-width: $resolution-1000) {
      display: none;
    }
  }

  .apartment-search__baloon{
    width: 250px;
    position: absolute;
    z-index: 10;
    background: #fff;
    /*transform: translateY(-100%);
    margin-top: -15px;*/

    @media (max-width: $resolution-1000) {
      position: fixed;
      transform: translateY(0);
      margin-top: 0;
      left: 0 !important;
      top: 0 !important;
      right: 0 !important;
      bottom: 0 !important;
      width: auto !important;
      padding: 40px;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .apartment-search__baloon-img{
    background-size: cover;
    background-position: 50% 50%;
    height: 160px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    background-repeat: no-repeat;

    &.plan{
      background-size: contain;
    }

    svg {
      width: 80px;
      height: auto;
    }

    path{
      fill: #e4e4e4;
    }

    @media (max-width: $resolution-1000) {
      width: 100%;
      height: 189px;
    }
  }

  .apartment-search__baloon-in{
    padding: 19px;
    border: 1px solid #e9e5e4;

    @media (max-width: $resolution-1000) {
      background: #fff;
      padding-bottom: 40px;
      width: 100%;
      box-sizing: border-box;
    }
  }

  .btn{
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    box-sizing: border-box;
    margin: 25px 0 25px 0;

    @media (max-width: $resolution-1000) {
      margin: 35px 0 25px 0;
    }
  }

  .apartment-search__map-outer{
    position: relative;
  }
}

.apartment-search__baloon-route{
  text-decoration: underline;
  font-family: 'Travels';
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  @media (min-width: $resolution-1000) {
    &:hover{
      text-decoration: none;
    }
  }
}

.apartment-search__view{
  margin-top: 170px;

  @media (max-width: $resolution-1200){
    margin-top: 140px;
  }

  @media (max-width: $resolution-1200){
    margin-top: 100px;
  }

  @media (max-width: $resolution-1000){
    margin: 0;
  }

  .slick-track{
    display: flex;
  }
  .title span{
    color: #808080;
    font-family: Georgia;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    display: block;
    padding-top: 10px;
    @media (max-width: 1000px) {
      font-size: 16px;
    }
  }
  .title{
    @media (max-width: 1000px) {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}
[data-id="similar-appartments"] .apartment-search__view {
  margin-top: 0;
}

.apartment-search__view-title{
  font-family: 'Travels';
  font-size: 30px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 50px;
  padding-top: 50px;
  border-top: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
    &.-no_border {
        border: none;
        padding-top: 0;
    }

  @media (max-width: $resolution-1400){
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 40px;
    padding-top: 40px;
  }

  @media (max-width: $resolution-1200){
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 30px;
    padding-top: 30px;
  }

  @media (max-width: $resolution-1000){
    margin: 0;
    padding: 74px 0 35px 0;
    font-size: 26px;
    line-height: 1;
    margin-left: 40px;
    margin-right: 40px;
  }
}

.apartment-search__view-title-line{
  width: 210px;
  height: 5px;
  background: $color-red;
  margin-right: 17px;

  @media (max-width: $resolution-1000){
    display: none;
  }
}

.apartment-search__view-slide{
  .apartment-search__item{
    width: auto;
    margin-right: 40px;

    @media (max-width: $resolution-1000){
      margin-right: 0;
    }
  }

  @media (max-width: $resolution-1000){
    padding: 0 40px;
    box-sizing: border-box;
  }
}

.apartment-search__slider{
  margin-bottom: 90px;
  margin-right: -40px;

  @media (max-width: $resolution-1200){

  }

  @media (max-width: $resolution-1000){
    margin-right: 0;
  }
}

.apartment-search__map-alert{
  position: absolute;
  z-index: 5;
  right: 20px;
  top: 20px;
  border: 2px solid #000;
  background: #fff;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  font-family: "Travels";
  font-weight: 500;
  font-size: 14px;

  @media (max-width: $resolution-1300){
    width: 300px;
    box-sizing: border-box;
  }

  @media (max-width: $resolution-1000){
    display: none;
  }

  svg{
    height: 26px;
    min-height: 26px;
    width: 18px;
    min-width: 18px;
    margin-right: 13px;
  }
}

.filter-mobile-tabs{
  font-family: "Travels";
  font-weight: 500;
  font-size: 34px;
  line-height: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  @media (max-width: $resolution-400){
    font-size: 26px;
  }

  .MuiInputBase-root{
    display: flex;
  }

  .MuiInputBase-root:after,
  .MuiInputBase-root:before{
    display: none;
  }

  .MuiSelect-select{
    background: none !important;
    position: relative;
    z-index: 2;
    font-family: "Travels";
    font-weight: 500;
    font-size: 34px;
    line-height: 1;
    padding: 0 !important;
    color: $color-red;
    display: flex !important;

    @media (max-width: $resolution-400){
      font-size: 26px;
    }
  }

  svg{
    z-index: 1;
    width: 22px;
    height: 34px;
  }
}

.apartment-search-mobile-showmap{
  background: #666666;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Travels";
  font-weight: 500;
  color: #fff;
  font-size: 16px;
  line-height: 1;

  path{
    fill: #fff;
  }

  svg{
    width: 16px;
    height: 9px;
    margin-left: 12px;
    display: block;
  }
}

.apartment-search-mobile-showmap-wrap{
  padding-top: 24px;
}

.apartment-search__item-row{
  @media (max-width: $resolution-1000){
    display: flex;
    justify-content: space-between;
  }
}

.apartment-search__baloon-close{
  display: none;
  position: absolute;
  top: -38px;
  right: -8px;
  padding: 8px;
  width: 30px;
  height: 30px;
  box-sizing: border-box;

  @media (max-width: $resolution-1000){
    display: block;
  }

  svg{
    width: 100% !important;
    height: 100% !important;
  }

  path{
    fill: #fff;
  }
}

.apartment-search__baloon-bg{
  @media (max-width: $resolution-1000){
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.apartment-search-similar{
  width: 100%;
}

.apartment-search-similar__title{
  position: relative;
  display: flex;
  padding-left: 80px;
  margin-bottom: 30px;

  div{
    position: relative;
    z-index: 2;
    padding: 0 20px;
    background: #fff;
    font-family: "Travels";
    font-size: 20px;
    font-weight: 500;
  }

  &:after{
    content: '';
    display: block;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: -2px;
    height: 4px;
    position: absolute;
    background: $color-red;
    z-index: 1;
  }
}

.apartment-search__view-wrapper{
  position: relative;
  z-index: 3;
  background: #fff;
  padding-bottom: 160px;

  @media (max-width: $resolution-1000){
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}

.apartment-search__view-wrapper-v{
  position: relative;
  z-index: 3;
  background: #fff;
  padding-bottom: 80px;

  @media (max-width: $resolution-1000){
    padding-bottom: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}

.apartment-search__table-image-empty{
  width: 125px;
  height: 100px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    width: 40px;
    height: auto;
    margin: 0 auto;
    display: block;

    path {
      fill: #e4e4e4;
    }
  }
}

.apartment-search__not-found{
  font-family: "Travels";
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  padding-top: 30px;

  @media (max-width: $resolution-1000){
    br{
      display: none;
    }
  }

  span{
    cursor: pointer;
    text-decoration: underline;

    @media (min-width: $resolution-1000){
      &:hover{
        text-decoration: none;
      }
    }
  }
}

@keyframes apartment-search-loader-animation {
  0% {transform: scaleX(0);}
  100% {transform: scaleX(1);}
}
.apartment-search-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 0.7);
  overflow: hidden;

  &:after{
    content: '';
    display: block;
    position: relative;
    height: 5px;
    background-color: $color-red;
    animation-name: apartment-search-loader-animation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

.apartment-search-ballon-body{
  .btn{
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    box-sizing: border-box;
    margin: 25px 0 25px 0;

    @media (max-width: $resolution-1000) {
      margin: 35px 0 25px 0;
    }
  }
}

.apartment-search-ballon-body{

}

.apartment-search-ballon-body-in{
  padding: 19px;
  width: 250px;
  box-sizing: border-box;
}

.apartment-search-ballon-body-metro{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  font-family: Travels;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 7px;
}

.apartment-search-ballon-body-name{
  font-family: Travels;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 7px;
}

.apartment-search-ballon-body-addr{
  color: grey;
  font-family: Travels;
  font-size: 13px;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 13px;
}

[class*="-cluster-content"]{
  padding: 0 !important;
  width: auto !important;
}

[class*="-cluster-tabs__section_type_content"]{
  padding: 0 !important;
}

[class*="-balloon-content__header"]{
  display: none !important;
}

[class*="-balloon__content"] > *{
  overflow: visible !important;
  height: auto !important;
}

.apartment-search-ballon-body-img{
  background-size: cover;
  background-position: 50% 50%;
  height: 160px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  background-repeat: no-repeat;

  &.plan{
    background-size: contain;
  }

  svg {
    width: 80px;
    height: auto;
  }

  path{
    fill: #e4e4e4;
  }

  @media (max-width: $resolution-1000) {
    width: 100%;
    height: 189px;
  }
}
