.search__top{
  background: #f2f2f2;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 50px;

  @media (max-width: $resolution-1400) {
    padding-top: 45px;
    padding-bottom: 45px;
    margin-bottom: 45px;
  }

  @media (max-width: $resolution-1300) {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  @media (max-width: $resolution-1200) {
    padding-top: 35px;
    padding-bottom: 35px;
    margin-bottom: 35px;
  }
}

.search__top-title{
  font-family: "Travels";
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 20px;

  @media (max-width: $resolution-1400) {
    font-size: 28px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 26px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 24px;
  }
}

.search__top-input{
  height: 50px;
  background: #fff;
  position: relative;

  div{
    position: absolute;
    right: 20px;
    top: 17px;
    cursor: pointer;
  }

  input{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    font-size: 18px;

    &::placeholder{
      color: #a6a6a6;
    }
  }
}

.search-result-top{
  font-family: "Travels";
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin-bottom: 45px;

  @media (max-width: $resolution-1000){
    display: block;
    margin-bottom: 20px;
  }
}

.search-result-top__title{
  display: flex;
  align-items: center;
  font-size: 30px;

  @media (max-width: $resolution-1000){
    font-size: 24px;
  }

  span{
    background: $color-red;
    width: 210px;
    height: 5px;
    display: block;
    margin-right: 15px;

    @media (max-width: $resolution-1000) {
      display: none;
    }
  }
}

.search-list{
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 50px;

  @media (max-width: $resolution-1000) {
    .apartment-search__item{
      width: 100%;
    }
  }
}

.search-items{
  padding: 20px 16.9% 0 16.9%;

  @media (max-width: $resolution-1400) {
    padding: 15px 14.9% 0 14.9%;
  }

  @media (max-width: $resolution-1300) {
    padding: 10px 12.9% 0 12.9%;
  }

  @media (max-width: $resolution-1200) {
    padding: 0 10.9% 0 10.9%;
  }

  @media (max-width: $resolution-1000) {
    padding: 20px 0 0 0;
  }
}

.search-item{
  margin-bottom: 85px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 75px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 65px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 55px;
  }
}

.search-item__title{
  font-family: "Travels";
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 35px;

  @media (max-width: $resolution-1400) {
    font-size: 20px;
    margin-bottom: 30px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 19px;
    margin-bottom: 25px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.search-item__text{
  margin-bottom: 45px;
  font-size: 20px;
  line-height: 1.5;

  @media (max-width: $resolution-1400) {
    font-size: 18px;
    margin-bottom: 40px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 17px;
    margin-bottom: 35px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 16px;
    margin-bottom: 30px;
  }

  @media (max-width: $resolution-1000) {
    margin-bottom: 15px;
  }
}

.search-item__more{
  font-family: "Travels";
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  border-bottom: 1px solid #000;
  transition: $animation-speed border-color;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: $resolution-1400) {
    font-size: 13px;
  }

  @media (min-width: $resolution-1000) {
    &:hover{
      border-color: transparent;
    }
  }
}

.search-not-found{
  text-align: center;
  font-family: "Travels";
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 100px;

  @media (max-width: $resolution-1000) {
    padding-bottom: 35px;
    font-size: 15px;
  }
}