.ui-select{
    .MuiInputBase-root{
        padding: 0;
        background: #fff;
        height: 50px;
        max-width: 280px;
        box-sizing: border-box;


        @media (max-width: $resolution-1000){
            height: 64px;
        }

        &:after,
        &:before{
            display: none;
        }
    }

    select{
        background: none;
        position: relative;
        z-index: 2;
        height: 100%;
        font-family: Georgia,Times,Times New Roman,serif;
        font-size: 18px;

        &.MuiSelect-select{
            padding: 0 60px 0 17px;
        }

        &:focus{
            background: none;
        }
    }

    svg{
        width: 16px;
        height: 9px;
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -3px;
        z-index: 1;
    }

    .MuiSelect-selectMenu{
        position: relative;
        z-index: 3;
        height: 100%;
        display: flex;
        align-items: center;
        font-family: Georgia,Times,Times New Roman,serif;
        font-size: 18px;
        box-sizing: border-box;
        padding-right: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.MuiSelect-select{
            padding: 0 60px 0 17px;
        }

        &:focus{
            background: none;
        }
    }
}

.ui-checkboxes{
    display: flex;

    @media (max-width: $resolution-1000){
        flex-wrap: wrap;
    }

    div{
        background: #fff;
        height: 50px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2px;
        cursor: pointer;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        color: #000;
        transition: $animation-speed background, $animation-speed color;
        overflow: hidden;
        text-overflow: ellipsis;
        box-sizing: border-box;

        @media (max-width: $resolution-1000){
            height: 64px;
            margin-bottom: 2px;
            min-width: 47px;
            padding: 0 10px;
        }

        @media (max-width: $resolution-320){
            font-size: 15px;
            line-height: 15px;
        }
    }

    .active{
        background: $color-red;
        color: #fff;
    }
}

.MuiList-root.MuiList-padding{
    padding: 0;
}

.MuiMenuItem-root{
    font-family: Georgia,Times,Times New Roman,serif !important;
}

.ui-multiple-select{
    position: relative;
}

.MuiMenu-paper{
    box-shadow: none !important;
    border-radius: 0 !important;

    .MuiMenu-list{
        border: 1px solid #c3c3c3;
    }

    .MuiMenuItem-root.Mui-selected{
        background: none;
    }

    .MuiMenuItem-root{
        padding: 10px 20px;
    }
}

.ui-multiple-select__in{
    background: #fff;
    height: 50px;
    padding: 0 60px 0 17px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.ui-multiple-select__text{
    font-size: 18px;
    line-height: 18px;
    font-family: Georgia,Times,Times New Roman,serif;
}

.ui-multiple-select__icon{
    position: absolute;
    width: 16px;
    height: 9px;
    right: 19px;
    top: 50%;
    margin-top: -3px;
    cursor: pointer;

    svg{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.ui-multiple-select__box{
    position: absolute;
    background: #fff;
    border: 1px solid #c3c3c3;
    padding: 20px;
    left: 0;
    top: 100%;

    .ui-checkbox{
        margin-bottom: 14px;

        &:last-child{
            margin-bottom: 0;
        }
    }
}

.ui-checkbox{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.ui-checkbox__rect{
    width: 25px;
    height: 25px;
    min-width: 25px;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 9px;

    @media (max-width: $resolution-1200){
        margin-right: 5px;
    }

    @media (max-width: $resolution-1000){
        margin-right: 30px;
    }

    &.border{
        border: 1px solid #c3c3c3;

        @media (max-width: $resolution-1000){
            margin-right: 10px;
        }
    }

    svg{
        width: 17px;
        height: 12px;
        transition: $animation-speed opacity, $animation-speed transform;
        opacity: 0;
        transform: scale(0.5);
    }

    &.active svg{
        opacity: 1;
        transform: scale(1);
    }
}

.ui-checkbox__label{
    font-size: 18px;
    line-height: 18px;
    font-family: Georgia,Times,Times New Roman,serif;
    color: #808080;
    transition: $animation-speed color;

    @media (max-width: $resolution-1400){
        font-size: 16px;
        line-height: 16px;
    }

    @media (max-width: $resolution-1200){
        font-size: 14px;
        line-height: 14px;
    }

    @media (max-width: $resolution-1000){
        font-size: 16px;
        line-height: 16px;
    }

    &.checked{
        color: #000;
    }
}


.ui-chip{
    border: 1px solid #e6e6e6;
    padding: 0 10px;
    height: 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-family: 'Travels';
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin: 14px 10px 0 0;

    &:active{
        opacity: 0.5;
    }

    svg{
        position: relative;
        width: 9px;
        height: 9px;
        right: 0;
        top: 0;
        margin: 0;
        margin-left: 10px;
    }
}

.ui-select-chips{
    width: 100%;
    .MuiInputBase-root{
        height: auto;
    }

    .MuiSelect-root{
        min-height: 60px;
        flex-wrap: wrap;
        padding-bottom: 14px !important;
        box-sizing: border-box;
    }
}

.ui-select-chips-placeholder{
    position: relative;
    top: 6px;
}

.MuiTouchRipple-root{
    display: none !important;
}

.ui-select-values{
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui-button{
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 60px;
    border: 2px solid #000;
}

.MuiListItem-button.Mui-selected{
    background: #f2f2f2 !important;
}

.ui-range-slider-labels{
    display: flex;
    justify-content: space-between;
    color: #9a9a9a;
    font-family: 'Travels';
    font-weight: 400;
    font-size: 11px;
    line-height: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
}

.MuiPagination-ul{
    justify-content: center;
    padding-top: 30px;

    button{
        font-family: "Travels";
        font-weight: 600;

        @media (max-width: $resolution-1000){
            height: 24px;
            min-width: 24px;
            font-size: 13px;
        }
    }

    .MuiPaginationItem-page.Mui-selected{
        background: $color-red;
        color: #fff;
    }

    .MuiPaginationItem-page.Mui-selected:hover,
    .MuiPaginationItem-page.Mui-selected.Mui-focusVisible{
        background: $color-red;
    }
}

.ui-select-image{
    max-width: 60px;
    max-height: 60px;
}

.ui-select-image-menu{
    min-width: 0 !important;
    max-width: 320px;
    ul{
        display: flex;
        flex-wrap: wrap;
    }

    li{
        padding: 10px !important;
    }
}

.ui-select-image-wrapper{
    display: flex;
    margin-top: 35px;
    justify-content: center;

    @media (min-width: $resolution-1000){
        &:hover .MuiSelect-root:after{
            opacity: 0;
        }
    }

    .MuiInputBase-root{
        background: none;
        height: auto;
        width: auto !important;
    }

    .MuiSelect-root{
        font-family: "Travels";
        font-weight: 500;
        font-size: 16px;
        position: relative;
        overflow: visible;
        width: auto;

        &:after{
            content: "";
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            bottom: -5px;
            height: 1px;
            border-bottom: 1px dashed #a6a6a6;
            transition: $animation-speed opacity;
        }
    }

    .MuiSelect-selectMenu{
        padding: 0 !important;
    }
}

.ui-image-select{

    @media (max-width: $resolution-1000) {
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }

    .filter-clear.filter-clear_top{
        top: 30px !important;
        margin-left: 0 !important;
        position: relative;

        @media (max-width: $resolution-1000) {
            display: inline-block;
            margin-right: 0;
            top: 0 !important;
            font-size: 17px;
        }
    }
}

.MuiMenu-paper.MuiPopover-paper{
    max-height: 225px;
    border: 1px solid #c3c3c3;

    .MuiMenu-list{
        border: none;
    }
}

.MuiListItem-root{
    &[data-arrow="down"]:after{
        content: ' ↓';
        display: block;
        margin-left: 8px;
    }

    &[data-arrow="up"]:after{
        content: ' ↑';
        display: block;
        margin-left: 8px;
    }
}

.pag_a {
  pointer-events: none;
  text-decoration: none;
  * {
    pointer-events: auto;
  }
  .MuiPaginationItem-root {
    font-family: Travels;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    height: 32px;
    margin: 0 3px;
    padding: 0 6px;
    font-size: 0.875rem;
    min-width: 32px;
    box-sizing: border-box;
    text-align: center;
    line-height: 1.43;
    border-radius: 16px;
    letter-spacing: 0.01071em;

    @media (max-width: 1000px) {
      height: 24px;
      min-width: 24px;
      font-size: 13px
    }
  }
  .MuiPaginationItem-icon {
    margin: 0 -8px;
    font-size: 1.25rem;
  }
}
