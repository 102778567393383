.franchise {

    .recommendsBlock__text1 {
        margin-top: 90px;
    }
    .detail__slider-item-bg {
        height: 650px;
        margin-right: 9.2vw;
    }
    .recommendsBlock__text2 {
        padding-top: 0;
        height: 100%;

    }
    .recommendsBlock2 {
        margin-bottom: 70px;
    }
    .page-columns-franchise .page-columns__left {
        display: flex;
        flex-direction: column;
    }
    .recommendsBlock2__text {
        padding-left: 6%;
        padding-right: 17.6%;
    }
    .title {
        font-size: 48px;
        margin-bottom: 30px;
    }
    &.recommends {
        margin-bottom: 0;
    }
    .news-feed__load-more {
        margin-top: 30px;
    }
    .news-feed {
        padding-top: 70px;
    }
    .bought__img3 span {
        padding-top: 100%;
    }

    /*
    .events-tale__column:first-child {
        display: none;
    }
    .events-tale {
        .events-tale__column>div {
            display: flex;
            margin-left: -20px;
            width: calc(100% + 40px);
        }
        .news-feed__item {
            width: 33.33%;
            margin: 20px;
        }
    }*/
}

@media (max-width: 1000px) {
    .franchise {
        .page-columns.revers_mob {
            flex-direction: column-reverse;
        }
    }

    .franchise .recommendsBlock2 {
        margin-bottom: 50px;
        flex-direction: column;
    }
    .franchise .recommendsBlock2__text {
        padding-left: 0;
        padding-right: 0;
        width: auto;
    }
    .recommendsBlock2__left {
        text-align: center;
        width: auto;
        max-width: none;
        margin-left: 0;
        margin-top: 20px;
    }
    .franchise .detail__slider-item-bg {
        height: 375px;
        margin-right: 0;
    }
}
