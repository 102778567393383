input{
  padding: 0; margin: 0; border: none; background: transparent;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  width: 100%;
  box-sizing: border-box;
  color: #000;

  &::placeholder{
    color: #a6a6a6;
  }
}
::-webkit-input-placeholder {color: inherit; }
:-ms-input-placeholder {color: inherit; }
::placeholder {color: inherit; }

.input-field{
  color: #4c4c4c;

  &[error="true"] input {
    // color: #ff0000
  }

}

.filter-field{
  margin-bottom: 20px;
  position: relative;
  &__title{
    font-family: 'Travels';
    font-size: 14px;
    margin-bottom: 4px;
  }
  &__body{
    background: #fff;
    padding: 20px 17px;
    line-height: 1;
    color: #a6a6a6;
    font-size: 18px;

    @media (max-width: $resolution-1400) {
      padding-top: 15px; padding-bottom: 15px; font-size: 16px;
    }
    @media (max-width: 1000px) {
      padding-top: 20px; padding-bottom: 20px;

    }
  }
  &__min{
    position: absolute;
    left: 0; bottom: -7px;
    font-size: 14px; color: #a6a6a6;
    font-family: 'Styrene';
    @media (max-width: 1000px) {
      bottom: -20px;
    }
  }
  &__max{
    position: absolute;
    right: 0; bottom: -7px;
    font-size: 14px; color: #a6a6a6;
    font-family: 'Styrene';
    @media (max-width: 1000px) {
      bottom: -20px;
    }
  }

  &--single{
    .filter-field__title{
      position: absolute;
      top: 10px; left: 17px; width: 100%;
    }
    .filter-field__body{
      padding: 28px 17px 12px;
      .filter-input{ color: #000;}
    }

  }

  &[error="true"] .ui-select .MuiInputBase-root, &[data-error="true"] .ui-select .MuiInputBase-root{
    box-shadow: inset 0 0 0 1px #ed1c24;
  }

  .ui-select .MuiInputBase-root{
    max-width: 100%;
    width: 100%;
    min-width: 160px;
    box-sizing: border-box;
    transition: 500ms box-shadow;

    @media (max-width: $resolution-1000){
      min-width: 0;
    }
  }
}


.filter-input{
  display: inline-flex;
  align-items: center;
  color: #000;
  span{height: 2px; width: 13px; background: #a8a8a8; margin: 0 8px 0 0;}
  input{width: auto; min-width: 0;}

  &::placeholder{
    color: #a6a6a6;
  }
}

.filter-button {
  text-align: right;
  .btn {
    display: inline-block;
    padding: 26px 62px 24px;
    font-size: 16px;
  }
}
.rc-slider {
  padding-top: 0 !important;
  margin-top: -1px !important;
  width: calc(100% - 10px);
  margin-left: 5px;
  &-rail{background-color: transparent !important; }
  &-track{height: 2px !important; background-color: #000 !important; }
  &-step{height: 2px !important; }
  &-handle{
    border:none !important;
    width: 11px !important;
    height: 11px !important;
    background-color: #000 !important;
    box-shadow: none !important;

    @media (max-width: 1000px) {
      width: 22px !important;
      height: 22px !important;
    }
  }
  &--single .rc-slider-track{left: -5px !important;}
  @media (max-width: 1000px) {
    margin-left: 11px !important;
    width: calc(100% - 22px) !important;
    &-handle{height: 22px; width: 22px; margin-top: -10px !important;}
    &--single .rc-slider-track{left: -8px !important;}
  }
}

.index__find-flat{
  .btn--gray{
    transition: 500ms;

    span{
      border: none;
      transition: none !important;
    }

    @media (min-width: $resolution-1000) {
      &:hover{
        background: #4c4c4c;
        border-color: #4c4c4c;
        color: #fff;
      }
    }
  }
}

.btn{
  padding: 22px 16px 20px;
  cursor: pointer;
  transition: 500ms;
  font-size: 14px;
  font-family: 'Travels';
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  position: relative;
  white-space: nowrap;
  line-height: 1;
  box-sizing: border-box;
  &--black{
    color: #fff;
    background: #000000;
  }
  &--white{
    color: #000;
    background: #fff;
  }
  &--red{
    color: #fff;
    background: #ed1c24;
  }
  &--gray{
    color: #000;
    background: #ece8e4;
  }
  &--dark-gray{
    color: #fff;
    background: #6a6a6a;
    @media(min-width: $resolution-1000) {
      &:hover{
        background-color: #000;
      }
    }
  }
  &--border{
    border: 2px solid;
    &:not(.no-border-link){
      &:after{
        content: '';
        position: absolute;
        bottom: -12px; left: 0; height: 5px; background: #ed1c24;
        width: 100%;
        width: 0;
        transition: 500ms;
      }
      @media (min-width: $resolution-1000) {
        &:hover{
          background: #4c4c4c;
          border-color: #4c4c4c;
          color: #fff;
          &:after{width: 100%;}
        }
      }
    }
  }
  @media (max-width: $resolution-1400) {
    font-size: 13px;
  }
  @media (max-width: 1000px) {
    font-size: 16px;
  }
}
a.btn{display: inline-block;}

.btn.disabled{
  opacity: 0.2;
  pointer-events: none;
}

.subscribe-form{
  padding-bottom: 10px;
  overflow: hidden;
  display: inline-flex;
  min-width: 450px;
  width: 450px;
  @media (max-width: 1000px) {
    width: 100%;
    min-width: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }
  &__btn{
    width: 268px;
    min-width: 268px;
    padding-top: 18px;
    padding-bottom: 17px;
    @media (max-width: 1000px) {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
  input{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 20px;
  }
  .input-field{
    position: relative;
    width: 100%;
    &:before{
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 300%;
      height: 1px;
      background: #b5b5b5
    }

    &[error="true"]:before, &[data-error="true"]:before {
        background: #ed1c24
    }
    @media (max-width: 1000px) {
      margin-bottom: 50px;
      &:before{
        bottom: 0;
      }
    }
  }

  @media (max-width: $resolution-1400) {
    &__btn{
      width: 240px;
      min-width: 240px;
    }
  }
}
.video-call-form-JK{
  background: #fff;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.video__call-feeld-wrap{
  display: flex;
}
.MuiInput-underline:after{
  width: 0;
}
.video-call-time .filter-field .ui-select .MuiInputBase-root{

  min-width: 220px;
}
.buuton_video_call{
  width: 70%;
}
.popup__title span{
  color: #000;
}
.video-call-time{
  margin-left: 15px;

}

.MuiInput-underline:before{
  width: 0;
}
.filter-field .ui-select .MuiInputBase-root
.video-call-wrap-colum{
  width: 30%;
}
.select-field__options{
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  right: 0;
  height: 300%;

  & > .select-field__option{
    transition: 300ms transform;
  }

  &[index="1"] > .select-field__option{
    transform: translateY(-100%);
  }

  &[index="2"] > .select-field__option{
    transform: translateY(-200%);
  }
}

.select-field {
  position: relative;
  &__desctope {
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;
    transition: 600ms;
    position: relative;
  }
  &__option{
    margin-bottom: 5px;
    color: #ccc;
    padding-right: 30px;
    span{
      line-height: 1;
      display: inline-block;
      cursor: pointer;
    }
    &[data-selected="true"]  {
      order: -1;
      color: #ed1c24;
      span{border-bottom-color: transparent;}
    }
  }
  &__icons{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 3;

    @media (max-width: $resolution-1000){
      top: 50% !important;
      transform: translateY(-50%) !important;
    }

    span{
      display: block;
      width: 22px;
      height: 14px;

      @media screen and (max-width: 1600px), screen and (max-height: 950px) {
        width: 21px;
        height: 13px;
      }

      @media screen and (max-width: 1500px), screen and (max-height: 850px) {
        width: 20px;
        height: 12px;
      }

      @media screen and (max-width: 1400px), screen and (max-height: 750px) {
        width: 19px;
        height: 11px;
      }

      @media screen and (max-width: 1300px), screen and (max-height: 650px) {
        width: 18px;
        height: 10px;
      }

      @media screen and (max-width: 1200px), screen and (max-height: 550px) {
        width: 17px;
        height: 9px;
      }

      svg{
        width: 100%;
        height: 100%;
        display: block;
        margin: 0 auto;
      }
    }
    span + span {margin-top: 10px;}
  }
  @media (max-width: 1000px) {

    position: relative;
    height: 50px;
    background: #fff;
    color: rgba(0, 0, 0, 0.87);

    &:before{
      position: absolute;
      top: 0; right: 0; width: 30px; height: 100%;
      pointer-events: none;
      background: #fff;
      z-index: 1;
    }

    select{
      padding: 0;
      background: none;
      border:none;
      box-shadow: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #000;
      font-weight: 600;
      font-size: 15px;
      margin-left: 0;
      font-family: inherit;
      padding: 0 30px 0 17px;
      font-family: 'Travels';
    }

    &--large{
      background: #f3f3f3;
      width: 293px;

      &:before{
        background: #f3f3f3;
        content: '';
      }
      select{
        font-size: 40px;
        color: #ed1c24;
        font-weight: 500;
        padding-left: 0;
      }
    }

    &__icons{
      right: 20px;
    }

  }
}

.form-gray{
  background: #f5f5f5;
  .input-title{
    font-size: 14px;
    font-family: 'Styrene';
    margin-bottom: 4px;
  }
  .input-field {
    margin-bottom: 20px;
    input{
      color: #a6a6a6;
      padding: 20px 17px;
      background: #fff;
      transition: 600ms;
    }

    &[error="true"] input, &[data-error="true"] input{
      box-shadow: inset 0 0 0 1px #ed1c24;
    }
  }
}
.input-field {
  &[error="true"] input, &[data-error="true"] input{
    box-shadow: inset 0 0 0 1px #ed1c24;
  }
}

.call-request{
  padding: 6.7vw 11.9vw 5vw 8.9vw;
  box-sizing: border-box;
  &__title{
    font-size: 36px;
    font-family: 'Travels';
    margin-bottom: 15px;
  }
  &__subtitle{
    font-size: 20px;
    margin-bottom: 40px;
  }
  &__field{
    position: relative;
  }
  &__get-sms{
    position: absolute;
    top: 30px;
    right: 14px;
    padding: 10px 11px;
    border: 2px solid;
    font-size: 14px;
    font-family: 'Travels';
    font-weight: 600;
    cursor: pointer;
    &--sent{
      opacity: .2;
      cursor: default;
    }
  }
  &__btn{
    float: right;
    padding-left: 50px;
    padding-right: 50px;
  }
  &__links{
    clear: both;
    padding-top: 3.2vw;
    &__color-gray{color: #808080;}
    &__color-red{color: #ed1c24;}
    &>div{padding-top: 10px;}
  }
  @media(max-width: $resolution-1300) {
    padding-left: 7vw;
    padding-right: 10vw;
    &__title{
      font-size: 32px;
    }
    &__subtitle{
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
}

.sign-up-for-central-office{
  padding: 11vw 12vw 11vw 9vw;
  box-sizing: border-box;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    top: 280px;
    width: 5px;
    bottom: 160px;
    background: #ed1c24;
    left: -5px;
  }
  &__title{
    font-size: 36px;
    font-family: 'Travels';
    margin-bottom: 40px;
    span{
      display: block;
      color: #ed1c24;
    }
    @media (max-width: 1000px) {
      font-size: 24px !important;
    }
  }
  &__btn{
    float: right;
    padding-left: 50px;
    padding-right: 50px;
    @media (max-width: 1000px) {
      float: none;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media(max-width: $resolution-1300) {
    padding-left: 7vw;
    padding-right: 10vw;
    &__title{
      font-size: 32px;
    }
  }
  @media (max-width: 1000px) {
    padding: 75px $padding_mobile 80px;
  }
}

.consulting-request{
  padding: 12.35vw 12.2vw 11vw 8.8vw;
  box-sizing: border-box;
  position: relative;

  @media (max-width: $resolution-1400) {
    padding: 10.35vw 10.2vw 11vw 6.8vw;
  }

  @media (max-width: $resolution-1300) {
    padding: 8.35vw 8.2vw 11vw 4.8vw;

    .input-field{
      margin-bottom: 15px;
    }
  }

  @media (max-width: $resolution-1200) {
    padding: 7.35vw 7.2vw 11vw 3.8vw;
  }

  &:before{
    content: '';
    position: absolute;
    top: 280px;
    width: 5px;
    bottom: 160px;
    background: #ed1c24;
    left: -5px;
  }
  &__title{
    font-size: 30px;
    font-family: 'Travels';
    font-weight: 500;
    margin-bottom: 45px;

    @media (max-width: $resolution-1400) {
      font-size: 28px;
      margin-bottom: 35px;
    }

    @media (max-width: $resolution-1300) {
      font-size: 26px;
      margin-bottom: 25px;
    }

    @media (max-width: $resolution-1200) {
      font-size: 24px;
      margin-bottom: 20px;
    }

    span{
      display: block;
      color: #ed1c24;
    }
    @media (max-width: 1000px) {
      font-size: 24px !important;
    }
  }
  .input-title {
    margin-bottom: 7px;
  }
  .input-field input {
    padding: 21px 17px;
  }
  .checkbox-field {
    margin-bottom: 37px;
    display: block;

    @media (max-width: $resolution-1400) {
      margin-bottom: 20px;
    }
  }
  &__btn{
    float: right;
    padding: 25px 63px 25px;
    font-size: 16px;
    @media (max-width: 1000px) {
      float: none;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media(max-width: $resolution-1300) {
    padding-left: 7vw;
    padding-right: 10vw;
    &__title{
      font-size: 32px;
    }
  }
  @media (max-width: 1000px) {
    padding: 75px $padding_mobile 80px;
  }
}



.checkbox-field {
  position: relative;
  display: inline-block;
  font-family: 'Styrene';
  font-size: 14px;
  padding-left: 40px;
  cursor: pointer;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    font-size: 11px;
  }

  &[valid="true"] .checkbox-field__check:before,
  &[data-valid="true"] .checkbox-field__check:before{
    opacity: 1;
  }
  &[error="true"] .checkbox-field__check,
   &[data-error="true"] .checkbox-field__check{
    box-shadow:  0 0 0 1px #ed1c24;
  }

  &--border {
    padding-left: 34px;
    font-family: inherit;
    font-size: inherit;
    margin-bottom: 0;
    .checkbox-field__check{
      box-shadow: inset 0 0 0 1px #c3c3c3;
      &:before{
        font-size: 14px;
      }
    }
  }
}
.checkbox-field__check {
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -12px;
  width: 25px;
  height: 25px;
  background: #fff;
  display: flex;
  min-width: 25px;
  min-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox-field__check:before {
  color: #000;
  font-size: 11px;
  opacity: 0;
  transition: 500ms;
}




.voice-field{
  position: relative;
  &__mike{
    font-size: 22px;
    color: #000;
    width: 59px;
    height: 59px;
    min-width: 59px;
    background: #fff;
    border-radius: 50%;
    margin-left: 2px;
    cursor: pointer;
    position: absolute; bottom: 0; right: 0;
    z-index: 1;
    &:before{
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
    transition: 1000ms;
    }
    @media (min-width: $resolution-1000) {
      &:hover:before{transform: translate(-50%, -50%) scale(1.2);}

    }
    @media (max-width: 1000px) {
      height: 60px !important;
    }
  }
  @media(max-width: $resolution-1370) {
    &__mike{height: 45px; width: 45px; min-width: 45px;}

  }
}

.index__find-flat .select-field__desctope{
  transition: none;
}
[data-animate="true"]{
  .select-field--large  {
    overflow: hidden;
    height: 73px;
    transition: none;
    .select-field__desctope{
      animation: o1 linear 2s;
      height: auto;
    }
    .select-field__option{
      color: #ed1c24;
    }
    .select-field__option span{border-bottom-color: transparent;}
  }
}

@keyframes o1 {
  0%, 45% { transform: translateY(0); }
  50%, 55% {transform: translateY(-20%); }
  60%, 65% {transform: translateY(-40%); }
  70%, 75% {transform: translateY(-60%); }
  80%, 100% {transform: translateY(-100%); }
}

.form__textarea{
  background: #fff;
  padding: 17px;
  outline: none;
  border: none;
  margin: 0;
  height: 162px;
  resize: none;
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  margin-bottom: 20px;

  @media (max-width: $resolution-1400) {
    height: 139px;
  }
}

.form__file{
  display: flex;
  width: 100%;
  overflow: hidden;

  .filter-field__body{
    flex: 1
  }
}

.form__file-button{
  background: #808080;
  padding: 0 48px;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Travels";
  font-weight: 500;
  font-size: 17px;
  margin-left: 9px;
  cursor: pointer;
  position: relative;
  transition: 300ms background;

  @media (max-width: $resolution-1400) {
    padding: 0 45px;
    font-size: 16px;
  }

  @media (max-width: $resolution-1300) {
    padding: 0 40px;
    font-size: 15px;
  }

  @media (max-width: $resolution-1200) {
    padding: 0 30px;
    font-size: 14px;
  }

  @media (min-width: $resolution-1000) {
    &:hover{
      background: $color-red;
    }
  }

  input{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 300px;
    height: 300px;
    opacity: 0;
    cursor: pointer;
  }
}

.input-field{
  textarea {
    background: #fff;
    padding: 17px 20px;
    outline: none;
    border: none;
    height: 166px;
    resize: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    overflow: hidden;
  }
}


.form-block{
  font-size: 16px;
  input{
    background: #fff;
    padding: 20px;
  }
  .input-title{
    font-family: Travels;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .input-field{
    position: relative;
    width: 100%;
    margin-bottom: 25px;
    color: #a6a6a6;
    input, textarea{ transition: 600ms;}
    &[error="true"] input,
    &[error="true"] textarea {
      box-shadow: inset 0 0 0 1px #ed1c24;
        // background: rgba(237, 28, 36, .05)
    }
  }
  .btn{
    margin-top: 40px;
    padding: 21px 16px 19px;
    @media (max-width: 1000px) {
      margin-top: 20px;
    }
  }
  &--review {
    margin-top: -100px;
    .btn{margin-top: 0;}
  }

}

.form-modal{
  position: fixed; top: 0; left: 0; width: 100%; height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  &__bg{
    position: fixed; top: 0; left: 0; width: 100%; height: 100%;
    background: rgba(255,255,255,.95);
    animation: fadeIn .6s linear;
  }
  &__body{
    max-width: 1335px;
    width: 80%;
    padding: 10vw 15vw;
    background: #f2f2f2;
    box-sizing: border-box;
    font-family: 'Travels';
    font-size: 25px;
    font-weight: 500;
    line-height: 1.7;
    position: relative;
    animation: fadeInDown30 1s linear;
    @media (min-width: 1680px) {padding: 180px 250px; }
    @media (max-width: 1680px) {font-size: 22px; }
    @media (max-width: 1520px) {font-size: 20px; }
    @media (max-width: 1300px) {font-size: 18px; }
  }
  &__content{
    position: relative;
    padding-left: 5.3vw;
    &:before{
      content: '';
      position: absolute;
      top: 0; bottom: 0; left: 0;
      width: 4px; background: #ed1c24;
    }
  }
  &__close{
    position: absolute;
    top: 25px; right: 25px;
    color: #ed1c24; font-size: 32px;
    cursor: pointer;
  }
  &__title{
    color: #ed1c24;
    font-size: 36px;
    margin-bottom: 25px;
  }
  .btn{
    max-width: 300px;
    margin-top: 40px;
  }
}

.select-slick{
  width: auto;
  color: #ed1c24;

  span{
    display: block;
    padding: 5px 0;
    cursor: pointer;
  }
}

.select-slick__arrow{
  display: block;
  width: 22px;
  height: 14px;
  position: absolute;
  right: 0;
  left: auto;
  top: 50%;
  transform: translateY(-50%) !important;
  cursor: pointer;
  z-index: 3;

  &:after,
  &:before{
    display: none !important;
  }

  &.slick-disabled{
    opacity: 0.2;
    filter: grayscale(100%);
  }

  @media screen and (max-width: 1600px), screen and (max-height: 950px) {
    width: 21px;
    height: 13px;
  }

  @media screen and (max-width: 1500px), screen and (max-height: 850px) {
    width: 20px;
    height: 12px;
  }

  @media screen and (max-width: 1400px), screen and (max-height: 750px) {
    width: 19px;
    height: 11px;
  }

  @media screen and (max-width: 1300px), screen and (max-height: 650px) {
    width: 18px;
    height: 10px;
  }

  @media screen and (max-width: 1200px), screen and (max-height: 550px) {
    width: 17px;
    height: 9px;
  }

  @media (min-width: $resolution-1000) {
    &:hover path{
      fill: #000;
    }
  }

  svg{
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;

    path{
      transition: $animation-speed fill;
    }
  }
}

.select-slick__arrow-prev{
  margin-top: -11px;
}

.select-slick__arrow-next{
  margin-top: 11px;
}

.select-field__symbol{
  position: relative;
  display: block;
  width: 70px;
}

.select-slick-symbol-wrap{
  display: flex;
  align-items: center;
}

.subscribe-form__btn{
  width: auto;
  display: inline-block;

  @media (max-width: $resolution-1000) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }
}



.uniqueness-request{
  padding: 5.6vw 9vw 10.5vw 6.5vw;
  box-sizing: border-box;
  position: relative;
  margin-right: -4.5vw;
  &__title{
    font-size: 30px;
    font-family: 'Travels';
    font-weight: 500;
    margin-bottom: 34px;
    @media (max-width: 1000px) {
      font-size: 24px !important;
    }
  }
  .input-title {
	margin-bottom:7px;
  }
  .input-field {
	input {
		padding: 21px 17px;
        color:#000;
	}
	textarea {
		height: 131px;
	}
  }
  &__btn{
    float: right;
    font-size: 16px;
    margin-top: 5px;
    padding: 25px 63px 25px;
    @media (max-width: 1000px) {
      float: none;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    background: #fff;
    padding-right: 75px;
    border: solid 1px transparent;
    transition: border 600ms;
  }
  .not-valid.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    border-color: #f00;
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
    height: 50px;
    padding: 0 0 0 17px;
    font-family: Georgia, Times, "Times New Roman", serif;
    font-size: 18px;
  }
  .MuiAutocomplete-endAdornment {
    right: 20px;
  }
  .MuiAutocomplete-clearIndicator {
    margin-right: 5px;
  }
  .MuiAutocomplete-popupIndicator  {
    &:hover {
      background: none!important;
    }
    .MuiIconButton-label svg{
      width:16px;
      height:9px;
    }
  }
  @media(max-width: $resolution-1300) {
    padding-left: 7vw;
    padding-right: 10vw;
    &__title{
      font-size: 32px;
    }
  }
  @media (max-width: 1000px) {
    padding: 75px $padding_mobile 80px;
	margin: 0 -40px;
  }
}

.cooperation-request{
  padding: 5.6vw 4.75vw 10.5vw 9.7vw;
  box-sizing: border-box;
  position: relative;
  margin-left: -4.5vw;
  &:before{
    content: '';
    position: absolute;
    top: 187px;
    width: 5px;
    bottom: 180px;
    background: #ed1c24;
    left: 0;
	@media (max-width: 1000px) {
		display:none;
	}
  }
  &__title{
    font-size: 30px;
    font-family: 'Travels';
    font-weight: 500;
    margin-bottom: 34px;
    @media (max-width: 1000px) {
      font-size: 24px !important;
    }
  }
  .input-title {
	margin-bottom:7px;
  }
  .input-field {
	input {
		padding: 21px 17px;
        color:#000;
	}
	textarea {
		height: 131px;
	}
  }
  &__btn{
    float: right;
    font-size: 16px;
    margin-top: 5px;
    padding: 25px 63px 25px;
    @media (max-width: 1000px) {
      float: none;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @media(max-width: $resolution-1300) {
    padding-left: 7vw;
    padding-right: 10vw;
    &__title{
      font-size: 32px;
    }
  }
  @media (max-width: 1000px) {
    padding: 75px $padding_mobile 80px;
	margin: 0 -40px;
  }
}
