.promo{
  padding-bottom: 159px;

  @media (max-width: $resolution-1000) {
    padding-bottom: 73px;
    padding-top: 45px;
  }

  .page-title{
    @media (max-width: $resolution-1000) {
      margin-bottom: 49px;
    }
  }

  .news-feed__filter{
    width: auto;
    flex-wrap: wrap;

    @media (max-width: $resolution-1000) {
      margin-left: 0;
    }
  }

  .news-feed__clear{
    float: none;
    display: flex;
    justify-content: flex-end;

    @media (max-width: $resolution-1000) {
      justify-content: center;
      font-size: 16px;
    }
    

    span{
      position: relative;
    }

    svg{
      width: 12px;
      height: 12px;
      position: absolute;
      left: -20px;
      top: 2px;
    }

    path{
      fill: #a6a6a6;
    }
  }

  .news-feed__filter__item{
    &:after{
      @media (max-width: $resolution-1000) {
        display: none;
      }
    }

    &[active="true"]:before{
      @media (max-width: $resolution-1000) {
        width: 100%;
      }
    }
  }

  .news-feed__filter__item span{
    height: 66px;
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;

    @media (max-width: $resolution-1000) {
      height: 45px;
      border-width: 2px;
    }
  }
}

.promo__list{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: $resolution-1000) {
    display: block;
  }
}

.promo__item{
  width: 31.62%;
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  padding: 3.29%;
  margin-bottom: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  transition: 300ms box-shadow;

  &.double{
    width: 65.81%;
    background: #f2f2f2;
    border: none;
    flex-direction: row;
    align-items: center;

    @media (max-width: $resolution-1000) {
      display: block;
      width: auto;
    }

    .promo__item-line2{
      display: none;
    }

    &.double > div:first-child{
      width: 65.5%;
      padding-right: 8%;
      box-sizing: border-box;

      @media (max-width: $resolution-1000) {
        width: auto;
        padding: 0;
      }
    }

    .promo__item-image{
      width: 37.5%;

      @media (max-width: $resolution-1000) {
        width: auto;
      }
    }

    .promo__item-date{
      margin-bottom: 0;

      @media (max-width: $resolution-1000) {
        margin-bottom: 35px;
      }
    }
  }

  @media (min-width: $resolution-1000) {
    &:hover{
      box-shadow: 0px 0px 30px 1px rgba(0,0,0,0.05);
    }
  }

  @media (max-width: $resolution-1400) {
    padding: 3%;
    margin-bottom: 35px;
  }

  @media (max-width: $resolution-1300) {
    padding: 2.5%;
    margin-bottom: 30px;
  }

  @media (max-width: $resolution-1000) {
    display: block;
    width: auto;
    padding: 25px;
    margin-bottom: 23px;
    border-width: 2px;
  }
}

.promo__list:after{
  content: '';
  display: block;
  width: 31.62%;
}

.promo__item-title{
  font-family: "Travels";
  font-weight: 500;
  font-size: 24px;
  line-height: 1.1;
  margin-bottom: 20px;

  @media (max-width: $resolution-1400) {
    font-size: 22px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 20px;
    margin-bottom: 15px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 22px;
    line-height: 1.2;
  }
}

.promo__item-dsc{
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 20px;

  @media (max-width: $resolution-1400) {
    font-size: 15px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 14px;
    margin-bottom: 15px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 17px;
    margin-bottom: 20px;
  }
}

.promo__item-date{
  font-family: "Travels";
  font-weight: 400;
  font-size: 13px;
  line-height: 1.2;
  margin-bottom: 55px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 50px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 12px;
    margin-bottom: 45px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 35px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 15px;
    line-height: 1.2;
  }
}

.promo__item-line{
  background-color: $color-red;
  position: absolute;
  left: -1px;
  top: -1px;
  width: 50%;
  height: 5px;
}

.promo__item:nth-child(odd) .promo__item-line{
  width: 30%;
}

.promo__item:nth-child(even) .promo__item-line{
  width: 10%;
}

.promo__item:nth-child(2n + 3) .promo__item-line{
  width: 70%;
}

.promo__item:nth-child(2n + 4) .promo__item-line{
  width: 55%;
}

.promo__item:nth-child(2n + 5) .promo__item-line{
  display: none;
}

.promo__item-line2{
  background-color: $color-red;
  position: absolute;
  left: -1px;
  top: -1px;
  width: 5px;
  height: 50%;

  &.animate{
    transition: 900ms transform, 900ms opacity;
    transition-delay: 300ms;
    transform: scaleY(0);
    transform-origin: 50% 0;
    opacity: 0;
  }

  &.animate.animated {
    transform: scaleY(1) !important;
    opacity: 1 !important;
  }
}

.promo__item:nth-child(odd) .promo__item-line2{
  height: 10%;
  top: 30%;
}

.promo__item:nth-child(even) .promo__item-line2{
  height: 30%;
  top: 50%;
}

.promo__item:nth-child(2n + 3) .promo__item-line2{
  height: 20%;
  top: 70%;
}

.promo__item:nth-child(2n + 4) .promo__item-line2{
  height: 15%;
  top: 10%;
}

.promo__item:nth-child(2n + 5) .promo__item-line2{
  display: none;
}

.promo__item-image{
  height: 229px;
  background-size: cover;
  background-position: 50% 50%;
  &.no-img {
    height: calc(100% + 6px);
    margin-top: -3px;
    margin-right: -3px;
    @media (max-width: $resolution-1000) {
      height:200px;
    }
  }

  @media (max-width: $resolution-1400) {
    height: 210px;
  }

  @media (max-width: $resolution-1300) {
    height: 190px;
  }

  @media (max-width: $resolution-1200) {
    height: 170px;
  }

  @media (max-width: $resolution-1000) {
    height: 150px;
  }
}

.promo__form{
  background: #f2f2f2 url('/static/img/index/estatet3.png') 50% 50% no-repeat;
  background-size: auto 170px;
  height: 250px;
  margin: 60px auto 100px auto;

  @media (max-width: $resolution-1400) {
    height: 230px;
    margin: 50px auto 90px auto;
  }

  @media (max-width: $resolution-1300) {
    height: 210px;
    margin: 40px auto 80px auto;
  }

  @media (max-width: $resolution-1000) {
    height: auto;
    padding: 73px 0;
    background: #f2f2f2;
  }

  .frame{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @media (max-width: $resolution-1000) {
      display: block;
    }
  }

  .social-item{
    font-size: 0;
    padding-top: 0;
    height: 24px;
    min-width: 77px;

    @media (max-width: $resolution-1400) {
      min-width: 67px;
    }

    @media (max-width: $resolution-1300) {
      min-width: 57px;
    }

    @media (max-width: $resolution-1200) {
      min-width: 40px;
    }

    &:before{
      height: 24px;
      line-height: 24px;
      font-size: 24px;
    }
    &--tg:before{font-size: 26px;}
    &--vk:before{font-size: 28px;}
    &--youtube:before{font-size: 24px;}
    &--ok:before{font-size: 22px;}
    &--fb:before{font-size: 24px;}
  }
}

.promo__form-title{
  font-family: "Travels";
  font-weight: 500;
  font-size: 36px;
  line-height: 1.2;
  margin-right: 20px;

  @media (max-width: $resolution-1400) {
    font-size: 30px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 26px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 22px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 26px;
    margin-bottom: 45px;
  }
}

.promo__form-social{
  display: flex;
  align-items: center;

  @media (max-width: $resolution-1000) {
    display: none;
  }
}

.promo__list-more{
  max-width: 300px;
  font-family: "Travels";
  font-weight: 600;
  margin: 19px auto 0 auto;

  @media (max-width: $resolution-1000) {
    margin-top: 49px;
  }
}