.popup {
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  padding: 22px;
  z-index: 1000;
  animation: 500ms ease 0s 1 normal none running modal-fadeIn;
  box-sizing: border-box;
  position: fixed;
  background: rgba(255, 255, 255, 0.9);

  @media (max-width: $resolution-1000) {
    padding: 0;
  }

  &__in {
    position: relative;
    width: 823px;
    padding: 75px 172px 75px;
    max-width: 100%;
    font-size: 20px;
    margin: auto;
    background: #f2f2f2;
    z-index: 1;
    box-sizing: border-box;

    @media (max-width: $resolution-1000) {
      width: auto;
      padding: 40px;
    }
    &:before {
      content:'';
      position: absolute;
      top: 80px;
      left: 106px;
      width:5px;
      height:calc(89.5% - 160px);
      background-color: $color-red;

      @media (max-width: $resolution-1000) {
        display: none;
      }
    }

    .filter-field__title {
      margin-bottom: 5px;
    }
    .filter-field__body {
      padding: 22px 17px;
    }
    .ui-select .MuiInputBase-root {
      height: 65px;
    }
    .checkbox-field {
      margin-bottom: 33px;
    }
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 26px;
    width: 47px;
    height: 47px;
    transition: $animation-speed transform;
    cursor: pointer;
    z-index: 10;

    @media (max-width: $resolution-1000) {
      width: 30px;
      height: 30px;
      right: 20px;
      top: 20px;
    }


    &:after,
    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: $color-red;
      position: absolute;
      left: 0;
      top: 50%;
    }
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
    &:hover {
      @media (min-width: $resolution-1001) {
        transform: scale(1.2) translateZ(0);
      }
    }
  }

  &__title {
    position: relative;
    font-family: 'Travels';
    font-weight: 500;
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: 43px;
    color: $color-red;
    
    @media (max-width: $resolution-1000) {
      font-size: 26px;
      margin-bottom: 30px;
    }

    div {
      color: #000;
    }
  }

  &__text {
    font-size: 20px;
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background: rgba(255, 255, 255, 0.95);
  }

  &_success {
    text-align: center;
  }

}
@keyframes modal-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.popup {
  .btn{
    padding: 26px 62px 24px;
    margin-top: 0;

    @media (max-width: $resolution-1000) {
      display: block;
    }
  }
}

.popup-change-plan{
  .btn{
    max-width: 220px;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }

  .popup__in{
    width: 90%;
    padding: 80px 205px 80px 80px;

    @media (max-width: $resolution-1400) {
      padding: 70px 175px 70px 70px;
    }
  
    @media (max-width: $resolution-1300) {
      padding: 70px 145px 60px 60px;
    }
  
    @media (max-width: $resolution-1200) {
      padding: 70px 50px 50px 50px;
    }

    @media (max-width: $resolution-1000) {
      width: 100%;
      padding: 40px;
    }

    &:before{
      display: none;
    }
  }
}

.popup-change-plan__title-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;

  @media (max-width: $resolution-1000) {
    display: block;
    margin-bottom: 35px;
  }
}

.popup-change-plan__title{
  font-family: 'Travels';
  font-weight: 500;
  font-size: 30px;

  @media (max-width: $resolution-1000) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.popup-change-plan-boxes{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 135px;
  padding-bottom: 45px;

  @media (max-width: $resolution-1400) {
    padding-left: 105px;
  }

  @media (max-width: $resolution-1300) {
    padding-left: 75px;
  }

  @media (max-width: $resolution-1200) {
    padding-left: 0;
  }

  @media (max-width: $resolution-1000) {
    display: block;
    padding-bottom: 10px;
  }

  &:after{
    content: '';
    display: flex;
    width: 26%;
  }
}

.popup-change-plan-box{
  width: 26%;
  font-family: "Travels";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 35px;
  margin-right: 30px;

  @media (max-width: $resolution-1000) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  &.active .checkbox-field__check:before{
    opacity: 1;
  }
}

.popup-change-plan-box-in{
  height: 230px;
  background: #fff;
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $resolution-1000) {
    height: 170px;
  }

  img{
    max-width: 70%;
    max-height: 70%;
    display: block;
    filter: grayscale(100%);
  }

  .checkbox-field__check{
    position: absolute;
    left: 11px;
    bottom: 11px;
    box-shadow: inset 0 0 0 1px #c3c3c3;
    top: auto;
  }
}

.popup-change-plan-chack-all{
  position: relative;
  padding-left: 35px;
  font-size: 18px;
  cursor: pointer;

  .checkbox-field__check{
    box-shadow: inset 0 0 0 1px #c3c3c3;
  }

  &.active .checkbox-field__check:before{
    opacity: 1;
  }
}

.popup__in-b{
  @media (max-width: $resolution-1000) {
    padding: 50px 15px;

    .form-gray .input-field input{
      font-size: 16px;
    }

    .call-request__get-sms {
      font-size: 13px;
      padding: 7px 5px;
      top: 33px;
    }
  }
}