$color-red: #ed1c24;

$animation-speed: 300ms;

$resolution-1680: 1680px;
$resolution-1600: 1600px;
$resolution-1500: 1500px;
$resolution-1480: 1480px;
$resolution-1440: 1440px;
$resolution-1400: 1400px;
$resolution-1370: 1370px;
$resolution-1300: 1300px;
$resolution-1200: 1200px;
$resolution-1100: 1100px;
$resolution-1001: 1001px;
$resolution-1000: 1000px;
$resolution-767: 767px;
$resolution-400: 400px;
$resolution-320: 320px;