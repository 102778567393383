.news{
  padding-bottom: 120px;
  .page-title{
    padding-right: 0;
    .path{
      margin-right: -135px;
    }
  }
}

.news__item{
  display: flex;
  margin-bottom: 70px;
  text-decoration: none;
  &__title{
    font-size: 24px;
    font-family: 'Travels';
    font-weight: 500;
    margin-bottom: 1em;
  }
  &__text{
    font-size: 16px;
  }
  &__info{
    display: flex;
    justify-content: space-between;
    font-family: 'Travels';
    font-weight: 500;
    font-size: 16px;
    margin-top: 1em;
  }
  &__link{
    border-bottom: 1px dashed;
    transition: 600ms;
  }
  &__date{
    font-family: 'Styrene';
    font-weight: 100;
    font-size: 14px;
  }
  &__img{
    position: relative;
    &>div{
      overflow: hidden;
      position: absolute; top: 0; left: 0; 
      width: 100%; height: 100%;
    }
    span{
      position: absolute;
      top: 0; left: 0; width: 100%; height: 100%;
      transition: 1200ms;
      background-size: cover;
      background-position: 50% 50%;
    }
    &:before{
      content: '';
      position: absolute;
      width: 5px; height: 153px;
      background: #ed1c24;
      z-index: 1;
    }
  }
  &__body{
    box-sizing: border-box;
  }
  &:nth-child(4n+2) {
    .news__item__img{
      width: 50%;
      margin-bottom: 55px;
      &:before{top: -33px; right: 0;}
    }
    .news__item__body{
      padding: 8vw 6.9vw 65px;
      background: #f2eeea;
      width: 50%;
    }
    .news__item__info{
      padding-top: 5.3vw;
    }
  }
  &:nth-child(4n+3) {
    padding-right: 0 !important;
    margin-bottom: 97px;
    .news__item__img{
      width: 68%; min-width: 68%;
      &:before{bottom: -59px; left: 0;}
    }
    .news__item__body{
      order: -1;
      padding: 3.2vw 3.2vw 45px;
    }
  }
  &:nth-child(4n+4) {
    padding-left: 0 !important;
    margin-bottom: 97px;
    .news__item__img{
      width: 34%; min-width: 34%;
      &:before{display: none;}
    }
    .news__item__body{
      padding: 2.3vw 4.1vw 2.3vw;
      max-width: 570px;
    }
  }
  &:nth-child(4n+1) {
    padding-left: 0 !important;
    margin-bottom: 97px;
    .news__item__img{
      width: 65%; min-width: 65%;
      &:before{top: -50px; left: 75px;}
    }
    .news__item__body{
      padding: 2.3vw 5.1vw 2.3vw;
    }
  }

  @media (min-width: 1000px) {
    &:hover{
      .news__item__link{
        border-bottom-color: transparent;
      }
      .news__item__img span{transform: scale(1.1);}

    }
  }
}

.news-feed__slider__item {
  margin-top: 10px;
}