.building{

  .appartment-by-id__header-bottom-menu{
    flex-wrap: wrap;
  }

  .appartment-menu-fixed__menu{
    flex-wrap: wrap;
    justify-content: flex-start;

    & > div{
      margin: 0 15px 5px 0;
    }
  }

  .appartment-menu-fixed__menu-item{
    margin: 0 5px 5px 0;
  }

  .appartment-by-id__header-metro-wrap{
    margin-bottom: 0;
  }
  .appartment-by-id__header-name {
    margin-bottom: 15px;
  }

  @media (max-width: $resolution-1000) {
    padding-top: 80px;

    .appartment-by-id__header-button > div:last-child{
      min-width: 17px;
    }

    .appartment-by-id__header-title{
      font-size: 26px;
      margin-bottom: 15px;
    }

    .appartment-by-id__header-metro{
      margin-right: 0;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .appartment-by-id__header-name{
      font-size: 16px;
    }

    .appartment-by-id__header-metro-wrap{
      display: block;
    }

    .appartment-by-id__header-in-last{
      display: block;
    }

    .appartment-by-id__header-in > div:first-child{
      margin-bottom: 30px;
      padding-bottom: 25px;
      border-bottom: 1px solid #d9d9d9;
    }

    .appartment-menu-fixed__priceup{
      top: 0;
      margin-right: 0;
      text-align: left;
      margin-bottom: 20px;
    }

    .appartment-by-id__header-in-last > div:first-child{
      display: block;
    }

    .appartment-by-id__header-price-wrap-first{
      display: block;
    }

    .appartment-by-id__header-button{
      margin-left: 0;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      padding: 20px 18px;
      margin-top: 25px;
      width: 100%;
      box-sizing: border-box;
    }

    .building-for-buy{
      text-align: center;
      font-size: 17px;
      padding-top: 22px;
      margin-bottom: 30px;
    }

    .appartment-by-id__header-bottom-icons{
      justify-content: center;
    }

    .appartment-by-id__header-in{
      border: none;
      padding: 0;
    }

    .appartment-by-id__header-bottom-icons{
      padding-top: 30px;
    }
  }

  .reportsBlock{

    @media (max-width: $resolution-1400) {
      margin-top: 110px;
    }

    @media (max-width: $resolution-1300) {
      margin-top: 90px;
    }

    @media (max-width: $resolution-1200) {
      margin-top: 75px;
    }

    @media (max-width: $resolution-1000) {
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 75px;

      .reportsConsalt__item{
        width: auto;
        display: block;
        padding: 120px 40px 30px 40px;
        text-align: center;
      }

      .reportsConsalt__name:before{
        display: none !important;
      }

      .reportsConsalt__item:before{
        left: 50%;
        top: 0;
        margin-left: -45px;
        transform: translateX(0);
      }

      .reportsConsalt__item:after{
        left: 50%;
        top: 0;
        margin-left: -17px;
        top: 22px;
        transform: translateX(0);
      }

      .title{
        padding-left: 40px;
        text-align: left !important;
        margin-bottom: 65px;
      }
    }
  }

  .title{
    text-align: left;
    font-size: 48px;
    line-height: 1;

    @media (max-width: $resolution-1400) {
      font-size: 44px;
    }

    @media (max-width: $resolution-1300) {
      font-size: 36px;
    }

    @media (max-width: $resolution-1200) {
      font-size: 34px;
    }

    @media (max-width: $resolution-1000) {
      font-size: 26px !important;
      overflow-wrap: break-word;
    }
  }

  .appartment-menu-fixed-detect{
    margin-bottom: 0;
  }

  .appartment-by-id__header-wrap{
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .reportsBlock{
    .title{
      text-align: center;
      font-size: 48px;
    }
  }

  .apartment-search__view-title{
    margin-top: 0;
    padding-top: 0;
    border: none;
  }

  .index__info{
    padding-top: 90px;
    padding-bottom: 90px;
    margin-bottom: 50px;

    @media (max-width: $resolution-1000) {
      padding-top: 50px;
      padding-bottom: 50px;
      margin-bottom: 75px;
    }
  }

  .index__info:before{
    top: 0px;
    bottom: 0px;
  }

  .index__info__item__title{
    font-weight: 500;
  }

  .index__info__item--large .index__info__item__title{
    font-weight: 400;
  }
  .video-call__select{
    width: 50%;
  }

  .appartment-by-id__header-bottom > div:first-child{
    width: 100%;
  }

  .appartment-by-id__header-bottom-menu div{
    margin-right: 30px;
  }

  .news-feed-tales .title{
    margin-bottom: 25px;
  }
}

.building-for-buy{
  text-align: right;
  font-family: "Travels";
  font-weight: 500;
  font-size: 16px;
  padding-top: 17px;

  a{
    text-decoration: none;
    position: relative;
    border-bottom: 1px dashed #a5a5a5;
    transition: $animation-speed border-color;
    cursor: pointer;
    text-decoration: none;
    color: #000;

    @media (min-width: $resolution-1000) {
      &:hover{
        border-color: transparent;
      }
    }
  }
}

.building-top{
  display: flex;
  //margin-bottom: 60px;

  .slick-dots {
    position: absolute;
    width: 100%;
    bottom: 30px;
  }
  .apartment-search__item-logo{
    z-index: 2;
  }

  @media (max-width: $resolution-1000) {
    display: block;
    margin-bottom: 50px;
  }

  &.single{
    display: block;

    .slick-prev{
      display: block !important;
    }

    & > div{
      width: 100% !important;
    }
  }

  & > div:first-child{
    box-sizing: border-box;
    padding-right: 10px;
    width: 34%;
    position: relative;

    @media (max-width: $resolution-1000) {
      width: auto;
      padding-right: 0;
    }
  }

  & > div:last-child{
    width: 66%;
    position: relative;

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }
}

#building-map{
  height: 500px;

  @media (max-width: $resolution-1000) {
    height: 355px;
  }
}

.building-top-slide-bg{
  height: 500px;
  background-size: cover;
  background-position: 50% 50%;

  @media (max-width: $resolution-1000) {
    height: 335px;
  }
}

.building-top-slide-label{
  font-size: 16px;
  padding-top: 3px;
}

.building-top-slider{

  @media (max-width: $resolution-1000) {
    .slick-list{

    }
  }

  .slick-arrow:before{
    display: none;
  }

  .slick-arrow{
    margin-top: -11px;
    svg{
      width: 23px;
      height: 62px;
    }

    path{
      fill: #000;
    }
  }

  .slick-prev{
    left: 29px;
    display: none !important;
  }

  .slick-next{
    right: 80px;
  }
}

.building-top-slide{
  width: 54vw;
  padding-right: 10px;
  box-sizing: border-box;
  opacity: 0.4;
  transition: $animation-speed opacity;
  //margin-bottom: 25px;


  @media (max-width: $resolution-1000) {
    width: calc(100vw - 30px);
    padding: 0 5px;
  }

  &.single{
    opacity: 1;
    width: auto;

    @media (max-width: $resolution-1000) {
      width: 100vw;
      padding: 0;
    }
  }

  &.active{
    opacity: 1;
  }
}

.building-options-wrap{
  align-items: center;
  padding-top: 17px;
  //border-top: 1px solid #e6e6e6;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  min-height: 120px;

  @media (max-width: $resolution-1000) {
    padding: 0;
    margin: 0;
    min-height: 0;
    display: block;
  }

  & > div:first-child{
    width: 25%;

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }

  & > div:last-child{
    width: 73%;

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }
}

.building-options{
  display: flex;
  justify-content: space-between;
  display: flex;
  font-family: "Travels";
  font-weight: 500;
  font-size: 15px;

  @media (max-width: $resolution-1400) {
    font-size: 14px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 13px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 13px;
  }

  @media (max-width: $resolution-1000) {
    display: block;
    padding-bottom: 40px;
    font-size: 16px;
  }

  & > div{
    @media (max-width: $resolution-1300) {
      padding-right: 15px;
    }

    @media (max-width: $resolution-1000) {
      padding-right: 0;
    }
  }

  & > div > div{
    font-size: 22px;
    font-weight: 600;
    padding-top: 10px;

    @media (max-width: $resolution-1400) {
      font-size: 20px;
    }

    @media (max-width: $resolution-1300) {
      font-size: 18px;
    }

    @media (max-width: $resolution-1200) {
      font-size: 16px;
    }

    @media (max-width: $resolution-1000) {
      padding-top: 0;
      font-size: 22px;
      margin-bottom: 30px;
    }
  }
}

.building-filter{
  background: #f2f2f2;
  padding: 75px 0 60px 0;
}

.building-filter-title{
  font-family: "Travels";
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 30px;

  @media (max-width: $resolution-1000) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.building-filter-top{
  display: flex;
  justify-content: space-between;

  @media (max-width: $resolution-1000) {
    display: block;
    margin-bottom: 30px;

    .appartment-by-id__header-button{
      margin-left: 0;
      justify-content: space-between;
      padding: 22px 30px 20px;
      font-size: 16px;
    }
  }
}

.building-filter-menu{
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: $resolution-1000) {
    justify-content: space-between;
    margin-bottom: 35px;
  }

  & > div{
    position: relative;
    font-family: "Travels";
    margin-right: 35px;
    font-size: 22px;
    line-height: 1;
    font-weight: 500;
    cursor: pointer;
    transition: $animation-speed color;

    @media (max-width: $resolution-1000) {
      font-size: 18px;
      margin-right: 0;
    }

    &:after{
      content: "";
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: -7px;
      height: 1px;
      border-bottom: 1px dashed #a6a6a6;
      transition: $animation-speed opacity;
    }

    &.active{
      color: $color-red;
      &:after{
        opacity: 0;
      }
    }
  }
}

.building-filter-select-button{
  .filter__more{
    margin-top: 16px;
  }
}

.building-result{
  margin-bottom: 100px;
  @media (max-width: 1000px) {
    margin-bottom: 65px;
  }
}

.building-result-value{
  font-family: "Travels";
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  padding-top: 15px;
}

.building-result-container:not(.active) {
  opacity: 0;
  display: none;
}
.building-result-container {
  transition: opacity 0.3s;
}

.building-result-tab{
  display: flex;
  border-bottom: 1px solid #e6e6e6;
  padding: 34px 0;
  justify-content: space-between;
  align-items: center;
  font-family: "Travels";
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: $resolution-1000) {
    padding: 29px 0;
  }

  & > div{
    display: flex;
    align-items: center;
    @media (max-width: $resolution-1000) {
      &:first-child > div:last-child{
        display: none;
      }
    }
  }

  &.active .building-result-tab__arrow{
    transform: rotateX(-180deg);
  }
}

.building-result-tab__title{
  margin-right: 32px;
  font-size: 30px;

  @media (max-width: $resolution-1000) {
    margin-right: 0;
    font-size: 24px;
  }
}
.building-result-tab__title.capital{
  text-transform: capitalize;
}

.building-result-tab__arrow{
  width: 16px;
  height: 10px;
  transform-origin: 50% 50%;
  transition: $animation-speed transform;

  svg{
    width: 100%;
    height: 100%;
  }
}

.building-result-tab__area{
  margin-right: 35px;
  text-align: right;

  @media (max-width: $resolution-1200) {
    margin-right: 20px;
  }

  @media (max-width: $resolution-1000) {
    display: none;
  }
}

.building-result-tab__price{
  margin-right: 95px;
  text-align: right;
  width: 200px;


  @media (max-width: $resolution-1200) {
    margin-right: 80px;
  }

  @media (max-width: $resolution-1000) {
    display: none;
  }
}

.building-result-table-wrap{
  overflow-y: hidden;
  @media (max-width: $resolution-1000) {
    overflow-x: auto;
  }
}

.building-result-no-photo{
  svg {
    width: 40px;
    height: auto;
  }

  path{
    fill: #e4e4e4;
  }
  @media (min-width: 1000px) and (hover: hover) {
    //img{transition: 600ms; transform-origin: right; position: relative; z-index: 1;}
    img{transition: 600ms;  position: relative; z-index: 1;}
    &:hover img{
      transform: scale(4);
      z-index: 30;
    }
  }

}
.building-result-table tbody tr{
  &[data-hidden="true"]{display: none;}
}
.building-result-table tbody tr:last-child td{border-bottom: 14px solid #fff; position: relative}
.building-result-table tbody tr:last-child td:after{
  content: '';
  position: absolute;
  bottom: 0; left: 0; right: 0;
  display: block; height: 1px; background: #e6e6e6;
}

.building-result-table{
  font-family: "Travels";
  border-collapse: collapse;
  width: 100%;

  @media (max-width: $resolution-1000) {
    width: 1000px;
  }

  td{
    text-align: center;
    border-bottom: 1px solid #e6e6e6;

    .add-favorite {
      margin: auto !important;
    }
    .appartment-by-id__header-button {
     display: inline-flex;
      margin: 0;
    }
    &.td_but {
      //padding: 0;
      width: 221px;
      &:last-child {
        padding-right: 0;
        width: 203px;
      }
    }
  }

  thead{
    font-weight: 500;
    position: relative;
    z-index: 20;

    td{
      font-size: 12px;
      color: #808080;
      padding-bottom: 20px;
      padding-top: 20px;
      background: #fff;
      &.sort {
        cursor: pointer;
        i {
          display: inline-block;
          vertical-align: middle;
          width: 10px;
          height: 7px;
          margin-left: 4px;
          margin-bottom: 1px;
          position: relative;
          &:after, &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: url("../img/building/corner.svg") center center no-repeat;
            background-size: contain;
          }
          &:before {
            opacity: 0;
            background-image: url("../img/building/corner_active.svg");
          }
        }

        &.asc, &.desc {

          i {
            margin-bottom: 0;

            &:after {
              opacity: 0;
            }
            &:before {
              opacity: 1;
            }
          }
        }
        &.desc {
          i {
            margin-bottom: 3px;
            &:before {
              transform: rotate(180deg);
            }
          }
        }



      }
    }
  }

  tbody{
    font-weight: 600;

    tr{
      cursor: pointer;
    }

    td{
      min-height: 93px;
      box-sizing: border-box;
      padding: 18px;
      font-size: 16px;
    }
  }

  img{
    max-height: 50px;
    width: auto;
  }
}

.building-result-table__note{
  width: 13px;
  height: 15px;
  cursor: pointer;
  position: relative;

  svg{
    width: 100%;
    height: 100%;
  }

  div{
    position: absolute;
    right: 18px;
    padding: 18px 35px;
    background: #fff;
    top: 50%;
    transform: translateY(-50%) scale(0.7);
    transform-origin: 100% 50%;
    font-size: 12px;
    width: 242px;
    box-sizing: border-box;
    box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
    pointer-events: none;
    font-weight: 500;
    text-align: left;
    transition: $animation-speed opacity, $animation-speed transform, $animation-speed visibility;
    opacity: 0;
    visibility: hidden;
  }

  @media (min-width: $resolution-1000) {
    &:hover div{
      opacity: 1;
      visibility: visible;
      transform: translateY(-50%) scale(1);
    }
  }
}

.building-result-table__percent{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ed1c24;
    color: #fff;
    font-size: 12px;
    margin-left: 6px;
    position: relative;
    top: -2px;

    &:hover i{
      opacity: 1;
      visibility: visible;
      transform: translateY(-50%) scale(1);
    }
  }

  i{
    font-style: normal;
    display: block;
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%) scale(0.8);
    padding: 15px 20px;
    background: #fff;
    font-family: "Travels";
    font-size: 12px;
    color: #000;
    box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
    white-space: nowrap;
    margin-right: 5px;
    pointer-events: none;
    font-weight: 400;
    transition: $animation-speed opacity, $animation-speed visiblity, $animation-speed transform;
    opacity: 0;
    visibility: hidden;
  }
}

.building-result-sort{
  display: flex;
  justify-content: space-between;
  padding: 20px 0 30px 0;

  & > div{
    display: flex;
    align-items: center;
    color: #808080;
  }

  .apartment-search__sort-select-wrap{
    padding-right: 0;
  }

  .apartment-search__sort-select{
    padding-left: 0;
  }
}

.building-result__list{
  .apartment-search__item-slide{
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      width: 80px;
      height: auto;
      display: block;
      path{
        fill: #e4e4e4;
      }
    }
  }
}

.building-chart{
  height: 270px;
  overflow: hidden;

  .recharts-cartesian-grid-horizontal{
    line:first-child,
    line:last-child{
      display: none;
    }
  }
}

.building-chart-title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  & > div{
    display: flex;
    align-items: center;
    //width: 100%;
  }

  & > div:first-child{
    font-family: "Travels";
    font-weight: 500;
    font-size: 46px;
    @media (max-width: 1000px) {
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 1000px) {
    flex-direction: column;
  }
}

.building-chart-title__button{
  color: #fff;
  font-family: "Travels";
  font-weight: 500;
  font-size: 15px;
  padding: 12px 19px;
  background: #666666;
  cursor: pointer;
  transition: $animation-speed background;
  display: flex;
  align-items: center;

  @media (max-width: $resolution-1000) {
    width: 100%;
    padding: 22px 0;
    justify-content: center;
    max-width: 400px;
  }

  @media (min-width: $resolution-1000) {
    &:hover{
      background: #949494;
    }
  }

  svg{
    width: 10px;
    height: 15px;
    margin-left: 30px;
    display: block;
  }

  path{
    fill: #fff;
  }
}

.building-chart-filter{
  background: #f2f2f2;
  padding-top: 32px;
  padding-bottom: 32px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }

  .ui-checkbox__label{
    font-family: "Travels";
    font-weight: 500;
    color: #000;
    font-size: 14px;
    line-height: 1;
  }

  .ui-checkbox__rect{
    transition: $animation-speed background;
    height: 22px;
    max-width: 22px;
    min-width: 22px;
    top: -1px;
    position: relative;

    &.active{
      background: $color-red;
    }

    svg{
      display: none;
    }
  }

  & > div{
    padding: 45px 0;
    display: flex;
    align-items: center;
    @media (max-width: 1000px) {
      padding: 0;
    }
  }

  & > div:nth-child(1){
    width: 26%;
    border-right: 1px solid #dcdcdc;
    @media (max-width: 1000px) {
      width: 100%;
      border-right: none;
      justify-content: flex-start;
    }
  }
  & > div:nth-child(2){
    width: 25%;
    border-right: 1px solid #dcdcdc;
    justify-content: space-around;
    @media (max-width: 1000px) {
      flex-direction: column;
      width: 50%;
      justify-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      padding-right: 20px;
    }
  }
  & > div:nth-child(3){
    width: 49%;
    justify-content: space-around;
    padding-right: 5%;
    box-sizing: border-box;
    @media (max-width: 1000px) {
      flex-direction: column;
      width: 50%;
      padding-right: 0;
      align-items: flex-start;
      padding-left: 20px;
    }
  }

  .ui-checkboxes{
    .active:nth-child(2){
      background: #4b4b4b;
    }
    .active:nth-child(3){
      background: #666666;
    }
    .active:nth-child(4){
      background: #949494;
    }
    .active:nth-child(5){
      background: #c2c2c2;
    }
  }
}

@media (max-width: 1000px) {
  .building-chart-filter__checkboxes{margin-bottom: 25px;}
  .building-chart-filter__checkboxes .ui-checkboxes div{
    height: 50px;
    padding: 0;
    width: 44px;
    &:first-child{width: 95px;}
  }
  .building-chart-filter > div:nth-child(2) .ui-checkbox, .building-chart-filter > div:nth-child(3) .ui-checkbox{
    margin-bottom: 17px;
  }
  .building-chart-filter .ui-checkbox__rect{margin-right: 12px;}
  .building-chart.frame{padding-left: 15px; padding-right: 15px;}
  .recharts-responsive-container{margin-left: -70px; width: calc(100% + 70px) !important;}
  .recharts-surface{padding-top: 30px; overflow: visible}
  .recharts-text.recharts-label{
    transform: translateX(15px);
  }
  .recharts-layer text:not(.text-small){
    fill: #000;
  }
}

.building-chart-filter__checkboxes{
  position: relative;
  top: -8px;
}

.building-chart-wrapper{
  margin-bottom: 90px;
  @media (max-width: 1000px) {
    margin-bottom: 40px;
  }
}

.building-about__top{
  background: #edeae5 url('/static/img/index/estatet3.png') 50% 0 no-repeat;
  background-size: auto 167px;
  padding-top: 94px;
  position: relative;

  @media (max-width: $resolution-1000) {
   padding-top: 0;
   background-size: 100% auto;
    .frame{
      padding: 0;
    }
  }

  &:after{
    content: '';
    display: block;
    height: 140px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: #fff;
  }
}

.building-about__top-in{
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  @media (max-width: $resolution-1000) {
    display: block;
    margin-bottom: 40px;

    .title{
      margin-bottom: 50px;
    }

    & > div:first-child{
      padding: 40px;
    }
  }

  ul{
    margin: 0 70px 180px 40px;
    padding: 0;
    list-style: none;

    @media (max-width: $resolution-1000) {
      margin: 0;
    }
  }

  li{
    position: relative;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 30px;
    padding-left: 30px;

    @media (max-width: $resolution-1400) {
      font-size: 18px;
    }

    @media (max-width: $resolution-1300) {
      font-size: 16px;
    }

    @media (max-width: $resolution-1200) {
      font-size: 16px;
    }

    @media (max-width: $resolution-1000) {
      font-size: 18px;
      margin-bottom: 25px;
      padding-left: 20px;

      &:last-child{
        margin-bottom: 0;
      }
    }

    &:after{
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      position: absolute;
      left: 0;
      top: 11px;
      background: $color-red;
    }
  }
}

.building-about__img1{
  margin-right: 130px;
  position: relative;
  width: 620px;
  height: 820px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;

  @media (max-width: $resolution-1600) {
    width: 580px;
    margin-right: 100px;
    height: 750px;
  }

  @media (max-width: $resolution-1600) {
    width: 500px;
    margin-right: 0px;
    height: 650px;
  }

  @media (max-width: $resolution-1000) {
    margin-right: 0;
    width: 100%;
    height: 300px;
  }

  &:after{
    content: '';
    display: block;
    height: 237px;
    width: 4px;
    position: absolute;
    left: 0;
    bottom: 68px;
    z-index: 2;
    background: $color-red;

    @media (max-width: $resolution-1000) {
      right: 0;
      top: 0;
      height: 4px;
      width: 50%;
      left: auto;
      bottom: auto;
    }
  }

  img{
    max-width: 100%;
    display: block;
  }
}

.building-about .title{
  white-space: nowrap;
  padding-right: 30px;
}

.building-about__box{
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-top: 164px;
  margin-bottom: 130px;
  padding-left: 5.8%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $resolution-1400) {
    padding-top: 130px;
    margin-bottom: 100px;
  }

  @media (max-width: $resolution-1300) {
    padding-top: 100px;
    margin-bottom: 70px;
  }

  @media (max-width: $resolution-1200) {
    padding-top: 60px;
    margin-bottom: 60px;
  }

  @media (max-width: $resolution-1000) {
    margin-bottom: 30px;
    padding-top: 0;
    padding-left: 40px;
    display: block;
  }

  &:after{
    content: '';
    display: block;
    width: 5px;
    top: 0;
    bottom: 0;
    left: 0;
    background: $color-red;
    position: absolute;
  }

  & > div{
    width: 50%;
    box-sizing: border-box;

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }

  & > div:nth-child(1),
  & > div:nth-child(2),
  & > div:nth-child(3),
  & > div:nth-child(4){
    margin-bottom: 149px;

    @media (max-width: $resolution-1400) {
      margin-bottom: 110px;
    }

    @media (max-width: $resolution-1300) {
      margin-bottom: 70px;
    }

    @media (max-width: $resolution-1200) {
      margin-bottom: 50px;
    }

    @media (max-width: $resolution-1000) {
      margin: 0;
    }
  }

  & > div:nth-child(2){
    padding: 0 5.8%;

    @media (max-width: $resolution-1000) {
      padding: 0;
    }
  }

  & > div:nth-child(3){
    padding-right: 5.8%;

    @media (max-width: $resolution-1000) {
      padding-right: 0;
    }
  }

  & > div:nth-child(6){
    padding: 0 5.8%;

    @media (max-width: $resolution-1000) {
      padding: 0;
    }
  }

  img{
    max-width: 100%;

    @media (max-width: $resolution-1000) {
      display: block;
      margin-bottom: 39px;
    }
  }
}

.building-about__box-title{
  font-family: "Travels";
  font-size: 24px;
  line-height: 1.1;
  font-weight: 500;
  margin-bottom: 20px;

  @media (max-width: $resolution-1400) {
    font-size: 22px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 20px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 18px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.building-about__box-text{
  font-size: 20px;
  line-height: 1.4;

  @media (max-width: $resolution-1400) {
    font-size: 18px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 16px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 16px;
  }

  @media (max-width: $resolution-1000) {
    margin-bottom: 48px;
    font-size: 16px;
    line-height: 1.6;
  }
}

.building-photo{
  margin-bottom: 125px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 115px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 105px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 95px;
  }

  @media (max-width: $resolution-1000) {
    margin-bottom: 75px;
    .frame{
      padding: 0;
    }
  }

  .title{
    margin-bottom: 25px;

    @media (max-width: $resolution-1000) {
      padding-left: 40px;
    }
  }

  .slick-arrow:after,
  .slick-arrow:before{
    display: none;
  }

  .slick-arrow{

    @media (max-width: $resolution-1000) {
      width: 13px;
      height: 33px;

      svg{
        width: 100%;
        height: 100%;
      }
    }

    path{
      fill: #fff;
    }
    margin-top: -15px;
  }

  .slick-prev{
    left: 30px;

    @media (max-width: $resolution-1000) {
      left: 50px;
    }
  }

  .slick-next{
    right: 30px;
    margin-right: 45px;

    @media (max-width: $resolution-1000) {
      right: 50px;
      margin-right: 0;
    }
  }
}

.building-photo-slider{
  margin-right: -45px;

  @media (max-width: $resolution-1000) {
    margin-right: 0;
  }
}

.building-photo-slide{
  height: 338px;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 45px;

  @media (max-width: $resolution-1000) {
    margin-right: 0;
    height: 285px;
  }
}

.building-photo-label{
  height: 30px;
  font-size: 18px;
}

.building-photo-slide-wrap{
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 25px;

  @media (max-width: $resolution-1000) {
    margin: 0 40px 40px 40px;
  }
}

.building-photo-slide-line{
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  background: $color-red;
}

.building-photo-slide-line1{
  width: 30%;
}

.building-photo-slide-line2{
  width: 50%;
}

.building-photo-slide-line3{
  width: 10%;
}

.building-video{
  display: flex;
  justify-content: space-between;
  margin-bottom: 150px;
  align-items: center;
  font-size: 20px;
  line-height: 1.4;

  @media (max-width: $resolution-1400) {
    margin-bottom: 120px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 90px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 70px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 18px;
    display: block;
    margin-bottom: 0;
  }

  p{
    margin: 0 0 30px 0;

    &:last-child{
      margin-bottom: 0;
    }
  }

  & > div{
    width: 48.7%;

    @media (max-width: $resolution-1000) {
      width: auto;
      margin-bottom: 100px;
    }
  }

  .building-video__last{
    padding: 0 5.9%;

    @media (max-width: $resolution-1000) {
      padding:  0 40px;
      margin-bottom: 75px;
    }
  }

  ul{
    margin: 0 0 30px 0;
    padding: 0;
    list-style: none;
  }

  li{
    position: relative;
    padding-left: 30px;

    &:last-child{
      margin-bottom: 0;
    }

    &:after{
      content: '';
      display: block;
      width: 4px;
      height: 4px;
      position: absolute;
      left: 0;
      top: 12px;
      background: $color-red;
    }
  }
}

.building-video__aero{
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: relative;
  height: 503px;
  text-decoration: none;
  display: block;

  @media (max-width: $resolution-1000) {
    height: 252px;
  }

  &:after{
    content: "";
    display: block;
    width: 170px;
    height: 5px;
    left: 0;
    bottom: -25px;
    position: absolute;
    background: #ed1c24;

    @media (max-width: $resolution-1000) {
      left: 40px;
      width: 225px;
    }
  }

  @media (min-width: $resolution-1000) {
    &:hover .building-video__aero-button{
      transform: scale(0.9);
    }
  }
}

.building-video__aero-button{
  width: 103px;
  height: 103px;
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  margin: -51px 0 0 -51px;
  transition: $animation-speed transform;

  svg{
    width: 100%;
    height: 100%;
  }
}

.building-video__box{
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 100%;

  video{
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: #000;
  }
}

.building-video__button{
  width: 97px;
  height: 97px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -49px 0 0 -49px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $animation-speed transform;
  z-index: 3;

  @media (min-width: $resolution-1000) {
    &:hover{
      transform: scale(0.9);
    }
  }

  &:after{
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11.5px 0 11.5px 20px;
    border-color: transparent transparent transparent $color-red;
    margin-left: 5px;
  }
}

.building-video-wrap{
  @media (max-width: $resolution-1000) {
    padding: 0;
  }

  .title{
    margin-bottom: 55px;

    @media (max-width: $resolution-1000) {
      margin-bottom: 30px;
      padding-left: 40px;
    }
  }
}

.building-video__box-wrap{
  position: relative;
  height: 503px;

  iframe{
    width: 100%;
    height: 100%;
  }

  @media (max-width: $resolution-1000) {
    height: 252px;
  }

  &:after{
    content: '';
    display: block;
    width: 330px;
    height: 5px;
    left: 0;
    bottom: -25px;
    position: absolute;
    background: $color-red;

    @media (max-width: $resolution-1000) {
      left: 40px;
      width: 225px;
    }
  }
}

.building-ready{
  margin-bottom: 150px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 140px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 130px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 120px;
  }

  @media (max-width: $resolution-1000) {
    margin-bottom: 75px;

    .frame{
      padding: 0;
    }
  }

  .title{
    margin-bottom: 45px;

    @media (max-width: $resolution-1000) {
      padding-left: 40px;
      margin-bottom: 30px;
    }
  }

  .slick-arrow:after,
  .slick-arrow:before{
    display: none;
  }

  .slick-arrow{

    @media (max-width: $resolution-1000) {
      width: 13px;
      height: 33px;
      margin-top: -60px;

      svg{
        width: 100%;
        height: 100%;
      }
    }

    path{
      fill: #fff;
    }

    margin-top: -36px;
  }

  .slick-prev{
    left: 30px;

    @media (max-width: $resolution-1000) {
      left: 50px;
    }
  }

  .slick-next{
    right: 30px;
    margin-right: 45px;

    @media (max-width: $resolution-1000) {
      right: 50px;
      margin-right: 0;
    }
  }
}

.building-ready-slider{
  margin-right: -60px;

  @media (max-width: $resolution-1000) {
    margin-right: 0;
  }
}

.building-ready-slide{
  height: 255px;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #000;

  div{
    padding-bottom: 20px;
    font-family: "Travels";
    font-weight: 500;
    font-size: 15px;
  }

  @media (max-width: $resolution-1000) {
    margin: 0 40px;
    height: 285px;
  }
}

.building-ready-dsc{
  height: 72px;
  box-sizing: border-box;
  padding-top: 15px;
  position: relative;

  @media (max-width: $resolution-1000) {
    margin: 0 40px 40px 40px;
  }

  &:after{
    content: '';
    display: block;
    height: 5px;
    width: 100px;
    left: 0;
    bottom: 0;
    background: $color-red;
    position: absolute;
    transition: $animation-speed transform;
    transform: scaleX(0);
    transform-origin: 0 50%;

    @media (max-width: $resolution-1000) {
      width: 60%;
    }
  }

  &.active:after{
    transform: scaleX(1);
  }
}

.building-ready-label{
  color: #808080;
  font-size: 12px;
  font-family: "Travels";
  font-weight: 500;
}

.building-ready-date{
  font-size: 18px;
}

.building-developer__top-in{
  display: flex;
  align-items: center;

  @media (max-width: $resolution-1000) {
    display: block;
  }

  & > div{
    position: relative;
    box-sizing: border-box;
  }

  & > div:first-child{
    width: 41%;
    padding-left: 3%;

    @media (max-width: $resolution-1000) {
      width: auto;
      padding: 50px 40px;
    }
  }

  & > div:last-child{
    width: 59%;
    padding: 4% 3%;

    @media (max-width: $resolution-1000) {
      width: auto;
      padding: 0;
    }
  }
}

.building-developer__top{
  background: #f2f2f2;
  margin-bottom: 43px;

  @media (max-width: $resolution-1000) {
    margin-bottom: 75px;

    .frame{
      padding: 0;
    }
  }
}

.building-developer__top-img{
  display: block;
  max-width: 100%;
}

.building-developer__top-imgs{
  position: relative;
  top: -70px;

  @media (max-width: $resolution-1000) {
    top: 0;
  }
}

.building-developer__top-logo{
  position: absolute;
  z-index: 2;
  max-width: 300px;
  left: 29px;
  top: 26px;

  @media (max-width: $resolution-1000) {
    max-width: 200px;
  }
}

.building-developer__dsc{
  position: relative;
  padding-left: 55px;
  font-size: 20px;
  line-height: 1.5;

  @media (max-width: $resolution-1400) {
    font-size: 18px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 16px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 16px;
  }

  @media (max-width: $resolution-1000) {
    padding-left: 25px;
    font-size: 18px;
    line-height: 1.7;
  }

  .title{
    margin-bottom: 20px;

    @media (max-width: $resolution-1000) {
      font-size: 25px !important;
      margin-bottom: 13px;
    }
  }

  &:after{
    content: '';
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    background: $color-red;
    position: absolute;
  }
}

.building-developer__bottom{
  padding: 0 2.5vw;
  margin-bottom: 130px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 110px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 90px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 75px;
  }

  @media (max-width: $resolution-1000) {
    padding: 0;
    margin-bottom: 75px;
  }

  .title{
    padding-left: 30px;
    margin-bottom: 40px;
    font-weight: 400;

    @media (max-width: 1800px) {
      font-size: 44px;
    }

    @media (max-width: 1680px) {
      font-size: 38px;
    }

    @media (max-width: 1450px) {
      font-size: 34px;
    }

    @media (max-width: 1300px) {
      font-size: 30px;
    }

    @media (max-width: 1150px) {
      font-size: 26px;
    }

    @media (max-width: $resolution-1000) {
      padding-left: 40px;
      margin-bottom: 20px;
      font-size: 30px !important;
    }
  }
}

.building-developer__bottom-in{
  background: url('/static/img/index/estatet3.png') 50% 0 no-repeat;
  background-size: 100% auto;
  padding-top: 100px;
  display: flex;
  justify-content: space-between;

  @media (max-width: $resolution-1000) {
    padding: 0;
    background: none;
    display: block;
  }

  & > div{
    box-sizing: border-box;
    padding: 44px 0 70px 0;

    @media (max-width: $resolution-1000) {
      padding: 50px 0 0 0;
    }
  }

  & > div:first-child{
    width: 30%;
    background: #f2eeea;

    @media (max-width: $resolution-1300) {
      width: 35%;
    }

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }

  & > div:last-child{
    width: 67.5%;
    border: 3px solid #f2eeea;
    background: #fff;

    @media (max-width: $resolution-1300) {
      width: 62.5%;
    }

    &.building-developer__bottom-in-news{
      width: 100%;
    }

    @media (max-width: $resolution-1000) {
      width: auto;
      background: #f2eeea;
      border: none;
      padding-bottom: 50px;
    }
  }

  .slick-slider{
    height: auto;
  }
}

.building-developer-item{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  @media (max-width: $resolution-1000) {
    margin-bottom: 30px;
  }

  @media (min-width: $resolution-1000) {
    &:hover .building-developer-item__text{
      border-color: #000;
    }
  }
}

.building-developer-item__img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
  margin-right: 28px;

  @media (max-width: $resolution-1000) {
    width: 57px;
    height: 57px;
    margin-right: 15px;
  }

  & > div:last-child{
    display: flex;
    flex: 1;
  }
}

.building-developer-item__text{
  font-size: 18px;
  display: inline;
  border-bottom: 1px solid transparent;
  transition: $animation-speed border-color;

  @media (max-width: $resolution-1000) {
    font-size: 16px;
  }
}

.building-developer-item__date{
  font-family: "Travels";
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
}

.building-developer-slide-promos{
  padding: 0 30px;
  padding-bottom: 75px;

  @media (max-width: $resolution-1000) {
    padding: 0 40px 15px 40px;
  }
}

.building-developer-slide-news{
  padding: 0 30px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 75px;

  @media (max-width: $resolution-1000) {
    padding: 0 40px 15px 40px;
    display: block;
  }

  .building-developer-item{
    width: 50%;

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }
}

.building-agents{
  margin-bottom: 140px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 120px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 80px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 80px;
  }

  @media (max-width: $resolution-1000) {
    margin-bottom: 80px;
  }

  .title{
    text-align: center;

    @media (max-width: $resolution-1000) {
      margin-bottom: 40px;
      text-align: left;
      padding-left: 40px;
    }
  }
}

.building-agents__list{
  display: flex;
  justify-content: space-between;

  &.building-agents__list-center{
    justify-content: center;

    .building-agents__item{
      margin: 0 2%;

      @media (max-width: $resolution-1000) {
        margin: 0 auto 50px auto;
      }
    }
  }
}

.building-agents__item{
  width: 255px;
  cursor: pointer;

  @media (max-width: $resolution-1400) {
    width: 245px;
  }

  @media (max-width: $resolution-1300) {
    width: 235px;
  }

  @media (max-width: $resolution-1200) {
    width: 225px;
  }

  @media (max-width: $resolution-1000) {
    width: 255px;
  }

  @media (max-width: $resolution-1000) {
    margin: 0 auto 50px auto;
  }

  &:nth-child(1) .building-agents__item-text:after{
    content: '';
    display: block;
    width: 40px;
    height: 5px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: $color-red;

    @media (max-width: $resolution-1000) {
      display: none;
    }
  }
}

.building-agents__item-img{
  height: 254px;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  div{
    position: relative;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    transition: $animation-speed opacity, $animation-speed visibility;
  }

  a{
    color: #fff;
    display: block;
    text-align: center;
    font-size: 18px;
    line-height: 1.4;

    &:hover{
      @media (min-width: $resolution-1000) {
        text-decoration: none;
      }
    }
  }

  .building-agents__item-phone{
    text-decoration: none;
    margin-bottom: 5px;
  }

  &:before{
    content: '';
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    opacity: 0;
    transition: $animation-speed opacity;
    z-index: 3;
  }
}

.building-agents__item-text{
  height: 85px;
  background: #ece8e4;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  line-height: 1.4;
  box-sizing: border-box;
  padding: 10px;
  position: relative;

  div{
    position: relative;
    z-index: 4;
    transition: $animation-speed color;
  }

  &:before{
    content: '';
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    opacity: 0;
    transition: $animation-speed opacity;
    z-index: 3;
  }
}

.building-agents__item.active{
  .building-agents__item-img:before,
  .building-agents__item-text:before{
    opacity: 1;
  }

  .building-agents__item-text div{
    color: #fff;
  }

  .building-agents__item-img div{
    opacity: 1;
    visibility: visible;
  }
}

.building-agents__item:hover{
  @media (min-width: $resolution-1000) {
    .building-agents__item-img:before,
    .building-agents__item-text:before{
      opacity: 1;
    }

    .building-agents__item-text div{
      color: #fff;
    }

    .building-agents__item-img div{
      opacity: 1;
      visibility: visible;
    }
  }
}

.building-filter-wrap{
  position: relative;
  //z-index: 3;
  z-index: auto;

  .apartment-search-loader{
    position: relative;
    height: 5px;
  }
}

.building div[data-id="apartment-calc"] .frame,
.building div[data-id="similar-appartments"] .frame,
.building div[data-id="similar-appartments2"] .frame,
.building div[data-id="events"] .frame{
  @media (max-width: $resolution-1000){
    padding: 0;
  }
}

.building div[data-id="similar-appartments"]{
  .apartment-search__slider{
    @media (max-width: $resolution-1000){
      margin-bottom: 70px;
    }
  }
}

.building div[data-id="events"]{
  @media (max-width: $resolution-1000){
    padding-bottom: 40px;
  }
}

.building div[data-id="events"] .news-feed__item{
  @media (max-width: $resolution-1000){
    padding: 0 40px;
    width: auto;
  }
}

.building div[data-id="events"] .title{
  @media (max-width: $resolution-1000){
    padding: 0 40px;
  }
}

.building-genplan{
  margin-bottom: 100px;

  @media (max-width: $resolution-1000){
    padding: 0;
  }
}

.building-genplan__in{
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  padding: 20px 30px 30px 30px;
  margin-top: 30px;
  align-items: flex-start;

  @media (max-width: $resolution-1400) {
    padding: 10px 20px 20px 20px;
  }

  @media (max-width: $resolution-1000){
    display: block;
    padding: 20px;
  }

  & > div{
    width: 50%;
    display: flex;

    @media (max-width: $resolution-1000){
      width: 100%;
    }
  }

  & > div:first-child{

    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.building-genplan__in-overflow{
    & > div:first-child{
      overflow-y: hidden;
      overflow-x: scroll;
      justify-content: flex-start;
      align-items: flex-start;

      .building-genplan__arrows{
        justify-content: flex-start;
      }
    }
  }

  & > div:last-child{
    justify-content: center;
    align-items: flex-start;
    position: sticky;
    top: 150px;
  }
}

.building-genplan__row{
  display: flex;
}

.building-genplan__cell-floor{
  width: 45px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Travels";
  font-weight: 500;
  font-size: 11px;
  color: #a3a3a3;
}

.building-genplan__arrows{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 16px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 15px;
    font-size: 15px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 10px;
    font-size: 14px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 13px;
  }

  & > div:first-child,
  & > div:last-child{
    width: 29px;
    height: 37px;
    box-sizing: border-box;
    padding: 10px 0;
    cursor: pointer;

    @media (min-width: $resolution-1000) {
      &:hover path{
        fill: $color-red;
      }
    }
  }

  .disabled{
    opacity: 0.2;
  }

  & > div:nth-child(2){
    padding: 0 13px;
    font-size: 18px;
  }

  svg{
    width: 100%;
    height: 100%;
    display: block;
  }

  path{
    transition: $animation-speed fill;
  }
}

.building-genplan__cell{
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-bottom: 5px;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 1;
  font-family: "Travels";
  font-weight: 500;
  border: 1px solid #fff;
  position: relative;

  @media (max-width: $resolution-1600) {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    margin-bottom: 4px;
  }

  @media (max-width: $resolution-1400) {
    width: 16px;
    height: 16px;
    margin-right: 3px;
    margin-bottom: 3px;
    font-size: 12px;
  }

  @media (max-width: $resolution-1200) {
    width: 14px;
    height: 14px;
    margin-right: 2px;
    margin-bottom: 2px;
    font-size: 11px;
  }

  @media (min-width: $resolution-1000) {
    &:hover .building-genplan__cell-popup{
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
}

.building-genplan__cell-active{
  color: #686868;
  background: #fff;
  border-color: #686868;
  cursor: pointer;
  transition: $animation-speed background, $animation-speed color, $animation-speed border-color;

  @media (min-width: $resolution-1000) {
    &:hover{
      background: $color-red;
      border-color: $color-red;
      color: #fff;
    }
  }
}

.building-genplan__cell-viewed{
  color: #fff;
  background: #686868;
  border-color: #686868;
  cursor: pointer;
  transition: $animation-speed background, $animation-speed color, $animation-speed border-color;

  @media (min-width: $resolution-1000) {
    &:hover{
      background: $color-red;
      border-color: $color-red;
    }
  }
}

.building-genplan__cell-popup{
  width: 260px;
  height: 131px;
  background: #fff;
  position: absolute;
  bottom: 22px;
  right: 0;
  box-shadow: 0px 0px 15px 0px rgba(34, 60, 80, 0.1);
  box-sizing: border-box;
  padding: 17px;
  display: flex;
  justify-content: space-between;
  color: #000;
  transform-origin: 100% 100%;
  transition: $animation-speed opacity, $animation-speed visibility, $animation-speed transform;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.8);

  &.left{
    right: auto;
    left: 0;
    transform-origin: 0 100%;
  }

  & > div{
    width: 48%;
  }

  img{
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;
  }

  svg{
    max-width: 100%;
    max-height: 100%;
    display: block;
    margin: 0 auto;

    path{
      fill: #e4e4e4;
    }
  }
}

.building-genplan__cell-popup-row{
  font-size: 12px;
  margin-bottom: 10px;

  div{
    font-weight: 600;
    padding-top: 2px;
  }

  &:last-child{
    margin-bottom: 0;
  }
}

.building-genplan__image{
  position: sticky;
  margin-top: 15px;

  img{
    display: block;
    max-width: 100%;
    height: auto;
  }

  svg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }

  text{
    fill: #fff;
  }

  g{
    cursor: pointer;
  }
}

.building-select-tabs{
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  @media (max-width: $resolution-1000) {
    margin-bottom: 20px;
  }
  &:before,
  &:after{
    display: none;
  }

  svg{
    width: 16px;
    height: 9px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -2px;
    display: block;
  }

  .MuiSelect-select{
    background: none !important;
    padding: 0 30px 0 0 !important;
    font-family: 'Travels';
    font-weight: 500;
    font-size: 30px;
    line-height: 1;
    min-height: 0;
    text-transform: capitalize !important;

    @media (max-width: $resolution-1000) {
      font-size: 24px;
    }
  }
}

.building-result-table-parking tbody tr{
  cursor: default;
}


.text-small{
  font: normal 9px 'Travels';
  fill: #fff;
}
