.appartment-by-id{
  padding-bottom: 114px;

  @media (max-width: $resolution-1400) {
    padding-bottom: 100px;
  }

  @media (max-width: $resolution-1300) {
    padding-bottom: 80px;
  }

  @media (max-width: $resolution-1200) {
    padding-bottom: 50px;
  }

  @media (max-width: $resolution-1000) {
    padding-bottom: 0px;
    padding-top: 80px;

    .apartment-about__in .btn{
      display: block;
    }
  }

  .apartment-search__view{
    margin-top: 0;
  }

  .apartment-search__view-title{
    border: none;
    margin-top: 0;
    padding-top: 0;
  }

  .title{
    text-align: left;
    font-size: 48px;
    line-height: 1.2;

    @media (max-width: $resolution-1400) {
      font-size: 44px;
    }

    @media (max-width: $resolution-1300) {
      font-size: 36px;
    }

    @media (max-width: $resolution-1200) {
      font-size: 34px;
    }

    @media (max-width: $resolution-1000) {
      font-size: 26px !important;
    }
  }

  .news-feed-tales .title{
    margin-bottom: 25px;
  }

  .appartment-by-id__header-title{
    cursor: default;
  }

  .appartment-by-id__header-name{
    text-decoration: underline;
    cursor: pointer;
  }

  .appartment-menu-fixed__name{
    text-decoration: underline;
    cursor: pointer;
  }
}

.appartment-by-id__header{
  background: #f2f2f2;
  padding:  40px 0;
}

.appartment-menu-fixed-detect{
  margin-bottom: 82px;
}

.appartment-by-id__header-title{
  font-family: 'Travels';
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 8px;
  cursor: pointer;
}

.appartment-by-id__header-metro-wrap{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.appartment-by-id__header-metro__out {

}
.appartment-by-id__header-metro{
  font-family: 'Travels';
  font-weight: 500;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  margin-right: 25px;

  span{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.appartment-by-id__header-addr {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
  font-family: 'Travels', sans-serif;

}

.appartment-by-id__header-in{
  display: flex;
  justify-content: space-between;
  padding-bottom: 39px;
  border-bottom: 1px solid #d9d9d9;

  @media (max-width: $resolution-1300) {
    /*display: block;

    & > div:first-child{
      margin-bottom: 30px;
    }*/
  }

  @media (max-width: $resolution-1000) {
    display: block;
  }
}

.appartment-by-id__header-in-last{
  display: flex;
  align-items: center;

  & > div:first-child{
    display: flex;
    justify-content: flex-end;
  }
}

.appartment-by-id__header-price{
  font-family: 'Travels';
  font-weight: 500;
  color: #000;
  font-size: 24px;
  line-height: 1;
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  white-space: nowrap;

  &.underline{
    color: #808080;
  }

  &.underline:after{
    content: '';
    display: block;
    left: 0;
    right: 0;
    top: 50%;
    position: absolute;
    background: #808080;
    height: 1px;
  }

  &.percent{
    font-weight: 600;
  }

  &.noline{
    color: #000;
    font-weight: 600 !important;
    &:after{
      display: none;
    }
  }
}

.appartment-by-id__header-price-percent{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $color-red;
  color: #fff;
  font-size: 12px !important;
  right: -26px;
  top: 50%;
  margin-top: -10px;
  cursor: pointer;
  font-family: sans-serif !important;

  span{
    display: block;
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%) scale(0.8);
    padding: 15px 20px;
    background: #fff;
    font-family: "Travels";
    font-size: 12px;
    color: #000;
    box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
    white-space: nowrap;
    margin-right: 5px;
    pointer-events: none;
    font-weight: 400;
    transition: $animation-speed opacity, $animation-speed visiblity, $animation-speed transform;
    opacity: 0;
    visibility: hidden;
  }

  @media (min-width: 1000px){
    &:hover span {
        opacity: 1;
        visibility: visible;
        transform: translateY(-50%) scale(1);
    }
  }
}

.appartment-by-id__header-calc{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Travels';
  font-weight: 500;
  font-size: 14px;
  line-height: 1;

  svg{
    width: 12px;
    height: auto;
    margin-right: 8px;
  }
}
.appartment-by-id__header-button-wrap{
  display: flex;

}
.appartment-by-id-video-call-wrap{
  width: 30%;
}
.appartment-by-id__header-buuton_video_call{
  text-align: center;
  padding: 14px 11px 14px 11px;
}

.appartment-by-id__header-button{
  padding: 14px 11px 14px 19px;
  border: 2px solid #000;
  margin-left: 25px;
  cursor: pointer;
  font-family: 'Travels';
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  transition: $animation-speed background, $animation-speed color, $animation-speed opacity;

  @media (min-width: $resolution-1000) {
    &:hover{
      background: #000;
      color: #fff;
      opacity: 0.7;
    }
  }

  & > div:last-child{
    width: 17px;
    height: 17px;
    min-width: 17px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid $color-red;
    margin-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after{
      content: '';
      display: block;
      width: 1px;
      height: 9px;
      background: $color-red;
    }
  }

  &--red{
    background: $color-red;
    color: #fff;
    border-color: $color-red;
    & > div:first-child{border-bottom: 1px solid transparent; transition: 300ms;}
    & > div:last-child{border-color: #fff; &:after{background: #fff;}}
    @media (min-width: $resolution-1000) {
      &:hover{
        background: $color-red;
        opacity: 1;
        & > div:first-child{border-bottom-color: #fff;}
      }
    }
  }
}
.appartment-by-id__header-buuton_video_call{
  text-align: center;
  padding: 14px 21px 14px 21px;
}

.appartment-by-id__header-bottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 26px;

  @media (max-width: $resolution-1000) {
    display: none;
  }
}

.appartment-by-id__header-bottom-icons{
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg{
    width: 100%;
    height: 100%;
    transition: $animation-speed transform;
  }
  .add-favorite {
    svg{
      width: calc(100% - 10px);
      height: calc(100% - 10px);
    }
  }

  div, a{
    cursor: pointer;
    margin-left: 17px;
    position: relative;

    span{
      display: block;
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%) scale(0.8);
      padding: 15px 20px;
      background: #fff;
      font-family: "Travels";
      font-size: 12px;
      color: #000;
      box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
      white-space: nowrap;
      margin-right: 5px;
      pointer-events: none;
      font-weight: 400;
      transition: $animation-speed opacity, $animation-speed visiblity, $animation-speed transform;
      opacity: 0;
      visibility: hidden;
    }

    @media (min-width: $resolution-1000) {
      &:hover span{
        opacity: 1;
        visibility: visible;
        transform: translateY(-50%) scale(1);
      }

      &:hover svg{
        transform: scale(1.1);
      }
    }
  }

  & > div:nth-child(1),
  & > a:nth-child(1){
    width: 25px;
    height: 19px;
    z-index: 2;
  }
  & > div:nth-child(2),
  & > a:nth-child(2){
    width: 18px;
    height: 18px;
    z-index: 2;
  }

  & > div:nth-child(3),
  & > a:nth-child(3){
    width: 24px;
    height: 24px;
    z-index: 3;
  }

  & > div:nth-child(4),
  & > a:nth-child(4){
    width: 21px;
    height: 21px;
    z-index: 4;
  }

  & > div:nth-child(5),
  & > a:nth-child(5){
    width: 18px;
    height: 20px;
    z-index: 5;
  }
}

.appartment-by-id__header-bottom-menu{
  display: flex;
  align-items: center;
  font-family: "Travels";
  font-weight: 500;
  font-size: 18px;

  @media (max-width: $resolution-1400) {
    font-size: 17px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 16px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 15px;
  }

  div,
  a{
    color: #000;
    cursor: pointer;
    margin-right: 75px;
    text-decoration: underline;

    &.active{
      text-decoration: none;
    }
    &.none {
      display: none;
    }

    @media (max-width: $resolution-1400) {
      margin-right: 55px;
    }

    @media (max-width: $resolution-1300) {
      margin-right: 45px;
    }

    @media (max-width: $resolution-1200) {
      margin-right: 25px;
    }

    &:last-child{
      margin-right: 0;
    }

    @media (min-width: $resolution-1000) {
      &:hover{
        text-decoration: none;
      }
    }
  }
}

.appartment-by-id__row{
  display: flex;
  justify-content: space-between;

  @media (max-width: $resolution-1000) {
    display: block;
  }

  & > div:first-child{
    width: 71%;
    max-width: 71%;

    @media (max-width: $resolution-1000) {
      width: auto;
      max-width: 100%;
    }
  }

  & > div:last-child{
    width: 25%;
    max-width: 25%;

    @media (max-width: $resolution-1000) {
      width: auto;
      max-width: 100%;
    }
  }
}

.appartment-by-id__photo-menu{
  font-family: "Travels";
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  line-height: 1;

  @media (max-width: $resolution-1400) {
    font-size: 19px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 17px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 16px;
  }

  @media (max-width: $resolution-1000) {
    flex-wrap: wrap;
    font-size: 18px;
  }

  div{
    margin-right: 49px;
    position: relative;
    cursor: pointer;

    @media (max-width: $resolution-1400) {
      margin-right: 40px;
    }

    @media (max-width: $resolution-1300) {
      margin-right: 35px;
    }

    @media (max-width: $resolution-1200) {
      margin-right: 30px;
    }

    @media (max-width: $resolution-1000) {
      margin-right: 30px;
      margin-bottom: 10px;
    }

    &:after{
      content: '';
      display: block;
      left: 0;
      bottom: -7px;
      right: 0;
      height: 1px;
      border-bottom: 1px dashed #a6a6a6;
      position: absolute;
      transition: $animation-speed opacity;

      @media (max-width: $resolution-1000) {
        bottom: -5px;
      }
    }

    @media (min-width: $resolution-1000) {
      &:hover:after{
        opacity: 0;
      }
    }
  }

  .active{
    color: $color-red;
    font-size: 30px;

    @media (max-width: $resolution-1400) {
      font-size: 28px;
    }

    @media (max-width: $resolution-1300) {
      font-size: 26px;
    }

    @media (max-width: $resolution-1200) {
      font-size: 24px;
    }

    @media (max-width: $resolution-1000) {
      font-size: 24px;
    }

    &:after{
      display: none;
    }
  }
}
.appartment-by-id__column-info-price{
  text-align: left;
  padding-bottom: 25px;
  padding-left: 34px;
  .appartment-by-id__header-price{
    font-size: 24px;
    font-weight: 600;
  }
  .appartment-by-id__header-price-wrap-first,
  .appartment-by-id__header-calc{
    justify-content: flex-start;
  }
}
.appartment-by-id__column-info-box{
  font-family: "Travels";
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 25px;
  padding-left: 34px;

  div{
    font-size: 24px;
    font-weight: 600;
  }
}

.appartment-by-id__column-info-line{
  height: 1px;
  background: #ebebeb;
  margin-top: 10px;
  margin-bottom: 35px;

  @media (max-width: $resolution-1000) {
    display: none;
  }
}

.appartment-by-id__column-mobile{
  @media (max-width: $resolution-1000) {
    display: flex;
    padding: 0 40px;
    justify-content: space-between;

    & > div{
      width: 48%;
    }

    .appartment-by-id__column-info-box{
      padding-left: 0;
    }

    .appartment-by-id__column-info-box div{
      font-size: 18px;
    }
  }
}

.appartment-by-id__photo-wrapper{
  @media (max-width: $resolution-1000) {
    padding: 0;
    .appartment-by-id__row-info-slide{
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }

    .slick-arrow{
      margin-top: -10px;

     svg {
        height: 42px !important;
      }
    }
  }
}

.appartment-by-id__row-info-slide{
  background: #f2f2f2;
  height: 682px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg{
    width: 80px;
    height: auto;
  }

  path{
    fill: #cdcdcd;
  }

  @media (max-width: $resolution-1000) {
    height: 230px;
    margin-bottom: 20px;
  }

  img{
    display: block;
    max-width: 90%;
    max-height: 90%;
  }

}

.appartment-by-id__row-info{
  margin-bottom: 20px;
  align-items: center;

  @media (max-width: $resolution-1000) {
    margin-bottom: 0;
  }

  .slick-arrow:before{
    display: none;
  }

  .slick-arrow{
    svg{
      width: 23px;
      height: 62px;
    }

    path{
      fill: #7b7b7b;
    }
  }

  .slick-prev{
    left: 29px;
  }

  .slick-next{
    right: 29px;
  }
}

.appartment-by-id__row-info-slide-prev{
  width: 100%;
  height: 155px;
  background: #f2f2f2;
  box-sizing: border-box;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  div{
    display: flex;
    flex: 1;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    transition: $animation-speed opacity;
  }
}

.slick-current .appartment-by-id__row-info-slide-prev{
  div{
    opacity: 1;
  }
}

.appartment-by-id__row-slider-preview{
  margin-bottom: 40px;
  height: 155px;

  .slick-list{
    box-sizing: border-box;
  }
}

.appartment-by-id__table{
  padding-top: 40px;

  @media (max-width: $resolution-1000) {
    border-top: 1px solid #e6e6e6;
    padding-top: 25px;
  }
}

.appartment-by-id__table > div{
  display: flex;
  font-family: "Travels";
  font-weight: 500;
  font-size: 16px;
  flex-wrap: wrap;

  @media (max-width: $resolution-1000) {
    justify-content: space-between;
  }

  .appartment-by-id__table-box{
    div{
      font-weight: 700;
      padding-top: 5px;

      @media (max-width: $resolution-1000) {
        font-weight: 600;
      }
    }
  }

  &:first-child{

    & > div{
      padding-bottom: 25px;
    }

    .appartment-by-id__table-box{
      div{
        font-size: 24px;
        font-weight: 600;
        padding-top: 0;

        @media (max-width: $resolution-1000) {
          font-size: 18px;
        }
      }
    }
  }
}

.appartment-by-id__table-last{
  border-top: 1px solid #e6e6e6;
  padding-top: 30px;
}

.appartment-by-id__table > div > div{
  width: 25%;
  padding-bottom: 30px;

  @media (max-width: $resolution-1000) {
    width: 48%;
  }
}

.appartment-by-id__seller{
  padding: 20px 30px;
  border: 1px solid #e6e6e6;
  position: relative;
  margin-bottom: 20px;

  @media (max-width: $resolution-1400) {
    padding: 20px;
  }

  @media (max-width: $resolution-1300) {
    padding: 20px;
  }

  @media (max-width: $resolution-1200) {
    padding: 20px;
  }

  @media (max-width: $resolution-1000) {
    padding: 15px
  }

  &:after{
    content: '';
    display: block;
    width: 4px;
    top: 20px;
    bottom: 20px;
    left: -4px;
    position: absolute;
    background: $color-red;
  }
}

.appartment-by-id__seller-title{
  font-family: "Travels";
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 20px;

  @media (max-width: $resolution-1200) {
    font-size: 20px;
    margin-bottom: 15px;
  }

}

.appartment-by-id__seller-info{
  display: flex;
  align-items: center;
  font-size: 16px;

  @media (max-width: $resolution-1200) {
    font-size: 14px;
  }

  @media (max-width: $resolution-1000) {
    font-size: 14px;
  }

  a{
    display: block;
    margin-top: 11px;

    @media (min-width: $resolution-1000) {
      &:hover{
        text-decoration: none;
      }
    }
  }

  .no-under{
    text-decoration: none;
  }
}

.appartment-by-id__seller-info-img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 15px;

  @media (max-width: $resolution-1200) {
    margin-right: 10px;
    width: 40px;
    height: 40px;
  }
}

.appartment-by-id__military{
  padding: 60px 40px;
  background: #f4f4f4;
  color: $color-red;
  font-family: "Travels";
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 20px;

  &:after{
    content: '';
    display: block;
    width: 4px;
    top: 35px;
    bottom: 35px;
    left: -4px;
    position: absolute;
    background: $color-red;
  }
}

.appartment-by-id__row-info-table{
  margin-bottom: 40px;
}

.appartment-by-id__header-wrap{
  padding-bottom: 70px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 95px;

  @media (max-width: $resolution-1000) {
    padding-bottom: 0;
    border: none;
    margin-bottom: 40px;
  }
}

.appartment-by-id__desc{
  position: relative;
  padding-top: 35px;
  display: flex;
  font-size: 20px;
  line-height: 1.5;
  justify-content: space-between;

  @media (max-width: $resolution-1400) {
    font-size: 19px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 18px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 17px;
  }

  @media (max-width: $resolution-1000) {
    display: block;
    font-size: 16px;
    line-height: 1.5;
    padding-top: 0;
  }

  & > div{
    width: 47%;

    &.appartment-by-id__desc-single{
      width: auto;
    }

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }

  p{
    margin: 0 0 30px 0;

    @media (max-width: $resolution-1000) {
      margin-bottom: 25px;
    }

    &:last-child{
      margin-bottom: 0;

      @media (max-width: $resolution-1000) {
        margin-bottom: 0;
      }
    }
  }

  &:after{
    content: '';
    display: block;
    width: 140px;
    height: 5px;
    background: $color-red;
    position: absolute;
    left: 0;
    top: 0;

    @media (max-width: $resolution-1000) {
      display: none;
    }
  }
}

.appartment__infra{
  .title{
    margin-bottom: 45px;

    @media (max-width: $resolution-1000) {
      margin-bottom: 20px;
    }
  }
}

.appartment__infra-map-wrap{
  position: relative;
  margin-bottom: 50px;

  @media (max-width: $resolution-1000) {
    margin-bottom: 20px;
  }
}

#appartment__infra-map{
  height: 700px;
  @media (max-width: $resolution-1000) {
    height: 343px;
  }
}

.appartment__infra-map-list{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  position: absolute;
  z-index: 3;
  top: 40px;
  right: 40px;
  bottom: 40px;
  padding: 60px 40px 40px 40px;
  width: 350px;
  box-sizing: border-box;

  @media (max-width: $resolution-1000) {
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.95);
    display: block;
    width: auto;
    padding: 40px;
    position: relative;

    .btn{
      margin-top: 20px;
    }
  }
}

.appartment__infra-map-items{
  overflow-x: hidden;
  overflow-y: auto;
}

.appartment__infra-map-item{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  margin-left: 20px;

  svg{
    width: 42px;
    height: 42px;
    margin-right: 10px;
    transition: $animation-speed transform;
  }

  @media (min-width: $resolution-1000) {
    &:hover svg{
      transform: scale(1.2);
    }

    &:hover{
      text-decoration: underline;
    }
  }

  circle{
    fill: #f2f2f2;
    transition: $animation-speed fill;
  }

  path{
    fill: #b5b5b5;
    transition: $animation-speed fill;
  }

  &.active{
    circle{
      fill: $color-red;
    }

    path{
      fill: #fff;
    }
  }
}

.appartment-by-id-news-feed{
  padding-bottom: 95px;

  .title{
    margin-bottom: 30px;
  }
}

.appartment__infra-map-dsc{
  display: flex;
  font-size: 20px;
  line-height: 1.5;
  justify-content: space-between;
  margin-bottom: 60px;

  @media (max-width: $resolution-1400) {
    font-size: 19px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 18px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 17px;
  }

  @media (max-width: $resolution-1000) {
    display: block;
    font-size: 16px;
  }

  & > div{
    width: 47%;

    @media (max-width: $resolution-1000) {
      width: auto;

      &:first-child{
        margin-bottom: 40px;
      }
    }
  }

  & > div > div{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $resolution-1000) {
      display: block;
    }

    p{
      width: 47%;
      position: relative;
      padding-top: 25px;

      @media (max-width: $resolution-1000) {
        width: auto;
        padding-top: 20px;
      }

      &:after{
        content: '';
        display: block;
        height: 5px;
        top: 0;
        left: 0;
        background: $color-red;
        position: absolute;
      }
    }

    p:after{
      width: 30%;
    }

    p:nth-child(3n+2):after{
      width: 40%;
    }

    p:nth-child(odd):after{
      width: 35%;
    }

    p:nth-child(even):after{
      width: 45%;
    }

    p:nth-child(3n+3):after{
      width: 15%;
    }
  }

  p {
    margin: 0 0 30px 0;
  }

  ul{
    list-style: none;
    margin: 0;
    padding: 0;

    li{
      position: relative;
      padding-left: 30px;

      &:after{
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        background: $color-red;
        position: absolute;
        left: 0;
        top: 12px;
      }
    }
  }

  h3{
    font-family: "Travels";
    font-weight: 500;
    margin: 0 0 45px 0;
    font-size: 24px;
    line-height: 1.2;

    @media (max-width: $resolution-1000) {
      font-size: 22px;
      margin-bottom: 20px;
    }
  }
}

.appartment-menu-fixed{
  position: fixed;
  left: 0;
  top: 80px;
  right: 0;
  background: #f2f2f2;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-100%);
  transition: 500ms opacity, 500ms visibility, 500ms transform;

  &.scrolldown {
    top:0;
    z-index: 100;
  }
  @media (max-width: $resolution-1000) {
    display: none;
  }

  &.active{
    transition-delay: 300ms;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    box-shadow: 0 0 29px rgb(0,0,0,0.1);
  }

  .appartment-by-id__header-calc path{
    fill: #000;
  }

  .appartment-by-id__header-price{
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 7px;

    @media (max-width: $resolution-1400) {
      font-size: 19px;
    }

    @media (max-width: $resolution-1300) {
      font-size: 18px;
    }

    @media (max-width: $resolution-1200) {
      font-size: 17px;
      white-space: nowrap;
    }

    &.percent{
      font-weight: 600;
      padding-right: 26px;

      & > span{
        margin-top: -9px;
        right: 0;
      }
    }
  }
}

.appartment-menu-fixed-button{
  padding: 12px 3px 12px 0;
  cursor: pointer;
  font-family: "Travels";
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 20px;

  &:after,
  &:before{
    content: '';
    display: block;
    left: 0;
    right: 0;
    height: 2px;
    background: #000;
    position: absolute;
    transition: $animation-speed background;
  }

  &:after{
    top: 0;
  }

  &:before{
    bottom: 0;
  }

  & > div:last-child{
    width: 17px;
    height: 17px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid #000;
    margin-left: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $animation-speed border-color;

    &:after{
      content: '';
      display: block;
      width: 1px;
      height: 9px;
      background: #000;
      transition: $animation-speed background;
    }
  }

  @media (min-width: $resolution-1000) {
    &:hover:after,
    &:hover:before{
      background: $color-red;
    }

    &:hover > div:last-child{
      border-color: $color-red;
    }

    &:hover > div:last-child:after{
      background: $color-red;
    }
  }
}

.appartment-menu-fixed__in{
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div:first-child{
    width: 81%;
  }
}

.appartment-menu-fixed__top{
  display: flex;
  justify-content: space-between;
  padding: 20px 0 15px 0;
  border-bottom: 1px solid #d9d9d9;
}

.appartment-menu-fixed__addr{
  font-family: "Travels";
  font-weight: 500;
  font-size: 22px;
  line-height: 1;
  margin-bottom: 8px;
}

.appartment-menu-fixed__name{
  color: #909090;
  font-family: "Travels";
  font-weight: 500;
  font-size: 14px;
  line-height: 1;

  &.disabled{
    text-decoration: none;
    cursor: default;
  }

  @media (min-width: $resolution-1000) {
    &:hover{
      text-decoration: none;
    }
  }
}

.appartment-menu-fixed__top-right{
  display: flex;

  & > div{
    padding-left: 25px;
  }
}

.appartment-menu-fixed__priceup{
  color: $color-red;
  font-family: "Travels";
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  text-align: right;
  margin-right: 75px;
  position: relative;
  top: -20px;
  white-space: nowrap;

  div{
    font-size: 25px;
    font-weight: 600;
    padding-bottom: 2px;

    @media (max-width: $resolution-1400) {
      font-size: 23px;
    }

    @media (max-width: $resolution-1000) {
      font-size: 22px;
    }
  }
}

.appartment-menu-fixed__menu{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:  14px 0;
  font-family: "Travels";
  font-weight: 500;
  font-size: 16px;
  line-height: 1;

  .none {
    display: none;
  }
  @media (max-width: $resolution-1400) {
    font-size: 15px;
    padding:  10px 0;
  }

  @media (max-width: $resolution-1300) {
    font-size: 14px;
    padding:  8px 0;
  }

  @media (max-width: $resolution-1200) {
    font-size: 13px;
    padding:  10px 0;
  }
}

.appartment-menu-fixed__menu-item{
  text-decoration: underline;
  cursor: pointer;
  color: #000;

  &.active{
    text-decoration: none;
  }

  @media (min-width: $resolution-1000) {
    &:hover{
      text-decoration: none;
    }
  }
}

.appartment-by-id__header-name{
  color: #909090;
  font-family: "Travels";
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  &.noline{
    text-decoration: none;
    color: #000;
  }

  &.disabled{
    cursor: default;
    text-decoration: none;
  }

  @media (min-width: $resolution-1000) {
    &:not(.disabled):hover{
      text-decoration: none;
    }
  }
}

.appartment-by-id__header-price-wrap{
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .appartment-by-id__header-price{
    margin-bottom: 0;
  }

  .appartment-menu-fixed__priceup{
    position: relative;
    top: 7px;
    div{
      font-size: 24px;
    }
  }
}

.appartment-menu-fixed{
  .appartment-menu-fixed__priceup{
    top: 0px;
    margin-right: 0;
    font-size: 13px;

    @media (max-width: $resolution-1300) {
      font-size: 12px;
    }
    div{
      font-size: 24px;

      @media (max-width: $resolution-1400) {
        font-size: 23px;
      }

      @media (max-width: $resolution-1300) {
        font-size: 22px;
      }

      @media (max-width: $resolution-1200) {
        font-size: 20px;
      }
    }
  }
}

.appartment-by-id__header-price-sep{
  width: 38px;
  height: 1px;
  background: $color-red;
  margin: 0 17px;
}

.appartment-by-id__header-price-wrap-first{
  display: flex;
  justify-content: flex-end;
}

.apartment-mortgage-programm{
  padding-bottom: 90px;

  @media (max-width: $resolution-1000) {
    padding-bottom: 50px;
  }
  .title{
    margin-bottom: 30px;
  }
}

.apartment-mortgage-programm-more{
  border-bottom: 1px solid #000;
  cursor: pointer;
  transition: $animation-speed border-color;

  @media (min-width: $resolution-1000) {
    &:hover{
      border-color: transparent;
    }
  }
}

.apartment-mortgage-programm__table{
  font-family: "Travels";
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  border-collapse: collapse;

  @media (max-width: $resolution-1000) {
    font-size: 13px;

    tr:first-child td{
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .more td{
    border-bottom: none;
  }

  td{
    vertical-align: middle;
    padding: 0 25px;
    height: 90px;
    border-bottom: 1px solid #e6e6e6;
    text-align: center;

    @media (max-width: $resolution-1000) {
      padding: 5px;
    }
  }

  td:nth-child(2),
  td:nth-child(3){
    text-align: left;
  }

  tr:first-child td{
    height: 46px;
    color: #808080;
    font-size: 12px;
  }

  img{
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
}

.apartment-mortgage-programm__button{
  margin-top: 79px auto 0 auto;
  max-width: 300px;
}

.apartment-about{
  .title{
    margin-bottom: 30px;

    @media (max-width: $resolution-1000) {
      margin-bottom: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  @media (max-width: $resolution-1000) {
    .frame{
      padding: 0;
    }
  }
}

.apartment-about__in{
  display: flex;
  min-height: 682px;
  margin-bottom: 135px;

  @media (max-width: $resolution-1000) {
    display: block;
    margin: 0 0 70px 0;
    min-height: 0;
  }

  .btn{
    width: 70%;
    margin: 0 auto;

    @media (max-width: $resolution-1000) {
      width: auto;
    }
  }

  ul.apartment-about__list{
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 65px;

    @media (max-width: $resolution-1400) {
      font-size: 19px;
    }

    @media (max-width: $resolution-1300) {
      font-size: 18px;
    }

    @media (max-width: $resolution-1200) {
      font-size: 17px;
    }

    @media (max-width: $resolution-1000) {
      font-size: 16px;
      margin-bottom: 50px;
    }

    li{
      margin-bottom: 25px;
      position: relative;
      padding-left: 30px;

      @media (max-width: $resolution-1000) {
        margin-bottom: 20px;
      }

      &:after{
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        position: absolute;
        left: 0;
        top: 13px;
        background: $color-red;
      }
    }
  }

  & > div:first-child{
    position: relative;
    width: 72%;

    @media (max-width: $resolution-1400) {
      width: 70%;
    }

    @media (max-width: $resolution-1300) {
      width: 68%;
    }

    @media (max-width: $resolution-1200) {
      width: 66%;
    }

    @media (max-width: $resolution-1000) {
      width: auto;
      background: #f2f2f2;
      padding: 40px;

      &:after{
        content: '';
        display: block;
        left: 0;
        top: 0;
        right: 0;
        height: 5px;
        background: $color-red;
        position: absolute;
      }
    }
  }
  & > div:last-child{
    position: relative;
    width: 28%;
    background: #f2f2f2;
    position: relative;
    padding: 2.6%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $resolution-1400) {
      width: 30%;
    }

    @media (max-width: $resolution-1300) {
      width: 32%
    }

    @media (max-width: $resolution-1200) {
      width: 34%;
    }

    @media (max-width: $resolution-1000) {
      width: auto;
      background: none;
      height: 375px;
      padding: 0;
    }

    &:after{
      content: '';
      display: block;
      left: 0;
      top: 0;
      right: 0;
      height: 5px;
      background: $color-red;
      position: absolute;

      @media (max-width: $resolution-1000) {
        display: none;
      }
    }
  }

  .slick-arrow{
    width: 22px;
    height: 62px;
    &:after,
    &:before{
      display: none;
    }

    svg{
      width: 100%;
      height: 100%;
    }
  }

  .slick-prev{
    left: 30px;
  }

  .slick-next{
    right: 30px;
  }

  .slick-slider{
    &:after{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 150px;
      background: linear-gradient(90deg, rgba(100,64,31,0.3) 0%, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
    }

    &:before{
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 150px;
      background: linear-gradient(90deg, rgba(100,64,31,1) 0%, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
    }
  }

  .slick-dots{
    position: absolute;
    left: 0;
    right: 0;
    bottom: -26px;
  }
}

.apartment-about__slide{
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 100%;
  position: relative;
}

.appartment-by-id div[data-id="apartment-calc"] .frame,
.appartment-by-id div[data-id="similar-appartments"] .frame,
.appartment-by-id div[data-id="events"] .frame{
  @media (max-width: $resolution-1000){
    padding: 0;
  }
}
.appartment-by-id div[data-id="apartment-calc"] .frame .title {
  @media (max-width: $resolution-1000){
    padding-left: 40px;
    padding-right: 40px;
  }
}

.appartment-by-id div[data-id="similar-appartments"]{
  .apartment-search__slider{
    @media (max-width: $resolution-1000){
      margin-bottom: 70px;
    }
  }
}

.appartment-by-id div[data-id="events"]{
  @media (max-width: $resolution-1000){
    padding-bottom: 40px;
  }
}

.appartment-by-id div[data-id="events"] .news-feed__item{
  @media (max-width: $resolution-1000){
    padding: 0 40px;
    width: auto;
  }
}

.appartment-by-id div[data-id="events"] .title{
  @media (max-width: $resolution-1000){
    padding: 0 40px;
  }
}

.apartment-calc{
  background: #f2f2f2;
  display: flex;
  padding: 3.29%;
  margin-bottom: 95px;

  @media (max-width: $resolution-1000){
    display: block;
    padding: 0;
    margin-bottom: 40px;

    .rc-slider-handle {
      width: 22px !important;
      height: 22px !important;
    }

    .ui-range-slider-labels{
      bottom: -10px;
    }

    .apartment-calc__field .rc-slider-track{
      left: -11px !important;
    }
  }

  & > div:first-child{
    width: 28%;
    box-sizing: border-box;
    padding-right: 3.29%;
    box-sizing: border-box;

    @media (max-width: $resolution-1000){
      width: auto;
      padding: 40px;
    }

    .btn{
      width: 73%;
      margin-top: 65px;

      @media (max-width: $resolution-1000){
        width: auto;
      }
    }
  }

  & > div:last-child{
    width: 72%;

    @media (max-width: $resolution-1000){
      width: auto;
    }
  }
}

.apartment-calc__field{
  margin-bottom: 40px;

  .ui-select .MuiInputBase-root{
    width: 100%;
    max-width: 100%;
  }
}

.apartment-calc__field{
  .rc-slider-track{
    left: -5px !important;
  }

  input{
    color: #000;
  }

  .filter-field__body {
    padding: 10px 17px 15px 17px;
    height: 52px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
}

.apartment-calc__title{
  font-family: "Travels";
  font-weight: 500;
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 45px;

  @media (max-width: $resolution-1000){
    font-size: 21px;
    line-height: 1.2;
    position: relative;
    z-index: 3;
  }
}

.apartment-calc__result-hidden{
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f3f3f3;

  @media (max-width: $resolution-1000){
    text-align: center;
    font-size: 14px;
  }

  &:last-child{
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  span{
    color: grey;
  }
}

.apartment-calc__result{
  background: #fff;
  height: 100%;
  position: relative;
  padding: 4.9%;
  box-sizing: border-box;

  @media (max-width: $resolution-1000){
    padding: 45px 40px;
  }

  .more + tr{
    td{
      border-top: 0;
      height: auto;
      text-align: center;
      padding-bottom: 40px;
    }
  }

  .btn{
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;

    @media (min-width: $resolution-1000){
      &.appartment-by-id__header-button--red {
        &:hover {
          span {
            border-color: #fff;
          }
        }
      }
    }
    @media (max-width: $resolution-1000){
      display: block;
      font-size: 14px;
    }
  }

  &:after{
    content: '';
    display: block;
    left: 0;
    top: 0;
    width: 5px;
    height: 300px;
    background: $color-red;
    position: absolute;

    @media (max-width: $resolution-1000){
      display: none;
    }
  }

  .apartment-calc__table {
    margin-bottom: 35px;
    @media (min-width: $resolution-1000){
      //max-height: 398px;
      //overflow: auto;
    }
    @media (max-width: $resolution-1000){
      overflow-x: auto;
    }
  }
  table{
    border-collapse: collapse;
    width: 100%;

    thead{
      font-weight: 500;

      td{
        font-size: 12px;
        color: #808080;
        padding-bottom: 5px;
        background: #fff;
        height: auto;
        text-align: center;
        border: none;
        padding-top: 0;
      }
    }


    @media (max-width: $resolution-1000){
      margin-bottom: 50px;
    }

    td{
      height: 125px;
      padding: 0 25px;
      font-family: "Travels";
      font-weight: 500;
      vertical-align: middle;
      font-size: 16px;
      border-top: 1px solid #e6e6e6;
      text-align: center;

      @media (max-width: $resolution-1440) {
        padding: 0 15px;
      }

      @media (max-width: $resolution-1400) {
        padding: 0 20px;
      }

      @media (max-width: $resolution-1300) {
        padding: 0 15px;
      }

      @media (max-width: $resolution-1200) {
        padding: 0 10px;
      }

      @media (max-width: $resolution-1000){
        font-size: 14px;
        padding: 0 5px;
        width: 20%;

        &:first-child{
          padding-left: 0;
        }

        &:last-child{
          padding-right: 0;
          text-align: right;
          width: 40%;
        }
      }
    }

    tr:last-child td{
      border-bottom: 1px solid #e6e6e6;
    }

    img{
      display: block;
      margin: 0 auto;
      max-width: 100px;

      @media (max-width: $resolution-1000){
        margin: 0
      }
    }

    .apartment-calc__result-percent{
      span{
        //text-decoration: underline;
        display: inline-block;
        margin: 0 10px;
        //cursor: pointer;
        @media (max-width: $resolution-1200) {
          margin: 0 5px;
          white-space: nowrap;
        }

        @media (max-width: $resolution-1000){
          text-decoration: none;
        }

        @media (min-width: $resolution-1000) {
          &:hover{
            text-decoration: none;
          }
        }
      }
    }

    .apartment-calc__result-value{
      color: $color-red;
      //min-width: 200px;
      box-sizing: border-box;
      white-space: nowrap;
      &.-right {
        text-align: right;
        padding-right: 0;
      }
      @media (max-width: $resolution-1000){
        min-width: 0;
        white-space: nowrap;
      }
    }

    .apartment-calc__result-more{
      cursor: pointer;
      border-bottom: 1px solid #000;
      transition: $animation-speed border-color;

      @media (min-width: $resolution-1000) {
        &:hover{
          border-color: transparent;
        }
      }
    }
  }
}

.apartment-calc__result-title{
  font-family: "Travels";
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 7px;

  @media (max-width: $resolution-1000) {
    font-size: 24px;
    margin-bottom: 6px;
    line-height: 1.2;
  }

  span{
    color: $color-red;
    font-weight: 600;

    @media (max-width: $resolution-1000) {
      display: block;
    }
  }
}

.apartment-calc__result-subtitle{
  font-family: "Travels";
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 40px;

  @media (max-width: $resolution-1000) {
    line-height: 1.2;
    //margin-bottom: 30px;
  }
}

.appartment-by-id__photo-menu-wrapper{
  justify-content: space-between;
  display: flex;
  margin-bottom: 53px;
  align-items: center;
  @media (max-width: $resolution-1000) {
    margin-bottom: 30px;
    padding: 0 40px;
  }
}

.appartment-by-id__photo-menu-title{
  font-family: "Travels";
  font-weight: 500;
  font-size: 48px;
  margin-right: 20px;

  @media (max-width: $resolution-1400) {
    font-size: 44px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 40px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 36px;
  }

  @media (max-width: $resolution-1000) {
    display: none;
  }
}

.appartment-by-id__promo-slider{
  position: relative;
  display: block;

  a{
    text-decoration: none;
  }

  .slick-dots{
    position: relative;
    top: 15px;

    @media (max-width: $resolution-1000) {
      top: 10px;
    }
  }
}

.appartment-by-id__promo{
  margin-bottom: 20px;
  padding: 30px 40px 50px 40px;
  background: #f4f4f4;
  position: relative;

  @media (max-width: $resolution-1000) {
    padding: 30px 40px;
    margin-bottom: 50px;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 10%;
    position: absolute;
    background: #ed1c24;

    @media (max-width: $resolution-1000) {
      width: auto;
      right: -40px;
    }
  }
}

.appartment-by-id__promo-title{
  color: #ed1c24;
  font-family: "Travels";
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.appartment-by-id__promo-text{
  font-family: "Travels";
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;

  strong{
    font-weight: 600;
    font-size: 18px;
  }
}

.appartment-by-id-mobile-info{
  background: #f2f2f2;
  padding: 40px;
  margin: 30px 0;

  .appartment-by-id__header-price{
    margin-bottom: 13px;
  }

  .appartment-menu-fixed__priceup{
    top: 0;
    margin: 0 0 30px 0;
    text-align: left;
  }

  .appartment-by-id__header-button{
    margin: 0 0 35px 0;
    justify-content: space-between;
    font-size: 16px;
    padding: 23px 18px;

    & > div:last-child{
      min-width: 17px;
    }
  }

  .appartment-by-id__header-title{
    font-size: 26px;
    margin-bottom: 15px;
    cursor: pointer;
  }

  .appartment-by-id__header-metro-wrap{
    display: block;
    margin-bottom: 10px;
  }

  .appartment-by-id__header-metro{
    margin-bottom: 13px;
    margin-right: 0;
    font-size: 16px;
    flex-wrap: wrap;
    line-height: 1.6;
  }

  .appartment-by-id__header-name{
    font-size: 14px;
    margin-bottom: 40px;
  }

  .appartment-by-id__header-bottom-icons{
    justify-content: center;

    & > div:first-child{
      margin-left: 0;
    }
  }
}

.header-mobile.hide + .appartment-by-id__mobile-menu{
  transform: translateY(-150px);
}

.appartment-by-id__mobile-menu{
  padding-top: 25px;
  position: fixed;
  left: 0;
  top: 70px;
  right: 0;
  z-index: 10;
  background: #fff;
  height: 80px;
  box-sizing: border-box;
  transition: $animation-speed transform;

  & > div{
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    padding:0 40px 17px 40px;
  }

  & > div > div{
    margin-right: 20px;
    white-space: nowrap;
    text-decoration: underline;
    font-family: "Travels";
    font-weight: 500;
    font-size: 16px;

    &.active{
      text-decoration: none;
    }

    &:last-child{
      padding-right: 40px;
    }
  }
}

.apartment-calc__result-more-btn{
  margin-right: 20px;

  @media (max-width: $resolution-1000) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.appartment-by-id__header-catalog{
  font-family: "Travels";
  font-weight: 500;
  font-style: 16px;
  margin-bottom: 20px;
  color: #979797;
}

.apartment-search-by-id-infra-baloon-title{
  padding: 0 10px;
}
.appartment-by-id .building-genplan__in .react-transform-wrapper{
  margin: 0 auto !important;
}

.apartment-calc__result table {
  .mort_table_logo {
    padding-left: 0;
    img {
      margin: auto;
      max-height: 40px;
      @media (max-width: $resolution-1000){
      }
    }
  }

  .mort_table_name {
    white-space: normal;
    text-align: left;
  }
  .mort_table_fee {
    white-space: nowrap;
  }

}

.apartment-calc__table_mob {
  border-bottom: 1px solid #D9D9D9;
  margin-bottom: 40px;

}

.ap_calc_mob_item {
  border-top: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
  font-family: 'Travels', sans-serif;

}


.ap_calc_mob_item__top {
  display: flex;
  gap: 20px;
  color: #000;
  /* Date */
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  &>div {
    &:first-child {
      width: 99px;
      min-width: 99px;
    }
  }
  img {
    max-width: 100%;
    max-height: 30px;
    display: block;
    margin: auto;
  }
}

.ap_calc_mob_item__bottom {
  display: flex;
  justify-content: space-between;
  &>div {
    //width: 33.33%;
    &:first-child {
      min-width: 25%;
    }
    &:nth-child(2) {
      min-width: 30%;
    }
  }


}

.ap_calc_mob_item__title {
  color: #828282;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
}
.ap_calc_mob_item__val {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-top: 5px;
  &.-red {
    color: #ED1C24;
  }
}


.app_metro_distance {
  display: inline-flex;
  align-items: center;
  gap: 2px;
  margin-left: 7px;
  white-space: nowrap;
  svg {
    width: 17px;
    height: 20px;
    margin-top: -3px;
  }
}
