@font-face {
    font-family: 'Travels';
    src: local('TT Travels Regular'), local('TT-Travels-Regular'),
        url('/static/fonts/TTTravels/TTTravels-Regular.woff2') format('woff2'),
        url('/static/fonts/TTTravels/TTTravels-Regular.woff') format('woff'),
        url('/static/fonts/TTTravels/TTTravels-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Travels';
    src: local('TT Travels Medium'), local('TT-Travels-Medium'),
        url('/static/fonts/TTTravels/TTTravels-Medium.woff2') format('woff2'),
        url('/static/fonts/TTTravels/TTTravels-Medium.woff') format('woff'),
        url('/static/fonts/TTTravels/TTTravels-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Travels';
    src: local('TT Travels Demi Bold'), local('TT-Travels-Demi-Bold'),
        url('/static/fonts/TTTravels/TTTravels-DemiBold.woff2') format('woff2'),
        url('/static/fonts/TTTravels/TTTravels-DemiBold.woff') format('woff'),
        url('/static/fonts/TTTravels/TTTravels-DemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'Travels';
  src: local('TT Travels Bold'), local('TT-Travels-Bold'),
      url('/static/fonts/TTTravels/tttravels-bold-webfont.woff2') format('woff2'),
      url('/static/fonts/TTTravels/tttravels-bold-webfont.woff') format('woff'),
      url('/static/fonts/TTTravels/tttravels-bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: 'Styrene';
    src: local('Styrene A Web Thin'), local('Styrene-A-Web-Thin'),
        url('/static/fonts/StyreneAWeb/StyreneAWeb-Thin.woff2') format('woff2'),
        url('/static/fonts/StyreneAWeb/StyreneAWeb-Thin.woff') format('woff'),
        url('/static/fonts/StyreneAWeb/StyreneAWeb-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Styrene';
    src: local('Styrene A Web Regular'), local('Styrene-A-Web-Regular'),
        url('/static/fonts/StyreneAWeb/StyreneAWeb-Regular.woff2') format('woff2'),
        url('/static/fonts/StyreneAWeb/StyreneAWeb-Regular.woff') format('woff'),
        url('/static/fonts/StyreneAWeb/StyreneAWeb-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LabGrotesque';
    src: local('Lab Grotesque Regular'), local('Lab-Grotesque-Regular'),
        url('/static/fonts/LabGrotesque/LabGrotesque-Regular.woff2') format('woff2'),
        url('/static/fonts/LabGrotesque/LabGrotesque-Regular.woff') format('woff'),
        url('/static/fonts/LabGrotesque/LabGrotesque-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'icons';
    src: 
      url('/static/fonts/icons.ttf?kn2kde1') format('truetype'),
      url('/static/fonts/icons.woff?kn2kde1') format('woff'),
      url('/static/fonts/icons.svg?kn2kde1#icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="ico-"]:before, [class*=" ico-"]:before, .slick-arrow:before, .checkbox-field__check:before {
  font-family: 'icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-1:beforex{
  content: "\e900";
}
.ico-2:before {
  content: "\e901";
  color: #ed1c24;
}
.ico-3:before {
  content: "\e902";
}
.ico-4:before, .slick-prev:before  {
  content: "\e903";
}
.ico-5:before, .slick-next:before  {
  content: "\e904";
}
.ico-6:before {
  content: "\e905";
}
.ico-7:before {
  content: "\e906";
}
.ico-8:before {
  content: "\e907";
}
.ico-9:before {
  content: "\e908";
}
.ico-10:before {
  content: "\e909";
}
.ico-11:before {
  content: "\e90a";
}
.ico-12:before {
  content: "\e90b";
}
.ico-13:before {
  content: "\e90c";
}
.ico-14:before {
  content: "\e90d";
}
.ico-15:before {
  content: "\e90e";
}
.ico-16:before {
  content: "\e90f";
}
.ico-17:before {
  content: "\e910";
}
.ico-18:before {
  content: "\e911";
}
.ico-19:before, .checkbox-field__check:before {
  content: "\e912";
}
.ico-20:before {
  content: "\e913";
}
.ico-21:before {
  content: "\e914";
}
.ico-22:before {
  content: "\e915";
}
.ico-23:before {
  content: "\e916";
}
.ico-24:before {
  content: "\e917";
}
.ico-25:before {
  content: "\e918";
}
.ico-26:before {
  content: "\e919";
}