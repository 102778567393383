.howbuy__box{
  display: flex;
  position: relative;

  & > div:nth-child(1){
    width: 41%;
    position: relative;
  }

  & > div:nth-child(2){
    width: 18%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  & > div:nth-child(3){
    width: 41%;
    position: relative;
  }
}

.howbuy__line{
  display: block;
  width: 5px;
  position: absolute;
  background: $color-red;

  left: 50%;
  margin-left: 23px;
  height: 314px;
}

.howbuy__box-img{
  background-position: 50% 50%;
  background-size: cover;
}

.howbuy__box1-img{
  height: 584px;

  @media (max-width: $resolution-1400) {
    height: 520px;
  }

  @media (max-width: $resolution-1300) {
    height: 480px;
  }

  @media (max-width: $resolution-1200) {
    height: 400px;
  }
}

.howbuy__numbers{
  height: 247px;
  width: auto;
  display: block;

  @media (max-width: $resolution-1400) {
    height: 220px;
  }

  @media (max-width: $resolution-1300) {
    height: 190px;
  }

  @media (max-width: $resolution-1200) {
    height: 160px;
  }
}

.howbuy__red-title{
  color: $color-red;
  font-family: "Travels";
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 30px;

  @media (max-width: $resolution-1400) {
    font-size: 28px;
    margin-bottom: 25px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 26px;
    margin-bottom: 20px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 24px;
  }
}

.howbuy__title{
  font-family: "Travels";
  font-size: 48px;
  line-height: 1.1;
  font-weight: 500;
  margin-bottom: 40px;
  position: relative;
  left: -3px;

  @media (max-width: $resolution-1400) {
    font-size: 44px;
    margin-bottom: 40px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 40px;
    margin-bottom: 35px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 35px;
    margin-bottom: 30px;
  }
}

.howbuy__text{
  font-size: 20px;
  line-height: 1.5;
  padding-left: 90px;

  @media (max-width: $resolution-1400) {
    font-size: 19px;
    line-height: 1.4;
    padding-left: 70px;
  }

  @media (max-width: $resolution-1300) {
    font-size: 18px;
    padding-left: 50px;
  }

  @media (max-width: $resolution-1200) {
    font-size: 17px;
    line-height: 1.3;
    padding-left: 30px;
  }

  p{
    margin: 0 0 30px 0;

    @media (max-width: $resolution-1200) {
      margin: 0 0 20px 0;
    }
  }

  p:last-child{
    margin: 0;

    @media (max-width: $resolution-1200) {
      margin: 0;
    }
  }

  @media (min-width: $resolution-1000) {
    a:hover{
      text-decoration: none;
    }
  }
}

.howbuy__box1{
  padding-bottom: 288px;

  @media (max-width: $resolution-1400) {
    padding-bottom: 248px;
  }

  @media (max-width: $resolution-1300) {
    padding-bottom: 208px;
  }

  @media (max-width: $resolution-1200) {
    padding-bottom: 168px;
  }

  .howbuy__line{
    bottom: -15px;
    height: 314px;

    @media (max-width: $resolution-1400) {
      height: 274px;
    }
  
    @media (max-width: $resolution-1300) {
      height: 234px;
    }
  
    @media (max-width: $resolution-1200) {
      height: 194px;
    }
  }

  & > div:nth-child(2){
    .howbuy__numbers{
      margin-top: 50%;
    }
  }
}

.howbuy__box2{
  padding-bottom: 262px;
  position: relative;
  z-index: 2;

  @media (max-width: $resolution-1400) {
    padding-bottom: 222px;
  }

  @media (max-width: $resolution-1300) {
    padding-bottom: 182px;
  }

  @media (max-width: $resolution-1200) {
    padding-bottom: 142px;
  }

  .howbuy__line{
    bottom: -103px;
    height: 310px;

    @media (max-width: $resolution-1400) {
      height: 270px;
    }
  
    @media (max-width: $resolution-1300) {
      height: 230px;
    }
  
    @media (max-width: $resolution-1200) {
      height: 190px;
    }
  }

  & > div:nth-child(3){
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $resolution-1300) {
      justify-content: flex-start;
    }
  }
}

.howbuy__box2-img-wrap{
  position: relative;
}

.howbuy__box2-img{
  width: 257px;
  height: 242px;

  @media (max-width: $resolution-1400) {
    width: 237px;
    height: 222px;
  }

  @media (max-width: $resolution-1300) {
    width: 217px;
    height: 202px;
  }

  @media (max-width: $resolution-1200) {
    width: 197px;
    height: 182px;
  }
}

.howbuy__box2-2-img{
  width: 127px;
  height: 120px;
  position: absolute;
  right: -160px;
  top: -135px;
}

.howbuy__box3-img{
  width: 438px;
  height: 438px;

  @media (max-width: $resolution-1400) {
    width: 408px;
    height: 408px;
  }

  @media (max-width: $resolution-1300) {
    width: 378px;
    height: 378px;
  }

  @media (max-width: $resolution-1200) {
    width: 338px;
    height: 338px;
  }
}

.howbuy__box3-wrap{
  position: relative;
}

.howbuy__box3-wrap-bg{
  left: 10%;
  position: absolute;
  top: 0;
  bottom: 140px;
  right: 0;
  background: #eceae4;
}

.howbuy__box3{
  padding-bottom: 260px;
  padding-top: 90px;
  position: relative;
  z-index: 2;

  @media (max-width: $resolution-1400) {
    padding-bottom: 240px;
    padding-top: 70px;
  }

  @media (max-width: $resolution-1300) {
    padding-bottom: 220px;
    padding-top: 50px;
  }

  @media (max-width: $resolution-1200) {
    padding-bottom: 200px;
    padding-top: 30px;
  }

  
  & > div:nth-child(1){
    display: flex;
    align-items: center;
  }

  .howbuy__line{
    bottom: 0px;
    height: 310px;

    @media (max-width: $resolution-1400) {
      height: 280px;
    }
  
    @media (max-width: $resolution-1300) {
      height: 250px;
    }
  
    @media (max-width: $resolution-1200) {
      height: 220px;
    }
  }
}

.howbuy__box4{
  margin-bottom: 96px;

  @media (max-width: $resolution-1400) {
    margin-bottom: 86px;
  }

  @media (max-width: $resolution-1300) {
    margin-bottom: 76px;
  }

  @media (max-width: $resolution-1200) {
    margin-bottom: 66px;
  }

  & > div:nth-child(2){
    align-items: flex-start;
  }

  .howbuy__numbers{
    margin-top: 60px;
  }

  .howbuy__line{
    bottom: -12px;
    height: 310px;

    @media (max-width: $resolution-1400) {
      height: 270px;
    }
  
    @media (max-width: $resolution-1300) {
      height: 240px;
    }
  
    @media (max-width: $resolution-1200) {
      height: 200px;
    }
  }
}

.howbuy__box4-img-wrap{
  position: relative;
  height: 670px;

  @media (max-width: $resolution-1400) {
    height: 600px;
  }

  @media (max-width: $resolution-1300) {
    height: 530px;
  }

  @media (max-width: $resolution-1200) {
    height: 460px;
  }
}

.howbuy__box4-img{
  position: absolute;
  left: 90px;
  top: 0;
  right: -4.5vw;
  bottom: 0;

  @media (max-width: $resolution-1400) {
    left: 90px;
  }

  @media (max-width: $resolution-1300) {
    left: 90px;
  }

  @media (max-width: $resolution-1200) {
    left: 90px;
    right: -25px;
  }
}

.howbuy__box5{
  .howbuy__numbers{
    margin-bottom: 35px;
  }
}

.howbuy__box5-wrap{
  position: relative;
  padding-bottom: 190px;

  @media (max-width: $resolution-1400) {
    padding-bottom: 160px;
  }

  @media (max-width: $resolution-1300) {
    padding-bottom: 130px;
  }

  @media (max-width: $resolution-1200) {
    padding-bottom: 100px;
  }

  .howbuy__line{
    bottom: 32px;
    height: 104px;

    @media (max-width: $resolution-1400) {
      height: 70px;
      bottom: 22px;
    }
  
    @media (max-width: $resolution-1300) {
      height: 70px;
      bottom: 12px;
    }
  
    @media (max-width: $resolution-1200) {
      height: 60px;
      bottom: 0px;
    }
  }

  .howbuy__title{
    text-align: center;
    max-width: 44%;
    margin-left: auto;
    margin-right: auto;
  }

  .howbuy__text{
    text-align: center;
    max-width: 44%;
    margin: 0 auto;
    padding-left: 0;
  }
}

.howbuy__box6-img{
  width: 369px;
  height: 369px;

  @media (max-width: $resolution-1400) {
    width: 339px;
    height: 339px;
  }

  @media (max-width: $resolution-1300) {
    width: 309px;
    height: 309px;
  }

  @media (max-width: $resolution-1200) {
    width: 279px;
    height: 279px;
  }
}

.howbuy__box6{
  padding-bottom: 147px;

  @media (max-width: $resolution-1400) {
    padding-bottom: 127px;
  }

  @media (max-width: $resolution-1300) {
    padding-bottom: 107px;
  }

  @media (max-width: $resolution-1200) {
    padding-bottom: 87px;
  }

  .howbuy__numbers{
    position: relative;
    top: -60px;
  }

  .howbuy__line{
    bottom: -80px;
    height: 311px;

    @media (max-width: $resolution-1400) {
      height: 191px;
    }
  
    @media (max-width: $resolution-1300) {
      height: 171px;
    }
  
    @media (max-width: $resolution-1200) {
      height: 151px;
    }
  }

  & > div:nth-child(3){
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.howbuy__box7{
  padding-bottom: 150px;

  @media (max-width: $resolution-1400) {
    padding-bottom: 120px;
  }

  @media (max-width: $resolution-1300) {
    padding-bottom: 90px;
  }

  @media (max-width: $resolution-1200) {
    padding-bottom: 60px;
  }
}

.howbuy__box7-img-wrap{
  position: relative;
  height: 604px;

  @media (max-width: $resolution-1400) {
    height: 574px;
  }

  @media (max-width: $resolution-1300) {
    height: 544px;
  }

  @media (max-width: $resolution-1200) {
    height: 500px;
  }
}

.howbuy__box7-img{
  position: absolute;
  left: -4.5vw;
  top: 0;
  right: 0;
  bottom: 0;

  @media (max-width: $resolution-1200) {
    left: -25px;
  }
}

.howbuy__gsap{
  transform: translateY(100px);

  @media (max-width: $resolution-1000) {
    transform: translateY(0);
  }
}

.howbuy-mobile{
  padding: 50px 0;

  .page-title{
    margin-bottom: 10px;
  }
}

.howbuy-mobile-title{
  padding: 0 40px;
  font-family: "Travels";
  font-weight: 500;
  color: $color-red;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 30px;
}

.howbuy-mobile-subtitle{
  display: flex;
  align-items: center;
  font-family: "Travels";
  font-weight: 500;
  font-size: 26px;
  line-height: 1.1;
  margin-bottom: 50px;

  & > div:first-child{
    width: 130px;
  }
}

.howbuy-mobile-numbers{
  height: 159px;
  width: auto;
  display: block;
  position: relative;
  left: -40px;
  margin: 0 auto;
}

.howbuy-mobile-section{
  position: relative;
  padding: 0 40px 0 80px;
  margin-bottom: 40px;

  &:after{
    content: '';
    display: block;
    width: 5px;
    top: 0;
    bottom: 0;
    left: 30px;
    background: $color-red;
    position: absolute;
    z-index: 3;
  }
}

.howbuy-mobile-section1{
  padding-bottom: 50px;
}

.howbuy-mobile-section2{
  padding-bottom: 50px;
}

.howbuy-mobile-section3{
  padding-bottom: 50px;
}

.howbuy-mobile-section4{
  padding-bottom: 50px;
}

.howbuy-mobile-section5{
  padding-bottom: 10px;
}

.howbuy-mobile-section6{
  padding-bottom: 20px;
}

.howbuy-mobile-section7{
  margin-bottom: 155px;
}

.howbuy-mobile-text{
  font-size: 17px;
  line-height: 1.7;
  margin-bottom: 50px;
}

.howbuy-mobile-img-wrap{
  position: relative;
}

.howbuy-mobile-img-wrap1{
  height: 345px;
}

.howbuy-mobile-img{
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.howbuy-mobile-img1{
  position: absolute;
  left: -65px;
  right: -25px;
  top: 0;
  bottom: 0;
}

.howbuy-mobile-img-wrap2{
  height: 320px;
}

.howbuy-mobile-img2{
  width: 106px;
  height: 100px;
  top: 0;
  right: -25px;
  position: absolute;
}

.howbuy-mobile-img3{
  width: 215px;
  height: 203px;
  left: -65px;
  top: 115px;
  position: absolute;
}

.howbuy-mobile-img-wrap3{
  height: 285px;
}

.howbuy-mobile-img4{
  position: absolute;
  left: -65px;
  top: 0;
  right: 40px;
  bottom: 0;
}

.howbuy-mobile-img-wrap4{
  height: 345px;
}

.howbuy-mobile-img5{
  position: absolute;
  left: -65px;
  top: 0;
  right: -25px;
  bottom: 0;
}

.howbuy-mobile-img-wrap6{
  height: 285px;
}

.howbuy-mobile-img6{
  position: absolute;
  left: -65px;
  top: 0;
  right: 40px;
  bottom: 0;
}

.howbuy-mobile-img-wrap7{
  height: 220px;
}

.howbuy-mobile-img7{
  position: absolute;
  left: -65px;
  top: 0;
  right: -25px;
  height: 345px;
}